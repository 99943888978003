import config from 'config';
import moment from 'moment';
// import { replaceImagesSourceWithBase64 } from '@ckeditor/ckeditor5-paste-from-office/src/filters/image';

const getCommonProps = ({
  isSdkEnabled,
  globalActions,
  intl,
  notificationActions,
  ui,
  uiActions,
}) => ({
  isSdkEnabled,
  globalActions,
  intl,
  notificationActions,
  ui,
  uiActions,
});

const getXHost = () =>
  config.DEBUG ? location.host.replace('.test', '.de') : location.host;

const isAdminHost = () => location.host === config.adminDomain;
const isWorldHost = () => location.host === config.worldDomain;
const isGlobalHost = () => location.host === config.globalDomain;

// when all null, account is being managed with manual invoicing (legacy system)
const isLegacyManagedSubscriptions = user =>
  !user ||
  (!user.get('card_brand') &&
    !user.get('trial_ends_at') &&
    !user.get('stripe_id'));

const shouldShowUpgrade = ({
  paymentMethodExists,
  user,
  subscriptionBillingDate,
  organisationBillableId,
}) =>
  !paymentMethodExists &&
  subscriptionBillingDate &&
  moment(subscriptionBillingDate).isAfter(moment().format('YYYY-MM-DD')) &&
  user &&
  user.get('id') === organisationBillableId;

const triggerDownload = (source, fileName) => {
  const anchor = document.createElement('a');
  anchor.setAttribute('download', fileName);
  anchor.setAttribute('href', source);
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor); // tidy things
  return true;
};

const getDecimalFormat = value => {
  if (value > 0 && value < 1) {
    return 2;
  } else if (value == 0 || value > 1000) {
    return 0;
  }
  return 1;
};

const isJsonString = str => {
  try {
    const json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
};

export {
  getCommonProps,
  getXHost,
  isAdminHost,
  isWorldHost,
  isGlobalHost,
  isLegacyManagedSubscriptions,
  triggerDownload,
  getDecimalFormat,
  shouldShowUpgrade,
  isJsonString,
  // replaceImagesSourceWithBase64,
};
