export const ORGANISATION_BILLING_FETCH_REQUEST =
  'changers/admin/ORGANISATION_BILLING_FETCH_REQUEST';
export const ORGANISATION_BILLING_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_BILLING_FETCH_SUCCESS';
export const ORGANISATION_BILLING_FETCH_FAILURE =
  'changers/admin/ORGANISATION_BILLING_FETCH_FAILURE';

export const BILLING_PLANS_FETCH_REQUEST =
  'changers/admin/BILLING_PLANS_FETCH_REQUEST';
export const BILLING_PLANS_FETCH_SUCCESS =
  'changers/admin/BILLING_PLANS_FETCH_SUCCESS';
export const BILLING_PLANS_FETCH_FAILURE =
  'changers/admin/BILLING_PLANS_FETCH_FAILURE';

export const ORGANISATION_CREDIT_CARD_INITIALIZE_FETCH_REQUEST =
  'changers/admin/ORGANISATION_CREDIT_CARD_INITIALIZE_FETCH_REQUEST';
export const ORGANISATION_CREDIT_CARD_INITIALIZE_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_CREDIT_CARD_INITIALIZE_FETCH_SUCCESS';
export const ORGANISATION_CREDIT_CARD_INITIALIZE_FETCH_FAILURE =
  'changers/admin/ORGANISATION_CREDIT_CARD_INITIALIZE_FETCH_FAILURE';

export const ORGANISATION_SUBSCRIPTION_FETCH_REQUEST =
  'changers/admin/ORGANISATION_SUBSCRIPTION_FETCH_REQUEST';
export const ORGANISATION_SUBSCRIPTION_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_SUBSCRIPTION_FETCH_SUCCESS';
export const ORGANISATION_SUBSCRIPTION_FETCH_FAILURE =
  'changers/admin/ORGANISATION_SUBSCRIPTION_FETCH_FAILURE';

export const ORGANISATION_BILLING_UPDATE_REQUEST =
  'changers/admin/ORGANISATION_BILLING_UPDATE_REQUEST';
export const ORGANISATION_BILLING_UPDATE_SUCCESS =
  'changers/admin/ORGANISATION_BILLING_UPDATE_SUCCESS';
export const ORGANISATION_BILLING_UPDATE_FAILURE =
  'changers/admin/ORGANISATION_BILLING_UPDATE_FAILURE';

export const STRIPE_TOKEN_UPDATE_REQUEST =
  'changers/admin/STRIPE_TOKEN_UPDATE_REQUEST';
export const STRIPE_TOKEN_UPDATE_SUCCESS =
  'changers/admin/STRIPE_TOKEN_UPDATE_SUCCESS';
export const STRIPE_TOKEN_UPDATE_FAILURE =
  'changers/admin/STRIPE_TOKEN_UPDATE_FAILURE';

export const ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_REQUEST =
  'changers/admin/ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_REQUEST';
export const ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_SUCCESS =
  'changers/admin/ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_SUCCESS';
export const ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_FAILURE =
  'changers/admin/ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_FAILURE';

export const ORGANISATION_SUBSCRIPTION_CANCEL_REQUEST =
  'changers/admin/ORGANISATION_SUBSCRIPTION_CANCEL_REQUEST';
export const ORGANISATION_SUBSCRIPTION_CANCEL_SUCCESS =
  'changers/admin/ORGANISATION_SUBSCRIPTION_CANCEL_SUCCESS';
export const ORGANISATION_SUBSCRIPTION_CANCEL_FAILURE =
  'changers/admin/ORGANISATION_SUBSCRIPTION_CANCEL_FAILURE';

export const ORGANISATION_SUBSCRIPTION_DEACTIVATE_REQUEST =
  'changers/admin/ORGANISATION_SUBSCRIPTION_DEACTIVATE_REQUEST';
export const ORGANISATION_SUBSCRIPTION_DEACTIVATE_SUCCESS =
  'changers/admin/ORGANISATION_SUBSCRIPTION_DEACTIVATE_SUCCESS';
export const ORGANISATION_SUBSCRIPTION_DEACTIVATE_FAILURE =
  'changers/admin/ORGANISATION_SUBSCRIPTION_DEACTIVATE_FAILURE';

export const ORGANISATION_SUBSCRIPTION_REACTIVATE_REQUEST =
  'changers/admin/ORGANISATION_SUBSCRIPTION_REACTIVATE_REQUEST';
export const ORGANISATION_SUBSCRIPTION_REACTIVATE_SUCCESS =
  'changers/admin/ORGANISATION_SUBSCRIPTION_REACTIVATE_SUCCESS';
export const ORGANISATION_SUBSCRIPTION_REACTIVATE_FAILURE =
  'changers/admin/ORGANISATION_SUBSCRIPTION_REACTIVATE_FAILURE';

export const ORGANISATION_SUBSCRIPTION_ACTIVATE_REQUEST =
  'changers/admin/ORGANISATION_SUBSCRIPTION_ACTIVATE_REQUEST';
export const ORGANISATION_SUBSCRIPTION_ACTIVATE_SUCCESS =
  'changers/admin/ORGANISATION_SUBSCRIPTION_ACTIVATE_SUCCESS';
export const ORGANISATION_SUBSCRIPTION_ACTIVATE_FAILURE =
  'changers/admin/ORGANISATION_SUBSCRIPTION_ACTIVATE_FAILURE';

export const ADD_PROMO_CODE_REQUEST = 'changers/admin/ADD_PROMO_CODE_REQUEST';
export const ADD_PROMO_CODE_SUCCESS = 'changers/admin/ADD_PROMO_CODE_SUCCESS';
export const ADD_PROMO_CODE_FAILURE = 'changers/admin/ADD_PROMO_CODE_FAILURE';
