import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import {
  donationProjectsListSchema,
  donationProjectSchema,
} from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as DonationProjectsActionTypes from '../../constants/actionTypes/donationProjects';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Challenges
 */
export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case DonationProjectsActionTypes.DONATION_PROJECTS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, donationProjectsListSchema);
      const normalizedDonationProjects = fromJS(
        normalizedFetch.entities.donationProjects
      );

      if (!normalizedDonationProjects) {
        return state;
      }

      return state.merge({
        byId: normalizedDonationProjects,
        result: fromJS(normalizedFetch.result),
      });
    }

    case DonationProjectsActionTypes.DONATION_PROJECTS_ADD_SUCCESS:
    case DonationProjectsActionTypes.DONATION_PROJECTS_UPDATE_SUCCESS: {
      const normalizedUpdate = normalize(data, donationProjectSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.donationProjects))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
