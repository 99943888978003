exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Footer__footer {\n  color: #007d8a !important;\n  font-size: 16px !important;\n  font-weight: normal !important;\n  margin-top: 0px !important;\n  padding: 0px 30px 30px 15px !important; }\n\n.Footer__ml8 {\n  margin-left: 8px; }\n", ""]);

// exports
exports.locals = {
	"footer": "Footer__footer",
	"ml8": "Footer__ml8"
};