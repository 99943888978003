import { defineMessages } from 'react-intl';

export default defineMessages({
  'warning.app.invalid_public_url': {
    id: 'warning.app.invalid_public_url',
    defaultMessage:
      'The domain you used is not registered with our service. You have been redirected to https://admin.changers.com',
    description: 'Public URL is not valid',
  },
  'warning.app.trialExpires': {
    id: 'warning.app.trialExpires',
    defaultMessage:
      'Your trial is about to expire in 2 days. Please upgrade you subscription.',
    description: 'Trial package expires soon',
  },
  'error.app.session.expired': {
    id: 'error.app.session.expired',
    defaultMessage: 'Session expired, please sign in again.',
    description: 'User: Current session expired',
  },
  'error.app.users__currentUser.fetchFailed': {
    id: 'error.app.users__currentUser.fetchFailed',
    defaultMessage: 'Failed to retrieve organisation details.',
    description:
      'Failed to retrieve your current user details from server. Please, try again.',
  },
  'error.app.challenges.fetchFailed': {
    id: 'error.app.challenges.fetchFailed',
    defaultMessage:
      'Failed to retrieve your challenges from server. Please, try again.',
    description: 'Challenges failed to load',
  },
  'error.app.donations.fetchFailed': {
    id: 'error.app.donations.fetchFailed',
    defaultMessage:
      'Failed to retrieve your donations from server. Please, try again.',
    description: 'Donations failed to load',
  },
  'error.app.donationProjects.fetchFailed': {
    id: 'error.app.donationProjects.fetchFailed',
    defaultMessage:
      'Failed to retrieve your donation projects from server. Please, try again.',
    description: 'Donation Projects failed to load',
  },
  'error.app.treeTypes.fetchFailed': {
    id: 'error.app.treeTypes.fetchFailed',
    defaultMessage:
      'Failed to retrieve your tree types from server. Please, try again.',
    description: 'TreeTypes failed to load',
  },
  'error.app.treeTypes.subscriptionUpgrade': {
    id: 'error.app.treeTypes.subscriptionUpgrade',
    defaultMessage: 'Please upgrade your subscription to access tree types.',
    description: 'TreeTypes failed to load',
  },
  'error.app.treePlantings.fetchFailed': {
    id: 'error.app.treePlantings.fetchFailed',
    defaultMessage:
      'Failed to retrieve your tree plantings from server. Please, try again.',
    description: 'TreePlantings failed to load',
  },
  'error.app.treePlantingPartners.fetchFailed': {
    id: 'error.app.treePlantingPartners.fetchFailed',
    defaultMessage:
      'Failed to retrieve your tree planting partners from server. Please, try again.',
    description: 'Tree Planting Partners failed to load',
  },
  'error.app.charities.fetchFailed': {
    id: 'error.app.charities.fetchFailed',
    defaultMessage:
      'Failed to retrieve your charities from server. Please, try again.',
    description: 'Charities failed to load',
  },
  'error.app.charities.subscriptionUpgrade': {
    id: 'error.app.charities.subscriptionUpgrade',
    defaultMessage: 'Please upgrade your subscription to access charities.',
    description: 'Charities failed to load',
  },
  'error.app.dashboards.user.fetchFailed': {
    id: 'error.app.dashboards.user.fetchFailed',
    defaultMessage:
      'Failed to retrieve your user statistics from server. Please, try again.',
    description: 'User statistics failed to load',
  },
  'error.app.user.notAllowed.modify': {
    id: 'error.app.user.notAllowed.modify',
    defaultMessage: "You aren't allowed modifying this user.",
    description: 'User is not allowed to be edited',
  },
  'error.app.dashboards.organisation.fetchFailed': {
    id: 'error.app.dashboards.organisation.fetchFailed',
    defaultMessage:
      'Failed to retrieve your organisation statistics from server. Please, try again.',
    description: 'Organisation statistics failed to load',
  },
  'error.app.activities.fetchFailed': {
    id: 'error.app.activities.fetchFailed',
    defaultMessage:
      'Failed to retrieve your activities from server. Please, try again.',
    description: 'Activities failed to load',
  },
  'error.app.currentUserTeams.fetchFailed': {
    id: 'error.app.currentUserTeams.fetchFailed',
    defaultMessage:
      'Failed to retrieve your current user teams from server. Please, try again.',
    description: 'Current User Teams failed to load',
  },
  'error.app.teamTypes.fetchFailed': {
    id: 'error.app.teamTypes.fetchFailed',
    defaultMessage:
      'Failed to retrieve team types from server. Please, try again.',
    description: 'Team Types failed to load',
  },
  'error.app.teams.fetchFailed': {
    id: 'error.app.teams.fetchFailed',
    defaultMessage: 'Failed to retrieve teams from server. Please, try again.',
    description: 'Teams failed to load',
  },
  'error.app.teams__byTeamType.fetchFailed': {
    id: 'error.app.teams__byTeamType.fetchFailed',
    defaultMessage: 'Failed to retrieve teams from server. Please, try again.',
    description: 'Teams failed to load',
  },
  'error.app.organisations.fetchFailed': {
    id: 'error.app.organisations.fetchFailed',
    defaultMessage:
      'Failed to retrieve all organisations from server. Please, try again.',
    description: 'Organisations failed to load',
  },
  'error.app.organisations__adminDetails.fetchFailed': {
    id: 'error.app.organisations__adminDetails.fetchFailed',
    defaultMessage:
      'Failed to retrieve all organisations from server. Please, try again.',
    description: 'Organisations failed to load',
  },
  'error.app.users.fetchFailed': {
    id: 'error.app.users.fetchFailed',
    defaultMessage:
      'Failed to retrieve all users from server. Please, try again.',
    description: 'Users failed to load',
  },
  'error.app.lotteries.fetchFailed': {
    id: 'error.app.lotteries.fetchFailed',
    defaultMessage:
      'Failed to retrieve lotteries from server. Please, try again.',
    description: 'Lotteries failed to load',
  },
  'error.app.vouchers.fetchFailed': {
    id: 'error.app.vouchers.fetchFailed',
    defaultMessage:
      'Failed to retrieve all vouchers from server. Please, try again.',
    description: 'Vouchers failed to load',
  },
});
