import config from 'config';

/**
 * Async loader of the analytics library in a separate bundle
 * @returns {Promise}
 */
export default () => {
  return import(/* webpackChunkName: "Analytics" */ './analytics.js');
};

/** IMPORTANT: NOTE: ------------------------
 *  -----------------------------------------
 *
 *  DON'T TRY TO IMPORT EXPORTED CODE FROM ./analytics.js
 *  DIRECTLY, AS IT WILL BREAK THE ASYNC LOGIC AND THE DIFFERENT BUNDLE
 */

/**
 * Global Dimensions that can be used from within our code (components)
 *
 * IMPORTANT: Don't use the ones from within `./anaytics.js`,
 * as it will stop being a separate bundle
 *
 * FIXME: register dimensions default values
 */
// export const dimensions = {
// };

/**
 * Global Metrics that can be used from within our code (components)
 *
 * IMPORTANT: Don't use the ones from within `./anaytics.js`,
 * as it will stop being a separate bundle
 */
// export const metrics = {
// };

/**
 * Set the userId for this GA session
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
 * @param userId
 */
const setUserId = userId => {
  window.ga && window.ga('set', 'userId', userId);
};

/**
 * A useful mapping for sending GA Events
 * in order it's being used from within components
 *
 * ga('send', 'event', {
 *   eventCategory: 'Client Form',
 *   eventAction: 'save',
 *   eventLabel: eventName,
 *   nonInteraction: true,
 *   [dimensions.XYZ]: value,
 * });
 */
const eventMap = {
  XHR_FAILURE_STATUSES: {
    eventCategory: 'XHR Failure',
  },
};

const trackEvent = (item, extra) => {
  if (!config.analytics.enabled || typeof ga === 'undefined') {
    return;
  }

  if (typeof eventMap[item] === 'undefined') {
    console.warn(`Failed to collect data for ${item}`);
    return;
  }

  ga('send', 'event', {
    nonInteraction: true,
    ...eventMap[item],
    ...extra,
  });
};

/**
 * A useful mapping for sending GA Timing events
 * in order it's being used from within components
 *
 * Example need to be send to GA
 * {
 *   hitType: 'timing',
 *   timingCategory: 'XHR Timing',
 *   timingVar: 'load',
 *   timingLabel: eventName,    // not required, useful to add the actual CALL to track
 *   timingValue: duration,     // actual integer value in `ms`
 *   nonInteraction: true,
 * }
 */
const timingMap = {
  XHR_TIMING: {
    timingCategory: 'XHR Timing',
    timingVar: 'load',
  },
};

const trackTiming = (item, extra) => {
  if (!config.analytics.enabled || typeof ga === 'undefined') {
    return;
  }

  if (
    typeof timingMap[item] === 'undefined' ||
    typeof extra.timingValue === 'undefined'
  ) {
    console.warn(`Failed to collect data for ${item}`);
    return;
  }

  ga('send', {
    nonInteraction: true,
    hitType: 'timing',
    ...timingMap[item],
    ...extra,
  });
};

export { setUserId, trackEvent, trackTiming };
