import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const urlKeyMap = {
  '/faq': 'faq',
  '/privacy-policy': 'privacy_policy',
  '/terms-of-service': 'terms_of_service',
};

class PublicPage extends React.Component {
  static propTypes = {
    publicOrganisationData: PropTypes.object,
    location: PropTypes.object,
    favicon: PropTypes.string,
  };

  componentWillUnmount() {
    console.warn('Public Page');
  }

  render() {
    // const commonProps = getCommonProps(this.props);
    const { publicOrganisationData, location, favicon } = this.props;
    const key = urlKeyMap[location.pathname.toLowerCase()];
    const content = publicOrganisationData ? publicOrganisationData[key] : '';

    return (
      <div className="public-page">
        <Helmet>
          <link rel="shortcut icon" type="image/png" href={favicon} />
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  }
}

export default PublicPage;
