import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForImageUpload,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as LotteriesActionTypes from '../constants/actionTypes/lotteries';

/**
 * Fetch Lotteries
 * @returns Promise
 */
const fetchLotteries = (locale = '', query = '?per_page=500') => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.LOTTERIES_FETCH_REQUEST,
      LotteriesActionTypes.LOTTERIES_FETCH_SUCCESS,
      LotteriesActionTypes.LOTTERIES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery' + query,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Lotteries archive
 * @returns Promise
 */
const fetchLotteriesArchive = (locale = '') => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.LOTTERIES_ARCHIVE_FETCH_REQUEST,
      LotteriesActionTypes.LOTTERIES_ARCHIVE_FETCH_SUCCESS,
      LotteriesActionTypes.LOTTERIES_ARCHIVE_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery?per_page=500&archived=true',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Adds a new Lottery
 * @param data
 * @returns Promise
 */
const addLottery = data => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.ADD_LOTTERY_REQUEST,
      LotteriesActionTypes.ADD_LOTTERY_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ LotteriesActionTypes.ADD_LOTTERY_FAILURE,
        /* entity */ 'rewards.lotteries'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/lottery',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a Lottery
 * @param data
 * @returns Promise
 */
const updateLottery = (lotteryId, data) => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.UPDATE_LOTTERY_REQUEST,
      {
        type: LotteriesActionTypes.UPDATE_LOTTERY_SUCCESS,
        meta: (action, state, res) => ({ lotteryId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ LotteriesActionTypes.UPDATE_LOTTERY_FAILURE,
        /* entity */ 'rewards.lotteries'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Activate an Lottery
 * @param lotteryId
 * @returns Promise
 */
const activateLottery = lotteryId => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.ACTIVATE_LOTTERY_REQUEST,
      {
        type: LotteriesActionTypes.ACTIVATE_LOTTERY_SUCCESS,
        meta: (action, state, res) => ({ lotteryId }),
      },
      LotteriesActionTypes.ACTIVATE_LOTTERY_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId + '/activate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Deactivates an Lottery
 * @param lotteryId
 * @returns Promise
 */
const deactivateLottery = lotteryId => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.DEACTIVATE_LOTTERY_REQUEST,
      {
        type: LotteriesActionTypes.DEACTIVATE_LOTTERY_SUCCESS,
        meta: (action, state, res) => ({ lotteryId }),
      },
      LotteriesActionTypes.DEACTIVATE_LOTTERY_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId + '/deactivate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Upload lottery image
 * @param lotteryId
 * @param file
 * @returns Promise
 */
const uploadLotteryImage = (lotteryId, file) => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.LOTTERY_UPLOAD_IMAGE_REQUEST,
      LotteriesActionTypes.LOTTERY_UPLOAD_IMAGE_SUCCESS,
      basicFailureRSAAHandlerForImageUpload(
        /* type */ LotteriesActionTypes.LOTTERY_UPLOAD_IMAGE_FAILURE
      ),
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId + '/image',
    method: 'POST',
    body: file,
  },
});

/**
 * Purchase a Lottery
 * @param lotteryId
 * @returns Promise
 */
const purchaseLottery = lotteryId => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.PURCHASE_LOTTERY_REQUEST,
      {
        type: LotteriesActionTypes.PURCHASE_LOTTERY_SUCCESS,
        meta: (action, state, res) => ({ lotteryId }),
      },
      LotteriesActionTypes.PURCHASE_LOTTERY_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId + '/purchase',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Fetch Lotteries tags
 * @returns Promise
 */
const fetchLotteriesTags = (locale = '') => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.LOTTERIES_TAGS_FETCH_REQUEST,
      LotteriesActionTypes.LOTTERIES_TAGS_FETCH_SUCCESS,
      LotteriesActionTypes.LOTTERIES_TAGS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/tag/lottery?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Archive a Lottery
 * @param lotteryId
 * @returns Promise
 */
const archiveLottery = lotteryId => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.ARCHIVE_LOTTERY_REQUEST,
      {
        type: LotteriesActionTypes.ARCHIVE_LOTTERY_SUCCESS,
        meta: (action, state, res) => ({ lotteryId }),
      },
      LotteriesActionTypes.ARCHIVE_LOTTERY_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId + '/archive',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Adds a new Lottery
 * @param data
 * @returns Promise
 */
const addLotteryTags = data => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.ADD_LOTTERIES_TAG_REQUEST,
      LotteriesActionTypes.ADD_LOTTERIES_TAG_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ LotteriesActionTypes.ADD_LOTTERIES_TAG_FAILURE
      ),
    ],
    endpoint: config.apiEndpoint + '/api/tag/lottery',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Unarchive a Lottery
 * @param lotteryId
 * @returns Promise
 */
const unarchiveLottery = lotteryId => ({
  [RSAA]: {
    types: [
      LotteriesActionTypes.UNARCHIVE_LOTTERY_REQUEST,
      {
        type: LotteriesActionTypes.UNARCHIVE_LOTTERY_SUCCESS,
        meta: (action, state, res) => ({ lotteryId }),
      },
      LotteriesActionTypes.UNARCHIVE_LOTTERY_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/lottery/' + lotteryId + '/unarchive',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

export {
  fetchLotteries,
  fetchLotteriesArchive,
  addLottery,
  updateLottery,
  activateLottery,
  deactivateLottery,
  uploadLotteryImage,
  purchaseLottery,
  fetchLotteriesTags,
  addLotteryTags,
  archiveLottery,
  unarchiveLottery,
};
