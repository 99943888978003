// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/*
  Adds support for:
  - retryDelay for forms on 429/503 API responses
 */
export default function errorHandler() {
  return BaseComponent => {
    class Handler extends BaseComponent {
      static displayName = `errorHandler(${BaseComponent.displayName ||
        BaseComponent.name})`;

      static propTypes = Object.assign({}, BaseComponent.propTypes, {
        error: PropTypes.object.isRequired,
        uiActions: PropTypes.object.isRequired,
        globalActions: PropTypes.object.isRequired,
      });

      componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps &&
          super.componentWillReceiveProps(nextProps);

        // eslint-disable-next-line react/prop-types
        const { error } = nextProps;

        // if error is changed
        if (this.props.error !== error) {
          const { uiActions } = this.props;

          const name = error.get('name');

          // On 429/503 API responses: Rate-limited or overloaded
          // use retryDelay to disable form for sometime
          if (name === 'RetryDelayError') {
            // disable form
            uiActions.setUIState({
              formDisabled: true,
              retryDelay: error.get('retryDelay'),
            });
          }
        }
      }
    }

    // Add support for IE9/10
    // This is because `defaultProps` becomes a static property of the component before you wrap it,
    // and a caveat of babel means that doesn't get copied to the subclass you create
    if (BaseComponent.hasOwnProperty('defaultProps')) {
      Handler.defaultProps = Object.assign(
        {},
        Handler.defaultProps,
        BaseComponent.defaultProps
      );
    }

    if (BaseComponent.hasOwnProperty('contextTypes')) {
      Handler.contextTypes = Object.assign(
        {},
        Handler.contextTypes,
        BaseComponent.contextTypes
      );
    }
    if (BaseComponent.hasOwnProperty('childContextTypes')) {
      Handler.childContextTypes = Object.assign(
        {},
        Handler.childContextTypes,
        BaseComponent.childContextTypes
      );
    }

    const mapStateToProps = state => ({
      error: state.get('error'),
    });

    return connect(mapStateToProps)(Handler);
  };
}
