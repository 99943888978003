import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import {
  organisationsListSchema,
  organisationSchema,
  editorialsListSchema,
} from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as OrganisationsActionTypes from '../../constants/actionTypes/organisations';
import * as UsersActionTypes from '../../constants/actionTypes/users';

const initialState = fromJS({
  current: null,
  byId: {},
  result: [],
  editorials: [],
});

/**
 * Handles Organisations
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [], organisation = {} } = data || {};

  switch (type) {
    case OrganisationsActionTypes.ORGANISATIONS_FETCH_SUCCESS:
    case OrganisationsActionTypes.ORGANISATIONS_ADMIN_DETAILS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, organisationsListSchema);
      return state.mergeDeep({
        byId: fromJS(normalizedFetch.entities.organisations),
        result: fromJS(normalizedFetch.result),
      });
    }

    case OrganisationsActionTypes.ORGANISATIONS_SETTINGS_UPDATE_SUCCESS:
    case OrganisationsActionTypes.ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_SUCCESS:
    case OrganisationsActionTypes.ORGANISATIONS_UPLOAD_LOGO_SUCCESS: {
      const normalizedUpdate = normalize(data, organisationSchema);
      return state.mergeIn(
        ['byId'],
        fromJS(normalizedUpdate.entities.organisations)
      );
    }

    case OrganisationsActionTypes.FETCH_ORGANISATIONS_EDITORIALS_SUCCESS: {
      const normalizedFetch = normalize(payload, editorialsListSchema);
      return state.set(
        'editorials',
        fromJS(normalizedFetch.entities.editorial).toList()
      );
    }

    // merge my user details
    case UsersActionTypes.CURRENT_USER_FETCH_SUCCESS: {
      const normalizedOrganisation = normalize(
        organisation,
        organisationSchema
      );
      return state
        .mergeIn(
          ['byId'],
          fromJS(normalizedOrganisation.entities.organisations)
        )
        .updateIn(['result'], val =>
          !val.includes(organisation.id) ? val.push(organisation.id) : val
        )
        .set('current', organisation.id);
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
