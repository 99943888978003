import { defineMessages } from 'react-intl';

export default defineMessages({
  'form.validation.required': {
    id: 'form.validation.required',
    defaultMessage: 'Required',
    description: 'Form validation: required field',
  },
  'form.validation.requiredIf': {
    id: 'form.validation.requiredIf',
    defaultMessage: 'Required, when "{other}" is empty.',
    description: 'Form validation: required field',
  },
  'form.validation.min': {
    id: 'form.validation.min',
    defaultMessage: 'Must be at least {min, number} characters',
    description: 'Form validation: minimum number of chars',
  },
  'form.validation.max': {
    id: 'form.validation.max',
    defaultMessage: 'Must not be more than {max} characters',
    description: 'Form validation: maximum number of chars',
  },
  'form.validation.alphaNumeric': {
    id: 'form.validation.alphaNumeric',
    defaultMessage: 'Only alphanumeric characters',
    description: 'Form validation: must be alphanumeric characters',
  },
  'form.validation.integer': {
    id: 'form.validation.integer',
    defaultMessage: 'Must be an integer',
    description: 'Form validation: must be integer',
  },
  'form.validation.number': {
    id: 'form.validation.number',
    defaultMessage: 'Must be an number',
    description: 'Form validation: must be number',
  },
  'form.validation.float': {
    id: 'form.validation.float',
    defaultMessage: 'Must be a float',
    description: 'Form validation: must be float',
  },
  'form.validation.currency': {
    id: 'form.validation.currency',
    defaultMessage: 'Must be a correct currency',
    description: 'Form validation: must be in the currency format e.g 12.99',
  },
  'form.validation.oneof': {
    id: 'form.validation.oneof',
    defaultMessage: 'Must be one of: {items}',
    description: 'Form validation: value must be in the allowed list',
  },
  'form.validation.match': {
    id: 'form.validation.match',
    defaultMessage: 'Does not match',
    description: "Form validation: value doesn't match",
  },
  'form.validation.email': {
    id: 'form.validation.email',
    defaultMessage: 'Invalid email address',
    description: 'Form validation: email address is not valid',
  },
  'form.validation.url': {
    id: 'form.validation.url',
    defaultMessage: 'Invalid URL',
    description: 'Form validation: value is not a valid URL',
  },
  'form.validation.subdomain': {
    id: 'form.validation.subdomain',
    defaultMessage: 'Invalid subdomain',
    description: 'Form validation: value is not a valid subdomain',
  },
  'form.validation.submissionError': {
    id: 'form.validation.submissionError',
    defaultMessage:
      'There was a problem saving. Please check the data you entered and try again',
    description: 'Form validation: General submission error',
  },
  'form.validation.password': {
    id: 'form.validation.password',
    defaultMessage:
      'Your password must have min 8 characters, 1 uppercase, 1 lowercase letter and 1 digit.',
    description: 'Form validation: invalid password pattern',
  },
  'form.validation.minNumber': {
    id: 'form.validation.minNumber',
    defaultMessage: 'Must be at least {min, number}',
    description: 'Form validation: minimum number',
  },
  'form.validation.maxNumber': {
    id: 'form.validation.maxNumber',
    defaultMessage: 'Must not be more than {max}',
    description: 'Form validation: maximum number',
  },
});
