import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { charitiesListSchema, charitySchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as CharitiesActionTypes from '../../constants/actionTypes/charities';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Challenges
 */
export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case CharitiesActionTypes.CHARITIES_FETCH_SUCCESS: {
      const normalizedFetch = normalize(data, charitiesListSchema);
      const normalizedCharities = fromJS(normalizedFetch.entities.charities);
      if (!normalizedCharities) {
        return state;
      }

      return state.merge({
        byId: normalizedCharities,
        result: fromJS(normalizedFetch.result),
      });
    }

    case CharitiesActionTypes.CHARITIES_ADD_ENTRY:
    case CharitiesActionTypes.CHARITIES_UPDATE_ENTRY: {
      const normalizedUpdate = normalize(data, charitySchema);

      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.charities))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
