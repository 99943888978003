import { combineReducers } from 'redux';
import { fromJS } from 'immutable';

// Types
import {
  GET_PAYMENT_METHODS_FAILURE,
  GET_CARD_PAYMENT_METHODS_LIST_SUCCESS,
  SET_LOADING,
  GET_DEBIT_PAYMENT_METHODS_LIST_SUCCESS,
  GET_CARD_PAYMENT_METHODS_LIST,
  GET_DEBIT_PAYMENT_METHODS_LIST,
  GET_LIST_OF_PURCHASES,
  GET_LIST_OF_PURCHASES_SUCCESS,
} from '../../../constants/actionTypes/billing/paymentMethods';

const initState = fromJS({
  paymentMethods: {
    card: [],
    debit: [],
  },
  // Create a new array to store purchase history
  purchaseDetails: [],
  isLoading: false,
  error: '',
});

const paymentMethods = (state = initState, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case (GET_CARD_PAYMENT_METHODS_LIST, GET_DEBIT_PAYMENT_METHODS_LIST):
      return state.set(isLoading, true);
    case GET_CARD_PAYMENT_METHODS_LIST_SUCCESS:
      return state
        .setIn(['paymentMethods', 'card'], fromJS(payload))
        .set(isLoading, false);

    case GET_DEBIT_PAYMENT_METHODS_LIST_SUCCESS:
      return state
        .setIn(['paymentMethods', 'debit'], fromJS(payload))
        .set(isLoading, false);

    default:
      return state;
  }
};

const purchaseDetails = (state = initState, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_LIST_OF_PURCHASES:
      return {};
    case GET_LIST_OF_PURCHASES_SUCCESS:
      return {
        // Spreads originial state, then adds new purchase details history
        ...state,
        purchaseDetails: payload,
      };
    default:
      return state;
  }
};

const isLoading = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_LOADING:
      return {};
    default:
      return state;
  }
};

const error = (state = initState, action = {}) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS_FAILURE:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  paymentMethods,
  purchaseDetails,
  isLoading,
  error,
});
