import { fromJS } from 'immutable';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as OrganisationBillingActionTypes from '../../constants/actionTypes/organisationBilling';

const initialState = fromJS({
  subscriptionData: {},
  billing_starts_at: null,
  next_invoice: null,
  billable_id: null,
  subscriptionId: null,
  payment_method_exists: false,
});

/**
 * Handles Organisation Subscription
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  switch (type) {
    case OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_FETCH_SUCCESS: {
      return state
        .set('subscriptionData', data)
        .set('billing_starts_at', payload.billing_starts_at)
        .set('next_bill_at', payload.next_bill_at)
        .set('billing_plan', payload.billing_plan)
        .set('canceled_at', payload.canceled_at)
        .set('discount_amount', payload.discount_amount)
        .set('discount_percentage', payload.discount_percentage)
        .set('payment_method_exists', payload.payment_method_exists)
        .set('currency', payload.currency)
        .set('subscriptionId', payload.id)
        .set('billable_id', payload.billable_id);
    }
    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
