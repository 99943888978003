export const LOCALE_UPDATE = 'changers/admin/LOCALE_UPDATE';
export const APPLANGA_LOCALE_REQUEST = 'changers/admin/APPLANGA_LOCALE_REQUEST';
export const APPLANGA_LOCALE_SUCCESS = 'changers/admin/APPLANGA_LOCALE_SUCCESS';
export const APPLANGA_LOCALE_FAILURE = 'changers/admin/APPLANGA_LOCALE_FAILURE';

export const FETCH_DEFAULT_MESSAGES_REQUEST =
  'changers/admin/FETCH_DEFAULT_MESSAGES_REQUEST';
export const FETCH_DEFAULT_MESSAGES_SUCCESS =
  'changers/admin/FETCH_DEFAULT_MESSAGES_SUCCESS';
export const FETCH_DEFAULT_MESSAGES_FAILURE =
  'changers/admin/FETCH_DEFAULT_MESSAGES_FAILURE';
