export const VOUCHERS_FETCH_REQUEST = 'changers/admin/VOUCHERS_FETCH_REQUEST';
export const VOUCHERS_FETCH_SUCCESS = 'changers/admin/VOUCHERS_FETCH_SUCCESS';
export const VOUCHERS_FETCH_FAILURE = 'changers/admin/VOUCHERS_FETCH_FAILURE';

export const VOUCHERS_ARE_ACTIVE_FETCH_REQUEST =
  'changers/admin/VOUCHERS_ARE_ACTIVE_FETCH_REQUEST';
export const VOUCHERS_ARE_ACTIVE_FETCH_SUCCESS =
  'changers/admin/VOUCHERS_ARE_ACTIVE_FETCH_SUCCESS';
export const VOUCHERS_ARE_ACTIVE_FETCH_FAILURE =
  'changers/admin/VOUCHERS_ARE_ACTIVE_FETCH_FAILURE';

export const CREATE_VOUCHER_LINK_FETCH_REQUEST =
  'changers/admin/CREATE_VOUCHER_LINK_FETCH_REQUEST';
export const CREATE_VOUCHER_LINK_FETCH_SUCCESS =
  'changers/admin/CREATE_VOUCHER_LINK_FETCH_SUCCESS';
export const CREATE_VOUCHER_LINK_FETCH_FAILURE =
  'changers/admin/CREATE_VOUCHER_LINK_FETCH_FAILURE';

export const VOUCHERS_ARCHIVE_FETCH_REQUEST =
  'changers/admin/VOUCHERS_ARCHIVE_FETCH_REQUEST';
export const VOUCHERS_ARCHIVE_FETCH_SUCCESS =
  'changers/admin/VOUCHERS_ARCHIVE_FETCH_SUCCESS';
export const VOUCHERS_ARCHIVE_FETCH_FAILURE =
  'changers/admin/VOUCHERS_ARCHIVE_FETCH_FAILURE';

export const ADD_VOUCHER_REQUEST = 'changers/admin/ADD_VOUCHER_REQUEST';
export const ADD_VOUCHER_SUCCESS = 'changers/admin/ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAILURE = 'changers/admin/ADD_VOUCHER_FAILURE';

export const UPDATE_VOUCHER_REQUEST = 'changers/admin/UPDATE_VOUCHER_REQUEST';
export const UPDATE_VOUCHER_SUCCESS = 'changers/admin/UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_FAILURE = 'changers/admin/UPDATE_VOUCHER_FAILURE';

export const VOUCHERS_TAGS_FETCH_REQUEST =
  'changers/admin/VOUCHERS_TAGS_FETCH_REQUEST';
export const VOUCHERS_TAGS_FETCH_SUCCESS =
  'changers/admin/VOUCHERS_TAGS_FETCH_SUCCESS';
export const VOUCHERS_TAGS_FETCH_FAILURE =
  'changers/admin/VOUCHERS_TAGS_FETCH_FAILURE';

export const ADD_VOUCHERS_TAG_REQUEST =
  'changers/admin/ADD_VOUCHERS_TAG_REQUEST';
export const ADD_VOUCHERS_TAG_SUCCESS =
  'changers/admin/ADD_VOUCHERS_TAG_SUCCESS';
export const ADD_VOUCHERS_TAG_FAILURE =
  'changers/admin/ADD_VOUCHERS_TAG_FAILURE';

export const ACTIVATE_VOUCHER_REQUEST =
  'changers/admin/ACTIVATE_VOUCHER_REQUEST';
export const ACTIVATE_VOUCHER_SUCCESS =
  'changers/admin/ACTIVATE_VOUCHER_SUCCESS';
export const ACTIVATE_VOUCHER_FAILURE =
  'changers/admin/ACTIVATE_VOUCHER_FAILURE';

export const DEACTIVATE_VOUCHER_REQUEST =
  'changers/admin/DEACTIVATE_VOUCHER_REQUEST';
export const DEACTIVATE_VOUCHER_SUCCESS =
  'changers/admin/DEACTIVATE_VOUCHER_SUCCESS';
export const DEACTIVATE_VOUCHER_FAILURE =
  'changers/admin/DEACTIVATE_VOUCHER_FAILURE';

export const TOGGLE_FEATURED_VOUCHER_REQUEST =
  'changers/admin/TOGGLE_FEATURED_VOUCHER_REQUEST';
export const TOGGLE_FEATURED_VOUCHER_SUCCESS =
  'changers/admin/TOGGLE_FEATURED_VOUCHER_SUCCESS';
export const TOGGLE_FEATURED_VOUCHER_FAILURE =
  'changers/admin/TOGGLE_FEATURED_VOUCHER_FAILURE';

export const VOUCHERS_UPLOAD_IMAGE_REQUEST =
  'changers/admin/VOUCHERS_UPLOAD_IMAGE_REQUEST';
export const VOUCHERS_UPLOAD_IMAGE_SUCCESS =
  'changers/admin/VOUCHERS_UPLOAD_IMAGE_SUCCESS';
export const VOUCHERS_UPLOAD_IMAGE_FAILURE =
  'changers/admin/VOUCHERS_UPLOAD_IMAGE_FAILURE';

export const PURCHASE_VOUCHER_REQUEST =
  'changers/admin/PURCHASE_VOUCHER_REQUEST';
export const PURCHASE_VOUCHER_SUCCESS =
  'changers/admin/PURCHASE_VOUCHER_SUCCESS';
export const PURCHASE_VOUCHER_FAILURE =
  'changers/admin/PURCHASE_VOUCHER_FAILURE';

export const ARCHIVE_VOUCHER_REQUEST = 'changers/admin/ARCHIVE_VOUCHER_REQUEST';
export const ARCHIVE_VOUCHER_SUCCESS = 'changers/admin/ARCHIVE_VOUCHER_SUCCESS';
export const ARCHIVE_VOUCHER_FAILURE = 'changers/admin/ARCHIVE_VOUCHER_FAILURE';

export const UNARCHIVE_VOUCHER_REQUEST =
  'changers/admin/UNARCHIVE_VOUCHER_REQUEST';
export const UNARCHIVE_VOUCHER_SUCCESS =
  'changers/admin/UNARCHIVE_VOUCHER_SUCCESS';
export const UNARCHIVE_VOUCHER_FAILURE =
  'changers/admin/UNARCHIVE_VOUCHER_FAILURE';

export const UPDATE_VOUCHER_ORDER_STATE =
  'changers/admin/UPDATE_VOUCHER_ORDER_STATE';

export const UPDATE_VOUCHER_ORDER_REQUEST =
  'changers/admin/UPDATE_VOUCHER_ORDER_REQUEST';
export const UPDATE_VOUCHER_ORDER_SUCCESS =
  'changers/admin/UPDATE_VOUCHER_ORDER_SUCCESS';
export const UPDATE_VOUCHER_ORDER_FAILURE =
  'changers/admin/UPDATE_VOUCHER_ORDER_FAILURE';
