import { RSAA } from 'redux-api-middleware';
import config from 'config';

import { basicFailureRSAAHandlerForUpdate } from '../utils/rsaa';
import * as EntitiesActionTypes from '../constants/actionTypes/organisationEntities';
import { getCurrentOrganisationId } from '../selectors/organisations';

/**
 * Fetch Teams
 * @returns Promise
 */
const fetchOrganisationEntities = () => (dispatch, getState) => {
  const organisationId = getCurrentOrganisationId(getState());

  return dispatch({
    [RSAA]: {
      types: [
        EntitiesActionTypes.ORGANISATION_ENTITIES_FETCH_REQUEST,
        EntitiesActionTypes.ORGANISATION_ENTITIES_FETCH_SUCCESS,
        EntitiesActionTypes.ORGANISATION_ENTITIES_FETCH_FAILURE,
      ],
      endpoint:
        config.apiEndpoint +
        `/api/organisation/${organisationId}/entities?per_page=1000`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  });
};

/**
 * Update Organisation Entities
 * @param data
 * @returns Promise
 */
const updateOrganisationEntities = data => (dispatch, getState) => {
  const organisationId = getCurrentOrganisationId(getState());

  return dispatch({
    [RSAA]: {
      types: [
        EntitiesActionTypes.ORGANISATION_ENTITIES_UPDATE_REQUEST,
        EntitiesActionTypes.ORGANISATION_ENTITIES_UPDATE_SUCCESS,
        basicFailureRSAAHandlerForUpdate(
          /* type */ EntitiesActionTypes.ORGANISATION_ENTITIES_UPDATE_FAILURE,
          /* entity */ 'organisationEntities'
        ),
      ],
      endpoint:
        config.apiEndpoint + `/api/organisation/${organisationId}/entities`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    },
  });
};

/**
 * Update Organisation Entities Areas
 * @param data
 * @param entityId
 * @returns Promise
 */
const updateOrganisationEntitiesAreas = ({ data, entityId }) => (
  dispatch,
  getState
) => {
  const organisationId = getCurrentOrganisationId(getState());

  return dispatch({
    [RSAA]: {
      types: [
        EntitiesActionTypes.ORGANISATION_ENTITIES_AREAS_UPDATE_REQUEST,
        EntitiesActionTypes.ORGANISATION_ENTITIES_AREAS_UPDATE_SUCCESS,
        basicFailureRSAAHandlerForUpdate(
          /* type */ EntitiesActionTypes.ORGANISATION_ENTITIES_AREAS_UPDATE_FAILURE,
          /* entity */ 'organisationEntities'
        ),
      ],
      endpoint:
        config.apiEndpoint +
        `/api/organisation/${organisationId}/entities/${entityId}/areas`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    },
  });
};

export {
  fetchOrganisationEntities,
  updateOrganisationEntities,
  updateOrganisationEntitiesAreas,
};
