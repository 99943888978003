import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { challengesListSchema, challengeSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as ChallengesActionTypes from '../../constants/actionTypes/challenges';

const initialState = fromJS({
  current: null,
  byId: {},
  result: [],
  past: [],
});

/**
 * Handles Challenges
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case ChallengesActionTypes.CHALLENGES_FETCH_SUCCESS: {
      // filter out finished challenges
      const upcomingChallenges = items.filter(
        challenge => challenge.status !== 'finished'
      );

      const pastChallenges = items.filter(
        challenge => challenge.status == 'finished'
      );

      const normalizedFetch = normalize(
        upcomingChallenges,
        challengesListSchema
      );

      const pastNormalizedFetch = normalize(
        pastChallenges,
        challengesListSchema
      );

      const normalizedChallenges = fromJS(
        normalizedFetch?.entities?.challenges
          ? normalizedFetch?.entities?.challenges
          : {}
      );

      if (!normalizedChallenges) {
        return state;
      }

      const normalizedPastChallenges = fromJS(
        pastNormalizedFetch.entities.challenges
          ? pastNormalizedFetch.entities.challenges
          : {}
      );

      return state.merge({
        // return integer for sanity with rest reducers
        current: Math.trunc(
          normalizedChallenges.findKey(val => val.get('status') === 'active') ||
            null
        ),
        byId: normalizedChallenges,
        result: fromJS(normalizedFetch.result),
        past: normalizedPastChallenges,
      });
    }

    case ChallengesActionTypes.CHALLENGES_ADD_SUCCESS:
    case ChallengesActionTypes.CHALLENGES_UPDATE_SUCCESS: {
      const normalizedUpdate = normalize(data, challengeSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.challenges))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
