import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
  basicFailureRSAAHandlerForImageUpload,
} from '../utils/rsaa';

import * as OrganisationsActionTypes from '../constants/actionTypes/organisations';
import { getXHost } from '../utils';
import { getCurrentOrganisationId } from '../selectors/organisations';
import { LocalizableFieldsDefaultLanguage } from '../constants';

/**
 * Fetch Organisation Logo
 * @returns Promise
 */
const fetchOrganisationLogo = language => {
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Host': getXHost(),
  };

  if (language) {
    headers['accept-language'] = language;
  }

  return {
    [RSAA]: {
      types: [
        OrganisationsActionTypes.ORGANISATION_LOGO_FETCH_REQUEST,
        OrganisationsActionTypes.ORGANISATION_LOGO_FETCH_SUCCESS,
        OrganisationsActionTypes.ORGANISATION_LOGO_FETCH_FAILURE,
      ],
      endpoint: config.apiEndpoint + '/api/organisation/logo',
      method: 'GET',
      headers,
    },
  };
};

/**
 * Fetch Organisations
 * @returns Promise
 */
const fetchOrganisations = () => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_FETCH_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_FETCH_SUCCESS,
      OrganisationsActionTypes.ORGANISATIONS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/organisation?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Fetch Organization Activity and Stats
 * @param {string} locale
 * @returns {Promise}
 */
const fetchOrganisationActivityStats = (locale = '') => (
  dispatch,
  getState
) => {
  const organisationId = getCurrentOrganisationId(getState());

  return {
    [RSAA]: {
      types: [
        OrganisationsActionTypes.ORGANISATION_ACTIVITY_STATS_FETCH_REQUEST,
        OrganisationsActionTypes.ORGANISATION_ACTIVITY_STATS_FETCH_SUCCESS,
        OrganisationsActionTypes.ORGANISATION_ACTIVITY_STATS_FETCH_FAILURE,
      ],
      endpoint: `${config.apiTrackingEndpoint}/api/statistics/organisations/${organisationId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': locale,
      },
    },
  };
};

/**
 * Fetch Organisation Challenges stats
 * @returns Promise
 */
const fetchOrganisationChallengesStats = (
  locale = '',
  query = 'page=1&limit=2'
) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATION_CHALLENGES_STATS_FETCH_REQUEST,
      OrganisationsActionTypes.ORGANISATION_CHALLENGES_STATS_FETCH_SUCCESS,
      OrganisationsActionTypes.ORGANISATION_CHALLENGES_STATS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiTrackingEndpoint + '/api/statistics/challenges?' + query,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Organisation Charity stats
 * @returns Promise
 */
const fetchOrganisationCharityStats = (locale = '') => (dispatch, getState) => {
  const organisationId = getCurrentOrganisationId(getState());

  return {
    [RSAA]: {
      types: [
        OrganisationsActionTypes.ORGANISATION_CHARITY_STATS_FETCH_REQUEST,
        OrganisationsActionTypes.ORGANISATION_CHARITY_STATS_FETCH_SUCCESS,
        OrganisationsActionTypes.ORGANISATION_CHARITY_STATS_FETCH_FAILURE,
      ],
      endpoint: `${config.apiTrackingEndpoint}/api/statistics/organisations/${organisationId}/charity`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': locale,
        'x-host': 'admin.changersdev.de',
      },
    },
  };
};

/**
 * Fetch info stats for recoins multiplier ¬.¬
 * @param {string} locale
 */
const fetchInfoStats = (locale = 'en') => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATION_INFO_STATS_FETCH_REQUEST,
      OrganisationsActionTypes.ORGANISATION_INFO_STATS_FETCH_SUCCESS,
      OrganisationsActionTypes.ORGANISATION_INFO_STATS_FETCH_FAILURE,
    ],
    endpoint: `${config.apiEndpoint}/api/activity/info`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Match an Organisation
 * @returns Promise
 */
const matchOrganisation = handle => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATION_MATCH_FETCH_REQUEST,
      OrganisationsActionTypes.ORGANISATION_MATCH_FETCH_SUCCESS,
      OrganisationsActionTypes.ORGANISATION_MATCH_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/organisation/match/' + handle,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },
  },
});

/**
 * Adds a new Organisation
 * @param data
 * @returns Promise
 */
const addOrganisation = data => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_ADD_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_ADD_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_ADD_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/organisation',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a Organisation
 * @param data
 * @returns Promise
 */
const updateOrganisation = (organisationId, data) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_UPDATE_REQUEST,
      {
        type: OrganisationsActionTypes.ORGANISATIONS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ organisationId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_UPDATE_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/organisation/' + organisationId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update Organisation Settings
 * @param data
 * @returns Promise
 */
const updateOrganisationSettings = (organisationId, data) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_SETTINGS_UPDATE_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_SETTINGS_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_SETTINGS_UPDATE_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint:
      config.apiEndpoint + '/api/organisation/' + organisationId + '/settings',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update Organisation Settings
 * @param data
 * @returns Promise
 */
const updateOrganisationWidgetSettings = (organisationId, data) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_WIDGET_SETTINGS_UPDATE_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_WIDGET_SETTINGS_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_WIDGET_SETTINGS_UPDATE_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint:
      config.apiEndpoint +
      '/api/organisation/' +
      organisationId +
      '/widget-settings',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update Organisation editorial
 * @param data
 * @returns Promise
 */
const updateOrganisationEditorial = (organisationId, data) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_EDITORIAL_UPDATE_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_EDITORIAL_UPDATE_SUCCESS,
      OrganisationsActionTypes.ORGANISATIONS_EDITORIAL_UPDATE_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/organisation/' + organisationId + '/editorial',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Fetch Organisation editorials
 * @param data
 * @returns Promise
 */
const fetchOrganisationEditorials = () => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.FETCH_ORGANISATIONS_EDITORIALS_REQUEST,
      OrganisationsActionTypes.FETCH_ORGANISATIONS_EDITORIALS_SUCCESS,
      OrganisationsActionTypes.FETCH_ORGANISATIONS_EDITORIALS_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/organisation/editorial',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update Organisation Settings
 * @param organisationId number
 * @param data
 * @returns Promise
 */
const updateReferralSettings = (organisationId, data) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint:
      config.apiEndpoint +
      '/api/user-referral/organisation-settings/' +
      organisationId,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update Organisation Settings
 * @param data
 * @returns Promise
 */
const updateOrganisationDynamicLinkSettings = (organisationId, data) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint:
      config.apiEndpoint +
      '/api/organisation/' +
      organisationId +
      '/dynamic-link-settings',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Upload organisation logo
 * @param organisationId
 * @param file
 * @returns Promise
 */
const uploadOrganisationLogo = (organisationId, file) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_UPLOAD_LOGO_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_UPLOAD_LOGO_SUCCESS,
      basicFailureRSAAHandlerForImageUpload(
        /* type */ OrganisationsActionTypes.ORGANISATIONS_UPLOAD_LOGO_FAILURE
      ),
    ],
    endpoint:
      config.apiEndpoint + '/api/organisation/' + organisationId + '/logo',
    method: 'POST',
    body: file,
  },
});

/**
 * Send newly registered email verification
 * @param {String} data
 */
const resendOrganizationAdminEmailVerificationRequest = (
  email = '',
  locale = LocalizableFieldsDefaultLanguage
) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST,
      // OrganisationsActionTypes.ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,
      {
        type:
          OrganisationsActionTypes.ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,
        payload: (action, state, res) => res,
      },
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/email/resend-verify',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ email }),
  },
});

const validateAdminEmailToken = (token = '') => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST,
      // OrganisationsActionTypes.ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_SUCCESS,
      {
        type:
          OrganisationsActionTypes.ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_SUCCESS,
        payload: (action, state, res) => res,
      },
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/email/verify/' + token,
    method: 'POST',
    headers: {
      // 'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: '',
  },
});

/**
 * Fetch users from current organization challenge
 */
const fetchOrgaChallengeUsers = (challengeId = null) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_USERS_STATS_FETCH_REQUEST,
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_USERS_STATS_FETCH_SUCCESS,
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_USERS_STATS_FETCH_FAILURE,
    ],
    endpoint: `${config.apiTrackingEndpoint}/api/statistics/challenges/${challengeId}/users?limit=10&page=1`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // 'Accept-Language': locale,
    },
  },
});
/**
 * Fetch team-type from current organization challenge by team position
 */
const fetchOrgaChallengeTeamTypesByPosition = (
  challengeId = null,
  teamTypeId = null
) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_REQUEST,
      {
        type:
          OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_SUCCESS,
        meta: () => ({ teamTypeId }),
      },
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_FAILURE,
    ],
    endpoint: `${config.apiTrackingEndpoint}/api/statistics/challenges/${challengeId}/team-types/${teamTypeId}/teams?limit=10&page=1&order_by=position&order=asc`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // 'Accept-Language': locale,
    },
  },
});
/**
 * Fetch team-type from current organization challenge by team position
 */
const fetchOrgaChallengeTeamTypesByAverage = (
  challengeId = null,
  teamTypeId = null
) => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_REQUEST,
      {
        type:
          OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_SUCCESS,
        meta: () => ({ teamTypeId }),
      },
      OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_FAILURE,
    ],
    endpoint: `${config.apiTrackingEndpoint}/api/statistics/challenges/${challengeId}/team-types/${teamTypeId}/teams?limit=10&page=1&order_by=average&order=desc`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // 'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Organisations Admin Details
 * @returns Promise
 */
const fetchOrganisationsAdminDetails = () => ({
  [RSAA]: {
    types: [
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_DETAILS_FETCH_REQUEST,
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_DETAILS_FETCH_SUCCESS,
      OrganisationsActionTypes.ORGANISATIONS_ADMIN_DETAILS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/organisation/details?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Fetch Organisation Complete Onboarding
 * @returns Promise
 */
const fetchOrganisationCompleteOnboard = () => (dispatch, getState) => {
  const organisationId = getCurrentOrganisationId(getState());

  return dispatch({
    [RSAA]: {
      types: [
        OrganisationsActionTypes.ORGANISATION_COMPLETE_ONBOARDING_FETCH_REQUEST,
        OrganisationsActionTypes.ORGANISATION_COMPLETE_ONBOARDING_FETCH_SUCCESS,
        OrganisationsActionTypes.ORGANISATION_COMPLETE_ONBOARDING_FETCH_FAILURE,
      ],
      endpoint:
        config.apiEndpoint +
        `/api/organisation/${organisationId}/complete-onboarding`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  });
};

/**
 * Send Organisation Onboard
 * @param data
 * @returns Promise
 */
const sendOrganisationOnboard = data => (dispatch, getState) => {
  console.log(data);
  const organisationId = getCurrentOrganisationId(getState());

  return dispatch({
    [RSAA]: {
      types: [
        OrganisationsActionTypes.ORGANISATION_COMPLETE_ONBOARDING_FETCH_REQUEST,
        OrganisationsActionTypes.ORGANISATION_COMPLETE_ONBOARDING_FETCH_SUCCESS,
        OrganisationsActionTypes.ORGANISATION_COMPLETE_ONBOARDING_FETCH_FAILURE,
      ],
      endpoint:
        config.apiEndpoint + `/api/organisation/${organisationId}/onboard`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    },
  });
};

export {
  fetchOrganisationLogo,
  fetchOrganisations,
  fetchOrganisationActivityStats,
  fetchOrganisationChallengesStats,
  fetchOrganisationCharityStats,
  fetchInfoStats,
  matchOrganisation,
  addOrganisation,
  updateOrganisation,
  updateOrganisationSettings,
  updateOrganisationWidgetSettings,
  updateOrganisationEditorial,
  uploadOrganisationLogo,
  updateReferralSettings,
  updateOrganisationDynamicLinkSettings,
  resendOrganizationAdminEmailVerificationRequest,
  validateAdminEmailToken,
  fetchOrgaChallengeUsers,
  fetchOrgaChallengeTeamTypesByPosition,
  fetchOrgaChallengeTeamTypesByAverage,
  fetchOrganisationEditorials,
  fetchOrganisationsAdminDetails,
  fetchOrganisationCompleteOnboard,
  sendOrganisationOnboard,
};
