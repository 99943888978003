import { fetchWhitelabel } from '../actions/whitelabel';
import config from '../config';

export const loadWLConfig = ({
  organisation,
  publicOrganisationData,
  dispatch,
}) => {
  let prefix = publicOrganisationData?.app || organisation?.get('app');

  const parent = organisation?.get('parent');
  // Get parent public URL if parent is not global
  if (parent && parent.get('id') !== 1) {
    prefix = parent.get('app');
  }

  if (!prefix || prefix === 'changers') {
    return;
  }

  if (publicOrganisationData.admin_url) {
    config.adminDomain = publicOrganisationData.admin_url;
  }

  if (publicOrganisationData.public_url_domain) {
    config.domain = publicOrganisationData.public_url_domain;
  }

  dispatch(fetchWhitelabel(prefix));
};
