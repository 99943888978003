export const ORGANISATION_LOGO_FETCH_REQUEST =
  'changers/admin/ORGANISATION_LOGO_FETCH_REQUEST';
export const ORGANISATION_LOGO_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_LOGO_FETCH_SUCCESS';
export const ORGANISATION_LOGO_FETCH_FAILURE =
  'changers/admin/ORGANISATION_LOGO_FETCH_FAILURE';

export const ORGANISATIONS_FETCH_REQUEST =
  'changers/admin/ORGANISATIONS_FETCH_REQUEST';
export const ORGANISATIONS_FETCH_SUCCESS =
  'changers/admin/ORGANISATIONS_FETCH_SUCCESS';
export const ORGANISATIONS_FETCH_FAILURE =
  'changers/admin/ORGANISATIONS_FETCH_FAILURE';

export const ORGANISATIONS_ADMIN_DETAILS_FETCH_REQUEST =
  'changers/admin/ORGANISATIONS_ADMIN_DETAILS_FETCH_REQUEST';
export const ORGANISATIONS_ADMIN_DETAILS_FETCH_SUCCESS =
  'changers/admin/ORGANISATIONS_ADMIN_DETAILS_FETCH_SUCCESS';
export const ORGANISATIONS_ADMIN_DETAILS_FETCH_FAILURE =
  'changers/admin/ORGANISATIONS_ADMIN_DETAILS_FETCH_FAILURE';

export const ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST =
  'changers/admin/ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST';
export const ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS =
  'changers/admin/ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS';
export const ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_FAILURE =
  'changers/admin/ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_FAILURE';

export const ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST =
  'changers/admin/ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST';
export const ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_SUCCESS =
  'changers/admin/ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_SUCCESS';
export const ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_FAILURE =
  'changers/admin/ORGANISATIONS_ADMIN_VALIDATE_EMAIL_TOKEN_REQUEST_FAILURE';

export const ORGANISATION_ACTIVITY_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_ACTIVITY_STATS_FETCH_REQUEST';
export const ORGANISATION_ACTIVITY_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_ACTIVITY_STATS_FETCH_SUCCESS';
export const ORGANISATION_ACTIVITY_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_ACTIVITY_STATS_FETCH_FAILURE';

export const ORGANISATION_CHALLENGES_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_CHALLENGES_STATS_FETCH_REQUEST';
export const ORGANISATION_CHALLENGES_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_CHALLENGES_STATS_FETCH_SUCCESS';
export const ORGANISATION_CHALLENGES_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_CHALLENGES_STATS_FETCH_FAILURE';

export const ORGANISATION_CHARITY_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_CHARITY_STATS_FETCH_REQUEST';
export const ORGANISATION_CHARITY_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_CHARITY_STATS_FETCH_SUCCESS';
export const ORGANISATION_CHARITY_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_CHARITY_STATS_FETCH_FAILURE';

export const ORGANISATION_INFO_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_INFO_STATS_FETCH_REQUEST';
export const ORGANISATION_INFO_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_INFO_STATS_FETCH_SUCCESS';
export const ORGANISATION_INFO_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_INFO_STATS_FETCH_FAILURE';

export const ORGANISATIONS_ADD_REQUEST =
  'changers/admin/ORGANISATIONS_ADD_REQUEST';
export const ORGANISATIONS_ADD_SUCCESS =
  'changers/admin/ORGANISATIONS_ADD_SUCCESS';
export const ORGANISATIONS_ADD_FAILURE =
  'changers/admin/ORGANISATIONS_ADD_FAILURE';

export const ORGANISATIONS_UPDATE_REQUEST =
  'changers/admin/ORGANISATIONS_UPDATE_REQUEST';
export const ORGANISATIONS_UPDATE_SUCCESS =
  'changers/admin/ORGANISATIONS_UPDATE_SUCCESS';
export const ORGANISATIONS_UPDATE_FAILURE =
  'changers/admin/ORGANISATIONS_UPDATE_FAILURE';

export const ORGANISATIONS_SETTINGS_UPDATE_REQUEST =
  'changers/admin/ORGANISATIONS_SETTINGS_UPDATE_REQUEST';
export const ORGANISATIONS_SETTINGS_UPDATE_SUCCESS =
  'changers/admin/ORGANISATIONS_SETTINGS_UPDATE_SUCCESS';
export const ORGANISATIONS_SETTINGS_UPDATE_FAILURE =
  'changers/admin/ORGANISATIONS_SETTINGS_UPDATE_FAILURE';

export const ORGANISATIONS_WIDGET_SETTINGS_UPDATE_REQUEST =
  'changers/admin/ORGANISATIONS_WIDGET_SETTINGS_UPDATE_REQUEST';
export const ORGANISATIONS_WIDGET_SETTINGS_UPDATE_SUCCESS =
  'changers/admin/ORGANISATIONS_WIDGET_SETTINGS_UPDATE_SUCCESS';
export const ORGANISATIONS_WIDGET_SETTINGS_UPDATE_FAILURE =
  'changers/admin/ORGANISATIONS_WIDGET_SETTINGS_UPDATE_FAILURE';

export const ORGANISATIONS_EDITORIAL_UPDATE_REQUEST =
  'changers/admin/ORGANISATIONS_EDITORIAL_UPDATE_REQUEST';
export const ORGANISATIONS_EDITORIAL_UPDATE_SUCCESS =
  'changers/admin/ORGANISATIONS_EDITORIAL_UPDATE_SUCCESS';
export const ORGANISATIONS_EDITORIAL_UPDATE_FAILURE =
  'changers/admin/ORGANISATIONS_EDITORIAL_UPDATE_FAILURE';

export const FETCH_ORGANISATIONS_EDITORIALS_REQUEST =
  'changers/admin/FETCH_ORGANISATIONS_EDITORIALS_REQUEST';
export const FETCH_ORGANISATIONS_EDITORIALS_SUCCESS =
  'changers/admin/FETCH_ORGANISATIONS_EDITORIALS_SUCCESS';
export const FETCH_ORGANISATIONS_EDITORIALS_FAILURE =
  'changers/admin/FETCH_ORGANISATIONS_EDITORIALS_FAILURE';

export const ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_REQUEST =
  'changers/admin/ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_REQUEST';
export const ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_SUCCESS =
  'changers/admin/ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_SUCCESS';
export const ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_FAILURE =
  'changers/admin/ORGANISATIONS_REFERRAL_SETTINGS_UPDATE_FAILURE';

export const ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_REQUEST =
  'changers/admin/ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_REQUEST';
export const ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_SUCCESS =
  'changers/admin/ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_SUCCESS';
export const ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_FAILURE =
  'changers/admin/ORGANISATIONS_DYNAMIC_LINK_SETTINGS_UPDATE_FAILURE';

export const ORGANISATIONS_UPLOAD_LOGO_REQUEST =
  'changers/admin/ORGANISATIONS_UPLOAD_LOGO_REQUEST';
export const ORGANISATIONS_UPLOAD_LOGO_SUCCESS =
  'changers/admin/ORGANISATIONS_UPLOAD_LOGO_SUCCESS';
export const ORGANISATIONS_UPLOAD_LOGO_FAILURE =
  'changers/admin/ORGANISATIONS_UPLOAD_LOGO_FAILURE';

export const ORGANISATION_MATCH_FETCH_REQUEST =
  'changers/admin/ORGANISATION_MATCH_FETCH_REQUEST';
export const ORGANISATION_MATCH_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_MATCH_FETCH_SUCCESS';
export const ORGANISATION_MATCH_FETCH_FAILURE =
  'changers/admin/ORGANISATION_MATCH_FETCH_FAILURE';

export const ORGANISATION_CHALLENGE_USERS_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_CHALLENGE_USERS_STATS_FETCH_REQUEST';
export const ORGANISATION_CHALLENGE_USERS_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_CHALLENGE_USERS_STATS_FETCH_SUCCESS';
export const ORGANISATION_CHALLENGE_USERS_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_CHALLENGE_USERS_STATS_FETCH_FAILURE';

export const ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_REQUEST';
export const ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_SUCCESS';
export const ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_FAILURE';

export const ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_REQUEST =
  'changers/admin/ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_REQUEST';
export const ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_SUCCESS';
export const ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_FAILURE =
  'changers/admin/ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_FAILURE';

export const ORGANISATION_COMPLETE_ONBOARDING_FETCH_REQUEST =
  'changers/admin/ORGANISATION_COMPLETE_ONBOARDING_FETCH_REQUEST';
export const ORGANISATION_COMPLETE_ONBOARDING_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_COMPLETE_ONBOARDING_FETCH_SUCCESS';
export const ORGANISATION_COMPLETE_ONBOARDING_FETCH_FAILURE =
  'changers/admin/ORGANISATION_COMPLETE_ONBOARDING_FETCH_FAILURE';
