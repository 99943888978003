import { defineMessages } from 'react-intl';

export default defineMessages({
  'error.FATAL_ERROR': {
    id: 'error.FATAL_ERROR',
    defaultMessage: 'Oops. Something went wrong. Please, contact our support.',
    description: 'Dashboard Error',
  },
  'error.RATE_LIMIT': {
    id: 'error.RATE_LIMIT',
    defaultMessage: 'Slow down, please. You can try again in a few seconds.',
    description: 'Dashboard Error',
  },
  'error.RATE_LIMIT.time': {
    id: 'error.RATE_LIMIT.time',
    defaultMessage:
      'You have reached the max. amount of attempts to login and have been blocked. Please try again in {minutes} minutes and {seconds} seconds.',
    description: 'Dashboard Error',
  },
});
