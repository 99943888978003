import { fromJS, List } from 'immutable';
import { normalize } from 'normalizr';

// Types
import * as types from '../../constants/actionTypes/compensationPartners';

// Schema
import { compensationListSchema } from '../../schemas/compensations';

const initialState = fromJS({
  result: [],
  loading: false,
  current_page: 1,
  total: 0,
  searchQuery: '',
  byId: {},
});

export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = [], current_page = 1, total = 0 } = payload;

  switch (type) {
    case (types.FETCH_COMPENSATION_LIST_REQUEST,
    types.UPDATE_COMPENSATION_ORDER_REQUEST):
      return state
        .set('loading', true)
        .set('searchQuery', payload.searchQuery)
        .set('current_page', payload.page);
    case types.FETCH_COMPENSATION_LIST_SUCCESS: {
      const normalizedFetch = normalize(data, compensationListSchema);
      const normalizedCompensations = fromJS(
        normalizedFetch.entities.compensations
      );

      if (!normalizedCompensations) {
        return state
          .set('loading', false)
          .setIn(['byId'], {})
          .setIn(['result'], val => List())
          .set('current_page', current_page)
          .set('total', total);
      }

      return state
        .set('loading', false)
        .setIn(['byId'], normalizedCompensations)
        .setIn(['result'], val => List(normalizedFetch.result))
        .set('current_page', current_page)
        .set('total', total);
    }

    case types.UPDATE_COMPENSATION_LIST_ORDER: {
      data.forEach(compensation => {
        const compensationId = compensation.get('id');
        const compensationIndex = state
          .getIn(['byId'])
          .findKey(item => item.get('id') === compensationId);
        state = state.updateIn(['byId', compensationIndex], entry => {
          return entry.set('order', entry.get('order'));
        });
      });
      return state;
    }
    default:
      return state;
  }
}
