import { LocalizableFieldsDefaultLanguage } from '../constants';

const getLocale = state =>
  state.getIn(['intl', 'locale'], LocalizableFieldsDefaultLanguage);

const getDefaultMessages = state => state.getIn(['intl', 'defaultMessages']);
const getDefaultMessage = (state, errorId) =>
  getDefaultMessages(state)?.getIn([errorId, 'v']);

export { getLocale, getDefaultMessages, getDefaultMessage };
