import { fromJS } from 'immutable';

import * as CommunicationActionTypes from '../../constants/actionTypes/communications';

const initialState = fromJS({
  result: [],
});

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case CommunicationActionTypes.COMMUNICATIONS_FETCH_SUCCESS: {
      return state.merge({
        result: fromJS(data),
      });
    }
    case CommunicationActionTypes.COMMUNICATIONS_RESEND_SUCCESS: {
      const jsResult = state.get('result').toJS();
      jsResult.unshift(data);

      return state.merge({
        result: fromJS(jsResult),
      });
    }
  }
  return state;
};

export default reducer;
