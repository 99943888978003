import { createSelector } from 'reselect';
import { List } from 'immutable';

const getActivityCategories = state =>
  state.getIn(['entities', 'activityCategories', 'customActivityCategories']);

const getDisabledActivityCategories = state =>
  state.getIn(['whitelabel', 'disabledCustomActivityCategories'])
    ? state.getIn(['whitelabel', 'disabledCustomActivityCategories'])
    : List();

const getActivityCategoriesById = state => {
  const disabledCategories = getDisabledActivityCategories(state);
  return state
    .getIn(['entities', 'activityCategories', 'byId'], List())
    .filter(category => !disabledCategories.includes(category.get('slug')));
};

const getActivityCategoriesBySlug = state => {
  const disabledCategories = getDisabledActivityCategories(state);
  return state
    .getIn(['entities', 'activityCategories', 'bySlug'])
    .filter((category, slug) => !disabledCategories.includes(slug));
};

const getActivityCategoriesOrder = state =>
  getActivityCategoriesById(state)
    .keySeq()
    .map(id => parseInt(id))
    .toList();

const getOrderedActivityCategories = createSelector(
  [getActivityCategoriesById, getActivityCategoriesOrder],
  (activityCategories, activityCategoriesOrder) =>
    activityCategoriesOrder
      .filter(id => id)
      .map(id => activityCategories.get(`${id}`))
);
export {
  getActivityCategories,
  getActivityCategoriesById,
  getActivityCategoriesBySlug,
  getActivityCategoriesOrder,
  getOrderedActivityCategories,
};
