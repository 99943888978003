// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
const AuthorizedRoute = ({
  component: Component,
  computedMatch,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...rest} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

AuthorizedRoute.propTypes = {
  component: PropTypes.func,
  computedMatch: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
};

//
export default AuthorizedRoute;
