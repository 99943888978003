import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { vouchersSchema, vouchersListSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as VouchersActionTypes from '../../constants/actionTypes/vouchers';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Vouchers
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {}, meta = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case VouchersActionTypes.VOUCHERS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(data, vouchersListSchema);
      const vouchers = fromJS(normalizedFetch.entities.vouchers);

      return state
        .merge({
          byId: vouchers,
          result: fromJS(normalizedFetch.result),
        })
        .set('total', payload.total || 0)
        .set('current_page', payload.current_page || 1)
        .set('create_page_url', payload.create_page_url || '');
    }

    case VouchersActionTypes.CREATE_VOUCHER_LINK_FETCH_SUCCESS: {
      return state.set('create_link', payload.link || 0);
    }

    case VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_SUCCESS: {
      return state.set('vouchers_are_active', payload.auto_activate);
    }

    case VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_FAILURE: {
      return state.set('vouchers_are_active', false);
    }

    case VouchersActionTypes.VOUCHERS_ARCHIVE_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, vouchersListSchema);
      const vouchers = fromJS(normalizedFetch.entities.vouchers);

      return state.updateIn(['archived'], val => fromJS(vouchers));
    }

    case VouchersActionTypes.ADD_VOUCHER_SUCCESS:
    case VouchersActionTypes.UPDATE_VOUCHER_SUCCESS: {
      const normalizedUpdate = normalize(data, vouchersSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.vouchers))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }

    case VouchersActionTypes.ACTIVATE_VOUCHER_SUCCESS:
    case VouchersActionTypes.DEACTIVATE_VOUCHER_SUCCESS: {
      const { voucherId } = meta;

      const voucherIndex = state
        .getIn(['byId'])
        .findKey(voucher => voucher.get('id') === voucherId);

      if (voucherIndex) {
        state = state.updateIn(['byId', voucherIndex], voucher =>
          voucher.set(
            'enabled',
            type === VouchersActionTypes.ACTIVATE_VOUCHER_SUCCESS
          )
        );
      }

      return state;
    }

    case VouchersActionTypes.TOGGLE_FEATURED_VOUCHER_SUCCESS: {
      const { voucherId, featured } = meta;

      const voucherIndex = state
        .getIn(['byId'])
        .findKey(voucher => voucher.get('id') === voucherId);

      if (voucherIndex) {
        state = state.updateIn(['byId', voucherIndex], voucher =>
          voucher.set('featured', featured)
        );
      }

      return state;
    }

    case VouchersActionTypes.VOUCHERS_UPLOAD_IMAGE_SUCCESS: {
      const normalizedUpdate = normalize(data, vouchersSchema);
      return state.mergeIn(
        ['byId'],
        fromJS(normalizedUpdate.entities.vouchers)
      );
    }

    case VouchersActionTypes.PURCHASE_VOUCHER_SUCCESS: {
      const { voucherId } = meta;
      const { code, qr_code } = payload;

      const voucherIndex = state
        .get('byId')
        .findKey(voucher => voucher.get('id') == voucherId);

      if (code && voucherIndex) {
        state = state.updateIn(['byId', voucherIndex], voucher =>
          voucher.set('code', code).set('qr_code', qr_code)
        );
      }

      return state;
    }

    case VouchersActionTypes.UPDATE_VOUCHER_ORDER_STATE: {
      data.forEach(voucher => {
        const voucherId = voucher.get('id');
        const voucherIndex = state
          .getIn(['byId'])
          .findKey(x => x.get('id') === voucherId);
        state = state.updateIn(['byId', voucherIndex], currentVoucher => {
          return currentVoucher.set('order', voucher.get('order'));
        });
      });

      return state;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
