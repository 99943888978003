import { fromJS } from 'immutable';
import { normalize } from 'normalizr';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as TeamTypesActionTypes from '../../constants/actionTypes/teamTypes';
import { teamTypesListSchema } from '../../schemas';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles TeamTypes
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case TeamTypesActionTypes.TEAM_TYPES_FETCH_SUCCESS:
    case TeamTypesActionTypes.TEAM_TYPES_UPDATE_SUCCESS: {
      const normalizedFetch = normalize(items, teamTypesListSchema);
      const normalizedTeamTypes = fromJS(normalizedFetch.entities.teamTypes);

      if (!normalizedTeamTypes) {
        return state;
      }

      return state.merge({
        byId: normalizedTeamTypes,
        result: fromJS(normalizedFetch.result),
      });
    }

    case TeamTypesActionTypes.TEAM_TYPES_ALL_TEAMS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(data, teamTypesListSchema);
      const normalizedTeamTypes = fromJS(normalizedFetch.entities.teamTypes);

      if (!normalizedTeamTypes) {
        return state;
      }

      return state
        .mergeIn(['byId'], normalizedTeamTypes)
        .updateIn(['result'], val => {
          normalizedTeamTypes.map(teamType => {
            if (!val.includes(teamType.get('id'))) {
              val = val.push(teamType.get('id'));
            }
          });

          return val;
        });
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
