import { combineReducers } from 'redux-immutable';

import { reducer as FormReducer } from 'redux-form/immutable';
import { reducer as notificationsReducer } from 'reapop';
import IntlReducer from './intl';
import AuthReducer from './auth';
import UIReducer from './ui';
import ErrorReducer from './error';
import UploadReducer from './upload';

import OrganisationsReducer from './entities/organisations';
import OrganisationBillingReducer from './entities/organisationBilling';
import OrganisationSubscriptionReducer from './entities/organisationSubscription';
import BillingPlansReducer from './entities/billing/billingPlans';
import UsersReducer from './entities/users';
import ChallengesReducer from './entities/challenges';
import TeamTypesReducer from './entities/teamTypes';
import TeamsReducer from './entities/teams';
import OrganisationEntities from './entities/organisationEntities';
import DonationsReducer from './entities/donations';
import DonationProjectsReducer from './entities/donationProjects';
import TreePlantingsReducer from './entities/treePlantings';
import TreePlantingPartnersReducer from './entities/treePlantingPartners';
import TreeTypesReducer from './entities/treeTypes';
import CharitiesReducer from './entities/charities';
import DashboardsReducer from './entities/dashboards';
import ActivitiesReducer from './entities/activities';
import ActivityCategoriesReducer from './entities/activityCategories';
import VouchersReducer from './entities/vouchers';
import VouchersTagsReducer from './entities/vouchersTags';
import LotteriesReducer from './entities/lotteries';
import LotteriesTags from './entities/lotteriesTags';
import CommunicationsReducer from './entities/communications';
import WhitelabelReducer from './entities/whitelabel';
import DynamicLinkConfigReducer from './entities/dynamicLinkConfig';
import PaymentMethodsReducer from './entities/billing/paymentMethods';
import ClimatePartnersReducer from './entities/climatePartners';
import ReportsReducer from './entities/reports';
import HomeOfficeReducer from './entities/homeOffice';

import UserReferralReducer from './entities/userReferral';

// Admin
import OrganisationsListReducer from './entities/admin/organisations';

// Super Admin Reducers
import CompensationPartnersReducer from './entities/compensationPartners';
import CompensationReducer from './entities/compensations';

const rootReducer = combineReducers({
  auth: AuthReducer,
  error: ErrorReducer,
  form: FormReducer,
  intl: IntlReducer,
  notifications: notificationsReducer(),
  ui: UIReducer,
  upload: UploadReducer,
  whitelabel: WhitelabelReducer,
  entities: combineReducers({
    organisations: OrganisationsReducer,
    homeOffice: HomeOfficeReducer,
    organisationBilling: OrganisationBillingReducer,
    organisationSubscription: OrganisationSubscriptionReducer,
    users: UsersReducer,
    challenges: ChallengesReducer,
    teamTypes: TeamTypesReducer,
    teams: TeamsReducer,
    organisationEntities: OrganisationEntities,
    charities: CharitiesReducer,
    donations: DonationsReducer,
    donationProjects: DonationProjectsReducer,
    treePlantings: TreePlantingsReducer,
    treePlantingPartners: TreePlantingPartnersReducer,
    treeTypes: TreeTypesReducer,
    dashboards: DashboardsReducer,
    activities: ActivitiesReducer,
    activityCategories: ActivityCategoriesReducer,
    vouchers: VouchersReducer,
    vouchersTags: VouchersTagsReducer,
    lotteries: LotteriesReducer,
    lotteriesTags: LotteriesTags,
    communications: CommunicationsReducer,
    dynamicLinkConfig: DynamicLinkConfigReducer,
    billing: PaymentMethodsReducer,
    billingPlans: BillingPlansReducer,
    climatePartners: ClimatePartnersReducer,
    compensationPartners: CompensationPartnersReducer,
    compensations: CompensationReducer,
    reports: ReportsReducer,
    userReferrals: UserReferralReducer,
  }),
  admin: combineReducers({
    organisations: OrganisationsListReducer,
  }),
});

export default rootReducer;
