import PropTypes from 'prop-types';
import React from 'react';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import config from 'config';

import {
  changeLocale,
  fetchApplangaLocalization,
  fetchDefaultMessages,
} from '../../actions/locale';

import history from '../../history';

import App from '../App';
import ConnectedIntlProvider from '../ConnectedIntlProvider';

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {
      store: { getState, dispatch },
    } = this.props;
    const locale = getState().getIn(['intl', 'locale']);
    dispatch(fetchDefaultMessages());
    // load language file for used locale or default language based on user-agent
    const changeLocalePromise = dispatch(changeLocale(locale));
    if (config.useApplanga) {
      changeLocalePromise.then(() => {
        dispatch(fetchApplangaLocalization(locale));
      });
    }
  }

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <ConnectedIntlProvider>
          <Router history={history}>
            <React.Fragment>
              <App />

              {/* <DevTools /> */}
            </React.Fragment>
          </Router>
        </ConnectedIntlProvider>
      </Provider>
    );
  }
}
