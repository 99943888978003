export const FETCH_USER_REFERRAL_REQUEST =
  'changers/admin/FETCH_USER_REFERRAL_REQUEST';
export const FETCH_USER_REFERRAL_SUCCESS =
  'changers/admin/FETCH_USER_REFERRAL_SUCCESS';
export const FETCH_USER_REFERRAL_FAILURE =
  'changers/admin/FETCH_USER_REFERRAL_FAILURE';

export const USER_REFERRAL_INVITE_REQUEST =
  'changers/admin/USER_REFERRAL_INVITE_REQUEST';
export const USER_REFERRAL_INVITE_SUCCESS =
  'changers/admin/USER_REFERRAL_INVITE_SUCCESS';
export const USER_REFERRAL_INVITE_FAILURE =
  'changers/admin/USER_REFERRAL_INVITE_FAILURE';

export const FETCH_USER_REFERRAL_INVITE_STATS_REQUEST =
  'changers/admin/FETCH_USER_REFERRAL_INVITE_STATS_REQUEST';
export const FETCH_USER_REFERRAL_INVITE_STATS_SUCCESS =
  'changers/admin/FETCH_USER_REFERRAL_INVITE_STATS_SUCCESS';
export const FETCH_USER_REFERRAL_INVITE_STATS_FAILURE =
  'changers/admin/FETCH_USER_REFERRAL_INVITE_STATS_FAILURE';

export const USER_REFERRAL_RESEND_INVITE_REQUEST =
  'changers/admin/USER_REFERRAL_RESEND_INVITE_REQUEST';
export const USER_REFERRAL_RESEND_INVITE_SUCCESS =
  'changers/admin/USER_REFERRAL_RESEND_INVITE_SUCCESS';
export const USER_REFERRAL_RESEND_INVITE_FAILURE =
  'changers/admin/USER_REFERRAL_RESEND_INVITE_FAILURE';
