import { fromJS } from 'immutable';

import * as types from '../constants/actionTypes/upload';

const initialState = fromJS({
  errorKey: null,
  loading: false,
});

const UPLOAD_ERRORS = {
  3003: 'ckeditor.audio.upload.error.size.exceeded.alert',
  3023: 'ckeditor.audio.upload.error.wrong.format.alert',
  UNKNOWN_ERROR: 'ckeditor.audio.upload.error.unknown.alert',
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  const { response = {} } = payload;
  const { _details } = response;

  switch (type) {
    case (types.UPLOAD_AUDIO_REQUEST, types.UPLOAD_IMAGE_REQUEST):
      return state.set('loading', true);
    case types.UPLOAD_AUDIO_SUCCESS:
      return state.set('loading', false);
    case types.UPLOAD_AUDIO_FAILURE:
      const hasError = _details && _details.audio && _details.audio.length;
      return hasError
        ? state.set('errorKey', UPLOAD_ERRORS[_details.audio[0]])
        : state.set('errorKey', UPLOAD_ERRORS.UNKNOWN_ERROR);
    default:
      return state;
  }
};

export default reducer;
