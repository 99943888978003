import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as ClimatePartnerActionTypes from '../constants/actionTypes/climatePartners';

/**
 * Fetch Climate Partners
 * @returns Promise
 */
const fetchClimatePartners = (locale = '', query = '?per_page=500') => ({
  [RSAA]: {
    types: [
      ClimatePartnerActionTypes.CLIMATE_PARTNERS_FETCH_REQUEST,
      ClimatePartnerActionTypes.CLIMATE_PARTNERS_FETCH_SUCCESS,
      ClimatePartnerActionTypes.CLIMATE_PARTNERS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/climate-partner' + query,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

export { fetchClimatePartners };
