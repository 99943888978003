import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as DonationProjectsActionTypes from '../constants/actionTypes/donationProjects';

/**
 * Fetch DonationProjects
 * @returns Promise
 */
const fetchDonationProjects = () => ({
  [RSAA]: {
    types: [
      DonationProjectsActionTypes.DONATION_PROJECTS_FETCH_REQUEST,
      DonationProjectsActionTypes.DONATION_PROJECTS_FETCH_SUCCESS,
      DonationProjectsActionTypes.DONATION_PROJECTS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/donation-project-defaults/?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update default donation project
 * @param data
 * @returns Promise
 */
const updateDonationProjectDefaults = (id, data) => ({
  [RSAA]: {
    types: [
      DonationProjectsActionTypes.DONATION_PROJECT_DEFAULTS_UPDATE_REQUEST,
      DonationProjectsActionTypes.DONATION_PROJECT_DEFAULTS_SUCCESS,
      DonationProjectsActionTypes.DONATION_PROJECT_DEFAULTS_FAILURE,
    ],
    endpoint: config.apiEndpoint + `/api/donation-project-defaults/${id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Adds a new DonationProject
 * @param data
 * @returns Promise
 */
const addDonationProject = data => ({
  [RSAA]: {
    types: [
      DonationProjectsActionTypes.DONATION_PROJECTS_ADD_REQUEST,
      DonationProjectsActionTypes.DONATION_PROJECTS_ADD_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ DonationProjectsActionTypes.DONATION_PROJECTS_ADD_FAILURE,
        /* entity */ 'donationProjects'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/donation-project',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a DonationProject
 * @param data
 * @returns Promise
 */
const updateDonationProject = (donationProjectId, data) => ({
  [RSAA]: {
    types: [
      DonationProjectsActionTypes.DONATION_PROJECTS_UPDATE_REQUEST,
      {
        type: DonationProjectsActionTypes.DONATION_PROJECTS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ donationProjectId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ DonationProjectsActionTypes.DONATION_PROJECTS_UPDATE_FAILURE,
        /* entity */ 'donationProjects'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/donation-project/' + donationProjectId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

export {
  fetchDonationProjects,
  addDonationProject,
  updateDonationProject,
  updateDonationProjectDefaults,
};
