import { RSAA, getJSON, ApiError } from 'redux-api-middleware';
import config from 'config';

import { FormError, RetryDelayError } from '../utils/errors';
import { basicFailureRSAAHandlerForUpdate } from '../utils/rsaa';

import * as UsersActionTypes from '../constants/actionTypes/users';
import { LocalizableFieldsDefaultLanguage } from '../constants';
import { getXHost } from '../utils';
import { getCurrentUser } from '../selectors/users';

/**
 * Fetch my user
 * @returns Promise
 */
const fetchCurrentUser = () => ({
  [RSAA]: {
    types: [
      UsersActionTypes.CURRENT_USER_FETCH_REQUEST,
      UsersActionTypes.CURRENT_USER_FETCH_SUCCESS,
      UsersActionTypes.CURRENT_USER_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/user/me',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Fetch users
 * @returns Promise
 */
const fetchUsers = () => ({
  [RSAA]: {
    types: [
      UsersActionTypes.USERS_FETCH_REQUEST,
      UsersActionTypes.USERS_FETCH_SUCCESS,
      UsersActionTypes.USERS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/user?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Fetch user stats
 * @returns Promise
 */
const fetchUserStats = (locale = '') => ({
  [RSAA]: {
    types: [
      UsersActionTypes.USER_STATS_FETCH_REQUEST,
      UsersActionTypes.USER_STATS_FETCH_SUCCESS,
      UsersActionTypes.USER_STATS_FETCH_FAILURE,
    ],
    endpoint: config.apiStatsEndpoint + '/api/dashboard/user',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch user activity stats
 * @returns Promise
 */
const fetchUserActivityStats = (locale = '') => (dispatch, getState) => {
  const user = getCurrentUser(getState());

  return {
    [RSAA]: {
      types: [
        UsersActionTypes.USER_ACTIVITY_STATS_FETCH_REQUEST,
        UsersActionTypes.USER_ACTIVITY_STATS_FETCH_SUCCESS,
        UsersActionTypes.USER_ACTIVITY_STATS_FETCH_FAILURE,
      ],
      endpoint: `${config.apiTrackingEndpoint}/api/statistics/users/${user.get(
        'id'
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': locale,
      },
    },
  };
};

/**
 * Fetch User Charity stats
 * @returns Promise
 */
const fetchUserCharityStats = (locale = '') => (dispatch, getState) => {
  const user = getCurrentUser(getState());

  return {
    [RSAA]: {
      types: [
        UsersActionTypes.USER_CHARITY_STATS_FETCH_REQUEST,
        UsersActionTypes.USER_CHARITY_STATS_FETCH_SUCCESS,
        UsersActionTypes.USER_CHARITY_STATS_FETCH_FAILURE,
      ],
      endpoint: `${config.apiTrackingEndpoint}/api/statistics/users/${user.get(
        'id'
      )}/charity`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': locale,
        'x-host': 'admin.changersdev.de',
      },
    },
  };
};

/**
 * Update a User
 * @param data
 * @returns Promise
 */
const updateUser = (userId, data) => ({
  [RSAA]: {
    types: [
      UsersActionTypes.UPDATE_USER_REQUEST,
      {
        type: UsersActionTypes.UPDATE_USER_SUCCESS,
        meta: (action, state, res) => ({ userId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ UsersActionTypes.UPDATE_USER_FAILURE,
        /* entity */ 'error.app.user'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/user/' + userId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * User change password (API Call)
 * @returns Promise
 */
const userChangePassword = (locale = LocalizableFieldsDefaultLanguage) => ({
  [RSAA]: {
    types: [
      UsersActionTypes.USER_CHANGE_PASSWORD_REQUEST,
      UsersActionTypes.USER_CHANGE_PASSWORD_SUCCESS,
      failureUserChangePassword(),
    ],
    endpoint: config.apiEndpoint + '/api/change-password',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },
  },
});

const failureUserChangePassword = () => ({
  type: UsersActionTypes.USER_CHANGE_PASSWORD_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
    } else if (status === 403) {
      return getJSON(res).then(json => {
        return new FormError(
          json._details === 2020
            ? 'authentication.forgotPassword.userBelongsToAnotherOrg'
            : 'error.TRY_AGAIN'
        ).json();
      });

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    } else if (status === 500) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.FATAL_ERROR',
          _details: json._details,
        }).json();
      });
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

const validateMarketingEmailOptInToken = (token = '') => ({
  [RSAA]: {
    types: [
      UsersActionTypes.USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST,
      {
        type:
          UsersActionTypes.USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST_SUCCESS,
        payload: (action, state, res) => res,
      },
      UsersActionTypes.USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/email/verify/' + token,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: '',
  },
});

export {
  fetchCurrentUser,
  fetchUsers,
  fetchUserStats,
  updateUser,
  userChangePassword,
  fetchUserActivityStats,
  fetchUserCharityStats,
  validateMarketingEmailOptInToken,
};
