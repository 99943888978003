// import Immutable from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

// import DevTools from '../containers/DevTools';

import apiAnalytics from '../middlewares/apiAnalytics';
import apiError from '../middlewares/apiError';
import apiRequestAuthorization from '../middlewares/apiRequestAuthorization';
import loadingIndicator from '../middlewares/loadingIndicator';
import rootReducer from '../reducers';

const middlewares = [];

// use browser extension for redux-devtools if exists
// FIX: DevTools.instrument() fails with redux-4
if (window.devToolsExtension) {
  middlewares.push(window.devToolsExtension());
  // } else {
  //   middlewares.push(DevTools.instrument());
}

const configureStore = initialState => {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      // loadingIndicator here handles the start() call
      // apiAnalytics here adds the RSAA to be tracked
      applyMiddleware(
        thunk,
        apiRequestAuthorization,
        loadingIndicator,
        apiAnalytics,
        apiMiddleware,

        // loadingIndicator here handles the stop() call
        loadingIndicator,
        apiError
      ),

      // apiAnalytics here handles all request types for tracking the request timing
      applyMiddleware(apiAnalytics),

      ...middlewares
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
