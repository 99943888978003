import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { activityCategoriesListSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as ActivityCategoriesActionTypes from '../../constants/actionTypes/activityCategories';

const initialState = fromJS({
  byId: {},
  bySlug: {},
  customActivityCategories: [],
  result: [],
});

/**
 * Handles Activities
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  let normalizedFetch = null;
  let normalizedActivityCategories = null;
  let activityCategories = null;
  let categoriesBySlug = null;
  switch (type) {
    case ActivityCategoriesActionTypes.CUSTOM_ACTIVITY_CATEGORIES_FETCH_SUCCESS:
      normalizedFetch = normalize(data, activityCategoriesListSchema);
      ({
        entities: { activityCategories },
      } = normalizedFetch);
      normalizedActivityCategories = fromJS(activityCategories);
      categoriesBySlug = {};
      if (activityCategories) {
        Object.keys(activityCategories).forEach(categoryId => {
          const category = activityCategories[categoryId];
          categoriesBySlug[category.slug] = category;
        });
      }

      if (!normalizedActivityCategories) {
        return state;
      }

      return state.merge({
        byId: normalizedActivityCategories,
        result: fromJS(normalizedFetch.result),
        bySlug: fromJS(categoriesBySlug),
      });

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
