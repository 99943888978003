import { fromJS } from 'immutable';
import {
  FETCH_ORGANISATIONS_LIST_REQUEST,
  FETCH_ORGANISATIONS_LIST_SUCCESS,
} from '../../../constants/actionTypes/admin/organisations';

const initialState = fromJS({
  result: [],
  loading: false,
  current_page: 1,
  total: 0,
  searchQuery: '',
});

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  const { data = [], current_page = 1, total = 0 } = payload;
  switch (type) {
    case FETCH_ORGANISATIONS_LIST_REQUEST:
      return state
        .set('loading', true)
        .set('searchQuery', payload.searchQuery)
        .set('current_page', payload.page);
    case FETCH_ORGANISATIONS_LIST_SUCCESS:
      return state
        .set('loading', false)
        .set('result', data)
        .set('current_page', current_page)
        .set('total', total);
    default:
      return state;
  }
};

export default reducer;
