import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, FormattedHTMLMessage } from 'react-intl';

import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';

import {
  AsyncAccount,
  AsyncHomeOffice,
  AsyncAdministration,
  AsyncChallenge,
  AsyncChallenges,
  AsyncCharities,
  AsyncCommunication,
  AsyncCompensationPartners,
  AsyncCustomActivities,
  AsyncIframe,
  AsyncOrganisationDashboard,
  AsyncRecognition,
  AsyncReports,
  AsyncRewards,
  AsyncSubscription,
  AsyncTeams,
  AsyncUserDashboard,
  AsyncUserMarketplace,
  AsyncUserReferralInvite,
  AsyncTools,
} from '../Loadable';

import { isAdminHost, isGlobalHost } from '../../utils';

import Logo from './images/logo.svg';

import translations from './translations';
import styles from './Sidebar.scss';
import { LocalizableFieldsDefaultLanguage } from '../../constants';

import { List } from 'immutable';
import { daysBetween } from '../../utils/date';
import LanguageToggle from '../LanguageToggle';
import tracking from '../../utils/tracking';

/**
 * Displays a Header for the dashboard
 */
class Sidebar extends React.Component {
  static propTypes = {
    // injected by Redux
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
    isWhiteLabel: PropTypes.bool.isRequired,
    isSdkEnabled: PropTypes.bool.isRequired,
    isRecognitionFeatureEnabled: PropTypes.bool.isRequired,
    isGlobal: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    isLegacyManagedSubscriptions: PropTypes.bool.isRequired,
    hasSubscription: PropTypes.bool.isRequired,
    logo: PropTypes.string,
    activeLocale: PropTypes.string.isRequired,
    logoutAction: PropTypes.func.isRequired,
    onLocaleChange: PropTypes.func.isRequired,
    showUpgrade: PropTypes.bool,
    whitelabel: PropTypes.object,
    customActivityCategories: PropTypes.instanceOf(List),
    isAllowedToAccess: PropTypes.bool,
    intl: intlShape.isRequired,
    subscriptionBillingDate: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    isGlobal: false,
    isSuperAdmin: false,
    isLegacyManagedSubscriptions: false,
    activeLocale: 'en',
    showUpgrade: false,
  };

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
    this.handleUncollapsedMenuClick = this.handleUncollapsedMenuClick.bind(
      this
    );

    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    this._element.removeEventListener(
      'click',
      this.handleUncollapsedMenuClick,
      true
    );
  }

  toggleNavbar() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleDropdownMenu(menu, state) {
    this.setState({
      [`dropdownMenu${menu}`]: state || !this.state[`dropdownMenu${menu}`],
    });
  }

  handleUncollapsedMenuClick(event) {
    const buttonClassList = event.target.classList;
    if (
      buttonClassList.contains('navbar-toggler') ||
      buttonClassList.contains('navbar-toggler-icon')
    ) {
      return true;
    }

    this.toggleNavbar();

    this._element.removeEventListener(
      'click',
      this.handleUncollapsedMenuClick,
      true
    );
  }

  checkIfRouteActive(pathname, parentPath, extraPages) {
    const regex = new RegExp(`^/${parentPath}(?:/.*|)$`);
    if (regex.test(pathname)) {
      return true;
    }
    return extraPages.indexOf(pathname) > -1;
  }

  render() {
    const {
      isAuthenticated,
      isGlobal,
      isSuperAdmin,
      isSdkEnabled,
      isRecognitionFeatureEnabled,
      isWhiteLabel,
      // hasSubscription,
      logo,
      activeLocale,
      logoutAction,
      onLocaleChange,
      whitelabel,
      customActivityCategories,
      intl,
      organisation,
      isAllowedToAccess,
      showUpgrade,
      subscriptionBillingDate,
    } = this.props;

    const comesFromReferrer =
      document.referrer !== '' &&
      ['/auth/register/organisation', '/auth/login'].indexOf(
        window.location.pathname
      ) > -1 &&
      document.referrer.indexOf(
        window.location.protocol + '//' + window.location.host
      ) === -1;
    const fullNav =
      isGlobalHost() ||
      (isAdminHost() && comesFromReferrer && !isAuthenticated);

    const subscriptionStatus = organisation.getIn(['subscription_status']);

    const billingDeactivated = organisation.getIn(['billing_deactivated']);

    return (
      <div ref={el => (this._element = el)}>
        <Navbar light expand="lg" className={styles.navBar}>
          <div
            className={`${fullNav ? 'container' : 'container-fluid'} ${
              styles.sidebarContainer
            }`}
          >
            {fullNav && (
              <NavbarBrand href="https://changers.com" className={styles.logo}>
                <img src={logo || Logo} height={50} />

                <FormattedMessage {...translations['menu.logo.title']} />
              </NavbarBrand>
            )}

            {!fullNav && (
              <NavbarBrand
                exact
                to="/"
                tag={RRNavLink}
                className={styles.logo}
                onMouseEnter={() => AsyncOrganisationDashboard.preload()}
              >
                <img src={logo || Logo} height={50} />

                <FormattedMessage {...translations['menu.logo.title']} />
              </NavbarBrand>
            )}

            <NavbarToggler
              onClick={() => {
                this.toggleNavbar();

                this._element.addEventListener(
                  'click',
                  this.handleUncollapsedMenuClick,
                  true
                );
              }}
              className="mr-2"
            />

            <Collapse navbar isOpen={this.state.isOpen} className="w-100">
              <Nav navbar className="ml-auto w-100">
                {isAuthenticated &&
                  isAdminHost() &&
                  // showUpgrade &&
                  // hasSubscription &&
                  isAllowedToAccess && (
                    <div>
                      {subscriptionStatus === 'active' &&
                        showUpgrade &&
                        window.location.pathname !== '/current-plan' &&
                        window.location.pathname !== '/billing' &&
                        window.location.pathname !== '/settings' && (
                          <NavItem
                            className={`mb-3 align-items-center  ${styles.upgradeButtonContainer}`}
                          >
                            <span>
                              <FormattedMessage
                                {...translations['menu.upgrade.timeLeft']}
                                values={{
                                  remainingDays:
                                    daysBetween(
                                      new Date(),
                                      subscriptionBillingDate
                                    ) || 14,
                                }}
                              />
                              <br />
                              <FormattedMessage
                                {...translations['menu.upgrade.freeTrial']}
                                values={{
                                  remainingDays:
                                    daysBetween(
                                      new Date(),
                                      subscriptionBillingDate
                                    ) || 14,
                                }}
                              />
                            </span>
                            <NavLink
                              to="/billing"
                              tag={RRNavLink}
                              className={`btn btn-sm btn-primary ${styles.upgradeButton}`}
                              onMouseEnter={() => AsyncSubscription.preload()}
                              active={false}
                            >
                              <div className={styles.defaultText}>
                                <FormattedMessage
                                  {...translations['menu.upgrade']}
                                />
                              </div>
                            </NavLink>
                          </NavItem>
                        )}

                      {subscriptionStatus === 'cancelling' &&
                        window.location.pathname !== '/current-plan' &&
                        window.location.pathname !== '/billing' && (
                          <NavItem
                            className={`mb-3 align-items-center  ${styles.upgradeButtonContainer} ${styles.upgradeButtonContainer__cancelling}`}
                          >
                            <span>
                              <FormattedHTMLMessage
                                {...translations[
                                  'menu.upgrade.cancelling.text'
                                ]}
                              />
                            </span>
                            <NavLink
                              to="/current-plan"
                              tag={RRNavLink}
                              className={`btn btn-sm btn-primary ${styles.upgradeButton}`}
                              onMouseEnter={() => AsyncSubscription.preload()}
                              active={false}
                            >
                              <div className={styles.defaultText}>
                                <FormattedMessage
                                  {...translations[
                                    'menu.upgrade.cancelling.btn'
                                  ]}
                                />
                              </div>
                            </NavLink>
                          </NavItem>
                        )}

                      {subscriptionStatus === 'canceled' &&
                        window.location.pathname !== '/current-plan' &&
                        window.location.pathname !== '/billing' && (
                          <NavItem
                            className={`mb-3 align-items-center  ${styles.upgradeButtonContainer} ${styles.upgradeButtonContainer__canceled}`}
                          >
                            <span>
                              <FormattedHTMLMessage
                                {...translations['menu.upgrade.canceled.text']}
                              />
                            </span>
                            <NavLink
                              to="/current-plan"
                              tag={RRNavLink}
                              className={`btn btn-sm btn-primary ${styles.upgradeButton}`}
                              onMouseEnter={() => AsyncSubscription.preload()}
                              active={false}
                            >
                              <div className={styles.defaultText}>
                                <FormattedMessage
                                  {...translations['menu.upgrade.canceled.btn']}
                                />
                              </div>
                            </NavLink>
                          </NavItem>
                        )}
                    </div>
                  )}
                {/* {isAuthenticated && !isAdminHost() && isAllowedToAccess && (
                  <React.Fragment>
                    {whitelabel.getIn(['menuItems', 'addActivities']) && (
                      <NavItem>
                        <NavLink
                          exact
                          to="/dashboards/user"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncUserDashboard.preload()}
                        >
                          <FormattedMessage
                            {...translations['menu.user.addActivities']}
                          />
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        exact
                        to="/marketplace"
                        tag={RRNavLink}
                        onMouseEnter={() => AsyncUserMarketplace.preload()}
                      >
                        <FormattedMessage
                          {...translations['menu.user.userMarketplace']}
                        />
                      </NavLink>
                    </NavItem>
                  </React.Fragment>
                )} */}

                {isAuthenticated && !isAdminHost() && isAllowedToAccess && (
                  <React.Fragment>
                    <Dropdown
                      // isOpen={this.state.dropdownMenuAccount}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        isActive={(match, location) =>
                          this.checkIfRouteActive(
                            location.pathname,
                            'overview',
                            ['/', '/dashboards/user']
                          )
                        }
                        tag="span"
                        className="text-nowrap"
                      >
                        <FormattedMessage
                          {...translations['menu.overview.dashboard']}
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        {whitelabel.getIn(['menuItems', 'addActivities']) && (
                          <React.Fragment>
                            <DropdownItem
                              exact
                              to="/dashboards/user"
                              tag={RRNavLink}
                              onMouseEnter={() => AsyncUserDashboard.preload()}
                            >
                              <FormattedMessage
                                {...translations['menu.overview.myDashboard']}
                              />
                            </DropdownItem>
                          </React.Fragment>
                        )}

                        <DropdownItem exact to="/" tag={RRNavLink}>
                          <FormattedMessage
                            {...translations['menu.overview.weAllTogether']}
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Dropdown
                      // isOpen={this.state.dropdownMenuCharities}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        tag="span"
                        className="text-nowrap"
                        onMouseEnter={() => AsyncCharities.preload()}
                        isActive={(match, location) =>
                          this.checkIfRouteActive(
                            location.pathname,
                            'rewards',
                            ['/marketplace']
                          )
                        }
                      >
                        <FormattedMessage {...translations['menu.rewards']} />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          exact
                          to="/marketplace"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncUserMarketplace.preload()}
                        >
                          <FormattedMessage
                            {...translations['menu.user.userMarketplace2']}
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </React.Fragment>
                )}

                {isAuthenticated && isAdminHost() && isAllowedToAccess && (
                  <React.Fragment>
                    <Dropdown
                      // isOpen={this.state.dropdownMenuCharities}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        tag="span"
                        className="text-nowrap"
                        onMouseEnter={() => AsyncCharities.preload()}
                        isActive={(match, location) =>
                          this.checkIfRouteActive(
                            location.pathname,
                            'overview',
                            ['/', '/reports']
                          )
                        }
                      >
                        <FormattedMessage {...translations['menu.overview']} />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          exact
                          to="/"
                          tag={RRNavLink}
                          onClick={() => tracking.track('Menu - Dashboard')}
                        >
                          <FormattedMessage
                            {...translations['menu.overview.dashboard']}
                          />
                        </DropdownItem>
                        <DropdownItem
                          to="/reports"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncReports.preload()}
                          onClick={() => tracking.track('Menu - Reports')}
                        >
                          <FormattedMessage
                            {...translations['menu.overview.reports']}
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Dropdown
                      // isOpen={this.state.dropdownMenuRewards}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        tag="span"
                        className="text-nowrap"
                        onMouseEnter={() => AsyncRewards.preload()}
                        isActive={(match, location) =>
                          this.checkIfRouteActive(
                            location.pathname,
                            'settings',
                            [
                              '/settings',
                              '/teams',
                              '/challenges',
                              '/challenges/new#challenge-form',
                              '/user-invite',
                              '/widget',
                              '/home-office',
                              '/referral-settings',
                            ]
                          )
                        }
                      >
                        <FormattedMessage
                          {...translations['menu.projectSettings']}
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          exact
                          to="/settings"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncChallenge.preload()}
                          onClick={() =>
                            tracking.track('Menu - Project settings')
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.projectSettings.general']}
                          />
                        </DropdownItem>
                        {!isSdkEnabled && (
                          <DropdownItem
                            to="/challenges"
                            tag={RRNavLink}
                            onMouseEnter={() => AsyncChallenges.preload()}
                            onClick={() => tracking.track('Menu - Challenges')}
                          >
                            <FormattedMessage
                              {...translations['menu.challenges']}
                            />
                          </DropdownItem>
                        )}
                        <DropdownItem
                          exact
                          to="/teams"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncTeams.preload()}
                          onClick={() => tracking.track('Menu - Teams')}
                        >
                          <FormattedMessage {...translations['menu.teams']} />
                        </DropdownItem>

                        <DropdownItem
                          exact
                          to="/user-invite"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncUserReferralInvite.preload()}
                          onClick={() => tracking.track('Menu - Invite users')}
                        >
                          <FormattedMessage
                            {...translations['menu.userReferralInvite']}
                          />
                        </DropdownItem>
                        {!isWhiteLabel && (
                          <DropdownItem
                            exact
                            to="/home-office"
                            tag={RRNavLink}
                            onMouseEnter={() => AsyncHomeOffice.preload()}
                            onClick={() => tracking.track('Menu - Home office')}
                          >
                            <FormattedMessage
                              {...translations['menu.homeoffice']}
                            />
                          </DropdownItem>
                        )}
                        <DropdownItem
                          exact
                          to="/referral-settings"
                          tag={RRNavLink}
                          onClick={() =>
                            tracking.track('Menu - Invite friends')
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.referralSettings']}
                          />
                        </DropdownItem>
                        <DropdownItem
                          exact
                          to="/widget"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncIframe.preload()}
                          onClick={() => tracking.track('Menu - iFrame Banner')}
                        >
                          <FormattedMessage
                            {...translations['menu.iframeBanner']}
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    {isRecognitionFeatureEnabled && (
                      <NavItem>
                        <NavLink
                          exact
                          to="/recognition"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncRecognition.preload()}
                        >
                          <FormattedMessage
                            {...translations['menu.recognition']}
                          />
                        </NavLink>
                      </NavItem>
                    )}
                    {customActivityCategories.first() && (
                      <Dropdown
                        // isOpen={this.state.dropdownMenuActivities}
                        isOpen={true}
                        toggle={() => {}}
                        nav
                      >
                        <DropdownToggle
                          nav
                          caret
                          className="text-nowrap"
                          isActive={(match, location) =>
                            this.checkIfRouteActive(
                              location.pathname,
                              'custom-activities',
                              ['/archives/custom-activities']
                            )
                          }
                          tag="span"
                          onMouseEnter={() => AsyncCustomActivities.preload()}
                        >
                          <FormattedMessage
                            {...translations['menu.activities']}
                          />
                        </DropdownToggle>

                        <DropdownMenu
                          onMouseEnter={event => {
                            clearTimeout(this.activitiesDropdownTimeout);
                          }}
                        >
                          {customActivityCategories &&
                            customActivityCategories.size > 0 &&
                            customActivityCategories
                              .sort((a, b) => {
                                return a.get('order') > b.get('order') ? 1 : -1;
                              })
                              .valueSeq()
                              .map(customActivityCategory => (
                                <DropdownItem
                                  key={customActivityCategory.get('id')}
                                  to={
                                    '/custom-activities/' +
                                    customActivityCategory.get('id')
                                  }
                                  tag={RRNavLink}
                                  onClick={() =>
                                    tracking.track(
                                      `Menu - ${customActivityCategory.getIn([
                                        'name',
                                        'en',
                                      ])}`
                                    )
                                  }
                                >
                                  {customActivityCategory.getIn([
                                    'name',
                                    intl.locale,
                                  ]) ||
                                    customActivityCategory.getIn([
                                      'name',
                                      LocalizableFieldsDefaultLanguage,
                                    ])}
                                </DropdownItem>
                              ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    <Dropdown
                      // isOpen={this.state.dropdownMenuCharities}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        tag="span"
                        className="text-nowrap"
                        onMouseEnter={() => AsyncCharities.preload()}
                        isActive={(match, location) =>
                          this.checkIfRouteActive(
                            location.pathname,
                            'charities',
                            [
                              '/charity-partners/tree-planting',
                              '/charity-partners/tree-planting/new',
                            ]
                          )
                        }
                      >
                        <FormattedMessage
                          {...translations['menu.charity.treePlantingHeader']}
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          exact
                          to="/charities/tree-planting"
                          tag={RRNavLink}
                          onClick={() =>
                            tracking.track('Menu - Tree planting List')
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.charity.treePlantingList']}
                          />
                        </DropdownItem>
                        <DropdownItem
                          exact
                          to="/charities/tree-planting/new"
                          tag={RRNavLink}
                          onClick={() =>
                            tracking.track('Menu - Tree planting Set up')
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.charity.treePlantingCreate']}
                          />{' '}
                        </DropdownItem>
                        {isSuperAdmin && (
                          <DropdownItem
                            exact
                            to="/charity-partners/tree-planting/new"
                            tag={RRNavLink}
                          >
                            <FormattedMessage
                              {...translations[
                                'menu.charityPartners.treePlanting'
                              ]}
                            />
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>

                    {!isSdkEnabled && (
                      <Dropdown
                        // isOpen={this.state.dropdownMenuCharities}
                        isOpen={true}
                        toggle={() => {}}
                        nav
                      >
                        <DropdownToggle
                          nav
                          caret
                          tag="span"
                          className="text-nowrap"
                          onMouseEnter={() => AsyncCharities.preload()}
                          isActive={(match, location) =>
                            this.checkIfRouteActive(
                              location.pathname,
                              'charities',
                              [
                                '/charity-partners/donation',
                                '/charity-partners/donation/new',
                              ]
                            )
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.charity.donation']}
                          />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            exact
                            to="/charities/donation"
                            tag={RRNavLink}
                            onClick={() =>
                              tracking.track('Menu - Donation List')
                            }
                          >
                            <FormattedMessage
                              {...translations[
                                'menu.charity.donationProjectsList'
                              ]}
                            />
                          </DropdownItem>
                          <DropdownItem
                            exact
                            to="/charities/donation/new"
                            tag={RRNavLink}
                            onClick={() =>
                              tracking.track('Menu - Donation Set up')
                            }
                          >
                            <FormattedMessage
                              {...translations['menu.charity.donationCreate']}
                            />
                          </DropdownItem>
                          {isSuperAdmin && (
                            <DropdownItem
                              exact
                              to="/charity-partners/donation/new"
                              tag={RRNavLink}
                            >
                              <FormattedMessage
                                {...translations['menu.charity.donation']}
                              />
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    <Dropdown
                      // isOpen={this.state.dropdownMenuRewards}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        tag="span"
                        className="text-nowrap"
                        onMouseEnter={() => AsyncRewards.preload()}
                      >
                        <FormattedMessage {...translations['menu.rewards']} />
                      </DropdownToggle>
                      <DropdownMenu
                        onMouseEnter={event => {
                          clearTimeout(this.rewardsDropdownTimeout);
                        }}
                      >
                        <DropdownItem
                          to="/rewards/vouchers"
                          tag={RRNavLink}
                          onClick={() => tracking.track('Menu - Vouchers')}
                        >
                          <FormattedMessage
                            {...translations['menu.rewards.vouchers']}
                          />
                        </DropdownItem>
                        <DropdownItem
                          to="/rewards/lotteries"
                          tag={RRNavLink}
                          onClick={() => tracking.track('Menu - Raffles')}
                        >
                          <FormattedMessage
                            {...translations['menu.rewards.lotteries']}
                          />
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                    <Dropdown
                      // isOpen={this.state.dropdownMenuCommunication}
                      isOpen={true}
                      toggle={() => {}}
                      nav
                    >
                      <DropdownToggle
                        nav
                        caret
                        tag="span"
                        className="text-nowrap"
                        isActive={(match, location) =>
                          this.checkIfRouteActive(
                            location.pathname,
                            'communication',
                            [
                              '/about-info-text',
                              '/editorials',
                              '/tools',
                              '/faq/settings',
                              '/privacy-policy/settings',
                              '/terms-of-service/settings',
                              '/co2-contribution/settings',
                              '/custom-activity/settings',
                            ]
                          )
                        }
                        onMouseEnter={() => AsyncCommunication.preload()}
                      >
                        <FormattedMessage
                          {...translations['menu.communication']}
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          exact
                          to="/communication"
                          tag={RRNavLink}
                          onMouseEnter={() => AsyncCommunication.preload()}
                          onClick={() =>
                            tracking.track('Menu - Push Notifications')
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.pushNotifications']}
                          />
                        </DropdownItem>

                        <DropdownItem
                          exact
                          to="/about-info-text"
                          tag={RRNavLink}
                          onClick={() =>
                            tracking.track('Menu - About Info-Text')
                          }
                        >
                          <FormattedMessage
                            {...translations['menu.aboutInfoText']}
                          />
                        </DropdownItem>
                        {whitelabel.getIn(['menuItems', 'promoMaterials']) && (
                          <DropdownItem
                            exact
                            to="/tools"
                            tag={RRNavLink}
                            onMouseEnter={() => AsyncTools.preload()}
                            onClick={() => tracking.track('Menu - Tools')}
                          >
                            <FormattedMessage {...translations['menu.tools']} />
                          </DropdownItem>
                        )}
                        {isSuperAdmin && (
                          <DropdownItem exact to="/editorials" tag={RRNavLink}>
                            <FormattedMessage
                              {...translations['menu.editorials']}
                            />
                          </DropdownItem>
                        )}

                        {(isSdkEnabled || isWhiteLabel || isGlobal) && (
                          <>
                            <DropdownItem
                              exact
                              to="/faq/settings"
                              tag={RRNavLink}
                              onClick={() => tracking.track('Menu - FAQ')}
                            >
                              <FormattedMessage {...translations['menu.faq']} />
                            </DropdownItem>
                            <DropdownItem
                              exact
                              to="/privacy-policy/settings"
                              tag={RRNavLink}
                              onClick={() =>
                                tracking.track('Menu - Privacy Policy')
                              }
                            >
                              <FormattedMessage
                                {...translations['menu.privacyPolicy']}
                              />
                            </DropdownItem>
                            <DropdownItem
                              exact
                              to="/terms-of-service/settings"
                              tag={RRNavLink}
                              onClick={() =>
                                tracking.track('Menu - Terms of Service')
                              }
                            >
                              <FormattedMessage
                                {...translations['menu.termsOfService']}
                              />
                            </DropdownItem>
                            {organisation &&
                              whitelabel.getIn([
                                'menuItems',
                                'editorials',
                                'co2Contribution',
                              ]) && (
                                <>
                                  <DropdownItem
                                    exact
                                    to="/co2-contribution/settings"
                                    tag={RRNavLink}
                                    onClick={() =>
                                      tracking.track(
                                        'Menu - CO2 Contribution Editorial'
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      {...translations[
                                        'menu.co2ContributionEditorial'
                                      ]}
                                    />
                                  </DropdownItem>
                                </>
                              )}
                            {organisation &&
                              whitelabel.getIn([
                                'menuItems',
                                'editorials',
                                'customActivity',
                              ]) && (
                                <>
                                  <DropdownItem
                                    exact
                                    to="/custom-activity/settings"
                                    tag={RRNavLink}
                                    onClick={() =>
                                      tracking.track(
                                        'Menu - Custom Activity Editorial'
                                      )
                                    }
                                  >
                                    <FormattedMessage
                                      {...translations[
                                        'menu.customActivityEditorial'
                                      ]}
                                    />
                                  </DropdownItem>
                                </>
                              )}
                          </>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </React.Fragment>
                )}

                {isAuthenticated && (
                  <Dropdown
                    // isOpen={this.state.dropdownMenuAccount}
                    isOpen={true}
                    toggle={() => {}}
                    nav
                  >
                    <DropdownToggle
                      nav
                      caret
                      isActive={(match, location) =>
                        this.checkIfRouteActive(location.pathname, 'account', [
                          '/billing',
                          '/current-plan',
                          '/download-center',
                        ])
                      }
                      tag="span"
                      className="text-nowrap"
                      onMouseEnter={() => AsyncAccount.preload()}
                    >
                      <FormattedMessage {...translations['menu.account']} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        exact
                        to="/account"
                        tag={RRNavLink}
                        onClick={() => tracking.track('Menu - Your Profile')}
                      >
                        <FormattedMessage
                          {...translations['menu.account.profile']}
                        />
                      </DropdownItem>

                      {!isGlobal && !isAdminHost() && isAllowedToAccess && (
                        <DropdownItem to="/account/teams" tag={RRNavLink}>
                          <FormattedMessage
                            {...translations['menu.account.teams']}
                          />
                        </DropdownItem>
                      )}

                      {isAuthenticated && isAdminHost() && !billingDeactivated && (
                        <React.Fragment>
                          <DropdownItem
                            to="/current-plan"
                            tag={RRNavLink}
                            onMouseEnter={() => AsyncSubscription.preload()}
                          >
                            <FormattedMessage
                              {...translations['menu.account.currentPlan']}
                            />
                          </DropdownItem>
                          <DropdownItem
                            to="/billing"
                            tag={RRNavLink}
                            onMouseEnter={() => AsyncSubscription.preload()}
                          >
                            <FormattedMessage
                              {...translations['menu.account.billingDetails']}
                            />
                          </DropdownItem>
                        </React.Fragment>
                      )}

                      {isAdminHost() &&
                        (whitelabel.getIn(['menuItems', 'downloadCenter']) && (
                          <React.Fragment>
                            <DropdownItem
                              to="/download-center"
                              tag={RRNavLink}
                              onClick={() =>
                                tracking.track('Menu - Your Profile')
                              }
                            >
                              <FormattedMessage
                                {...translations['menu.account.downloadCenter']}
                              />
                            </DropdownItem>
                          </React.Fragment>
                        ))}

                      {isAuthenticated && (
                        <DropdownItem
                          to="#"
                          tag={RRNavLink}
                          onClick={
                            (event => {
                              tracking.track('Menu - Sign Out');
                              this.toggleDropdownMenu('Sign out', true);
                            },
                            logoutAction)
                          }
                        >
                          <FormattedMessage {...translations['menu.signout']} />
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}

                {isAuthenticated &&
                  isAdminHost() &&
                  isSuperAdmin &&
                  isAllowedToAccess && (
                    <NavItem>
                      <NavLink
                        to="/administration"
                        tag={RRNavLink}
                        onMouseEnter={() => AsyncAdministration.preload()}
                      >
                        <FormattedMessage
                          {...translations['menu.administration']}
                        />
                      </NavLink>
                    </NavItem>
                  )}

                {isAuthenticated &&
                  isAdminHost() &&
                  isSuperAdmin &&
                  isAllowedToAccess && (
                    <NavItem>
                      <NavLink
                        to="/compensation-partners"
                        tag={RRNavLink}
                        onMouseEnter={() => AsyncCompensationPartners.preload()}
                      >
                        <FormattedMessage
                          {...translations['menu.co2.compensationPartners']}
                        />
                      </NavLink>
                    </NavItem>
                  )}

                {fullNav && (
                  <React.Fragment>
                    {[
                      ['globalChallenge', '#', '#', false],
                      [
                        'pricing',
                        'https://changers.com/pricing/',
                        'https://changers.com/de/prise/',
                        false,
                      ],
                      [
                        'faq',
                        'https://changers.com/faq/',
                        'https://changers.com/de/faq/',
                        true,
                      ],
                      [
                        'blog',
                        'https://changers.com/blog/',
                        'https://changers.com/de/blog/',
                        true,
                      ],
                    ]
                      .filter(
                        ([text, enLink, deLink, status]) => status === true
                      )
                      .map(([text, enLink, deLink]) => {
                        const link = enLink;
                        return (
                          <NavItem key={text}>
                            <NavLink
                              href={link}
                              className={`${
                                text === 'globalChallenge' ? 'active' : ''
                              }`}
                            >
                              <FormattedMessage
                                {...translations[`menu.website.${text}`]}
                              />
                            </NavLink>
                          </NavItem>
                        );
                      })}
                  </React.Fragment>
                )}

                <LanguageToggle
                  activeLocale={activeLocale}
                  onLocaleChange={onLocaleChange}
                />

                {/* {isAuthenticated &&
                  isAdminHost() &&
                  showUpgrade &&
                  !hasSubscription &&
                  whitelabel.getIn(['menuItems', 'billing']) &&
                  isAllowedToAccess && (
                    <NavItem className="d-lg-none">
                      <NavLink
                        to="/billing/upgrade"
                        tag={RRNavLink}
                        className="btn btn-outline-success"
                        onMouseEnter={() => AsyncSubscription.preload()}
                      >
                        <FormattedMessage {...translations['menu.upgrade']} />
                      </NavLink>
                    </NavItem>
                  )} */}

                {fullNav && (
                  <NavItem
                    className={`${comesFromReferrer ? 'invisible' : ''}`}
                  >
                    {/* TODO: Change hardcoded url */}
                    <NavLink
                      href="https://admin.changers.com/auth/register/organisation"
                      className={`btn btn-success ${styles.btnWebsiteCta} ml-5`}
                    >
                      <FormattedMessage
                        {...translations['menu.website.getTrial']}
                      />
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default Sidebar;
