import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { treeTypesListSchema } from '../../schemas';

import * as TreeTypesActionTypes from '../../constants/actionTypes/treeTypes';
import * as AuthActionTypes from '../../constants/actionTypes/auth';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Challenges
 */
export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case TreeTypesActionTypes.TREE_TYPES_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, treeTypesListSchema);
      const normalizedTreeTypes = fromJS(normalizedFetch.entities.treeTypes);

      if (!normalizedTreeTypes) {
        return state;
      }

      return state.merge({
        byId: normalizedTreeTypes,
        result: fromJS(normalizedFetch.result),
      });
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
