import { RSAA } from 'redux-api-middleware';

import config from 'config';

/**
 * Redux Middleware
 *
 * Adds Authorization header on API requests to OP
 */
export default store => next => action => {
  // if is an API request
  if (action && action[RSAA]) {
    const accessToken = store.getState().getIn(['auth', 'accessToken']);
    const endpoint = action[RSAA].endpoint;

    if (!action[RSAA].headers) {
      action[RSAA].headers = {};
    }

    // if access_token exists
    // and the API call is for our API
    if (
      endpoint &&
      accessToken &&
      (endpoint.indexOf(config.apiEndpoint) > -1 ||
        endpoint.indexOf(config.apiStatsEndpoint) > -1 ||
        endpoint.indexOf(config.apiTrackingEndpoint) > -1 ||
        endpoint.indexOf(config.apiCoinStageEndpoint) > -1)
    ) {
      // add Authorization header
      action[RSAA].headers.Authorization = `Bearer ${accessToken}`;
    }

    // SST API requires the X-Agent to be set
    if (action[RSAA].headers['X-Agent'] !== null) {
      action[RSAA].headers['X-Agent'] = 'Admin';
    } else {
      delete action[RSAA].headers['X-Agent'];
    }

    // SST API support localization using `Accept-Language`
    // for the Admin Interface though we need all langs, which can be achieved if no header send
    // browsers adds this header automatically in fetch API, so let's make it blank
    if (!action[RSAA].headers['Accept-Language']) {
      action[RSAA].headers['Accept-Language'] = '*';
    }
  }

  // So the middleware doesn't get applied to every single action
  return next(action);
};
