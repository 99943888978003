import { Map } from 'immutable';

import * as ErrorActionTypes from '../constants/actionTypes/error';

const initialState = Map({
  //   // is Fatal error, redirect back to OP
  //   redirect: false,
  //   message: null,
  //   type: null,
  //   status: null,
  //   statusText: null,
  //   retryDelay: null
});

/**
 * Updates error message to notify about the failed fetches.
 */
const reducer = (state = initialState, action) => {
  const { type, error, payload = {}, meta = {} } = action;

  // try to unpack location redirect payload
  const { location } = payload;

  if (
    type === ErrorActionTypes.RESET_ERROR_MESSAGE ||
    // if is not a redirect back to index page or error one
    // then clear error data
    (!!location && !!location.pathname && location.pathname !== '/')
  ) {
    return initialState;
  } else if (type === ErrorActionTypes.ADD_ERROR_MESSAGE || error) {
    const { status, statusText } = payload;

    return state.merge({ status, statusText, ...meta });
  }

  return state;
};

export default reducer;
