import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as DonationsActionTypes from '../constants/actionTypes/donations';

/**
 * Fetch Donations
 * @returns Promise
 */
const fetchDonations = () => ({
  [RSAA]: {
    types: [
      DonationsActionTypes.DONATIONS_FETCH_REQUEST,
      DonationsActionTypes.DONATIONS_FETCH_SUCCESS,
      DonationsActionTypes.DONATIONS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/donation?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Adds a new Donation
 * @param data
 * @returns Promise
 */
const addDonation = data => ({
  [RSAA]: {
    types: [
      DonationsActionTypes.DONATIONS_ADD_REQUEST,
      DonationsActionTypes.DONATIONS_ADD_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ DonationsActionTypes.DONATIONS_ADD_FAILURE,
        /* entity */ 'donations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/donation',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a Donation
 * @param data
 * @returns Promise
 */
const updateDonation = (donationId, data) => ({
  [RSAA]: {
    types: [
      DonationsActionTypes.DONATIONS_UPDATE_REQUEST,
      {
        type: DonationsActionTypes.DONATIONS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ donationId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ DonationsActionTypes.DONATIONS_UPDATE_FAILURE,
        /* entity */ 'donations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/donation/' + donationId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});
/**
 * Fetch a Donation Goals
 * @param data
 * @returns Promise
 */
const fetchDonationGoals = donationId => ({
  [RSAA]: {
    types: [
      DonationsActionTypes.DONATIONS_GOALS_FETCH_REQUEST,
      {
        type: DonationsActionTypes.DONATIONS_GOALS_FETCH_SUCCESS,
        meta: (action, state, res) => ({ donationId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ DonationsActionTypes.DONATIONS_GOALS_FETCH_FAILURE,
        /* entity */ 'donations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/donation/' + donationId + '/goal',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update a Donation Goal
 * @param data
 * @returns Promise
 */
const addDonationGoal = (donationId, goal) => ({
  [RSAA]: {
    types: [
      DonationsActionTypes.DONATIONS_GOALS_UPDATE_REQUEST,
      {
        type: DonationsActionTypes.DONATIONS_GOALS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ donationId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ DonationsActionTypes.DONATIONS_GOALS_UPDATE_FAILURE,
        /* entity */ 'donations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/donation/' + donationId + '/goal',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ goal }),
  },
});
export {
  fetchDonations,
  addDonation,
  updateDonation,
  fetchDonationGoals,
  addDonationGoal,
};
