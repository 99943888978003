import { fromJS } from 'immutable';

import * as AuthActionTypes from '../../../constants/actionTypes/auth';
import * as OrganisationBillingActionTypes from '../../../constants/actionTypes/organisationBilling';
import { formatAmount } from '../../../utils/number';

const initialState = fromJS({});

/**
 * Handles Billing Plans
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case OrganisationBillingActionTypes.BILLING_PLANS_FETCH_SUCCESS: {
      const allPlans = Object.keys(payload).reduce((plans, key) => {
        const planData = payload[key];
        const tiers = planData.tiers.reduce((allTiers, tier) => {
          const updatedTier = { ...tier };
          updatedTier.lower =
            allTiers.length === 0 ? 1 : allTiers[allTiers.length - 1].upper + 1;
          updatedTier.upper = tier.users;

          updatedTier.formatted_price_per_month = formatAmount({
            number: updatedTier.price_per_month,
            dp: 2,
          });

          allTiers.push(updatedTier);

          return allTiers;
        }, []);

        plans[key] = {
          key,
          tiers,
        };

        return plans;
      }, {});
      return fromJS(allPlans);
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
