import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { dynamicLinkConfigSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as DynamicLinkConfigTypes from '../../constants/actionTypes/dynamicLinkConfig';

const initialState = fromJS({});

/**
 * Handles dynamic link configs
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case DynamicLinkConfigTypes.DYNAMIC_LINK_CONFIG_FETCH_SUCCESS: {
      let normalizedDynamicLinkConfig = null;
      if (data) {
        const normalizedFetch = normalize(data, dynamicLinkConfigSchema);
        normalizedDynamicLinkConfig = fromJS(
          normalizedFetch.entities.dynamicLinkConfig
        );
      }

      if (!data || !normalizedDynamicLinkConfig) {
        return state;
      }

      return state.merge(normalizedDynamicLinkConfig);
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
