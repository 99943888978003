const CharitiesTypes = {
  DONATION: 'donation',
  TREE_PLANTING: 'tree-planting',
};

const CostTypes = {
  KM: 1,
  RECOIN: 2,
};
const CharityStates = {
  ACTIVE: 1,
  NEW: 2,
  PAUSED: 3,
  FINISHED: 4,
};
const DonationProjects = {
  UNICEF: 1,
  GREENPEACE: 2,
  SOS_CHILDRENS_VILLAGE: 3,
  MISEREOR: 4,
  WWF: 5,
  AERTZE_OHNE_GRENZEN: 6,
  AMNESTY: 7,
  WATER_ORG: 8,
  BROT_WELT: 9,
  OXFAM: 10,
};

const TreeTypes = {
  PINE: 1,
  LARCH: 2,
  BLACK_ALDER: 3,
  OAK: 4,
  BEECH: 5,
};

export {
  CharitiesTypes,
  CostTypes,
  DonationProjects,
  TreeTypes,
  CharityStates,
};
