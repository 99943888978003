export const CUSTOM_ACTIVITIES_FETCH_REQUEST =
  'changers/admin/CUSTOM_ACTIVITIES_FETCH_REQUEST';
export const CUSTOM_ACTIVITIES_FETCH_SUCCESS =
  'changers/admin/CUSTOM_ACTIVITIES_FETCH_SUCCESS';
export const CUSTOM_ACTIVITIES_FETCH_FAILURE =
  'changers/admin/CUSTOM_ACTIVITIES_FETCH_FAILURE';

export const ARCHIVED_CUSTOM_ACTIVITIES_FETCH_REQUEST =
  'changers/admin/ARCHIVED_CUSTOM_ACTIVITIES_FETCH_REQUEST';
export const ARCHIVED_CUSTOM_ACTIVITIES_FETCH_SUCCESS =
  'changers/admin/ARCHIVED_CUSTOM_ACTIVITIES_FETCH_SUCCESS';
export const ARCHIVED_CUSTOM_ACTIVITIES_FETCH_FAILURE =
  'changers/admin/ARCHIVED_CUSTOM_ACTIVITIES_FETCH_FAILURE';

export const RESTRICTED_CUSTOM_ACTIVITIES_FETCH_REQUEST =
  'changers/admin/RESTRICTED_CUSTOM_ACTIVITIES_FETCH_REQUEST';
export const RESTRICTED_CUSTOM_ACTIVITIES_FETCH_SUCCESS =
  'changers/admin/RESTRICTED_CUSTOM_ACTIVITIES_FETCH_SUCCESS';
export const RESTRICTED_CUSTOM_ACTIVITIES_FETCH_FAILURE =
  'changers/admin/RESTRICTED_CUSTOM_ACTIVITIES_FETCH_FAILURE';

export const ADD_ACTIVITY_REQUEST = 'changers/admin/ADD_ACTIVITY_REQUEST';
export const ADD_ACTIVITY_SUCCESS = 'changers/admin/ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAILURE = 'changers/admin/ADD_ACTIVITY_FAILURE';

export const RESTRICTED_ADD_ACTIVITY_REQUEST =
  'changers/admin/RESTRICTED_ADD_ACTIVITY_REQUEST';
export const RESTRICTED_ADD_ACTIVITY_SUCCESS =
  'changers/admin/RESTRICTED_ADD_ACTIVITY_SUCCESS';
export const RESTRICTED_ADD_ACTIVITY_FAILURE =
  'changers/admin/RESTRICTED_ADD_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITY_REQUEST = 'changers/admin/UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'changers/admin/UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'changers/admin/UPDATE_ACTIVITY_FAILURE';

export const RESTRICTED_UPDATE_ACTIVITY_REQUEST =
  'changers/admin/RESTRICTED_UPDATE_ACTIVITY_REQUEST';
export const RESTRICTED_UPDATE_ACTIVITY_SUCCESS =
  'changers/admin/RESTRICTED_UPDATE_ACTIVITY_SUCCESS';
export const RESTRICTED_UPDATE_ACTIVITY_FAILURE =
  'changers/admin/RESTRICTED_UPDATE_ACTIVITY_FAILURE';

export const ACTIVATE_ACTIVITY_REQUEST =
  'changers/admin/ACTIVATE_ACTIVITY_REQUEST';
export const ACTIVATE_ACTIVITY_SUCCESS =
  'changers/admin/ACTIVATE_ACTIVITY_SUCCESS';
export const ACTIVATE_ACTIVITY_FAILURE =
  'changers/admin/ACTIVATE_ACTIVITY_FAILURE';

export const DEACTIVATE_ACTIVITY_REQUEST =
  'changers/admin/DEACTIVATE_ACTIVITY_REQUEST';
export const DEACTIVATE_ACTIVITY_SUCCESS =
  'changers/admin/DEACTIVATE_ACTIVITY_SUCCESS';
export const DEACTIVATE_ACTIVITY_FAILURE =
  'changers/admin/DEACTIVATE_ACTIVITY_FAILURE';

export const TRACK_NEW_ACTIVITY_REQUEST =
  'changers/admin/TRACK_NEW_ACTIVITY_REQUEST';
export const TRACK_NEW_ACTIVITY_SUCCESS =
  'changers/admin/TRACK_NEW_ACTIVITY_SUCCESS';
export const TRACK_NEW_ACTIVITY_FAILURE =
  'changers/admin/TRACK_NEW_ACTIVITY_FAILURE';

export const SHOW_ACTIVITY_QR_CODE_REQUEST =
  'changers/admin/SHOW_ACTIVITY_QR_CODE_REQUEST';
export const SHOW_ACTIVITY_QR_CODE_SUCCESS =
  'changers/admin/SHOW_ACTIVITY_QR_CODE_SUCCESS';
export const SHOW_ACTIVITY_QR_CODE_FAILURE =
  'changers/admin/SHOW_ACTIVITY_QR_CODE_FAILURE';

export const ARCHIVE_ACTIVITY_REQUEST =
  'changers/admin/ARCHIVE_ACTIVITY_REQUEST';
export const ARCHIVE_ACTIVITY_SUCCESS =
  'changers/admin/ARCHIVE_ACTIVITY_SUCCESS';
export const ARCHIVE_ACTIVITY_FAILURE =
  'changers/admin/ARCHIVE_ACTIVITY_FAILURE';

export const UNARCHIVE_ACTIVITY_REQUEST =
  'changers/admin/UNARCHIVE_ACTIVITY_REQUEST';
export const UNARCHIVE_ACTIVITY_SUCCESS =
  'changers/admin/UNARCHIVE_ACTIVITY_SUCCESS';
export const UNARCHIVE_ACTIVITY_FAILURE =
  'changers/admin/UNARCHIVE_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITY_ORDER_STATE =
  'changers/admin/UPDATE_ACTIVITY_ORDER_STATE';

export const UPDATE_ACTIVITY_ORDER_REQUEST =
  'changers/admin/UPDATE_ACTIVITY_ORDER_REQUEST';
export const UPDATE_ACTIVITY_ORDER_SUCCESS =
  'changers/admin/UPDATE_ACTIVITY_ORDER_SUCCESS';
export const UPDATE_ACTIVITY_ORDER_FAILURE =
  'changers/admin/UPDATE_ACTIVITY_ORDER_FAILURE';
