import { defineMessages } from 'react-intl';

export default defineMessages({
  'form.info.maxChars': {
    id: 'form.info.maxChars',
    defaultMessage: 'Max. {maxChars} characters',
    description:
      'Used as informational text in forms when a field has a max chars limit',
  },
  'form.info.ifAvailable': {
    id: 'form.info.ifAvailable',
    defaultMessage: '*if available',
    description: 'Used as informational text in forms for optional fields',
  },
  'form.passwordPolicy': {
    id: 'form.passwordPolicy',
    defaultMessage:
      'Your password must have a minimum of 8 characters and should include at least 1 upper and 1 lower case character as well as 1 digit.',
    description: 'Used as informational text for password policy',
  },
  'form.unsavedChanges': {
    id: 'form.unsavedChanges',
    defaultMessage:
      'You have not saved your changes yet. Do you want to leave this page without saving?',
    description:
      'Shown when users attempt to leave a page with unsaved changes',
  },
});
