import Loadable from 'react-loadable';
import LoadingCurtain from './LoadingCurtain/LoadingCurtain';

const AsyncAuthentication = Loadable({
  loader: () => import('../containers/Authentication/Authentication'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncWelcome from '../components/Welcome/Welcome';
// const AsyncWelcome = Loadablec(() =>
//   import('../components/Welcome/Welcome'),
//   defaultOptions
// );
const AsyncWelcome = Loadable({
  loader: () => import('../components/Welcome/Welcome'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncOrganisationDashboard from '../containers/OrganisationDashboard';
// const AsyncOrganisationDashboard = Loadablec(() =>
//   import('../containers/OrganisationDashboard'),
//   defaultOptions
// );
const AsyncOrganisationDashboard = Loadable({
  loader: () => import('../containers/OrganisationDashboard'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncGlobalDashboard from '../containers/GlobalDashboard/GlobalDashboard';
// const AsyncGlobalDashboard = Loadablec(() =>
//   import('../containers/GlobalDashboard/GlobalDashboard'),
//   defaultOptions
// );
const AsyncGlobalDashboard = Loadable({
  loader: () => import('../containers/GlobalDashboard/GlobalDashboard'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncAccount from '../containers/Account/Account';
// const AsyncAccount = Loadablec(() =>
//   import('../containers/Account/Account'),
//   defaultOptions
// );

const AsyncAccount = Loadable({
  loader: () => import('../containers/Account/Account'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncSubscription from '../containers/Subscription/Subscription';

// const AsyncSubscription = Loadablec(() =>
//   import('../containers/Subscription/Subscription'),
//   defaultOptions
// );

const AsyncSubscription = Loadable({
  loader: () => import('../containers/Subscription/Subscription'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncChallenge from '../containers/Challenge/Challenge';

// const AsyncChallenge = Loadablec(() =>
//   import('../containers/Challenge/Challenge'),
//   defaultOptions
// );
const AsyncChallenge = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Challenge" */ '../containers/Challenge/Challenge'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncChallenges from '../containers/Challenges/Challenges';

// const AsyncChallenges = Loadablec(() =>
//   import('../containers/Challenges/Challenges'),
//   defaultOptions
// );
const AsyncChallenges = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Challenges" */ '../containers/Challenges/Challenges'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncHomeOffice = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Challenges" */ '../containers/HomeOffice/HomeOffice'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncCharities from '../containers/Charities/Charities';
// const AsyncCharities = Loadablec(() =>
//   import('../containers/Charities/Charities'),
//   defaultOptions
// );
const AsyncCharities = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Charities" */ '../containers/Charities/Charities'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});
const AsyncCharityPartners = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CharityPartners" */ '../containers/CharityPartners/CharityPartners'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncTeams from '../containers/Teams/Teams';

// const AsyncTeams = Loadablec(() =>
//   import('../containers/Teams/Teams'),
//   defaultOptions
// );
const AsyncTeams = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Teams" */ '../containers/Teams/Teams'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncTeams from '../containers/Teams/Teams';

// const AsyncTeams = Loadablec(() =>
//   import('../containers/Teams/Teams'),
//   defaultOptions
// );
const AsyncRecognition = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Recognition" */ '../containers/Recognition/Recognition'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncCustomActivities from '../containers/CustomActivities/CustomActivities';
// const AsyncCustomActivities = Loadablec(() =>
//   import('../containers/CustomActivities/CustomActivities'),
//   defaultOptions
// );
const AsyncCustomActivities = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CustomActivities" */ '../containers/CustomActivities/CustomActivities'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncCompensationPartners = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompensationPartners" */ '../containers/CompensationPartners/CompensationPartners'
    ),
  loading: LoadingCurtain,
  delay: 300,
});

// import AsyncRewards from '../containers/Rewards/Rewards';
// const AsyncRewards = Loadablec(() =>
//   import('../containers/Rewards/Rewards'),
//   defaultOptions
// );
const AsyncRewards = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Rewards" */ '../containers/Rewards/Rewards'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncUserDashboard from '../containers/UserDashboard';
// const AsyncUserDashboard = Loadablec(() =>
//   import('../containers/UserDashboard'),
//   defaultOptions
// );
const AsyncUserDashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserDashboard" */ '../containers/UserDashboard'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncUserMarketplace from '../containers/UserMarketplace/UserMarketplace';
// const AsyncUserMarketplace = Loadablec(() =>
//   import('../containers/UserMarketplace/UserMarketplace'),
//   defaultOptions
// );
const AsyncUserMarketplace = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserMarketplace" */ '../containers/UserMarketplace/UserMarketplace'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncAdministration from '../containers/Administration';

// const AsyncAdministration = Loadablec(() =>
//   import('../containers/Administration'),
//   defaultOptions
// );
const AsyncAdministration = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Administration" */ '../containers/Administration'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncIframe from '../components/Iframe/Iframe';
// const AsyncIframe = Loadablec(() =>
//   import('../components/Iframe/Iframe'),
//   defaultOptions
// );
const AsyncIframe = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Iframe" */ '../containers/IframeWidget/IframeWidget.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncInfoText = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ChallengeInfoText" */ '../containers/ChallengeInfoText/ChallengeInfoText.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncFaq = Loadable({
  loader: () => import(/* webpackChunkName: "Faq" */ '../containers/Faq/Faq'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncPrivacyPolicy = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PrivacyPolicy" */ '../containers/PrivacyPolicy/PrivacyPolicy'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncTermsOfService = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TermsOfService" */ '../containers/TermsOfService/TermsOfService'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});
const AsyncCO2ContributionEditorial = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Faq" */ '../containers/CO2ContributionEditorial/CO2ContributionEditorial'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});
const AsyncCustomActivityEditorial = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Faq" */ '../containers/CustomActivityEditorial/CustomActivityEditorial'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});
const AsyncReferralSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ReferralSettings" */ '../containers/ReferralSettings/ReferralSettings'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncDynamicLinks = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DynamicLinks" */ '../containers/DynamicLinks/DynamicLinks'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncDownloadCenter from '../components/DownloadCenter/DownloadCenter';
// const AsyncDownloadCenter = Loadablec(() =>
//   import('../components/DownloadCenter/DownloadCenter'),
//   defaultOptions
// );
const AsyncDownloadCenter = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "DownloadCenter" */ '../components/DownloadCenter/DownloadCenter.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncGoodBye from '../components/GoodBye/GoodBye';
// const AsyncGoodBye = Loadablec(() =>
//   import('../components/GoodBye/GoodBye'),
//   defaultOptions
// );
const AsyncGoodBye = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "GoodBye" */ '../components/GoodBye/GoodBye.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import AsyncSubscriptionCancelled from '../components/SubscriptionCancelled/SubscriptionCancelled';
// const AsyncSubscriptionCancelled = Loadablec(() =>
//   import('../components/SubscriptionCancelled/SubscriptionCancelled'),
//   defaultOptions
// );
const AsyncSubscriptionCancelled = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "GoodBye" */ '../components/SubscriptionCancelled/SubscriptionCancelled.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

// import Communication from '../components/Communication/Communication';
// const Communication = Loadablec(() =>
//   import('../components/Communication/Communication'),
//   defaultOptions
// );
const AsyncCommunication = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "GoodBye" */ '../containers/Communication/Communication.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncTools = Loadable({
  loader: () =>
    import(/* webpackChunkName: "GoodBye" */ '../containers/Tools/Tools.js'),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncUserReferralInvite = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "GoodBye" */ '../containers/UserReferralInvite/UserReferralInvite.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
  // timeout: 15,
});

const AsyncArchives = Loadable({
  loader: () => import('../containers/Archives/Archives.js'),
  loading: LoadingCurtain,
  delay: 300,
});

const AsyncReports = Loadable({
  loader: () => import('../containers/Reports/Reports.js'),
  loading: LoadingCurtain,
  delay: 300,
});

const AsyncEditorials = Loadable({
  loader: () => import('../containers/Editorials/Editorials.js'),
  loading: LoadingCurtain,
  delay: 300,
});

const AsyncOrganisationOnboardModal = Loadable({
  loader: () =>
    import(
      '../components/OrganizationOnboardModal/OrganizationOnboardModal.js'
    ),
  loading: LoadingCurtain,
  delay: 300,
});

export {
  AsyncHomeOffice,
  AsyncAuthentication,
  AsyncOrganisationDashboard,
  AsyncGlobalDashboard,
  AsyncAccount,
  AsyncSubscription,
  AsyncChallenge,
  AsyncChallenges,
  AsyncCharities,
  AsyncCharityPartners,
  AsyncTeams,
  AsyncCustomActivities,
  AsyncRewards,
  AsyncCommunication,
  AsyncUserDashboard,
  AsyncUserMarketplace,
  AsyncAdministration,
  AsyncIframe,
  AsyncDownloadCenter,
  AsyncWelcome,
  AsyncGoodBye,
  AsyncInfoText,
  AsyncFaq,
  AsyncPrivacyPolicy,
  AsyncTermsOfService,
  AsyncCO2ContributionEditorial,
  AsyncCustomActivityEditorial,
  AsyncReferralSettings,
  AsyncDynamicLinks,
  AsyncRecognition,
  AsyncArchives,
  AsyncCompensationPartners,
  AsyncUserReferralInvite,
  AsyncReports,
  AsyncEditorials,
  AsyncSubscriptionCancelled,
  AsyncOrganisationOnboardModal,
  AsyncTools,
};
