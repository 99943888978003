export const DONATIONS_FETCH_REQUEST = 'changers/admin/DONATIONS_FETCH_REQUEST';
export const DONATIONS_FETCH_SUCCESS = 'changers/admin/DONATIONS_FETCH_SUCCESS';
export const DONATIONS_FETCH_FAILURE = 'changers/admin/DONATIONS_FETCH_FAILURE';

export const DONATIONS_ADD_REQUEST = 'changers/admin/DONATIONS_ADD_REQUEST';
export const DONATIONS_ADD_SUCCESS = 'changers/admin/DONATIONS_ADD_SUCCESS';
export const DONATIONS_ADD_FAILURE = 'changers/admin/DONATIONS_ADD_FAILURE';

export const DONATIONS_UPDATE_REQUEST =
  'changers/admin/DONATIONS_UPDATE_REQUEST';
export const DONATIONS_UPDATE_SUCCESS =
  'changers/admin/DONATIONS_UPDATE_SUCCESS';
export const DONATIONS_UPDATE_FAILURE =
  'changers/admin/DONATIONS_UPDATE_FAILURE';

export const DONATIONS_GOALS_FETCH_REQUEST =
  'changers/admin/DONATIONS_GOALS_FETCH_REQUEST';
export const DONATIONS_GOALS_FETCH_SUCCESS =
  'changers/admin/DONATIONS_GOALS_FETCH_SUCCESS';
export const DONATIONS_GOALS_FETCH_FAILURE =
  'changers/admin/DONATIONS_GOALS_FETCH_FAILURE';

export const DONATIONS_GOALS_UPDATE_REQUEST =
  'changers/admin/TDONATIONS_GOALS_UPDATE_REQUEST';
export const DONATIONS_GOALS_UPDATE_SUCCESS =
  'changers/admin/DONATIONS_GOALS_UPDATE_SUCCESS';
export const DONATIONS_GOALS_UPDATE_FAILURE =
  'changers/admin/DONATIONS_GOALS_UPDATE_FAILURE';
