import { schema } from 'normalizr';
import { processLocalizedFieldsFromAPI } from '../components/LocalizedInput/utils';

const compensationPartnerSchema = new schema.Entity(
  'compensationPartners',
  {},
  {
    processStrategy: entity => {
      const { name, description, slogan, compensations, ...rest } = entity;
      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
        description: processLocalizedFieldsFromAPI(description),
        slogan: processLocalizedFieldsFromAPI(slogan),
        compensations:
          compensations && compensations.length
            ? compensations.map(compensation => {
                const compensationDescription = compensation.description;
                const compensationTitle = compensation.title;
                const compensationSale = compensation.sale;
                return {
                  ...compensation,
                  title: processLocalizedFieldsFromAPI(compensationTitle),
                  description: processLocalizedFieldsFromAPI(
                    compensationDescription
                  ),
                  sale: processLocalizedFieldsFromAPI(compensationSale),
                };
              })
            : [],
      };
    },
  }
);

const compensationPartnersListSchema = [compensationPartnerSchema];

export { compensationPartnersListSchema };
