import config from 'config';

let ConfigureStore;

if (config.DEBUG) {
  ConfigureStore = require('./configureStore.dev').default;
} else {
  ConfigureStore = require('./configureStore.prod').default;
}

export default ConfigureStore;
