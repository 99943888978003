const actionPrefix = 'BILLING_PAYMENT_METHODS_';

export const GET_PAYMENT_METHODS = actionPrefix + 'GET_PAYMENT_METHODS';
export const SET_LOADING = actionPrefix + 'SET_LOADING';
export const GET_PAYMENT_METHODS_SUCCESS =
  actionPrefix + 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE =
  actionPrefix + 'GET_PAYMENT_METHODS_FAILURE';

export const GET_CARD_PAYMENT_METHODS_LIST =
  actionPrefix + 'GET_CARD_PAYMENT_METHODS_LIST';
export const GET_CARD_PAYMENT_METHODS_LIST_SUCCESS =
  actionPrefix + 'GET_CARD_PAYMENT_METHODS_LIST_SUCCESS';
export const GET_CARD_PAYMENT_METHODS_LIST_FAILURE =
  actionPrefix + 'GET_CARD_PAYMENT_METHODS_LIST_FAILURE';

export const GET_DEBIT_PAYMENT_METHODS_LIST =
  actionPrefix + 'GET_DEBIT_PAYMENT_METHODS_LIST';
export const GET_DEBIT_PAYMENT_METHODS_LIST_SUCCESS =
  actionPrefix + 'GET_DEBIT_PAYMENT_METHODS_LIST_SUCCESS';
export const GET_DEBIT_PAYMENT_METHODS_LIST_FAILURE =
  actionPrefix + 'GET_DEBIT_PAYMENT_METHODS_LIST_FAILURE';

export const DELETE_PAYMENT_METHOD = actionPrefix + 'DELETE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD_SUCCESS =
  actionPrefix + 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE =
  actionPrefix + 'DELETE_PAYMENT_METHOD_FAILURE';

export const ADD_PRIMARY_PAYMENT_METHOD =
  actionPrefix + 'ADD_PRIMARY_PAYMENT_METHOD';
export const ADD_PRIMARY_PAYMENT_METHOD_SUCCESS =
  actionPrefix + 'ADD_PRIMARY_PAYMENT_METHOD_SUCCESS';
export const ADD_PRIMARY_PAYMENT_METHOD_FAILURE =
  actionPrefix + 'ADD_PRIMARY_PAYMENT_METHOD_FAILURE';

export const GET_LIST_OF_PURCHASES = actionPrefix + 'GET_LIST_OF_PURCHASES';
export const GET_LIST_OF_PURCHASES_SUCCESS =
  actionPrefix + 'GET_LIST_OF_PURCHASES_SUCCESS';
export const GET_LIST_OF_PURCHASES_FAILURE =
  actionPrefix + 'GET_LIST_OF_PURCHASES_FAILURE';

export const GET_PURCHASE_INVOICE = actionPrefix + 'GET_PURCHASE_INVOICE';
export const GET_PURCHASE_INVOICE_SUCCESS =
  actionPrefix + 'GET_PURCHASE_INVOICE_SUCCESS';
export const GET_PURCHASE_INVOICE_FAILURE =
  actionPrefix + 'GET_PURCHASE_INVOICE_FAILURE';
