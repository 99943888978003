import { RSAA } from 'redux-api-middleware';

import { trackEvent, trackTiming } from '../analytics';

// stores request, success, failure (types) and start time of requests,
// in order we can listen on API response and stop the NProgress
const listeners = [];

const skipActionTypes = [];

/**
 * Redux Middleware
 *
 * Adds Authorization header on API requests to OP
 */
export default store => next => action => {
  // if is an API request
  if (action && action[RSAA]) {
    const callAPI = action[RSAA];

    // store callAPI types,
    // in order we can listen on API response and collect metrics
    if (callAPI.types && Array.isArray(callAPI.types)) {
      let requestType = callAPI.types[0];
      if (requestType && requestType.type) {
        requestType = requestType.type;
      }

      if (skipActionTypes.indexOf(requestType) > -1) {
        // no need for this middleware to take any action
        return next(action);
      }

      // if API call supports bailout, then check if call will execute
      const bailout = callAPI.bailout;
      if (
        (typeof bailout === 'boolean' && bailout) ||
        (typeof bailout === 'function' && bailout(store.getState()))
      ) {
        // no need for this middleware to take any action
        return next(action);
      }

      let successType = callAPI.types[1];
      if (successType && successType.type) {
        successType = successType.type;
      }

      let failureType = callAPI.types[2];
      if (failureType && failureType.type) {
        failureType = failureType.type;
      }

      // store types in an array
      listeners.push([requestType, successType, failureType]);
    }
  }

  // if listeners are not empty, then check if we have to stop NProgress
  if (listeners.length > 0 && action && action.type) {
    const { type, payload = {}, error } = action;

    // check if current action.type is registered in listeners
    const foundIndex = listeners.findIndex(
      value => value && value.indexOf(type) > -1
    );
    if (foundIndex && foundIndex > -1) {
      // if this is the request type
      if (listeners[foundIndex][0] === type) {
        // store the start time
        const startTime = Date.now();
        listeners[foundIndex][3] = startTime;
      } else {
        setTimeout(() => {
          const listener = listeners[foundIndex];
          if (listener) {
            const startTime = listener[3];
            const endTime = Date.now();
            const duration = endTime - startTime;

            const eventName = type.substr(type.lastIndexOf('/') + 1);

            // track timings in GA
            trackTiming('XHR_TIMING', {
              timingLabel: eventName,
              timingValue: duration,
            });

            const { status } = payload;

            // track failure events
            if (error && status) {
              trackEvent('XHR_FAILURE_STATUSES', {
                eventAction: eventName,
                eventLabel: status,
              });
            }

            // unset used listeners, without breaking array indexes because of concurrency issues (don't use splice)
            listeners[foundIndex] = null;
          }
        }, 0);
      }
    }
  }

  // so the middleware doesn't get applied to every single action
  return next(action);
};
