import { fromJS } from 'immutable';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as OrganisationBillingActionTypes from '../../constants/actionTypes/organisationBilling';

const initialState = fromJS({});

/**
 * Handles Organisation Billing details
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  switch (type) {
    case OrganisationBillingActionTypes.ORGANISATION_BILLING_FETCH_SUCCESS: {
      return fromJS(payload);
    }

    case OrganisationBillingActionTypes.STRIPE_TOKEN_UPDATE_SUCCESS:
    case OrganisationBillingActionTypes.ORGANISATION_BILLING_UPDATE_SUCCESS: {
      return state.mergeDeep(fromJS(data));
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
