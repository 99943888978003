import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import {
  treePlantingPartnersListSchema,
  // treePlantingPartnerSchema,
} from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as TreePlantingPartnersActionTypes from '../../constants/actionTypes/treePlantingPartners';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles TreePlantingPartners
 */
export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, treePlantingPartnersListSchema);
      const normalizedTreePlantingPartners = fromJS(
        normalizedFetch.entities.treePlantingPartners
      );

      if (!normalizedTreePlantingPartners) {
        return state;
      }

      return state.merge({
        byId: normalizedTreePlantingPartners,
        result: fromJS(normalizedFetch.result),
      });
    }

    case TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_ADD_SUCCESS:
    case TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_UPDATE_SUCCESS: {
      return state;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
