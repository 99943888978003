export const DONATION_PROJECTS_FETCH_REQUEST =
  'changers/admin/DONATION_PROJECTS_FETCH_REQUEST';
export const DONATION_PROJECTS_FETCH_SUCCESS =
  'changers/admin/DONATION_PROJECTS_FETCH_SUCCESS';
export const DONATION_PROJECTS_FETCH_FAILURE =
  'changers/admin/DONATION_PROJECTS_FETCH_FAILURE';

export const DONATION_PROJECTS_ADD_REQUEST =
  'changers/admin/DONATION_PROJECTS_ADD_REQUEST';
export const DONATION_PROJECTS_ADD_SUCCESS =
  'changers/admin/DONATION_PROJECTS_ADD_SUCCESS';
export const DONATION_PROJECTS_ADD_FAILURE =
  'changers/admin/DONATION_PROJECTS_ADD_FAILURE';

export const DONATION_PROJECTS_UPDATE_REQUEST =
  'changers/admin/DONATION_PROJECTS_UPDATE_REQUEST';
export const DONATION_PROJECTS_UPDATE_SUCCESS =
  'changers/admin/DONATION_PROJECTS_UPDATE_SUCCESS';
export const DONATION_PROJECTS_UPDATE_FAILURE =
  'changers/admin/DONATION_PROJECTS_UPDATE_FAILURE';

export const DONATION_PROJECT_DEFAULTS_UPDATE_REQUEST =
  'changers/admin/DONATION_PROJECT_DEFAULTS_UPDATE_REQUEST';
export const DONATION_PROJECT_DEFAULTS_SUCCESS =
  'changers/admin/DONATION_PROJECT_DEFAULTS_SUCCESS';
export const DONATION_PROJECT_DEFAULTS_FAILURE =
  'changers/admin/DONATION_PROJECT_DEFAULTS_FAILURE';
