import 'regenerator-runtime/runtime.js';

// eslint-disable-next-line no-unused-vars
import 'core-js/stable';

// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import 'isomorphic-fetch';
import { fromJS } from 'immutable';
import WebFont from 'webfontloader';
import 'blueimp-canvas-to-blob';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from 'config';
import { UIViews } from './constants';

import Root from './containers/Root/Root';
import configureStore from './store/configureStore';

import Storage from './utils/storage';
import storagePersister from './actors/storagePersister';

// import intl from './reducers/intl';
// import Analytics from './analytics'; // deprecated :/
import OutdatedBrowserWarning, {
  detectIE,
} from './utils/outdatedBrowserWarning';

// import global localized strings once for the whole project
import './i18n';

import './assets/styles/bootstrap/bootstrap.scss';
import './assets/styles/default.scss';

// check if is IE or Edge
if (/Trident|MSIE|Edge/g.test(window.navigator.userAgent)) {
  const root = document.getElementsByTagName('html')[0];
  root.className += ' ie';
}

Sentry.init({
  dsn:
    'https://4f9ca7cf1830477daa028c9235e30e05@o292127.ingest.sentry.io/5272263',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
  environment: process.env.NODE_ENV || 'development',
});

// load persisted state from localStorage
const loadPersistedState = () => {
  const state = {};

  // load locale state
  const localeState = Storage('sessionStorage').getItem(config.storage.locale);
  if (localeState !== null) {
    state.intl = { locale: localeState };
  }

  // load auth state if exists in sessionStorage
  const session = Storage('localStorage').getItem(config.storage.authSession);
  if (session !== null) {
    state.auth = JSON.parse(session);
  }

  // load COMMON ui_state if exists in localStorage
  const uiState = Storage('localStorage').getItem(config.storage.uiState);
  if (uiState !== null) {
    state.ui = {
      [UIViews.COMMON]: JSON.parse(uiState),
    };
  }

  return state;
};

// create redux store
const store = configureStore(fromJS(loadPersistedState()));

// handle storage persistence
const storagePersisterUnsubscriber = storagePersister(store); // eslint-disable-line no-unused-vars

const runApp = () => {
  ReactDOM.render(<Root store={store} />, document.getElementById('app'));
};

// adds support for Intl in older browsers
// fine to remove later
// check at http://caniuse.com/#search=Intl
if (!window.Intl) {
  // support Safari and IE
  // import(/* webpackChunkName: "IntlBundle" */ 'intl')
  //   .then(([intl]) => {
  //     window.Intl = intl.default;
  //     runApp();
  //   })
  //   .catch(err => {
  //     console.log('IntlBundle chunk loading failed');
  //   });
} else {
  // all modern browsers
  runApp();
}

// start analytics (loaded async)
// if (config.analytics.enabled) {
//   Analytics().then(analytics => analytics.init());
// }

// check if browser is IE<11 and show an upgrade browser warning to the user
const ieVersion = detectIE();

if (ieVersion) {
  // import(/* webpackChunkName: "iePolyfills" */ './utils/iePolyfills').catch(
  //   err => {
  //     console.log('iePolyfills chunk loading failed');
  //   }
  // );

  if (ieVersion < 11) {
    OutdatedBrowserWarning();
  }
}
WebFont.load({
  google: {
    families: ['Material Icons'],
  },
});
