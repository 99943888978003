/**
 * Async loader for the outdated browser library in a separate bundle
 * @returns {Promise}
 */
export default () => {
  // return new Promise.all([
  //   import(// import minimum jQuery components
  //   /* webpackChunkName: "OutdatedBrowserWarning" */ 'jquery/src/queue/delay'),
  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ 'jquery/src/attributes'),
  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ 'jquery/src/event/alias'),
  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ 'jquery/src/ajax/load'),
  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ 'jquery/src/deprecated'),
  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ 'jquery/src/exports/global'), // import jReject library

  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ '../assets/vendor/jReject/css/jquery.reject'),
  //   import(/* webpackChunkName: "OutdatedBrowserWarning" */ '../assets/vendor/jReject/js/jquery.reject'),
  // ]).then(() => {
  // show upgrade warning to user
  $.reject({
    reject: {
      msie: 10, // Microsoft Internet Explorer
    },
    display: ['chrome', 'firefox', 'safari', 'msie'],

    // Path where images are located
    imagePath: '//cdnjs.cloudflare.com/ajax/libs/jReject/1.1.4/images/',

    // Pop-up Window Text
    header: 'Upgrade your browser',

    paragraph1:
      'The browser you are currently using is outdated and unsafe ' +
      'to use. Click on one of the icons to download and install a ' +
      'recent version of your favourite browser.',

    paragraph2: '',

    // Disallow closing of window
    close: false,

    beforeReject: function() {
      if ($.os.name === 'iphone' || $.os.name === 'ipad') {
        // Everything is fine for iOS
        this.browserShow = false;
      }
    },
  });
  // });
};

/**
 * Get IE or Edge browser version
 * returns version of IE or false, if browser is not Internet Explorer
 */
export const detectIE = () => {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71
  // Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0
  // Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};
