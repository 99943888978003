import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { Map } from 'immutable';

import { basicFailureRSAAHandlerForUpdate } from '../utils/rsaa';

import * as TeamsActionTypes from '../constants/actionTypes/teams';
import { getCurrentUser } from '../selectors/users';

/**
 * Fetch my Teams
 * @returns Promise
 */
const fetchCurrentUserTeams = () => (dispatch, getState) =>
  dispatch({
    [RSAA]: {
      types: [
        TeamsActionTypes.CURRENT_USER_TEAMS_FETCH_REQUEST,
        TeamsActionTypes.CURRENT_USER_TEAMS_FETCH_SUCCESS,
        TeamsActionTypes.CURRENT_USER_TEAMS_FETCH_FAILURE,
      ],
      endpoint:
        config.apiEndpoint +
        '/api/user/' +
        (getCurrentUser(getState()) || Map()).get('id') +
        '/teams?per_page=500',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  });

/**
 * Fetch Teams
 * @returns Promise
 */
const fetchTeams = () => ({
  [RSAA]: {
    types: [
      TeamsActionTypes.TEAMS_FETCH_REQUEST,
      TeamsActionTypes.TEAMS_FETCH_SUCCESS,
      TeamsActionTypes.TEAMS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/team?per_page=1000',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update Teams
 * @param data
 * @returns Promise
 */
const updateTeams = data => ({
  [RSAA]: {
    types: [
      TeamsActionTypes.TEAMS_UPDATE_REQUEST,
      TeamsActionTypes.TEAMS_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ TeamsActionTypes.TEAMS_UPDATE_FAILURE,
        /* entity */ 'teams'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/teams',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Join Team
 * @param data
 * @returns Promise
 */
const joinTeam = id => ({
  [RSAA]: {
    types: [
      TeamsActionTypes.TEAM_JOIN_REQUEST,
      {
        type: TeamsActionTypes.TEAM_JOIN_SUCCESS,
        meta: (action, state, res) => ({ team_id: id }),
      },
      TeamsActionTypes.TEAM_JOIN_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/team/' + id + '/join',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Leave Team
 * @param data
 * @returns Promise
 */
const leaveTeam = id => ({
  [RSAA]: {
    types: [
      TeamsActionTypes.TEAM_LEAVE_REQUEST,
      {
        type: TeamsActionTypes.TEAM_LEAVE_SUCCESS,
        meta: (action, state, res) => ({ team_id: id }),
      },
      TeamsActionTypes.TEAM_LEAVE_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/team/' + id + '/leave',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

const updateTeamOrderState = data => ({
  type: TeamsActionTypes.UPDATE_TEAM_ORDER_STATE,
  payload: { data },
});

/**
 * Update Teams Order
 * @param data
 * @returns Promise
 */
const updateTeamOrder = data => ({
  [RSAA]: {
    types: [
      TeamsActionTypes.UPDATE_TEAM_ORDER_REQUEST,
      {
        type: TeamsActionTypes.UPDATE_TEAM_ORDER_SUCCESS,
      },
      TeamsActionTypes.UPDATE_TEAM_ORDER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/team/order',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

export {
  fetchCurrentUserTeams,
  fetchTeams,
  updateTeams,
  joinTeam,
  leaveTeam,
  updateTeamOrderState,
  updateTeamOrder,
};
