// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Renders a 404 page
 */
const Page404 = props => (
  <div className="container-fluid">
    <div className="col-lg-12 text-center">
      <h1 className="s-mb-xlarge">Oops! Something went wrong.</h1>

      <p>The requested page could not be found, we will try to solve this.</p>
    </div>
  </div>
);

export default Page404;
