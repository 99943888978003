import { createSelector } from 'reselect';

const getUsers = state => state.getIn(['entities', 'users', 'byId']);
const getUsersOrder = state => state.getIn(['entities', 'users', 'result']);

// Returns an array of all users
const getOrderedUsers = createSelector(
  [getUsers, getUsersOrder],
  (users, usersOrder) => usersOrder.map(id => users.get(id))
);

const getCurrentUser = state =>
  state.getIn([
    'entities',
    'users',
    'byId',
    `${state.getIn(['entities', 'users', 'current'])}`,
  ]);

export { getUsers, getOrderedUsers, getCurrentUser };
