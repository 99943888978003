import { defineMessages } from 'react-intl';

export default defineMessages({
  'footer.copyright': {
    id: 'footer.copyright',
    defaultMessage: 'Changers.com',
    description: 'Footer: copyright',
  },
  'footer.radkultur.copyright': {
    id: 'footer.radkultur.copyright',
    defaultMessage:
      '2023 Initiative RadKULTUR des Ministeriums für Verkehr Baden-Württemberg Vertreten durch: NVBW – Nahverkehrsgesellschaft Baden-Württemberg mbH',
    description: 'Footer: copyright',
  },
});
