export const formatNumber = ({ number, dp, preserveLeadingZero }) => {
  if (!number) {
    return number;
  }

  const normalizedNumber = number > 0 && number < 0.01 ? 0.01 : number;

  const roundedNumber = parseFloat(
    parseFloat(normalizedNumber.toString()).toFixed(dp)
  );

  if (!preserveLeadingZero || normalizedNumber % 1 === 0) {
    return roundedNumber.toLocaleString();
  }

  return roundedNumber.toLocaleString(undefined, { minimumFractionDigits: dp });
};

export const formatAmount = ({ number, dp }) =>
  formatNumber({
    number,
    dp,
    preserveLeadingZero: true,
  });

export const numberToKm = ({ number, dp }) =>
  formatNumber({
    number: number / 1000,
    dp,
  });
