import { SubmissionError } from 'redux-form/immutable';
import { isJsonString } from './index';

const apiErrorMap = {
  /* General */
  2000: 'error.api.INTERNAL_SERVER_ERROR',
  2001: 'error.api.INVALID_REQUEST_PARAMETERS',
  2002: 'error.api.INVALID_USER_TOKEN',
  2003: 'error.api.INVALID_REQUEST_PAYLOAD',
  2004: 'error.api.SAVE_ENTITY_FAILED',
  2005: 'error.api.INVALID_JSON_REQUEST',
  2006: 'error.api.RESOURCE_NOT_FOUND',
  2007: 'error.api.METHOD_NOT_ALLOWED',
  2020: 'error.api.PERMISSION_DENIED',
  2021: 'error.api.DB_ERROR',
  2022: 'error.api.TOO_MANY_REQUESTS',
  2023: 'error.api.INVALID_USER_AGENT',
  2024: 'error.api.INVALID_LANGUAGE',
  2025: 'error.api.HOST_HEADER_MISSING',
  2026: 'error.api.INVALID_HOST_HEADER',
  2027: 'error.api.GENERAL_ERROR',
  2028: 'error.api.ORIGIN_HEADER_MISSING',
  // 2029: 'error.api.DEVICE_ID_MISSING',
  // 2030: 'error.api.MOBILE_VERSION_NOT_SUPPORTED',
  2031: 'error.api.VALUE_NOT_ALLOWED',

  /* Statistics */
  2103: 'error.api.INVALID_MOBILITY_TYPE',
  2106: 'error.api.INVALID_TRACKING_SOURCE',
  2107: 'error.api.DISTANCE_QUOTA_EXCEEDED',
  2110: 'error.api.TIMEFRAME_QUOTA_EXCEEDED',

  2140: 'error.api.ENTRY_NONEXISTENT',
  2141: 'error.api.ENTRY_NOT_ENABLED',
  2142: 'error.api.ENTRY_TIMEFRAME_DAILY',
  2143: 'error.api.ENTRY_TIMEFRAME_WEEKLY',
  2144: 'error.api.ENTRY_TIMEFRAME_MONTHLY',
  2145: 'error.api.ENTRY_TIMEFRAME_YEARLY',
  2146: 'error.api.ENTRY_CATEGORY_DAILY_MAXIMUM',
  2147: 'error.api.NO_ACTIVE_CHALLENGES',
  2148: 'error.api.INVALID_ACTIVITY_DATE',

  /* Login - Logout */
  2201: 'error.api.INVALID_CREDENTIALS',
  2203: 'error.api.USER_LOGOUT_FAILED',

  /* Challenge */
  2301: 'error.api.INVALID_CHALLENGE_TIMEFRAME',
  2302: 'error.api.CHARITY_QUOTA_EXCEEDED',
  2303: 'error.api.TREE_PLANTING_ALREADY_EXISTS',
  2304: 'error.api.TREE_PLANTING_IN_USE',
  2305: 'error.api.DONATION_PROJECT_ALREADY_EXISTS',
  2306: 'error.api.DONATION_ALREADY_EXISTS',
  2307: 'error.api.DONATION_IN_USE',
  2308: 'error.api.CAN_NOT_UPDATE_ONGOING_CHALLENGE',
  2309: 'error.api.PARTNER_QUOTA_EXCEEDED',
  2310: 'error.api.NO_ACTIVE_CHALLENGE',
  2311: 'error.api.PARTNER_SHOULD_NOT_HAVE_TREE_TYPE',
  2312: 'error.api.PARTNER_SHOULD_HAVE_TREE_TYPE',
  2313: 'error.api.CHARITY_ALREADY_SET',
  2314: 'error.api.TREE_PLANTING_EXISTS_IN_SAME_TIME',
  2315: 'error.api.TREE_PLANTING_SHOULD_START_BEFORE',
  2316: 'error.api.DONATION_EXISTS_IN_SAME_TIME',
  2317: 'error.api.DONATION_SHOULD_START_BEFORE',

  /* Organisation */
  2402: 'error.api.DELETE_ORGANISATION_FAIL',
  2403: 'error.api.USERS_JOIN_QUOTA_EXCEEDED',
  2404: 'error.api.DUPLICATE_ORGANISATION',
  2405: 'error.api.INVALID_PASSPHRASE',
  2407: 'error.api.CAN_NOT_REGISTER_NEW_ORGANISATION',
  /* Teams */
  2503: 'error.api.TEAM_TYPE_QUOTA_EXCEEDED',
  2504: 'error.api.TEAM_QUOTA_EXCEEDED',
  2506: 'error.api.TEAM_MAX_LIMIT_REACHED',
  2508: 'error.api.CANNOT_LEAVE_TEAM',
  2510: 'error.api.CANNOT_JOIN_TEAM',

  /* Subscription */
  2601: 'error.api.SUBSCRIPTION_ERROR',
  2602: 'error.api.INVALID_VATLAYER_RESPONSE',
  2603: 'error.api.INVALID_VAT_NUMBER',
  2604: 'error.api.SUBSCRIPTION_CREATE_FAILED',
  2605: 'error.api.SUBSCRIPTION_CHANGE_FAILED',
  2606: 'error.api.SUBSCRIPTION_CANCEL_FAILED',
  2607: 'error.api.UPDATE_CREDIT_CARD_FAILED',
  2608: 'error.api.USER_ALREADY_SUBSCRIBED',
  2609: 'error.api.INVOICE_CHARGE_FAILED',
  2610: 'error.api.SUBSCRIPTION_ENDED',

  2701: 'error.api.NOT_ENOUGH_RECOINS',
  2702: 'error.api.VOUCHER_CODES_EXCEEDED',
  2704: 'error.api.CAN_NOT_UPDATE_DRAWN_LOTTERY',
  2705: 'error.api.VOUCHER_CODES_REQUIRED',

  2801: 'error.api.SUPERADMIN_ACCESS_REQUIRED',

  /* Marketplace */
  10301: 'error.api.NO_OFFERS_LEFT',
  10302: 'error.api.OFFER_LIMIT_EXCEEDED',
  10401: 'error.api.NOT_ENOUGH_RECOINS',

  /* Validation codes */
  3001: 'error.api.REQUIRED_PARAMETER',
  3002: 'error.api.UNIQUE_PARAMETER',
  3003: 'error.api.CHARACTERS_QUOTA_EXCEEDED',
  3004: 'error.api.CHARACTERS_QUOTA_NOT_EXCEEDED',
  3005: 'error.api.PARAMETER_IS_NOT_ARRAY',
  3006: 'error.api.INVALID_DATE',
  3007: 'error.api.INVALID_EMAIL',
  3008: 'error.api.ITEM_NOT_IN_LIST',
  3009: 'error.api.SUBSCRIPTION_UPGRADE_REQUIRED',
  3010: 'error.api.REGEX_QUOTA_EXCEEDED',
  3011: 'error.api.INVALID_RECORD',
  3012: 'error.api.COULD_NOT_REMOVE_REGEX_IN_USE',
  3013: 'error.api.FIELD_CAN_NOT_BE_UPDATED',
  3014: 'error.api.INVALID_IMAGE_DIMENSIONS',
  3015: 'error.api.RESOURCE_CAN_NOT_BE_UPDATED',
  3016: 'error.api.PASSWORDS_DO_NOT_MATCH',
  3017: 'error.api.INVALID_REGEX_FORMAT',
  3018: 'error.api.AMOUNT_CAN_NOT_BE_DECREASED',
  3019: 'error.api.COULD_NOT_REMOVE_TEAM_TYPE_IN_USE',
  3020: 'error.api.COULD_NOT_REMOVE_TEAM_IN_USE',
  3021: 'error.api.FIELD_HAS_DUPLICATE_VALUE',
  3022: 'error.api.DEFAULT_TRANSLATION_MISSING',
  3023: 'error.api.INVALID_FORMAT',
  3024: 'error.api.FIELD_MUST_BE_STRING',
  3025: 'error.api.UNITS_ALREADY_REACHED',
  3026: 'error.api.FIELD_MUST_BE_BOOLEAN',
  3102: 'error.api.EMAIL_TOKEN_EXPIRED',
  3103: 'error.api.EMAIL_TOKEN_INVALID',
};

/**
 * Error class for a Form in redux-form
 *
 * @class FormError
 * @access public
 * @param {object|string} errors - The form errors
 */
class FormError extends SubmissionError {
  constructor(errors) {
    // if single string is passed, then add it as main form error
    errors =
      typeof errors === 'string' || errors instanceof String
        ? { _error: errors }
        : errors;

    // calling parent constructor of base Error class
    super(errors);

    // saving class name in the property of our custom error as a shortcut
    this.name = 'FormError';

    // exchange API error code with text
    // _details: string | number | object
    //   fieldName: string | array
    //     nestedField: string | JSON(array)

    if ('_details' in errors) {
      if (
        (typeof errors._details === 'string' ||
          errors._details instanceof String ||
          typeof errors._details === 'number' ||
          errors._details instanceof Number) &&
        errors._details in apiErrorMap
      ) {
        errors._error = apiErrorMap[errors._details];
      } else {
        // parse fields
        for (const [field, fieldValue] of Object.entries(errors._details)) {
          // check for array of nested fields
          // or array of multiple errors for field
          if (Array.isArray(fieldValue)) {
            errors[field] = {};

            // parse nested errors or nested fields
            for (const nestedValue of fieldValue) {
              if (isJsonString(nestedValue)) {
                // parse JSON string to check if it is an array
                // if array and , it means we use nested fields
                const jsonValue = JSON.parse(nestedValue);
                if (Array.isArray(jsonValue)) {
                  // parse nested errors
                  for (const [nestedKey, nestedError] of jsonValue.entries()) {
                    if (nestedError in apiErrorMap) {
                      errors[field][nestedKey] = apiErrorMap[nestedError];
                    }
                  }
                }

                // when string: check if it is a known error
              } else if (nestedValue in apiErrorMap) {
                errors[field] = apiErrorMap[nestedValue];
              } else {
                errors[field] = nestedValue;
                // checking if nestedValue is string('nestedValue') then break otherwise (nestedValue === '123') then continue
                if (isNaN(+nestedValue)) {
                  break;
                }
              }
            }

            // when string: check if it is a known error
          } else if (fieldValue in apiErrorMap) {
            errors[field] = apiErrorMap[fieldValue];
          }
        }
      }
    }

    this.formErrors = errors;
  }

  json = () => ({
    name: this.name,
    formErrors: this.formErrors,
  });
}

/**
 * Error class for a RetryDelay in Forms
 *
 * @class RetryDelayError
 * @access public
 * @param {object} response - The parsed JSON response of the API server if the
 * @param {string} message - The text to be returned to RP
 */
class RetryDelayError extends Error {
  constructor(response, message) {
    // calling parent constructor of base Error class
    super(message);

    // capturing stack trace, excluding constructor call from it
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    // saving class name in the property of our custom error as a shortcut
    this.name = 'RetryDelayError';

    this.formErrors = {
      _error: message,
    };

    // get RetryDelay from `RateLimit-Reset` header
    this.retryDelay = this.getRetryDelay(response.headers);
  }

  getRetryDelay = headers => {
    // TODO: simplify when server returns Retry-After header

    const rate_limit_reset = headers.get('X-RateLimit-Reset');
    const now = Math.floor(new Date().getTime() / 1000);
    const delay = rate_limit_reset - now || null;
    return delay && delay > 0 ? delay * 1000 : 3000;
  };

  json = () => ({
    name: this.name,
    formErrors: this.formErrors,
    retryDelay: this.retryDelay,
  });
}

export { FormError, RetryDelayError, apiErrorMap };
