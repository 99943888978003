const getOrganisationSubscription = state =>
  state.getIn(['entities', 'organisationSubscription']);

const getOrganisationSubscriptionData = state =>
  state.getIn(['entities', 'organisationSubscription', 'subscriptionData']);

const getOrganisationSubscriptionBillingDate = state =>
  state.getIn(['entities', 'organisationSubscription', 'billing_starts_at']);

const getOrganisationSubscriptionPaymentMethodExists = state =>
  state.getIn([
    'entities',
    'organisationSubscription',
    'payment_method_exists',
  ]);

const getOrganisationSubscriptionBillableId = state =>
  state.getIn(['entities', 'organisationSubscription', 'billable_id']);

const getSubscriptionId = state =>
  state.getIn(['entities', 'organisationSubscription', 'subscriptionId']);

export {
  getOrganisationSubscription,
  getOrganisationSubscriptionData,
  getOrganisationSubscriptionBillingDate,
  getOrganisationSubscriptionBillableId,
  getOrganisationSubscriptionPaymentMethodExists,
  getSubscriptionId,
};
