import { fromJS } from 'immutable';

import * as WhitelabelActionTypes from '../../constants/actionTypes/whitelabel';
import DefaultSettings from '../../constants/config.json';

const initialState = fromJS(DefaultSettings);

/**
 * Handles Whitelabel state
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case WhitelabelActionTypes.WHITELABEL_FETCH_SUCCESS: {
      const whitelabel = fromJS(payload);

      if (!whitelabel) {
        return initialState;
      }

      return initialState.mergeDeep(whitelabel);
    }
    case WhitelabelActionTypes.WHITELABEL_RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
