import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as GlobalStatsActionTypes from '../constants/actionTypes/globalStats';

/**
 * Fetch Global stats
 * @returns Promise
 */
const fetchGlobalStats = (locale = '') => ({
  [RSAA]: {
    types: [
      GlobalStatsActionTypes.GLOBAL_STATS_FETCH_REQUEST,
      GlobalStatsActionTypes.GLOBAL_STATS_FETCH_SUCCESS,
      GlobalStatsActionTypes.GLOBAL_STATS_FETCH_FAILURE,
    ],
    endpoint: config.apiStatsEndpoint + '/api/dashboard',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

export { fetchGlobalStats };
