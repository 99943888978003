import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { userSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as UsersActionTypes from '../../constants/actionTypes/users';
import * as UserReferralActionTypes from '../../constants/actionTypes/userReferral';
import * as ActivitiesActionTypes from '../../constants/actionTypes/activities';
import * as VouchersActionTypes from '../../constants/actionTypes/vouchers';
import * as LotteriesActionTypes from '../../constants/actionTypes/lotteries';

const initialState = fromJS({
  current: null,
  byId: {},
  result: [],
});

/**
 * Handles Users
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { user = {} } = data || {};

  switch (type) {
    case UsersActionTypes.CURRENT_USER_FETCH_SUCCESS: {
      const normalizedUser = normalize(user, userSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUser.entities.users))
        .updateIn(['result'], val =>
          !val.includes(user.id) ? val.push(user.id) : val
        )
        .set('current', user.id);
    }
    case UserReferralActionTypes.FETCH_USER_REFERRAL_SUCCESS: {
      const userId = state.get('current').toString();
      const userData = state.getIn(['byId', userId]);
      return state.setIn(
        ['byId', userId],
        userData.set('referral_link', fromJS(payload))
      );
    }

    case UsersActionTypes.UPDATE_USER_SUCCESS: {
      const normalizedUpdate = normalize(data, userSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.users))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }

    case VouchersActionTypes.PURCHASE_VOUCHER_SUCCESS:
    case LotteriesActionTypes.PURCHASE_LOTTERY_SUCCESS: {
      const { recoins } = data;
      const currentUserId = state.get('current');

      // update recoins number for current user
      if (currentUserId) {
        state = state.updateIn(
          ['byId', `${currentUserId}`, 'recoins'],
          val => val - recoins
        );
      }

      return state;
    }

    case ActivitiesActionTypes.TRACK_NEW_ACTIVITY_SUCCESS: {
      const [item = {}] = data || [];
      const { activity_recoins = 0 } = item;

      const currentUserId = state.get('current');

      // update recoins number for current user
      if (currentUserId) {
        state = state.updateIn(
          ['byId', `${currentUserId}`, 'recoins'],
          recoins => recoins + activity_recoins
        );
      }

      return state;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
