import { fromJS, List, Map } from 'immutable';

import * as OrganisationsActionTypes from '../../constants/actionTypes/organisations';
import * as UsersActionTypes from '../../constants/actionTypes/users';
import * as GlobalStatsActionTypes from '../../constants/actionTypes/globalStats';
import * as ActivitiesActionTypes from '../../constants/actionTypes/activities';
import * as VouchersActionTypes from '../../constants/actionTypes/vouchers';
import * as LotteriesActionTypes from '../../constants/actionTypes/lotteries';
import * as AuthActionTypes from '../../constants/actionTypes/auth';

// import mockOrgaState from '../../constants/mockResponse.json';

// console.log(mockOrgaState);

const initialState = fromJS({
  global: {},
  organisation: {},
  organisationStats: {},
  challengesStats: {},
  charityStats: {},
  infoStats: {},
  user: {},
  userStats: {},
  userCharityStats: {},
  currentChallengeTopUsers: {},
  challengeTeamTypes: {
    position: {},
    average: {},
  },
});

/**
 * Handles Challenges
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case OrganisationsActionTypes.ORGANISATION_ACTIVITY_STATS_FETCH_SUCCESS:
      return state.set('organisationStats', fromJS(payload));

    case OrganisationsActionTypes.ORGANISATION_CHALLENGES_STATS_FETCH_SUCCESS:
      return state.set('challengesStats', fromJS(data));

    case OrganisationsActionTypes.ORGANISATION_CHARITY_STATS_FETCH_SUCCESS:
      return state.set('charityStats', fromJS(payload));

    case OrganisationsActionTypes.ORGANISATION_INFO_STATS_FETCH_SUCCESS:
      return state.set('infoStats', fromJS(data));

    case UsersActionTypes.USER_STATS_FETCH_SUCCESS:
      return state.set('user', fromJS(data));

    case UsersActionTypes.USER_ACTIVITY_STATS_FETCH_SUCCESS:
      return state.set('userStats', fromJS(payload));

    case UsersActionTypes.USER_CHARITY_STATS_FETCH_SUCCESS:
      return state.set('userCharityStats', fromJS(payload));

    case GlobalStatsActionTypes.GLOBAL_STATS_FETCH_SUCCESS:
      return state.set('global', fromJS(data));

    case OrganisationsActionTypes.ORGANISATION_CHALLENGE_USERS_STATS_FETCH_SUCCESS:
      return state.set('currentChallengeTopUsers', fromJS(data));

    case OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_STATS_FETCH_SUCCESS:
      return state.setIn(
        ['challengeTeamTypes', 'position', `${action.meta.teamTypeId}`],
        fromJS(data)
      );

    case OrganisationsActionTypes.ORGANISATION_CHALLENGE_TEAM_TYPES_AVERAGE_STATS_FETCH_SUCCESS:
      return state.setIn(
        ['challengeTeamTypes', 'average', `${action.meta.teamTypeId}`],
        fromJS(data)
      );

    case ActivitiesActionTypes.TRACK_NEW_ACTIVITY_SUCCESS: {
      const [item = {}] = data || [];
      const {
        activity_id,
        category = undefined,
        distance = 0,
        co2_avoided = 0,
        co2_emission = 0,
        activity_recoins = 0,
        challenge_id = 0,
        amount = 0,
      } = item;

      // console.log("%c state", "font-size:2em;", state.toJS());
      // console.log("%c item", "font-size:2em;", item);
      // debugger;
      // window.location.reload(); // ugly hack?

      // add data accordingly
      return state.update('challengesStats', (challenges = List()) => {
        const currentChallengeIndex = challenges.findKey(
          challenge => challenge.get('id') === challenge_id
        );

        if (!currentChallengeIndex) {
          return;
        }
        // return updated challenge
        return challenges.update(currentChallengeIndex, challenge => {
          return (
            challenge
              // update recoins in the current challenge:
              .updateIn(
                ['user', 'recoins_earned'],
                recoins => recoins + activity_recoins
              )
              // update in challenge summary to allow top - bottom recalculation
              .updateIn(['user', 'summary'], (entries = List()) => {
                const entryIndex = entries.findKey(
                  entry => entry.get('transaction_type_id') === activity_id
                );

                // if transaction id exists, update entry
                if (entryIndex) {
                  return entries.update(entryIndex, entry => {
                    // return updated entry
                    return entry
                      .update('quantity', val => val + distance)
                      .update('meters', val => val + distance)
                      .update('co2_avoided', val => val + co2_avoided)
                      .update('co2_emissions', val => val + co2_emission)
                      .update('recoins_earned', val => val + activity_recoins);
                  });
                }
                // or else add it
                return entries.set(
                  0,
                  Map({
                    challenge_source_id: activity_id,
                    co2_avoided: co2_avoided,
                    co2_emissions: co2_emission,
                    meters: distance,
                    count: Number(distance / 1000).toFixed(2),
                    quantity: distance,
                    recoins_earned: activity_recoins,
                    recoins_spent: 0,
                    transaction_type_id: activity_id,
                  })
                );
              })
              // update custom activity categories
              .updateIn(
                ['user', 'custom_activity_categories'],
                (entries = List()) => {
                  if (category) {
                    const entryIndex = entries.findKey(
                      entry => entry.get('category_id') === category
                    );

                    if (entryIndex) {
                      return entries.update(entryIndex, entry => {
                        return entry
                          .update('quantity', value => value + amount)
                          .update('co2_avoided', value => value + co2_avoided)
                          .update(
                            'co2_emissions',
                            value => value + co2_emission
                          )
                          .update('recoins', value => value + activity_recoins);
                      });
                    }
                    return entries.set(
                      0,
                      Map({
                        category_id: category,
                        quantity: amount,
                        recoins: activity_recoins,
                        co2_avoided: co2_avoided,
                        co2_emissions: co2_emission,
                      })
                    );
                  }
                  // return no changes
                  return entries;
                }
              )
          );
        });
      });

      // add recoins earned for user
      // deprecated
      // if (state.get('user').size > 0) {
      //   state = state.updateIn(
      //     ['user', 'recoins'],
      //     recoins => recoins + activity_recoins
      //   );
      // }

      // add recoins earned for organisation
      //  deprecated
      // if (state.get('organisation').size > 0) {
      //   state = state.updateIn(
      //     ['organisation', 'recoins', 'current', 'earned'],
      //     recoins => recoins + activity_recoins
      //   );
      // }
      // debugger;
      // check if is custom_activity
      // deprecated
      // if (category) {
      //   const currentCategoryIndex = state
      //     .getIn(['user', 'categories', 'current'])
      //     .findKey(cat => cat.get('category_id') === category);

      //   if (currentCategoryIndex) {
      //     state = state.updateIn(
      //       [
      //         'user',
      //         'categories',
      //         'current',
      //         currentCategoryIndex,
      //         'activities',
      //       ],
      //       (val = 0) => val++
      //     );
      //   } else {
      //     state = state.updateIn(
      //       ['user', 'categories', 'current'],
      //       (list = List()) =>
      //         list.push(
      //           Map({
      //             category_id: category,
      //             activities: 1,
      //           })
      //         )
      //     );
      //   }

      //   return state;
      // }

      // else continue with mobility activities
      // const mobilityId = parseInt(activity_id, 10) || undefined;

      // increase meters
      // deprecated
      // const currentMobilityIndex = state
      //   .getIn(['user', 'challenge_sources', 'current'])
      //   .findKey(mob => mob.get('source_id') == mobilityId);

      // if (typeof currentMobilityIndex !== 'undefined') {
      //   state = state.updateIn(
      //     [
      //       'user',
      //       'challenge_sources',
      //       'current',
      //       currentMobilityIndex,
      //       'meters',
      //     ],
      //     (val = 0) => val + distance
      //   );
      // } else {
      //   state = state.updateIn(
      //     ['user', 'challenge_sources', 'current'],
      //     (list = List()) =>
      //       list.push(
      //         Map({
      //           source_id: mobilityId,
      //           meters: distance,
      //         })
      //       )
      //   );
      // }

      // const annualMobilityIndex = state
      // deprecated
      //   .getIn(['user', 'annual_sources'])
      //   .findKey(mob => mob.get('source_id') == mobilityId);

      // if (typeof annualMobilityIndex !== 'undefined') {
      //   state = state.updateIn(
      //     ['user', 'annual_sources', annualMobilityIndex],
      //     mobility =>
      //       mobility.withMutations(mobilityMutable =>
      //         mobilityMutable
      //           .update('meters', (val = 0) => val + distance)
      //           .update('co2_avoided', (val = 0) => val + co2_avoided)
      //           .update('co2_emission', (val = 0) => val + co2_emission)
      //       )
      //   );
      // } else {
      //   state = state.updateIn(['user', 'annual_sources'], (list = List()) =>
      //     list.push(
      //       Map({
      //         source_id: mobilityId,
      //         meters: distance,
      //         co2_avoided: co2_avoided,
      //         co2_emission: co2_emission,
      //       })
      //     )
      //   );
      // }
    }

    case VouchersActionTypes.PURCHASE_VOUCHER_SUCCESS:
    case LotteriesActionTypes.PURCHASE_LOTTERY_SUCCESS: {
      const { recoins } = data;

      if (state.get('user').size > 0) {
        state = state.updateIn(
          ['user', 'recoins'],
          curRecoins => curRecoins - recoins
        );
      }

      if (state.get('organisation').size > 0) {
        state = state.updateIn(
          ['organisation', 'recoins', 'current', 'spent'],
          curRecoins => curRecoins + recoins
        );
      }

      return state;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;
  }

  return state;
};

export default reducer;
