import DashboardErrors from './errors';
import ApiErrors from './errors/api';
import AppErrors from './errors/app';
import Validations from './validations';
import Buttons from './buttons';
import Forms from './forms';
import Texts from './texts';
import Activities from './activities';
import Pagination from './pagination';

export default {
  ...DashboardErrors,
  ...ApiErrors,
  ...AppErrors,
  ...Validations,
  ...Buttons,
  ...Forms,
  ...Texts,
  ...Activities,
  ...Pagination,
};
