import { createSelector } from 'reselect';
import { Map } from 'immutable';

const getOrganisations = state =>
  state.getIn(['entities', 'organisations', 'byId']);
const getOrganisationsOrder = state =>
  state.getIn(['entities', 'organisations', 'result']);

// Returns an array of all organisations
const getOrderedOrganisations = createSelector(
  [getOrganisations, getOrganisationsOrder],
  (organisations, organisationsOrder) =>
    organisationsOrder.map(id => organisations.get(`${id}`))
);

const getCurrentOrganisationId = state =>
  state.getIn(['entities', 'organisations', 'current']);

const getOrganisationEditorials = state =>
  state.getIn(['entities', 'organisations', 'editorials']);

const getPublicOrganisationData = state => {
  const publicOrganisationData = state.getIn([
    'auth',
    'publicOrganisationData',
  ]);
  return publicOrganisationData?.toJS
    ? publicOrganisationData?.toJS()
    : publicOrganisationData;
};

const getCurrentOrganisation = createSelector(
  [getOrganisations, getCurrentOrganisationId],
  (organisations, currentOrganisationId) => {
    // console.log(organisations.get(`${currentOrganisationId}`));
    return organisations.get(`${currentOrganisationId}`);
  }
);

const getOrganisationLogoForDomain = state => state.getIn(['auth', 'logo']);

const getOrganisationLogo = createSelector(
  [getCurrentOrganisation, getOrganisationLogoForDomain],
  (organisation = Map(), organisationLogoForDomain) =>
    organisation.get('logo') || organisationLogoForDomain
);

export {
  getOrganisations,
  getOrderedOrganisations,
  getCurrentOrganisationId,
  getCurrentOrganisation,
  getOrganisationLogo,
  getOrganisationEditorials,
  getPublicOrganisationData,
};
