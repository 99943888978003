import { schema } from 'normalizr';
import { processLocalizedFieldsFromAPI } from '../components/LocalizedInput/utils';

const compensationSchema = new schema.Entity(
  'compensations',
  {},
  {
    processStrategy: entity => {
      const { title, description, sale, ...rest } = entity;
      return {
        ...rest,
        title: processLocalizedFieldsFromAPI(title),
        description: processLocalizedFieldsFromAPI(description),
        sale: processLocalizedFieldsFromAPI(sale),
      };
    },
  }
);

const compensationListSchema = [compensationSchema];

export { compensationListSchema };
