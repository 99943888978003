import { fromJS } from 'immutable';

import * as ActionTypes from '../constants/actionTypes/auth';
import * as OrganisationsActionTypes from '../constants/actionTypes/organisations';
import * as OrganisationBillingActionTypes from '../constants/actionTypes/organisationBilling';

const initialState = fromJS({
  accessToken: null,
  logo: null,
  allow_access: false,
  email: null,
  publicOrganisationData: null,
});

/**
 * Handles Authentication
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    // - Set single token
    // main action for setting tokens
    case ActionTypes.SET_TOKEN: {
      const { key, value } = payload;
      return state.set(key, value);
    }

    // - Set multiple tokens
    case ActionTypes.SET_TOKENS:
      return state.merge(payload);

    case ActionTypes.UNSET_TOKENS: {
      const clearedObject = payload.reduce((items, key) => {
        items[key] = initialState.get(key, null);
        return items;
      }, {});

      return state.merge(clearedObject);
    }

    // case ActionTypes.ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS:
    // case ActionTypes.ORGANISATION_REGISTER_REQUEST:
    // case ActionTypes.ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST: {
    //   // console.log("%c ORGANISATIONS_ADMIN_RESEND_VERIFICATION_EMAIL_REQUEST", "font-size:2em;", payload);
    //   return state;
    // }
    case ActionTypes.ORGANISATION_REGISTER_REQUEST: {
      const { email } = payload;
      return state.set('email', email);
    }
    case ActionTypes.ORGANISATION_REGISTER_SUCCESS: {
      const {
        data: { admin_account_verified, token },
      } = payload;

      return state.merge({
        admin_account_verified,
        accessToken: token,
      });
    }

    case ActionTypes.USER_REGISTER_SUCCESS:
    case ActionTypes.USER_LOGIN_SUCCESS:
    case ActionTypes.USER_ORGANISATION_CONFIRM_SUCCESS: {
      const {
        data: { token },
      } = payload;

      return state.set('accessToken', token);
    }

    case OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CANCEL_SUCCESS:
    case ActionTypes.USER_LOGOUT_SUCCESS:
      return state.delete('accessToken');

    case OrganisationsActionTypes.ORGANISATION_LOGO_FETCH_SUCCESS: {
      const { data } = payload;
      const { logo, allow_access } = data;

      return state
        .set('logo', logo)
        .set('allow_access', allow_access)
        .set('publicOrganisationData', data);
    }
    case ActionTypes.UPDATE_LOGIN_EMAIL: {
      const { email } = payload;

      return state.set('email', email);
    }
    default:
      return state;
  }
};

export default reducer;
