export const TEAMS_FETCH_REQUEST = 'changers/admin/TEAMS_FETCH_REQUEST';
export const TEAMS_FETCH_SUCCESS = 'changers/admin/TEAMS_FETCH_SUCCESS';
export const TEAMS_FETCH_FAILURE = 'changers/admin/TEAMS_FETCH_FAILURE';

export const CURRENT_USER_TEAMS_FETCH_REQUEST =
  'changers/admin/CURRENT_USER_TEAMS_FETCH_REQUEST';
export const CURRENT_USER_TEAMS_FETCH_SUCCESS =
  'changers/admin/CURRENT_USER_TEAMS_FETCH_SUCCESS';
export const CURRENT_USER_TEAMS_FETCH_FAILURE =
  'changers/admin/CURRENT_USER_TEAMS_FETCH_FAILURE';

export const TEAMS_UPDATE_REQUEST = 'changers/admin/TEAMS_UPDATE_REQUEST';
export const TEAMS_UPDATE_SUCCESS = 'changers/admin/TEAMS_UPDATE_SUCCESS';
export const TEAMS_UPDATE_FAILURE = 'changers/admin/TEAMS_UPDATE_FAILURE';

export const TEAM_JOIN_REQUEST = 'changers/admin/TEAM_JOIN_REQUEST';
export const TEAM_JOIN_SUCCESS = 'changers/admin/TEAM_JOIN_SUCCESS';
export const TEAM_JOIN_FAILURE = 'changers/admin/TEAM_JOIN_FAILURE';

export const TEAM_LEAVE_REQUEST = 'changers/admin/TEAM_LEAVE_REQUEST';
export const TEAM_LEAVE_SUCCESS = 'changers/admin/TEAM_LEAVE_SUCCESS';
export const TEAM_LEAVE_FAILURE = 'changers/admin/TEAM_LEAVE_FAILURE';

export const UPDATE_TEAM_ORDER_STATE = 'changers/admin/UPDATE_TEAM_ORDER_STATE';

export const UPDATE_TEAM_ORDER_REQUEST =
  'changers/admin/UPDATE_TEAM_ORDER_REQUEST';
export const UPDATE_TEAM_ORDER_SUCCESS =
  'changers/admin/UPDATE_TEAM_ORDER_SUCCESS';
export const UPDATE_TEAM_ORDER_FAILURE =
  'changers/admin/UPDATE_TEAM_ORDER_FAILURE';
