exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".App__appContainer {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex; }\n  @media (max-width: 767.98px) {\n    .App__appContainer {\n      -webkit-box-orient: vertical;\n      -webkit-box-direction: normal;\n          -ms-flex-direction: column;\n              flex-direction: column; } }\n\n.App__pageWrapperContainer {\n  height: 100vh;\n  overflow: scroll;\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1; }\n  @media (max-width: 767.98px) {\n    .App__pageWrapperContainer {\n      height: auto; } }\n\n.App__pageWrapperContainerPt {\n  padding-top: 3rem; }\n\n.App__pageWrapperContainerGuest {\n  background-color: #ebf5f6; }\n\n.wl-radkultur .App__pageWrapperContainerGuest {\n  background-color: #e4eff8; }\n", ""]);

// exports
exports.locals = {
	"appContainer": "App__appContainer",
	"pageWrapperContainer": "App__pageWrapperContainer",
	"pageWrapperContainerPt": "App__pageWrapperContainerPt",
	"pageWrapperContainerGuest": "App__pageWrapperContainerGuest"
};