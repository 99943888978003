import { defineMessages } from 'react-intl';

export default defineMessages({
  'activities.mobility.walking': {
    id: 'activities.mobility.walking',
    defaultMessage: 'Walking',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.cycling': {
    id: 'activities.mobility.cycling',
    defaultMessage: 'Cycling',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.publicTransport': {
    id: 'activities.mobility.publicTransport',
    defaultMessage: 'Public Transport in cities',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.longDistanceTrain': {
    id: 'activities.mobility.longDistanceTrain',
    defaultMessage: 'Long Distance Trains',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.car': {
    id: 'activities.mobility.car',
    defaultMessage: 'Car',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.plane': {
    id: 'activities.mobility.plane',
    defaultMessage: 'Plane',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.running': {
    id: 'activities.mobility.running',
    defaultMessage: 'Running',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.vouchers': {
    id: 'activities.mobility.vouchers',
    defaultMessage: 'Vouchers',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.custom': {
    id: 'activities.mobility.custom',
    defaultMessage: 'Custom activities',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.treePlanting': {
    id: 'activities.mobility.treePlanting',
    defaultMessage: 'Tree planting',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.other': {
    id: 'activities.mobility.other',
    defaultMessage: 'Other',
    description: 'Activities texts: Mobility title',
  },
  'activities.mobility.co2Measures': {
    id: 'activities.mobility.co2Measures',
    defaultMessage: 'CO2-MEASURES',
    description: 'Activities texts: Mobility title',
  },

  'activities.sport': {
    id: 'activities.sport',
    defaultMessage: 'Sport',
    description: 'Activities texts: Mobility title',
  },
  'activities.nutrition': {
    id: 'activities.nutrition',
    defaultMessage: 'Nutrition',
    description: 'Activities texts: Mobility title',
  },
  'activities.prevention': {
    id: 'activities.prevention',
    defaultMessage: 'Prevention',
    description: 'Activities texts: Mobility title',
  },
  'activities.sustainability': {
    id: 'activities.sustainability',
    defaultMessage: 'Sustainability',
    description: 'Activities texts: Mobility title',
  },
  'activities.safety': {
    id: 'activities.safety',
    defaultMessage: 'Safety Measurements',
    description: 'Activities texts: Mobility title',
  },

  recoins: {
    id: 'recoins',
    defaultMessage: `{recoins, plural,
      one {1 ReCoin}
      other {{recoins} ReCoins}
    }`,
    description: 'ReCoins',
  },
  'recoins.adac': {
    id: 'recoins.adac',
    defaultMessage: `{recoins, plural,
      one {1 Climate-Point}
      other {{recoins} Climate-Points}
    }`,
    description: 'ReCoins (adac)',
  },
  'recoins.allianz': {
    id: 'recoins.allianz',
    defaultMessage: `{recoins, plural,
      one {1 Eagle}
      other {{recoins} Eagles}
    }`,
    description: 'ReCoins (allianz)',
  },
  'recoins.badkreuznach': {
    id: 'recoins.badkreuznach',
    defaultMessage: `{recoins, plural,
      one {1 Point}
      other {{recoins} Points}
    }`,
    description: 'ReCoins (badkreuznach)',
  },
  'recoins.bielefeld': {
    id: 'recoins.bielefeld',
    defaultMessage: `{recoins, plural,
      one {1 Climate-Point}
      other {{recoins} Climate-Points}
    }`,
    description: 'ReCoins (bielefeld)',
  },
  'recoins.darmstadt': {
    id: 'recoins.darmstadt',
    defaultMessage: `{recoins, plural,
      one {1 Climate-Heart}
      other {{recoins} Climate-Hearts}
    }`,
    description: 'ReCoins (darmstadt)',
  },
  'recoins.duesseldorf': {
    id: 'recoins.duesseldorf',
    defaultMessage: `{recoins, plural,
      one {1 Climate-Coin}
      other {{recoins} Climate-Coin}
    }`,
    description: 'ReCoins (duesseldorf)',
  },
  'recoins.hagen': {
    id: 'recoins.hagen',
    defaultMessage: `{recoins, plural,
      one {1 Hero-Point}
      other {{recoins} Hero-Points}
    }`,
    description: 'ReCoins (hagen)',
  },
  'recoins.ikkclassic': {
    id: 'recoins.ikkclassic',
    defaultMessage: `{recoins, plural,
      one {1 IKK Taler}
      other {{recoins} IKK Taler}
    }`,
    description: 'ReCoins (ikkclassic)',
  },
  'recoins.jt': {
    id: 'recoins.jt',
    defaultMessage: `{recoins, plural,
      one {1 Climate-Coin}
      other {{recoins} Climate-Coins}
    }`,
    description: 'ReCoins (jt)',
  },
  'recoins.muenster': {
    id: 'recoins.muenster',
    defaultMessage: `{recoins, plural,
      one {1 Climate-Coin}
      other {{recoins} Climate-Coins}
    }`,
    description: 'ReCoins (muenster)',
  },
  'recoins.vienna': {
    id: 'recoins.vienna',
    defaultMessage: `{recoins, plural,
      one {1 Kultur-Token}
      other {{recoins} Kultur-Token}
    }`,
    description: 'ReCoins (vienna)',
  },
  'recoins.dresden': {
    id: 'recoins.dresden',
    defaultMessage: `{recoins, plural,
      one {1 cleema-Taler}
      other {{recoins} cleema-Taler}
    }`,
    description: 'ReCoins (dresden)',
  },
  'recoins.radkultur': {
    id: 'recoins.radkultur',
    defaultMessage: `{recoins, plural,
      one {1 RadKULTUR Coin}
      other {{recoins} RadKULTUR Coins}
    }`,
    description: 'ReCoins (radkultur)',
  },

  'recoins.label': {
    id: 'recoins.label',
    defaultMessage: `{recoins, plural,
      one {ReCoin}
      other {ReCoins}
    }`,
    description: 'ReCoins',
  },

  'recoins.adac.label': {
    id: 'recoins.adac.label',
    defaultMessage: `{recoins, plural,
      one {Climate-Point}
      other {Climate-Points}
    }`,
    description: 'ReCoins (adac)',
  },
  'recoins.allianz.label': {
    id: 'recoins.allianz.label',
    defaultMessage: `{recoins, plural,
      one {Eagle}
      other {Eagles}
    }`,
    description: 'ReCoins (allianz)',
  },
  'recoins.badkreuznach.label': {
    id: 'recoins.badkreuznach.label',
    defaultMessage: `{recoins, plural,
      one {Point}
      other {Points}
    }`,
    description: 'ReCoins (badkreuznach)',
  },
  'recoins.bielefeld.label': {
    id: 'recoins.bielefeld.label',
    defaultMessage: `{recoins, plural,
      one {Climate-Point}
      other {Climate-Points}
    }`,
    description: 'ReCoins (bielefeld)',
  },
  'recoins.darmstadt.label': {
    id: 'recoins.darmstadt.label',
    defaultMessage: `{recoins, plural,
      one {Climate-Heart}
      other {Climate-Hearts}
    }`,
    description: 'ReCoins (darmstadt)',
  },
  'recoins.duesseldorf.label': {
    id: 'recoins.duesseldorf.label',
    defaultMessage: `{recoins, plural,
      one {Climate-Coin}
      other {Climate-Coins}
    }`,
    description: 'ReCoins (duesseldorf)',
  },
  'recoins.hagen.label': {
    id: 'recoins.hagen.label',
    defaultMessage: `{recoins, plural,
      one {Hero-Point}
      other {Hero-Points}
    }`,
    description: 'ReCoins (hagen)',
  },
  'recoins.ikkclassic.label': {
    id: 'recoins.ikkclassic.label',
    defaultMessage: `{recoins, plural,
      one {IKK Taler}
      other {IKK Taler}
    }`,
    description: 'ReCoins (ikkclassic)',
  },
  'recoins.jt.label': {
    id: 'recoins.jt.label',
    defaultMessage: `{recoins, plural,
      one {Climate-Coin}
      other {Climate-Coins}
    }`,
    description: 'ReCoins (jt)',
  },
  'recoins.muenster.label': {
    id: 'recoins.muenster.label',
    defaultMessage: `{recoins, plural,
      one {Climate-Coin}
      other {Climate-Coins}
    }`,
    description: 'ReCoins (muenster)',
  },
  'recoins.vienna.label': {
    id: 'recoins.vienna.label',
    defaultMessage: `{recoins, plural,
      one {Kultur-Token}
      other {Kultur-Token}
    }`,
    description: 'ReCoins (vienna)',
  },
  'recoins.dresden.label': {
    id: 'recoins.dresden.label',
    defaultMessage: `{recoins, plural,
      one {cleema-Taler}
      other {cleema-Taler}
    }`,
    description: 'ReCoins (dresden)',
  },
  'recoins.radkultur.label': {
    id: 'recoins.radkultur.label',
    defaultMessage: `{recoins, plural,
      one {RadKULTUR Coin}
      other {RadKULTUR Coins}
    }`,
    description: 'ReCoins (radkultur)',
  },

  'recoins.short': {
    id: 'recoins.short',
    defaultMessage: 'RC',
    description: 'ReCoins short',
  },
  'recoins.adac.short': {
    id: 'recoins.adac.short',
    defaultMessage: 'KP',
    description: 'Recoins short (adac)',
  },
  'recoins.allianz.short': {
    id: 'recoins.allianz.short',
    defaultMessage: 'Eagles',
    description: 'Eagles short (allianz)',
  },
  'recoins.badkreuznach.short': {
    id: 'recoins.badkreuznach.short',
    defaultMessage: 'Points',
    description: 'Recoins short (badkreuznach)',
  },
  'recoins.bielefeld.short': {
    id: 'recoins.bielefeld.short',
    defaultMessage: 'KP',
    description: 'ReCoins short (bielefeld)',
  },
  'recoins.darmstadt.short': {
    id: 'recoins.darmstadt.short',
    defaultMessage: 'KH',
    description: 'Recoins short (darmstadt)',
  },
  'recoins.duesseldorf.short': {
    id: 'recoins.duesseldorf.short',
    defaultMessage: 'KT',
    description: 'Recoins short (duesseldorf)',
  },
  'recoins.hagen.short': {
    id: 'recoins.hagen.short',
    defaultMessage: 'HP',
    description: 'Recoins short (hagen)',
  },
  'recoins.ikkclassic.short': {
    id: 'recoins.ikkclassic.short',
    defaultMessage: 'IKK Taler',
    description: 'Recoins short (ikkclassic)',
  },
  'recoins.jt.short': {
    id: 'recoins.jt.short',
    defaultMessage: 'CP',
    description: 'Recoins short (JT)',
  },
  'recoins.muenster.short': {
    id: 'recoins.muenster.short',
    defaultMessage: 'KT',
    description: 'Recoins short (muenster)',
  },
  'recoins.vienna.short': {
    id: 'recoins.vienna.short',
    defaultMessage: 'Kultur-Token',
    description: 'Recoins short (vienna)',
  },
  'recoins.dresden.short': {
    id: 'recoins.dresden.short',
    defaultMessage: 'CT',
    description: 'Recoins short (dresden)',
  },
  'recoins.radkultur.short': {
    id: 'recoins.radkultur.short',
    defaultMessage: 'RC',
    description: 'Recoins short (radkultur)',
  },
  and: {
    id: 'and',
    defaultMessage: 'and',
    description: 'and',
  },
  'C02.short': {
    id: 'C02.short',
    defaultMessage: 'g C02',
    description: 'C02 short',
  },
  'CO2.saving': {
    id: 'CO2.saving',
    defaultMessage: ' kg CO2 saving',
    description: 'CO2 full: kg CO2 saving',
  },
  'timeframes.none': {
    id: 'timeframes.none',
    defaultMessage: 'None',
    description: 'API Timeframe string representation for 0 = None',
  },
  'timeframes.none2': {
    id: 'timeframes.none2',
    defaultMessage: 'None',
    description: 'API Timeframe string representation for 0 = None',
  },
  'timeframes.unlimited': {
    id: 'timeframes.unlimited',
    defaultMessage: 'Unlimited',
    description: 'API Timeframe string representation for 0 = Unlimited',
  },
  'timeframes.unlimited2': {
    id: 'timeframes.unlimited2',
    defaultMessage: 'Unlimited',
    description: 'API Timeframe string representation for 0 = Unlimited',
  },
  'timeframes.daily': {
    id: 'timeframes.daily',
    defaultMessage: 'Daily',
    description: 'API Timeframe string representation for 1 = Daily',
  },
  'timeframes.daily2': {
    id: 'timeframes.daily2',
    defaultMessage: 'd.',
    description: 'API Timeframe string representation for 1 = Daily',
  },
  'timeframes.weekly': {
    id: 'timeframes.weekly',
    defaultMessage: 'Weekly',
    description: 'API Timeframe string representation for 2 = Weekly',
  },
  'timeframes.weekly2': {
    id: 'timeframes.weekly2',
    defaultMessage: 'w.',
    description: 'API Timeframe string representation for 2 = Weekly',
  },
  'timeframes.monthly': {
    id: 'timeframes.monthly',
    defaultMessage: 'Monthly',
    description: 'API Timeframe string representation for 3 = Monthly',
  },
  'timeframes.monthly2': {
    id: 'timeframes.monthly2',
    defaultMessage: 'm.',
    description: 'API Timeframe string representation for 3 = Monthly',
  },
  'timeframes.yearly': {
    id: 'timeframes.yearly',
    defaultMessage: 'Yearly',
    description: 'API Timeframe string representation for 4 = Yearly',
  },
  'timeframes.yearly2': {
    id: 'timeframes.yearly2',
    defaultMessage: 'y.',
    description: 'API Timeframe string representation for 4 = Yearly',
  },
});
