import React, { useCallback, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';

const LanguageToggle = ({ activeLocale, onLocaleChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(_isOpen => !_isOpen);
  }, []);

  return (
    <Dropdown
      isOpen={isOpen}
      className="hide-children langSwitcher"
      toggle={handleToggle}
      nav
    >
      <DropdownToggle color="white" className="btn-link dropdown-toggle">
        {activeLocale.toUpperCase()}
      </DropdownToggle>
      <DropdownMenu>
        {['en', 'de'].map(locale => {
          if (locale !== activeLocale) {
            return (
              <DropdownItem
                key={locale}
                onClick={() => onLocaleChange(locale)}
                caret
              >
                {locale.toUpperCase()}
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

LanguageToggle.propTypes = {
  activeLocale: PropTypes.string,
  onLocaleChange: PropTypes.func,
};

export default LanguageToggle;
