const prefix = 'changers/compensation/';

export const FETCH_COMPENSATION_PARTNERS_LIST_REQUEST =
  prefix + 'FETCH_COMPENSATION_PARTNERS_LIST_REQUEST';

export const FETCH_COMPENSATION_PARTNERS_LIST_SUCCESS =
  prefix + 'FETCH_COMPENSATION_PARTNERS_LIST_SUCCESS';

export const FETCH_COMPENSATION_PARTNERS_LIST_FAILURE =
  prefix + 'FETCH_COMPENSATION_PARTNERS_LIST_FAILURE';

export const FETCH_COMPENSATION_LIST_REQUEST =
  prefix + 'FETCH_COMPENSATION_LIST_REQUEST';

export const FETCH_COMPENSATION_LIST_SUCCESS =
  prefix + 'FETCH_COMPENSATION_LIST_SUCCESS';

export const FETCH_COMPENSATION_LIST_FAILURE =
  prefix + 'FETCH_COMPENSATION_LIST_FAILURE';

export const CREATE_COMPENSATION_PARTNER_REQUEST =
  prefix + 'CREATE_COMPENSATION_PARTNER_REQUEST';
export const CREATE_COMPENSATION_PARTNER_SUCCESS =
  prefix + 'CREATE_COMPENSATION_PARTNER_SUCCESS';
export const CREATE_COMPENSATION_PARTNER_FAILURE =
  prefix + 'CREATE_COMPENSATION_PARTNER_FAILURE';

export const CREATE_COMPENSATION_REQUEST =
  prefix + 'CREATE_COMPENSATION_REQUEST';
export const CREATE_COMPENSATION_SUCCESS =
  prefix + 'CREATE_COMPENSATION_SUCCESS';
export const CREATE_COMPENSATION_FAILURE =
  prefix + 'CREATE_COMPENSATION_FAILURE';

export const UPDATE_COMPENSATION_PARTNER_REQUEST =
  prefix + 'UPDATE_COMPENSATION_PARTNER_REQUEST';
export const UPDATE_COMPENSATION_PARTNER_SUCCESS =
  prefix + 'UPDATE_COMPENSATION_PARTNER_SUCCESS';
export const UPDATE_COMPENSATION_PARTNER_FAILURE =
  prefix + 'CREATE_COMPENSATION_PARTNER_FAILURE';

export const UPDATE_COMPENSATION_REQUEST =
  prefix + 'UPDATE_COMPENSATION_REQUEST';
export const UPDATE_COMPENSATION_SUCCESS =
  prefix + 'UPDATE_COMPENSATION_SUCCESS';
export const UPDATE_COMPENSATION_FAILURE =
  prefix + 'CREATE_COMPENSATION_FAILURE';

export const UPDATE_COMPENSATION_PARTNER_ORDER_REQUEST =
  prefix + 'UPDATE_COMPENSATION_PARTNER_ORDER_REQUEST';
export const UPDATE_COMPENSATION_PARTNER_ORDER_SUCCESS =
  prefix + 'UPDATE_COMPENSATION_PARTNER_ORDER_SUCCESS';
export const UPDATE_COMPENSATION_PARTNER_ORDER_FAILURE =
  prefix + 'CREATE_COMPENSATION_PARTNER_ORDER_FAILURE';

export const UPDATE_COMPENSATION_ORDER_REQUEST =
  prefix + 'UPDATE_COMPENSATION_ORDER_REQUEST';
export const UPDATE_COMPENSATION_ORDER_SUCCESS =
  prefix + 'UPDATE_COMPENSATION_ORDER_SUCCESS';
export const UPDATE_COMPENSATION_ORDER_FAILURE =
  prefix + 'CREATE_COMPENSATION_ORDER_FAILURE';

export const UPLOAD_COMPENSATION_IMAGE_REQUEST =
  prefix + 'UPLOAD_COMPENSATION_IMAGE_REQUEST';
export const UPLOAD_COMPENSATION_IMAGE_SUCCESS =
  prefix + 'UPLOAD_COMPENSATION_IMAGE_SUCCESS';
export const UPLOAD_COMPENSATION_IMAGE_FAILURE =
  prefix + 'UPLOAD_COMPENSATION_IMAGE_FAILURE';

export const UPDATE_COMPENSATION_PARTNER_LIST_ORDER_STATUS =
  prefix + 'UPDATE_COMPENSATION_PARTNER_LIST_ORDER_STATUS';

export const UPDATE_COMPENSATION_LIST_ORDER =
  prefix + 'UPDATE_COMPENSATION_LIST_ORDER';

export const ACTIVATE_COMPENSATION_REQUEST =
  prefix + 'ACTIVATE_COMPENSATION_REQUEST';
export const ACTIVATE_COMPENSATION_SUCCESS =
  prefix + 'ACTIVATE_COMPENSATION_SUCCESS';
export const ACTIVATE_COMPENSATION_FAILURE =
  prefix + 'ACTIVATE_COMPENSATION_FAILURE';

export const DEACTIVATE_COMPENSATION_REQUEST =
  prefix + 'DEACTIVATE_COMPENSATION_REQUEST';
export const DEACTIVATE_COMPENSATION_SUCCESS =
  prefix + 'DEACTIVATE_COMPENSATION_SUCCESS';
export const DEACTIVATE_COMPENSATION_FAILURE =
  prefix + 'DEACTIVATE_COMPENSATION_FAILURE';

export const ACTIVATE_COMPENSATION_PARTNER_REQUEST =
  prefix + 'ACTIVATE_COMPENSATION_PARTNER_REQUEST';
export const ACTIVATE_COMPENSATION_PARTNER_SUCCESS =
  prefix + 'ACTIVATE_COMPENSATION_PARTNER_SUCCESS';
export const ACTIVATE_COMPENSATION_PARTNER_FAILURE =
  prefix + 'ACTIVATE_COMPENSATION_PARTNER_FAILURE';

export const DEACTIVATE_COMPENSATION_PARTNER_REQUEST =
  prefix + 'DEACTIVATE_COMPENSATION_PARTNER_REQUEST';
export const DEACTIVATE_COMPENSATION_PARTNER_SUCCESS =
  prefix + 'DEACTIVATE_COMPENSATION_PARTNER_SUCCESS';
export const DEACTIVATE_COMPENSATION_PARTNER_FAILURE =
  prefix + 'DEACTIVATE_COMPENSATION_PARTNER_FAILURE';
