import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { userInviteListSchema } from '../../schemas';
import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as UserReferralActions from '../../constants/actionTypes/userReferral';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Teams
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case UserReferralActions.FETCH_USER_REFERRAL_INVITE_STATS_SUCCESS: {
      const normalizedFetch = normalize(payload, userInviteListSchema);
      const normalizedUserInvites = fromJS(
        normalizedFetch.entities.userReferrals
      );

      if (!normalizedUserInvites) {
        return state;
      }

      return state.merge({
        byId: normalizedUserInvites,
        result: fromJS(normalizedFetch.result),
      });
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
