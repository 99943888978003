import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

const { number, string, boolean, any, oneOfType } = PropTypes;
const { mapOf, mapContains, listOf /* contains, */ } = ImmutablePropTypes;

const localizedContent = oneOfType([
  // when the response contains only the requested language, `en` by default
  string,

  // used for localized content
  mapOf(string, /* key */ any),
]);

/**
 * Challenge Source types
 */
const challengeSourceType = mapContains({
  source_id: number,
});

/**
 * Organisation types
 */
const organisationType = mapContains({
  id: number.isRequired,
  tree_planting_id: number,
  donation_id: number,
  name: string,
  display_name: localizedContent,
  description: localizedContent,
  public_url: string,
  passphrase: string,
  logo: string,
  challenge_type: number,
  challenge_sources: listOf(challengeSourceType),
  regex: listOf(string),
});

const organisationsListType = listOf(organisationType);

/**
 * User types
 */
const userType = mapContains({
  id: number.isRequired,
  organisation_id: number.isRequired,
  firstname: string.isRequired,
  lastname: string.isRequired,
  email: string.isRequired,
  image: string,
  recoins: number,
  is_admin: boolean,
  is_superadmin: boolean,
  stripe_id: string,
  card_brand: string,
  trial_ends_at: string,
});

const usersType = mapOf(userType, /* key */ any);

/**
 * Organisation Billing types
 */
const organisationBillingDetailsType = mapContains({
  city: string.isRequired,
  company: string.isRequired,
  country: string.isRequired,
  firstname: string.isRequired,
  lastname: string.isRequired,
  phone: string,
  postal_code: string.isRequired,
  street_address: string.isRequired,
  vat_number: string,
});

const organisationStripeInfoType = mapContains({
  card_brand: string,
  card_last_four: string,
});

const organisationBillingType = mapContains({
  billing_details: organisationBillingDetailsType,
  credit_card: organisationStripeInfoType,
});

/**
 * Challenge types
 */
const challengeType = mapContains({
  id: number.isRequired,
  organisation_id: number,
  tree_planting_id: number,
  // donation_id: number,
  state_id: number,
  activity_types: any,
  start_date: string.isRequired,
  end_date: string,
  recurring: number,
  status: string,
});

const challengesType = mapContains({
  byId: mapOf(challengeType, /* key */ any),
  result: listOf(number),
});

const challengesListType = listOf(challengeType);

/**
 * Team types
 */
const teamType = mapContains({
  id: number.isRequired,
  organisation_id: number,
  team_type_id: number,
  name: string.isRequired,
  users: number,
});

const teamsType = mapContains({
  byId: mapOf(teamType, /* key */ any),
  result: listOf(number),
});

const teamsListType = listOf(teamType);

/**
 * TeamType types
 */
const teamTypeType = mapContains({
  id: number.isRequired,
  organisation_id: number,
  name: string.isRequired,
  teams: listOf(teamType),
  users: number,
});

const teamTypesType = mapContains({
  byId: mapOf(teamTypeType, /* key */ any),
  result: listOf(number),
});

const teamTypesListType = listOf(teamTypeType);

/**
 * Donation types
 */
const donationType = mapContains({
  id: number.isRequired,
  donation_project_id: number.isRequired,
  cost_type: number.isRequired,
  cost_per_unit: number.isRequired,
  max_amount: number.isRequired,
  challenges: listOf(number),
});

const donationsType = mapContains({
  byId: mapOf(donationType, /* key */ any),
  result: listOf(number),
});

/**
 * DonationProject types
 */
const donationProjectType = mapContains({
  id: number.isRequired,
  organisation_id: number,
  name: localizedContent.isRequired,
  description: localizedContent,
  website: string,
  is_default: number,
  image: string,
});

const donationProjectsType = mapContains({
  byId: mapOf(donationProjectType, /* key */ any),
  result: listOf(number),
});

/**
 * TreePlanting types
 */
const treePlantingType = mapContains({
  id: number.isRequired,
  tree_type_id: number.isRequired,
  tree_planting_partner_id: number.isRequired,
  cost_type: number.isRequired,
  cost_per_unit: number.isRequired,
  max_amount: number.isRequired,
  challenges: listOf(number),
});

const treePlantingsType = mapContains({
  byId: mapOf(treePlantingType, /* key */ any),
  result: listOf(number),
});

/**
 * TreeType types
 */
const treeTypeType = mapContains({
  id: number.isRequired,
  name: string.isRequired,
  cost_per_tree: number.isRequired,
  image: string,
});

const treeTypesType = mapContains({
  byId: mapOf(treeTypeType, /* key */ any),
  result: listOf(number),
});

/**
 * TreePlantingPartner types
 */
const treePlantingPartnerType = mapContains({
  id: number.isRequired,
  organisation_id: number,
  name: localizedContent.isRequired,
  description: localizedContent,
  website: string,
  is_default: number,
});

const treePlantingPartnersType = mapContains({
  byId: mapOf(treePlantingPartnerType, /* key */ any),
  result: listOf(number),
});

const charityType = mapContains({
  id: number.isRequired,
  tree_planting_id: number,
  organisation_id: number.isRequired,
  tree_type_id: number,
  tree_planting_partner_id: number,
  type: string.isRequired,
  cost_type: number.isRequired,
  cost_per_unit: number.isRequired,
  start_date: string,
  end_date: string,
  amount: number,
  goal: number,
  state_id: number,
});

const charitiesType = mapContains({
  byId: mapOf(charityType, /* key */ any),
  result: listOf(number),
});

const charitiesListType = listOf(charityType);

const customActivityType = mapContains({
  id: number.isRequired,
  category_id: number,
  name: localizedContent,
  editorial: localizedContent,
  duration: number,
  max_quantity: number,
  recoins: number,
  timeframe: number,
  featured: boolean,
  restricted: boolean,
  enabled: boolean,
  default: boolean,
  printable_qr_code: string,
});

const customActivitiesType = mapContains({
  byId: mapOf(customActivityType, /* key */ any),
  result: listOf(number),
});

const customActivitiesByIdType = mapOf(customActivitiesType, /* key */ any);
const customActivitiesListType = listOf(customActivityType);

const customActivitiesByCategoryType = mapOf(
  customActivitiesListType,
  /* key */ string
);

const customActivitiesByCategoryGroupedByFeaturedType = mapContains({
  plain: customActivitiesListType,
  featured: customActivitiesListType,
});
const customActivityCategoryType = mapContains({
  id: number.isRequired,
  name: localizedContent,
  max_daily_quantity: number,
  duration: number,
  max_quantity: number,
  default: boolean,
  slug: string,
  icon_web: string,
  image: string,
  description: localizedContent,
  enabled: boolean,
});
const customActivityCategoriesType = mapContains({
  byId: mapOf(customActivityCategoryType, /* key */ any),
  result: listOf(number),
});
const customActivityCategoriesByIdType = mapOf(
  customActivityCategoriesType,
  /* key */ any
);
const voucherType = mapContains({
  id: number.isRequired,
  title: localizedContent,
  description: localizedContent,
  how_to: localizedContent,
  terms: localizedContent,
  image: string,
  website_name: string,
  website_url: string,
  timeframe: number,
  type: number,
  limit_per_user: number,
  recoins: number,
  enabled: boolean,
  default: boolean,
  voucher_codes_available: boolean,
  voucher_codes: listOf(string),
});

const vouchersType = mapContains({
  byId: mapOf(voucherType, /* key */ any),
  result: listOf(number),
});

const vouchersByIdType = mapOf(vouchersType, /* key */ any);
const vouchersListType = listOf(voucherType);

const prizesType = mapContains({
  id: number.isRequired,
  lottery_id: number,
  organisation_id: number,
  prize_name: localizedContent,
  amount: number,
});

const lotteryType = mapContains({
  id: number.isRequired,
  title: localizedContent,
  description: localizedContent,
  how_to: localizedContent,
  terms: localizedContent,
  image: string,
  website_name: string,
  website_url: string,
  recoins: number,
  end_date: string,
  is_global: boolean,
  enabled: boolean,
  lottery_available: boolean,
  prizes: listOf(prizesType),
});

const lotteriesType = mapContains({
  byId: mapOf(lotteryType, /* key */ any),
  result: listOf(number),
});

const lotteriesByIdType = mapOf(lotteriesType, /* key */ any);
const lotteriesListType = listOf(lotteryType);

const lotteriesGroupedByGlobalType = mapContains({
  global: listOf(lotteryType),
  private: listOf(lotteryType),
});

export {
  organisationType,
  organisationsListType,
  usersType,
  userType,
  organisationStripeInfoType,
  organisationBillingType,
  challengesType,
  challengeType,
  challengesListType,
  teamTypeType,
  teamTypesType,
  teamTypesListType,
  teamsType,
  teamType,
  teamsListType,
  donationsType,
  donationType,
  donationProjectsType,
  donationProjectType,
  treePlantingsType,
  treePlantingType,
  treeTypesType,
  treeTypeType,
  treePlantingPartnersType,
  treePlantingPartnerType,
  charitiesType,
  charityType,
  charitiesListType,
  customActivityType,
  customActivitiesType,
  customActivitiesByIdType,
  customActivitiesListType,
  customActivitiesByCategoryType,
  customActivitiesByCategoryGroupedByFeaturedType,
  customActivityCategoryType,
  customActivityCategoriesByIdType,
  voucherType,
  vouchersType,
  vouchersByIdType,
  vouchersListType,
  lotteryType,
  lotteriesType,
  lotteriesByIdType,
  lotteriesListType,
  lotteriesGroupedByGlobalType,
};
