import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as TreePlantingsActionTypes from '../constants/actionTypes/treePlantings';

/**
 * Fetch TreePlantings
 * @returns Promise
 */
const fetchTreePlantings = () => ({
  [RSAA]: {
    types: [
      TreePlantingsActionTypes.TREE_PLANTINGS_FETCH_REQUEST,
      TreePlantingsActionTypes.TREE_PLANTINGS_FETCH_SUCCESS,
      TreePlantingsActionTypes.TREE_PLANTINGS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/tree-planting?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Adds a new TreePlanting
 * @param data
 * @returns Promise
 */
const addTreePlanting = data => ({
  [RSAA]: {
    types: [
      TreePlantingsActionTypes.TREE_PLANTINGS_ADD_REQUEST,
      TreePlantingsActionTypes.TREE_PLANTINGS_ADD_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ TreePlantingsActionTypes.TREE_PLANTINGS_ADD_FAILURE,
        /* entity */ 'treePlantings'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/tree-planting',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a TreePlanting
 * @param data
 * @returns Promise
 */
const updateTreePlanting = (treePlantingId, data) => ({
  [RSAA]: {
    types: [
      TreePlantingsActionTypes.TREE_PLANTINGS_UPDATE_REQUEST,
      {
        type: TreePlantingsActionTypes.TREE_PLANTINGS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ treePlantingId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ TreePlantingsActionTypes.TREE_PLANTINGS_UPDATE_FAILURE,
        /* entity */ 'treePlantings'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/tree-planting/' + treePlantingId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Fetch a TreePlanting Goals
 * @param data
 * @returns Promise
 */
const fetchTreePlantingGoals = treePlantingId => ({
  [RSAA]: {
    types: [
      TreePlantingsActionTypes.TREE_PLANTINGS_GOALS_FETCH_REQUEST,
      {
        type: TreePlantingsActionTypes.TREE_PLANTINGS_GOALS_FETCH_SUCCESS,
        meta: (action, state, res) => ({ treePlantingId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ TreePlantingsActionTypes.TREE_PLANTINGS_GOALS_FETCH_FAILURE,
        /* entity */ 'treePlantings'
      ),
    ],
    endpoint:
      config.apiEndpoint + '/api/tree-planting/' + treePlantingId + '/goal',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update a TreePlanting Goal
 * @param data
 * @returns Promise
 */
const addTreePlantingGoal = (treePlantingId, goal) => ({
  [RSAA]: {
    types: [
      TreePlantingsActionTypes.TREE_PLANTINGS_GOALS_UPDATE_REQUEST,
      {
        type: TreePlantingsActionTypes.TREE_PLANTINGS_GOALS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ treePlantingId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ TreePlantingsActionTypes.TREE_PLANTINGS_GOALS_UPDATE_FAILURE,
        /* entity */ 'treePlantings'
      ),
    ],
    endpoint:
      config.apiEndpoint + '/api/tree-planting/' + treePlantingId + '/goal',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ goal }),
  },
});
export {
  fetchTreePlantings,
  addTreePlanting,
  updateTreePlanting,
  addTreePlantingGoal,
  fetchTreePlantingGoals,
};
