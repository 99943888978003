// eslint-disable-next-line no-unused-vars
import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

const Fragment = props =>
  props.children || <span {...props} /> || React.Fragment;

const defaultSelector = state => state.get('intl').toJS();

const mapStateToProps = (state, { intlSelector = defaultSelector }) => {
  return {
    ...intlSelector(state),
    textComponent: Fragment,
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(IntlProvider);
