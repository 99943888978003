export const REPORT_GENERATE_REQUEST = 'changers/admin/REPORT_GENERATE_REQUEST';
export const REPORT_GENERATE_SUCCESS = 'changers/admin/REPORT_GENERATE_SUCCESS';
export const REPORT_GENERATE_FAILURE = 'changers/admin/REPORT_GENERATE_FAILURE';

export const REPORT_LIST_REQUEST = 'changers/admin/REPORT_LIST_REQUEST';
export const REPORT_LIST_SUCCESS = 'changers/admin/REPORT_LIST_SUCCESS';
export const REPORT_LIST_FAILURE = 'changers/admin/REPORT_LIST_FAILURE';

export const REPORT_DOWNLOAD_REQUEST = 'changers/admin/REPORT_DOWNLOAD_REQUEST';
export const REPORT_DOWNLOAD_SUCCESS = 'changers/admin/REPORT_DOWNLOAD_SUCCESS';
export const REPORT_DOWNLOAD_FAILURE = 'changers/admin/REPORT_DOWNLOAD_FAILURE';

export const REPORT_RESET = 'changers/admin/REPORT_RESET';
