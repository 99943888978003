export const TREE_PLANTING_PARTNERS_FETCH_REQUEST =
  'changers/admin/TREE_PLANTING_PARTNERS_FETCH_REQUEST';
export const TREE_PLANTING_PARTNERS_FETCH_SUCCESS =
  'changers/admin/TREE_PLANTING_PARTNERS_FETCH_SUCCESS';
export const TREE_PLANTING_PARTNERS_FETCH_FAILURE =
  'changers/admin/TREE_PLANTING_PARTNERS_FETCH_FAILURE';

export const TREE_PLANTING_PARTNER_DEFAULTS_UPDATE_REQUEST =
  'changers/admin/TREE_PLANTING_PARTNER_DEFAULTS_UPDATE_REQUEST';
export const TREE_PLANTING_PARTNER_DEFAULTS_SUCCESS =
  'changers/admin/TREE_PLANTING_PARTNER_DEFAULTS_SUCCESS';
export const TREE_PLANTING_PARTNER_DEFAULTS_FAILURE =
  'changers/admin/TREE_PLANTING_PARTNER_DEFAULTS_FAILURE';

export const TREE_PLANTING_PARTNERS_ADD_REQUEST =
  'changers/admin/TREE_PLANTING_PARTNERS_ADD_REQUEST';
export const TREE_PLANTING_PARTNERS_ADD_SUCCESS =
  'changers/admin/TREE_PLANTING_PARTNERS_ADD_SUCCESS';
export const TREE_PLANTING_PARTNERS_ADD_FAILURE =
  'changers/admin/TREE_PLANTING_PARTNERS_ADD_FAILURE';

export const TREE_PLANTING_PARTNERS_UPDATE_REQUEST =
  'changers/admin/TREE_PLANTING_PARTNERS_UPDATE_REQUEST';
export const TREE_PLANTING_PARTNERS_UPDATE_SUCCESS =
  'changers/admin/TREE_PLANTING_PARTNERS_UPDATE_SUCCESS';
export const TREE_PLANTING_PARTNERS_UPDATE_FAILURE =
  'changers/admin/TREE_PLANTING_PARTNERS_UPDATE_FAILURE';
