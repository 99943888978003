import config from 'config';
import { addNotification } from 'reapop';
import { RSAA } from 'redux-api-middleware';

import * as UploadActionTypes from '../constants/actionTypes/upload';
import * as ErrorActionTypes from '../constants/actionTypes/error';

import { basicFailureRSAAHandlerForImageUpload } from '../utils/rsaa';

import { getMessageString } from '../components/i18n/GetMessage';
import globalTranslations from '../i18n';

/**
 * Add an error message
 * @param msg String
 * @returns {{type: String, error: boolean, meta: {message: String}}}
 */
const addErrorMessage = msg => ({
  type: ErrorActionTypes.ADD_ERROR_MESSAGE,
  error: true,
  meta: {
    message: msg,
  },
});

/**
 * Resets the currently visible error message
 * @returns {{type: String}}
 */
const resetErrorMessage = () => ({
  type: ErrorActionTypes.RESET_ERROR_MESSAGE,
});

/**
 * Fetches collection of entity from API
 *
 * @param fetchAction
 * @param entity
 * @param intl
 * @param force     Forces the update of the entity
 * @param allowUI   Allow UI changes (notifications) from within this action
 * @param locale    Force a specific locale, useful for Localizable fields
 */
const fetchEntity = (
  fetchAction,
  entity,
  intl,
  force = false,
  allowUI = true,
  locale
) => (dispatch, getState) => {
  // support nested values using dot notation: x.y.z
  // ignore whatever follows after `__` which is used only for naming conventions
  const nestedEntity = entity.split('__')[0].split('.');

  let data;
  if (getState().hasIn(['entities', ...nestedEntity, 'result'])) {
    data = getState().getIn(['entities', ...nestedEntity, 'result']);
  } else {
    data = getState().getIn(['entities', ...nestedEntity]);
  }

  // check if entity collection is already fetched from API
  // and we don't want to force a fetch of fresh data
  if (!force && data && data.size > 0) {
    return Promise.resolve();
  }

  return dispatch(
    fetchAction(typeof locale !== 'undefined' ? locale : intl.locale)
  ).then(action => {
    const {
      error,
      payload: { status, response: { _details } = {} } = {},
    } = action;
    // handle errors
    if (error) {
      // 2310: means no active challenge, skip
      if (allowUI && _details !== 2310) {
        let reason = 'fetchFailed';
        const notificationOptions = {
          status: 'error',
          dismissible: true,
          dismissAfter: 10000,
          buttons: [
            {
              name: getState().getIn(
                ['intl', 'messages', 'button.retry'],
                'Retry'
              ),
              primary: true,
              onClick: () => {
                dispatch(fetchEntity(fetchAction, entity, intl));
              },
            },
            {
              name: getState().getIn(
                ['intl', 'messages', 'button.skip'],
                'Skip'
              ),
              primary: false,
            },
          ],
        };

        if (status === 403) {
          reason = 'subscriptionUpgrade';

          notificationOptions.status = 'warning';
          notificationOptions.dismissible = true;
          notificationOptions.dismissAfter = 3000;
          notificationOptions.buttons = [];

          // TODO: button to move to upgrade page
        }

        dispatch(
          addNotification({
            message: getMessageString(
              intl,
              ...globalTranslations[`error.app.${entity}.${reason}`]
            ),
            ...notificationOptions,
          })
        );
      }

      return Promise.reject(action);
    }

    return Promise.resolve(action);
  });
};

/**
 * Upload image
 * @param file
 * @returns Promise
 */
const uploadImage = formData => ({
  [RSAA]: {
    types: [
      UploadActionTypes.UPLOAD_IMAGE_REQUEST,
      UploadActionTypes.UPLOAD_IMAGE_SUCCESS,
      basicFailureRSAAHandlerForImageUpload(
        /* type */ UploadActionTypes.UPLOAD_IMAGE_FAILURE
      ),
    ],
    endpoint: config.apiEndpoint + '/api/image',
    method: 'POST',
    body: formData,
  },
});

const uploadAudio = file => ({
  [RSAA]: {
    types: [
      UploadActionTypes.UPLOAD_AUDIO_REQUEST,
      UploadActionTypes.UPLOAD_AUDIO_SUCCESS,
      UploadActionTypes.UPLOAD_AUDIO_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/file-upload/audio',
    method: 'POST',
    body: file,
  },
});

export {
  addErrorMessage,
  resetErrorMessage,
  fetchEntity,
  uploadImage,
  uploadAudio,
};
