import { fromJS, List } from 'immutable';
import { normalize } from 'normalizr';
import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as ReportActionTypes from '../../constants/actionTypes/reports';
import { reportListSchema } from '../../schemas';

const initialState = fromJS({});

/**
 * Handles Teams
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = [], current_page = 1, total = 0 } = payload;

  switch (type) {
    case ReportActionTypes.REPORT_GENERATE_SUCCESS: {
      payload.file = null;
      payload.file_name = null;
      return state.merge(payload);
    }

    case ReportActionTypes.REPORT_LIST_SUCCESS: {
      const normalizedFetch = normalize(data, reportListSchema);
      const normalizedReports = fromJS(normalizedFetch.entities.reports);

      if (!normalizedReports) {
        return state
          .set('loading', false)
          .setIn(['byId'], {})
          .setIn(['result'], val => List())
          .set('current_page', current_page)
          .set('total', total);
      }

      return state
        .set('loading', false)
        .setIn(['byId'], normalizedReports)
        .setIn(['result'], val => List(normalizedFetch.result))
        .set('current_page', current_page)
        .set('total', total);
    }

    case ReportActionTypes.REPORT_RESET: {
      return initialState;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
