// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import styles from './Footer.scss';
import WhitelabelContext from '../../decorators/WhitelabelContext';
import globalTranslations from '../../i18n';
import translations from './translations';
import classnames from 'classnames';

const currentYear = new Date().getFullYear();

/**
 * Displays a footer for the dashboard
 */

class Footer extends React.Component {
  static propTypes = {
    locale: PropTypes.string,
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {whitelabel => {
          const hideFooter = whitelabel.get('hideFooter') || false;
          const showCustomFooter = whitelabel.get('showCustomFooter') || false;
          return (
            <footer className={classnames('footer', styles.footer, styles.ml8)}>
              <Nav className="ml-0 d-block">
                {showCustomFooter && (
                  <div className="row">
                    <div className="col-12">
                      <NavItem>
                        <FormattedMessage
                          {...translations[
                            whitelabel.getIn([
                              'translations',
                              'footerCopyright',
                            ]) || 'footer.copyright'
                          ]}
                        />
                      </NavItem>
                    </div>
                    <div className="col-3 mt-1">
                      <NavItem>
                        <NavLink
                          href={whitelabel.getIn([
                            'privacyLink',
                            this.props.locale,
                          ])}
                          target="_blank"
                          className="p-0"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            {...globalTranslations['text.privacyPolicy']}
                          />
                        </NavLink>
                      </NavItem>
                    </div>
                  </div>
                )}
                {!hideFooter && (
                  <div className="row">
                    <div className="col-3">
                      <NavItem>
                        © {currentYear}{' '}
                        <FormattedMessage
                          {...translations[
                            whitelabel.getIn([
                              'translations',
                              'footerCopyright',
                            ]) || 'footer.copyright'
                          ]}
                        />
                      </NavItem>
                    </div>
                    <div className="col-3">
                      <NavItem>
                        <NavLink
                          href={whitelabel.getIn([
                            'privacyLink',
                            this.props.locale,
                          ])}
                          target="_blank"
                          className="p-0"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            {...globalTranslations['text.privacyPolicy']}
                          />
                        </NavLink>
                      </NavItem>
                    </div>
                    {/* <div className="col-3">
                      <NavItem>
                        <NavLink
                          href="https://changers.zendesk.com/"
                          target="_blank"
                          className="p-0"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            {...globalTranslations['text.support']}
                          />
                        </NavLink>
                      </NavItem>
                    </div> */}
                  </div>
                )}
              </Nav>
            </footer>
          );
        }}
      </WhitelabelContext.Consumer>
    );
  }
}

export default Footer;
