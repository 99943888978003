import { defineMessages } from 'react-intl';

export default defineMessages({
  'text.min': {
    id: 'text.min',
    defaultMessage: 'min',
    description: 'Global text',
  },
  'text.km': {
    id: 'text.km',
    defaultMessage: 'km',
    description: 'Global text',
  },
  'text.steps': {
    id: 'text.steps',
    defaultMessage: 'Steps',
    description: 'Global text',
  },
  'text.kg': {
    id: 'text.kg',
    defaultMessage: 'Kg',
    description: 'Global text',
  },
  'text.times': {
    id: 'text.times',
    defaultMessage: '{times, plural, one {1 time} other {{times} times}}',
    description: 'Global text',
  },
  'text.support': {
    id: 'text.support',
    defaultMessage: 'Support',
    description: 'Global text',
  },
  'text.tos': {
    id: 'text.tos',
    defaultMessage: 'Terms & Conditions',
    description: 'Global text',
  },
  'text.privacyPolicy': {
    id: 'text.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'Global text',
  },
  'text.archives': {
    id: 'text.archives',
    defaultMessage: 'Archives',
    description: 'Archives',
  },
  'text.archives2': {
    id: 'text.archives2',
    defaultMessage: 'Go to archive',
    description: 'Archives',
  },
  'text.noEntries': {
    id: 'text.noEntries',
    defaultMessage: 'There are no entries',
    description: 'Global Text',
  },
  'text.entryStillActive': {
    id: 'text.entryStillActive',
    defaultMessage: 'Entry still active',
    description: 'Global Text',
  },
  'text.archiving.deactivateFirst': {
    id: 'text.archiving.deactivateFirst',
    defaultMessage: 'Please deactivate before archiving',
    description: 'Archives',
  },
  'text.archive.failed': {
    id: 'text.archive.failed',
    defaultMessage: 'Archiving failed',
    description: 'Archives',
  },
  'text.app.title': {
    id: 'text.app.title',
    defaultMessage: 'Changers Admin Area',
    description: 'App Title',
  },
  'text.app.title.notAdmin': {
    id: 'text.app.title.notAdmin',
    defaultMessage: 'Changers Challenge',
    description: 'App Title',
  },
  'text.app.title.radkultur': {
    id: 'text.app.title.radkultur',
    defaultMessage: 'RadKULTUR Admin Area',
    description: 'App Title',
  },
});
