import { fromJS, Map } from 'immutable';
import { normalize } from 'normalizr';
import { teamsListSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as EntitiesActionTypes from '../../constants/actionTypes/organisationEntities';
import * as TeamsActionTypes from '../../constants/actionTypes/teams';
import * as TeamTypesActionTypes from '../../constants/actionTypes/teamTypes';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Teams
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {}, meta = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case EntitiesActionTypes.ORGANISATION_ENTITIES_FETCH_SUCCESS: {
      return state.merge({
        result: fromJS(data),
      });
    }

    case TeamTypesActionTypes.TEAM_TYPES_ALL_TEAMS_FETCH_SUCCESS: {
      // maintain teams ordering
      data.map(teamType => {
        const normalizedFetch = normalize(teamType.teams, teamsListSchema);
        const normalizedTeams = fromJS(normalizedFetch.entities.teams);

        if (!normalizedTeams) {
          return state;
        }

        state = state
          // mergeDeepIn is used for CURRENT_USER_TEAMS_FETCH_SUCCESS
          .mergeDeepIn(['byId'], normalizedTeams)
          .updateIn(['result'], val => {
            normalizedFetch.result.map(teamId => {
              if (!val.includes(teamId)) {
                val = val.push(teamId);
              }
            });

            return val;
          });
      });

      return state;
    }

    case TeamsActionTypes.CURRENT_USER_TEAMS_FETCH_SUCCESS: {
      if (data && data.length > 0) {
        for (const { team_id } of data) {
          state = state.updateIn(['byId', `${team_id}`], (val = Map()) =>
            val.set('selected', true)
          );
        }
      }

      return state;
    }

    case TeamsActionTypes.TEAM_JOIN_SUCCESS:
    case TeamsActionTypes.TEAM_LEAVE_SUCCESS: {
      const { team_id } = meta;
      if (team_id) {
        state = state.updateIn(['byId', `${team_id}`], (val = Map()) =>
          val.set('selected', type === TeamsActionTypes.TEAM_JOIN_SUCCESS)
        );
      }

      return state;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
