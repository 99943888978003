export const TEAM_TYPES_FETCH_REQUEST =
  'changers/admin/TEAM_TYPES_FETCH_REQUEST';
export const TEAM_TYPES_FETCH_SUCCESS =
  'changers/admin/TEAM_TYPES_FETCH_SUCCESS';
export const TEAM_TYPES_FETCH_FAILURE =
  'changers/admin/TEAM_TYPES_FETCH_FAILURE';

export const TEAM_TYPES_ALL_TEAMS_FETCH_REQUEST =
  'changers/admin/TEAM_TYPES_ALL_TEAMS_FETCH_REQUEST';
export const TEAM_TYPES_ALL_TEAMS_FETCH_SUCCESS =
  'changers/admin/TEAM_TYPES_ALL_TEAMS_FETCH_SUCCESS';
export const TEAM_TYPES_ALL_TEAMS_FETCH_FAILURE =
  'changers/admin/TEAM_TYPES_ALL_TEAMS_FETCH_FAILURE';

export const TEAM_TYPES_UPDATE_REQUEST =
  'changers/admin/TEAM_TYPES_UPDATE_REQUEST';
export const TEAM_TYPES_UPDATE_SUCCESS =
  'changers/admin/TEAM_TYPES_UPDATE_SUCCESS';
export const TEAM_TYPES_UPDATE_FAILURE =
  'changers/admin/TEAM_TYPES_UPDATE_FAILURE';
