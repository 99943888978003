import { defineMessages } from 'react-intl';

export default defineMessages({
  'error.api.INTERNAL_SERVER_ERROR': {
    id: 'error.api.INTERNAL_SERVER_ERROR',
    defaultMessage:
      'Something went wrong. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.INVALID_REQUEST_PARAMETERS': {
    id: 'error.api.INVALID_REQUEST_PARAMETERS',
    defaultMessage: 'Invalid request parameters.',
    description: 'API Error',
  },
  'error.api.INVALID_USER_TOKEN': {
    id: 'error.api.INVALID_USER_TOKEN',
    defaultMessage:
      'Authorization Error. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.INVALID_REQUEST_PAYLOAD': {
    id: 'error.api.INVALID_REQUEST_PAYLOAD',
    defaultMessage: 'Invalid request payload.',
    description: 'API Error',
  },
  'error.api.SAVE_ENTITY_FAILED': {
    id: 'error.api.SAVE_ENTITY_FAILED',
    defaultMessage: 'Failed to save resource.',
    description: 'API Error',
  },
  'error.api.INVALID_JSON_REQUEST': {
    id: 'error.api.INVALID_JSON_REQUEST',
    defaultMessage:
      'Wrong request format. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.RESOURCE_NOT_FOUND': {
    id: 'error.api.RESOURCE_NOT_FOUND',
    defaultMessage: 'Resource could not be found. Please try again.',
    description: 'API Error',
  },
  'error.api.METHOD_NOT_ALLOWED': {
    id: 'error.api.METHOD_NOT_ALLOWED',
    defaultMessage: 'Method not allowed.',
    description: 'API Error',
  },
  'error.api.PERMISSION_DENIED': {
    id: 'error.api.PERMISSION_DENIED',
    defaultMessage:
      'The credentials you used for login do not match with this organisation. Please get in touch with the contact person within your organisation.',
    description: 'API Error',
  },
  'error.api.DB_ERROR': {
    id: 'error.api.DB_ERROR',
    defaultMessage:
      'Database error. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.TOO_MANY_REQUESTS': {
    id: 'error.api.TOO_MANY_REQUESTS',
    defaultMessage: 'Too many requests.',
    description: 'API Error',
  },
  'error.api.INVALID_USER_AGENT': {
    id: 'error.api.INVALID_USER_AGENT',
    defaultMessage: 'Invalid User Agent.',
    description: 'API Error',
  },
  'error.api.INVALID_LANGUAGE': {
    id: 'error.api.INVALID_LANGUAGE',
    defaultMessage: 'Invalid Language.',
    description: 'API Error',
  },
  'error.api.INVALID_CREDENTIALS': {
    id: 'error.api.INVALID_CREDENTIALS',
    defaultMessage: 'Invalid credentials. Please try again.',
    description: 'API Error',
  },
  'error.api.HOST_HEADER_MISSING': {
    id: 'error.api.HOST_HEADER_MISSING',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'API Error',
  },
  'error.api.INVALID_HOST_HEADER': {
    id: 'error.api.INVALID_HOST_HEADER',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'API Error',
  },
  'error.api.GENERAL_ERROR': {
    id: 'error.api.GENERAL_ERROR',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'API Error',
  },
  'error.api.ORIGIN_HEADER_MISSING': {
    id: 'error.api.ORIGIN_HEADER_MISSING',
    defaultMessage:
      'Something went wrong. Please try again or try with another browser.',
    description: 'API Error',
  },
  'error.api.VALUE_NOT_ALLOWED': {
    id: 'error.api.VALUE_NOT_ALLOWED',
    defaultMessage: 'Value set is not allowed.',
    description: 'API Error',
  },
  'error.api.INVALID_MOBILITY_TYPE': {
    id: 'error.api.INVALID_MOBILITY_TYPE',
    defaultMessage: 'Invalid activity.',
    description: 'API Error',
  },
  'error.api.INVALID_TRACKING_SOURCE': {
    id: 'error.api.INVALID_TRACKING_SOURCE',
    defaultMessage: 'Invalid tracking source. Please try again.',
    description: 'API Error',
  },
  'error.api.DISTANCE_QUOTA_EXCEEDED': {
    id: 'error.api.DISTANCE_QUOTA_EXCEEDED',
    defaultMessage: 'You have reached the maximum distance.',
    description: 'API Error',
  },
  'error.api.TIMEFRAME_QUOTA_EXCEEDED': {
    id: 'error.api.TIMEFRAME_QUOTA_EXCEEDED',
    defaultMessage: 'You have reached the maximum amount of activities.',
    description: 'API Error',
  },
  'error.api.ENTRY_NONEXISTENT': {
    id: 'error.api.ENTRY_NONEXISTENTRROR',
    defaultMessage: 'Invalid activity.',
    description: 'API Error',
  },
  'error.api.ENTRY_NOT_ENABLED': {
    id: 'error.api.ENTRY_NOT_ENABLEDRROR',
    defaultMessage: 'This activity is not enabled any more.',
    description: 'API Error',
  },
  'error.api.ENTRY_TIMEFRAME_DAILY': {
    id: 'error.api.ENTRY_TIMEFRAME_DAILY',
    defaultMessage:
      'You have reached the maximum amount of this activity for today.',
    description: 'API Error',
  },
  'error.api.ENTRY_TIMEFRAME_WEEKLY': {
    id: 'error.api.ENTRY_TIMEFRAME_WEEKLY',
    defaultMessage:
      'You have reached the maximum amount of this activity for this week.',
    description: 'API Error',
  },
  'error.api.ENTRY_TIMEFRAME_MONTHLY': {
    id: 'error.api.ENTRY_TIMEFRAME_MONTHLY',
    defaultMessage:
      'You have reached the maximum amount of this activity for this month.',
    description: 'API Error',
  },
  'error.api.ENTRY_TIMEFRAME_YEARLY': {
    id: 'error.api.ENTRY_TIMEFRAME_YEARLY',
    defaultMessage:
      'You have reached the maximum amount of this activity for this year.',
    description: 'API Error',
  },
  'error.api.ENTRY_CATEGORY_DAILY_MAXIMUM': {
    id: 'error.api.ENTRY_CATEGORY_DAILY_MAXIMUM',
    defaultMessage:
      'You have reached the maximum amount of activities for this category for today.',
    description: 'API Error',
  },
  'error.api.NO_ACTIVE_CHALLENGES': {
    id: 'error.api.NO_ACTIVE_CHALLENGES',
    defaultMessage: 'There is no active challenge, please check back later.',
    description: 'API Error',
  },
  'error.api.INVALID_ACTIVITY_DATE': {
    id: 'error.api.INVALID_ACTIVITY_DATE',
    defaultMessage:
      'The activity date set does not belong in the current challenge.',
    description: 'API Error',
  },
  'error.api.USER_LOGOUT_FAILED': {
    id: 'error.api.USER_LOGOUT_FAILED',
    defaultMessage: 'Sign out failed. Please try again.',
    description: 'API Error',
  },
  'error.api.INVALID_CHALLENGE_TIMEFRAME': {
    id: 'error.api.INVALID_CHALLENGE_TIMEFRAME',
    defaultMessage:
      'It is not allowed to have two challenges at the same time. Please change your challenge timeframe.',
    description: 'API Error',
  },
  'error.api.CHARITY_QUOTA_EXCEEDED': {
    id: 'error.api.CHARITY_QUOTA_EXCEEDED',
    defaultMessage: 'Only one charity option allowed.',
    description: 'API Error',
  },
  'error.api.TREE_PLANTING_ALREADY_EXISTS': {
    id: 'error.api.TREE_PLANTING_ALREADY_EXISTS',
    defaultMessage: 'Tree planting with same configuration already exists.',
    description: 'API Error',
  },
  'error.api.TREE_PLANTING_IN_USE': {
    id: 'error.api.TREE_PLANTING_IN_USE',
    defaultMessage:
      'You can not update a Tree planting that is used by an ongoing challenge.',
    description: 'API Error',
  },
  'error.api.DONATION_PROJECT_ALREADY_EXISTS': {
    id: 'error.api.DONATION_PROJECT_ALREADY_EXISTS',
    defaultMessage: 'Donation Project with same configuration already exists.',
    description: 'API Error',
  },
  'error.api.DONATION_ALREADY_EXISTS': {
    id: 'error.api.DONATION_ALREADY_EXISTS',
    defaultMessage: 'Donation with same configuration already exists.',
    description: 'API Error',
  },
  'error.api.DONATION_IN_USE': {
    id: 'error.api.DONATION_IN_USE',
    defaultMessage:
      'You can not update a Donation that is used by an ongoing challenge.',
    description: 'API Error',
  },
  'error.api.CAN_NOT_UPDATE_ONGOING_CHALLENGE': {
    id: 'error.api.CAN_NOT_UPDATE_ONGOING_CHALLENGE',
    defaultMessage: 'You can not update an ongoing challenge.',
    description: 'API Error',
  },
  'error.api.PARTNER_QUOTA_EXCEEDED': {
    id: 'error.api.PARTNER_QUOTA_EXCEEDED',
    defaultMessage: 'You have already set up one Tree Planting Partner.',
    description: 'API Error',
  },
  'error.api.NO_ACTIVE_CHALLENGE': {
    id: 'error.api.NO_ACTIVE_CHALLENGE',
    defaultMessage:
      'Your organisation has no active challenges at the moment. Please get back later.',
    description: 'API Error',
  },
  'error.api.PARTNER_SHOULD_NOT_HAVE_TREE_TYPE': {
    id: 'error.api.PARTNER_SHOULD_NOT_HAVE_TREE_TYPE',
    defaultMessage:
      'You have selected a partner that does not support tree types.',
    description: 'API Error',
  },
  'error.api.PARTNER_SHOULD_HAVE_TREE_TYPE': {
    id: 'error.api.PARTNER_SHOULD_HAVE_TREE_TYPE',
    defaultMessage: 'Tree type is required for the partner you selected.',
    description: 'API Error',
  },
  'error.api.CHARITY_ALREADY_SET': {
    id: 'error.api.CHARITY_ALREADY_SET',
    defaultMessage:
      'You have already set a charity project for current challenge.',
    description: 'API Error',
  },
  'error.api.DELETE_ORGANISATION_FAIL': {
    id: 'error.api.DELETE_ORGANISATION_FAIL',
    defaultMessage:
      'Update Organisation resource failed. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.USERS_JOIN_QUOTA_EXCEEDED': {
    id: 'error.api.USERS_JOIN_QUOTA_EXCEEDED',
    defaultMessage: 'Max user number reached. Please update your subscription.',
    description: 'API Error',
  },
  'error.api.DUPLICATE_ORGANISATION': {
    id: 'error.api.DUPLICATE_ORGANISATION',
    defaultMessage: 'Organisation with same configuration already exists.',
    description: 'API Error',
  },
  'error.api.INVALID_PASSPHRASE': {
    id: 'error.api.INVALID_PASSPHRASE',
    defaultMessage:
      "Passphrase does not match the one set by organisation. Please contant your organisation's admin.",
    description: 'API Error',
  },
  'error.api.TEAM_TYPE_QUOTA_EXCEEDED': {
    id: 'error.api.TEAM_TYPE_QUOTA_EXCEEDED',
    defaultMessage:
      'Max team type number reached. Please update your subscription.',
    description: 'API Error',
  },
  'error.api.TEAM_QUOTA_EXCEEDED': {
    id: 'error.api.TEAM_QUOTA_EXCEEDED',
    defaultMessage: 'Max team number reached. Please update your subscription.',
    description: 'API Error',
  },
  'error.api.TEAM_MAX_LIMIT_REACHED': {
    id: 'error.api.TEAM_MAX_LIMIT_REACHED',
    defaultMessage: 'Max team limit reached. Please update your subscription.',
    description: 'API Error',
  },
  'error.api.CANNOT_LEAVE_TEAM': {
    id: 'error.api.CANNOT_LEAVE_TEAM',
    defaultMessage: 'Can not leave team.',
    description: 'API Error',
  },
  'error.api.CANNOT_JOIN_TEAM': {
    id: 'error.api.CANNOT_JOIN_TEAM',
    defaultMessage: 'Can not join team',
    description: 'API Error',
  },
  'error.api.SUBSCRIPTION_ERROR': {
    id: 'error.api.SUBSCRIPTION_ERROR',
    defaultMessage:
      'Something went wrong with your subscription. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.INVALID_VATLAYER_RESPONSE': {
    id: 'error.api.INVALID_VATLAYER_RESPONSE',
    defaultMessage:
      'Something went wrong with your subscription. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.INVALID_VAT_NUMBER': {
    id: 'error.api.INVALID_VAT_NUMBER',
    defaultMessage: 'Your VAT Number is Invalid. Please try again.',
    description: 'API Error',
  },
  'error.api.SUBSCRIPTION_CREATE_FAILED': {
    id: 'error.api.SUBSCRIPTION_CREATE_FAILED',
    defaultMessage:
      'Something went wrong during the confirmation of your subscription. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.SUBSCRIPTION_CHANGE_FAILED': {
    id: 'error.api.SUBSCRIPTION_CHANGE_FAILED',
    defaultMessage:
      'Something went wrong during the update of your subscription. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.SUBSCRIPTION_CANCEL_FAILED': {
    id: 'error.api.SUBSCRIPTION_CANCEL_FAILED',
    defaultMessage:
      'Something went wrong during the cancellation of your subscription. If the problem persists, please contact our support.',
    description: 'API Error',
  },
  'error.api.UPDATE_CREDIT_CARD_FAILED': {
    id: 'error.api.UPDATE_CREDIT_CARD_FAILED',
    defaultMessage:
      'Something went wrong during the update of your credit card. Please try again.',
    description: 'API Error',
  },
  'error.api.USER_ALREADY_SUBSCRIBED': {
    id: 'error.api.USER_ALREADY_SUBSCRIBED',
    defaultMessage: 'Your Organisation already has this type of subscription.',
    description: 'API Error',
  },
  'error.api.INVOICE_CHARGE_FAILED': {
    id: 'error.api.INVOICE_CHARGE_FAILED',
    defaultMessage:
      'We encountered an error with your invoice. Please contact our support.',
    description: 'API Error',
  },
  'error.api.SUBSCRIPTION_ENDED': {
    id: 'error.api.SUBSCRIPTION_ENDED',
    defaultMessage: 'You do not have a subscription. Unable to proceed.',
    description: 'API Error',
  },
  'error.api.NOT_ENOUGH_RECOINS': {
    id: 'error.api.NOT_ENOUGH_RECOINS',
    defaultMessage: 'You do not have enough RC for this purchase.',
    description: 'API Error',
  },
  'error.api.VOUCHER_CODES_EXCEEDED': {
    id: 'error.api.VOUCHER_CODES_EXCEEDED',
    defaultMessage:
      'Unfortunately this offer has no more vouchers. Please select another offer.',
    description: 'API Error',
  },
  'error.api.CAN_NOT_UPDATE_DRAWN_LOTTERY': {
    id: 'error.api.CAN_NOT_UPDATE_DRAWN_LOTTERY',
    defaultMessage: 'Drawn lotteries can not be updated.',
    description: 'API Error',
  },
  'error.api.VOUCHER_CODES_REQUIRED': {
    id: 'error.api.VOUCHER_CODES_REQUIRED',
    defaultMessage: 'You can not activate a voucher without voucher codes.',
    description: 'API Error',
  },
  'error.api.NO_OFFERS_LEFT': {
    id: 'error.api.NO_OFFERS_LEFT',
    defaultMessage: 'No offers left.',
    description: 'API Error',
  },
  'error.api.OFFER_LIMIT_EXCEEDED': {
    id: 'error.api.OFFER_LIMIT_EXCEEDED',
    defaultMessage: 'Offer Limit Exceeded',
    description: 'API Error',
  },
  'error.api.OFFER_LIMIT_EXCEEDED.1': {
    id: 'error.api.OFFER_LIMIT_EXCEEDED.1',
    defaultMessage: `You can purchase only {value} per day.`,
    description: 'API Error',
  },
  'error.api.OFFER_LIMIT_EXCEEDED.2': {
    id: 'error.api.OFFER_LIMIT_EXCEEDED.2',
    defaultMessage: `You can purchase only {value} per week.`,
    description: 'API Error',
  },
  'error.api.OFFER_LIMIT_EXCEEDED.3': {
    id: 'error.api.OFFER_LIMIT_EXCEEDED.3',
    defaultMessage: `You can purchase only {value} per month`,
    description: 'API Error',
  },
  'error.api.OFFER_LIMIT_EXCEEDED.4': {
    id: 'error.api.OFFER_LIMIT_EXCEEDED.4',
    defaultMessage: `You can purchase only {value} per year.`,
    description: 'API Error',
  },
  'error.api.SUPERADMIN_ACCESS_REQUIRED': {
    id: 'error.api.SUPERADMIN_ACCESS_REQUIRED',
    defaultMessage: 'Only super admins are allowed to change this.',
    description: 'API Error',
  },
  'error.api.REQUIRED_PARAMETER': {
    id: 'error.api.REQUIRED_PARAMETER',
    defaultMessage: 'Required parameter.',
    description: 'API Error',
  },
  'error.api.UNIQUE_PARAMETER': {
    id: 'error.api.UNIQUE_PARAMETER',
    defaultMessage: 'Already taken. Please, try Something else.',
    description: 'API Error',
  },
  'error.api.CHARACTERS_QUOTA_EXCEEDED': {
    id: 'error.api.CHARACTERS_QUOTA_EXCEEDED',
    defaultMessage: 'You should decrease the number of characters.',
    description: 'API Error',
  },
  'error.api.CHARACTERS_QUOTA_NOT_EXCEEDED': {
    id: 'error.api.CHARACTERS_QUOTA_NOT_EXCEEDED',
    defaultMessage: 'You should increase the number of characters.',
    description: 'API Error',
  },
  'error.api.PARAMETER_IS_NOT_ARRAY': {
    id: 'error.api.PARAMETER_IS_NOT_ARRAY',
    defaultMessage: 'parameter is not an array.',
    description: 'API Error',
  },
  'error.api.INVALID_DATE': {
    id: 'error.api.INVALID_DATE',
    defaultMessage: 'Invalid date.',
    description: 'API Error',
  },
  'error.api.INVALID_EMAIL': {
    id: 'error.api.INVALID_EMAIL',
    defaultMessage: 'Invalid email.',
    description: 'API Error',
  },
  'error.api.ITEM_NOT_IN_LIST': {
    id: 'error.api.ITEM_NOT_IN_LIST',
    defaultMessage: 'Item not accepted.',
    description: 'API Error',
  },
  'error.api.SUBSCRIPTION_UPGRADE_REQUIRED': {
    id: 'error.api.SUBSCRIPTION_UPGRADE_REQUIRED',
    defaultMessage: 'Subscription upgrade required.',
    description: 'API Error',
  },
  'error.api.REGEX_QUOTA_EXCEEDED': {
    id: 'error.api.REGEX_QUOTA_EXCEEDED',
    defaultMessage:
      'You are not allowed to have more regular expressions. Please upgrade your subscription.',
    description: 'API Error',
  },
  'error.api.INVALID_RECORD': {
    id: 'error.api.INVALID_RECORD',
    defaultMessage: 'Invalid resource.',
    description: 'API Error',
  },
  'error.api.COULD_NOT_REMOVE_REGEX_IN_USE': {
    id: 'error.api.COULD_NOT_REMOVE_REGEX_IN_USE',
    defaultMessage: 'Regular expression could not be deleted as it used.',
    description: 'API Error',
  },
  'error.api.FIELD_CAN_NOT_BE_UPDATED': {
    id: 'error.api.FIELD_CAN_NOT_BE_UPDATED',
    defaultMessage: 'You can not update this field.',
    description: 'API Error',
  },
  'error.api.INVALID_IMAGE_DIMENSIONS': {
    id: 'error.api.INVALID_IMAGE_DIMENSIONS',
    defaultMessage: 'Invalid image dimensions.',
    description: 'API Error',
  },
  'error.api.RESOURCE_CAN_NOT_BE_UPDATED': {
    id: 'error.api.RESOURCE_CAN_NOT_BE_UPDATED',
    defaultMessage: 'Resource can not be updated.',
    description: 'API Error',
  },
  'error.api.PASSWORDS_DO_NOT_MATCH': {
    id: 'error.api.PASSWORDS_DO_NOT_MATCH',
    defaultMessage: 'Please make sure passwords match.',
    description: 'API Error',
  },
  'error.api.INVALID_REGEX_FORMAT': {
    id: 'error.api.INVALID_REGEX_FORMAT',
    defaultMessage:
      'Invalid Regular expression format. Please make sure it is valid.',
    description: 'API Error',
  },
  'error.api.AMOUNT_CAN_NOT_BE_DECREASED': {
    id: 'error.api.AMOUNT_CAN_NOT_BE_DECREASED',
    defaultMessage:
      'Amount can not be decreased. Please provide a higher or equal amount.',
    description: 'API Error',
  },
  'error.api.COULD_NOT_REMOVE_TEAM_TYPE_IN_USE': {
    id: 'error.api.COULD_NOT_REMOVE_TEAM_TYPE_IN_USE',
    defaultMessage:
      'Team Type can not be removed. Make sure its teams have no users.',
    description: 'API Error',
  },
  'error.api.COULD_NOT_REMOVE_TEAM_IN_USE': {
    id: 'error.api.COULD_NOT_REMOVE_TEAM_IN_USE',
    defaultMessage: 'Team can not be removed. Make sure it has no users.',
    description: 'API Error',
  },
  'error.api.FIELD_HAS_DUPLICATE_VALUE': {
    id: 'error.api.FIELD_HAS_DUPLICATE_VALUE',
    defaultMessage:
      'Field should be unique. Make sure no other field has the same value.',
    description: 'API Error',
  },
  'error.api.DEFAULT_TRANSLATION_MISSING': {
    id: 'error.api.DEFAULT_TRANSLATION_MISSING',
    defaultMessage: 'Please make sure default translation is not missing.',
    description: 'API Error',
  },
  'error.api.INVALID_FORMAT': {
    id: 'error.api.INVALID_FORMAT',
    defaultMessage: 'Invalid format. Please try again.',
    description: 'API Error',
  },
  'error.api.FIELD_MUST_BE_STRING': {
    id: 'error.api.FIELD_MUST_BE_STRING',
    defaultMessage: 'Field should be a string.',
    description: 'API Error',
  },
  'error.api.UNITS_ALREADY_REACHED': {
    id: 'error.api.UNITS_ALREADY_REACHED',
    defaultMessage: 'Goal set is already reached. Please increase the goal.',
    description: 'API Error',
  },
  'error.api.FIELD_MUST_BE_BOOLEAN': {
    id: 'error.api.FIELD_MUST_BE_BOOLEAN',
    defaultMessage: 'Field should be a boolean.',
    description: 'API Error',
  },
  'error.api.EMAIL_TOKEN_EXPIRED': {
    id: 'error.api.EMAIL_TOKEN_EXPIRED',
    defaultMessage: 'Activation link has expired',
    description: 'API Error',
  },
  'error.api.EMAIL_TOKEN_INVALID': {
    id: 'error.api.EMAIL_TOKEN_INVALID',
    defaultMessage: 'Activation link is invalid or has been used already',
    description: 'API Error',
  },
});
