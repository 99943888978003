import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as TreeTypesActionTypes from '../constants/actionTypes/treeTypes';

/**
 * Fetch TreeTypes
 * @returns Promise
 */
const fetchTreeTypes = () => ({
  [RSAA]: {
    types: [
      TreeTypesActionTypes.TREE_TYPES_FETCH_REQUEST,
      TreeTypesActionTypes.TREE_TYPES_FETCH_SUCCESS,
      TreeTypesActionTypes.TREE_TYPES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/tree-type?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

export { fetchTreeTypes };
