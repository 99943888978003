import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as OrganisationBillingActionTypes from '../constants/actionTypes/organisationBilling';

/**
 * Fetch Organisation Billing Details
 * @returns Promise
 */
const fetchOrganisationBillingDetails = () => dispatch => {
  return dispatch({
    [RSAA]: {
      types: [
        OrganisationBillingActionTypes.ORGANISATION_BILLING_FETCH_REQUEST,
        OrganisationBillingActionTypes.ORGANISATION_BILLING_FETCH_SUCCESS,
        OrganisationBillingActionTypes.ORGANISATION_BILLING_FETCH_FAILURE,
      ],
      endpoint: config.apiEndpoint + '/api/billing/details',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  });
};

/**
 * Fetch Billing plans
 * @returns Promise
 */
const fetchBillingPlans = () => dispatch => {
  return dispatch({
    [RSAA]: {
      types: [
        OrganisationBillingActionTypes.BILLING_PLANS_FETCH_REQUEST,
        OrganisationBillingActionTypes.BILLING_PLANS_FETCH_SUCCESS,
        OrganisationBillingActionTypes.BILLING_PLANS_FETCH_FAILURE,
      ],
      endpoint: config.apiEndpoint + '/api/billing/plans',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  });
};

/**
 * Fetch Organisation Subscription
 * @returns Promise
 */

const fetchOrganisationSubscription = () => dispatch => {
  return dispatch({
    [RSAA]: {
      types: [
        OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_FETCH_REQUEST,
        OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_FETCH_SUCCESS,
        OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_FETCH_FAILURE,
      ],
      endpoint: config.apiEndpoint + '/api/billing/subscriptions' + '/saas',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  });
};

/**
 * Update Organisation Billing Details
 * @param data
 * @returns Promise
 */
const updateOrganisationBillingDetails = data => dispatch => {
  return dispatch({
    [RSAA]: {
      types: [
        OrganisationBillingActionTypes.ORGANISATION_BILLING_UPDATE_REQUEST,
        OrganisationBillingActionTypes.ORGANISATION_BILLING_UPDATE_SUCCESS,
        OrganisationBillingActionTypes.ORGANISATION_BILLING_UPDATE_FAILURE,
      ],
      endpoint: config.apiEndpoint + '/api/billing/details',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    },
  });
};

/**
 * Update Stripe Token
 * @param data
 * @returns Promise
 */
const updateStripeToken = token => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.STRIPE_TOKEN_UPDATE_REQUEST,
      OrganisationBillingActionTypes.STRIPE_TOKEN_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ OrganisationBillingActionTypes.STRIPE_TOKEN_UPDATE_FAILURE,
        /* entity */ 'organisations'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/subscription/credit-card',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ stripe_token: token }),
  },
});

/**
 * Cancel Organisation Subscription
 * @returns Promise
 */
const cancelOrganisationSubscription = subscriptionId => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CANCEL_REQUEST,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CANCEL_SUCCESS,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CANCEL_FAILURE,
    ],
    endpoint: `${config.apiEndpoint}/api/billing/subscriptions/${subscriptionId}/cancel`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Deactivate Organisation Subscription
 * @returns Promise
 */
const deactivateOrganisationSubscription = subscriptionId => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_DEACTIVATE_REQUEST,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_DEACTIVATE_SUCCESS,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_DEACTIVATE_FAILURE,
    ],
    endpoint: `${config.apiEndpoint}/api/billing/subscriptions/${subscriptionId}/deactivate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Reactivate Organisation Subscription
 * @returns Promise
 */
const reactivateOrganisationSubscription = subscriptionId => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_REACTIVATE_REQUEST,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_REACTIVATE_SUCCESS,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_REACTIVATE_FAILURE,
    ],
    endpoint: `${config.apiEndpoint}/api/billing/subscriptions/${subscriptionId}/reactivate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Adds a new promo code
 * @param subscriptionId
 * @param data
 * @returns Promise
 */
const addPromoCode = (subscriptionId, data) => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.ADD_PROMO_CODE_REQUEST,
      OrganisationBillingActionTypes.ADD_PROMO_CODE_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ OrganisationBillingActionTypes.ADD_PROMO_CODE_FAILURE
      ),
    ],
    endpoint: `${config.apiEndpoint}/api/billing/subscriptions/${subscriptionId}/coupon`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Adds a new promo code
 * @param subscriptionId
 * @param data
 * @returns Promise
 */
const changeSubscriptionPlan = (subscriptionId, data) => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_REQUEST,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_CHANGE_PLAN_FAILURE
      ),
    ],
    endpoint: `${config.apiEndpoint}/api/billing/subscriptions/${subscriptionId}/change-plan`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

const activateSubscriptionPlan = (id, data) => ({
  [RSAA]: {
    types: [
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_ACTIVATE_REQUEST,
      OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_ACTIVATE_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ OrganisationBillingActionTypes.ORGANISATION_SUBSCRIPTION_ACTIVATE_FAILURE
      ),
    ],
    endpoint: `${config.apiEndpoint}/api/organisation/${id}/subscribe`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

export {
  fetchOrganisationBillingDetails,
  fetchBillingPlans,
  fetchOrganisationSubscription,
  updateOrganisationBillingDetails,
  updateStripeToken,
  activateSubscriptionPlan,
  cancelOrganisationSubscription,
  deactivateOrganisationSubscription,
  reactivateOrganisationSubscription,
  addPromoCode,
  changeSubscriptionPlan,
};
