import { fromJS } from 'immutable';
import { normalize } from 'normalizr';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as EntitiesActionTypes from '../../constants/actionTypes/climatePartners';
import { climatePartnerListSchema } from '../../schemas';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Teams
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case EntitiesActionTypes.CLIMATE_PARTNERS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(data, climatePartnerListSchema);
      const normalizedClimatePartners = fromJS(
        normalizedFetch.entities.climatePartners
      );
      if (!normalizedClimatePartners) {
        return state;
      }

      return state.merge({
        byId: normalizedClimatePartners,
        result: fromJS(normalizedFetch.result),
      });
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
