export const SET_TOKEN = 'changers/admin/SET_TOKEN';
export const SET_TOKENS = 'changers/admin/SET_TOKENS';
export const UNSET_TOKENS = 'changers/admin/UNSET_TOKENS';

export const ORGANISATION_REGISTER_REQUEST =
  'changers/admin/ORGANISATION_REGISTER_REQUEST';
export const ORGANISATION_REGISTER_SUCCESS =
  'changers/admin/ORGANISATION_REGISTER_SUCCESS';
export const ORGANISATION_REGISTER_FAILURE =
  'changers/admin/ORGANISATION_REGISTER_FAILURE';

export const ORGANISATION_REGISTER_TOKEN_REQUEST =
  'changers/admin/ORGANISATION_REGISTER_TOKEN_REQUEST';
export const ORGANISATION_REGISTER_TOKEN_SUCCESS =
  'changers/admin/ORGANISATION_REGISTER_TOKEN_SUCCESS';
export const ORGANISATION_REGISTER_TOKEN_FAILURE =
  'changers/admin/ORGANISATION_REGISTER_FAILURE';

export const ORGANISATION_REGISTER_VERIFY_TOKEN_REQUEST =
  'changers/admin/ORGANISATION_REGISTER_VERIFY_TOKEN_REQUEST';
export const ORGANISATION_REGISTER_VERIFY_TOKEN_SUCCESS =
  'changers/admin/ORGANISATION_REGISTER_VERIFY_TOKEN_SUCCESS';
export const ORGANISATION_REGISTER_VERIFY_TOKEN_FAILURE =
  'changers/admin/ORGANISATION_REGISTER_VERIFY_TOKEN_FAILURE';

export const ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_REQUEST =
  'changers/admin/ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_REQUEST';
export const ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_SUCCESS =
  'changers/admin/ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_SUCCESS';
export const ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_FAILURE =
  'changers/admin/ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_FAILURE';

export const USER_REGISTER_REQUEST = 'changers/admin/USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'changers/admin/USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'changers/admin/USER_REGISTER_FAILURE';

export const USER_ORGANISATION_CONFIRM_REQUEST =
  'changers/admin/USER_ORGANISATION_CONFIRM_REQUEST';
export const USER_ORGANISATION_CONFIRM_SUCCESS =
  'changers/admin/USER_ORGANISATION_CONFIRM_SUCCESS';
export const USER_ORGANISATION_CONFIRM_FAILURE =
  'changers/admin/USER_ORGANISATION_CONFIRM_FAILURE';

export const USER_LOGIN_REQUEST = 'changers/admin/USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'changers/admin/USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'changers/admin/USER_LOGIN_FAILURE';

export const USER_LOGOUT_REQUEST = 'changers/admin/USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'changers/admin/USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'changers/admin/USER_LOGOUT_FAILURE';

export const USER_FORGOT_PASSWORD_REQUEST =
  'changers/admin/USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS =
  'changers/admin/USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAILURE =
  'changers/admin/USER_FORGOT_PASSWORD_FAILURE';

export const USER_RESET_PASSWORD_REQUEST =
  'changers/admin/USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS =
  'changers/admin/USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILURE =
  'changers/admin/USER_RESET_PASSWORD_FAILURE';

export const CHECK_RESET_PASSWORD_TOKEN_REQUEST =
  'changers/admin/CHECK_RESET_PASSWORD_TOKEN_REQUEST';
export const CHECK_RESET_PASSWORD_TOKEN_SUCCESS =
  'changers/admin/CHECK_RESET_PASSWORD_TOKEN_SUCCESS';
export const CHECK_RESET_PASSWORD_TOKEN_FAILURE =
  'changers/admin/CHECK_RESET_PASSWORD_TOKEN_FAILURE';

export const UPDATE_LOGIN_EMAIL = 'changers/admin/UPDATE_LOGIN_EMAIL';
