import { fromJS, List } from 'immutable';
import { normalize } from 'normalizr';
import { activitiesSchema, activitiesListSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as ActivitiesActionTypes from '../../constants/actionTypes/activities';

const initialState = fromJS({
  byId: {},
  customActivities: [],
  restrictedCustomActivities: [],
  result: [],
});

/**
 * Handles Activities
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {}, meta = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case ActivitiesActionTypes.CUSTOM_ACTIVITIES_FETCH_SUCCESS:
    case ActivitiesActionTypes.RESTRICTED_CUSTOM_ACTIVITIES_FETCH_SUCCESS: {
      let stateField = 'customActivities';
      if (
        type ===
        ActivitiesActionTypes.RESTRICTED_CUSTOM_ACTIVITIES_FETCH_SUCCESS
      ) {
        stateField = 'restrictedCustomActivities';
      }
      //Duplicated being sent from backend
      //Remove duplicates
      const cleanedItems = items.filter(
        (item, index, self) =>
          index === self.findIndex(searchedItem => searchedItem.id === item.id)
      );

      const normalizedFetch = normalize(cleanedItems, activitiesListSchema);
      const normalizedActivities = fromJS(normalizedFetch.entities.activities);
      if (!normalizedActivities) {
        return state;
      }

      return state
        .mergeIn(['byId'], normalizedActivities)
        .updateIn([stateField], val => List(normalizedFetch.result));
    }
    case ActivitiesActionTypes.ARCHIVED_CUSTOM_ACTIVITIES_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, activitiesListSchema);
      const normalizedActivities = fromJS(normalizedFetch.entities.activities);

      return state.updateIn(['archived'], val => fromJS(normalizedActivities));
    }
    case ActivitiesActionTypes.ADD_ACTIVITY_SUCCESS:
    case ActivitiesActionTypes.UPDATE_ACTIVITY_SUCCESS:
    case ActivitiesActionTypes.RESTRICTED_ADD_ACTIVITY_SUCCESS:
    case ActivitiesActionTypes.RESTRICTED_UPDATE_ACTIVITY_SUCCESS: {
      let stateField = 'customActivities';
      if (
        type === ActivitiesActionTypes.RESTRICTED_ADD_ACTIVITY_SUCCESS ||
        type === ActivitiesActionTypes.RESTRICTED_UPDATE_ACTIVITY_SUCCESS
      ) {
        stateField = 'restrictedCustomActivities';
      }
      const normalizedUpdate = normalize(data, activitiesSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.activities))
        .updateIn([stateField], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }

    case ActivitiesActionTypes.ACTIVATE_ACTIVITY_SUCCESS:
    case ActivitiesActionTypes.DEACTIVATE_ACTIVITY_SUCCESS: {
      const { activityId } = meta;

      const activityIndex = state
        .getIn(['byId'])
        .findKey(activity => activity.get('id') === activityId);

      if (activityIndex) {
        state = state.updateIn(['byId', activityIndex], activity =>
          activity.set(
            'enabled',
            type === ActivitiesActionTypes.ACTIVATE_ACTIVITY_SUCCESS
          )
        );
      }

      return state;
    }
    case ActivitiesActionTypes.SHOW_ACTIVITY_QR_CODE_SUCCESS: {
      const { activityId } = meta;

      const activityIndex = state
        .getIn(['byId'])
        .findKey(activity => activity.get('id') === activityId);

      if (activityIndex) {
        state = state.updateIn(['byId', activityIndex], activity =>
          activity.set('printable_qr_code', data.printable_qr_code)
        );
      }

      return state;
    }
    case ActivitiesActionTypes.UPDATE_ACTIVITY_ORDER_STATE: {
      data.forEach(activity => {
        const activityId = activity.get('id');
        const activityIndex = state
          .getIn(['byId'])
          .findKey(x => x.get('id') === activityId);
        state = state.updateIn(['byId', activityIndex], currentActivity => {
          return currentActivity.set('order', activity.get('order'));
        });
      });

      return state;
    }
    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
