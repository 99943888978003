import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as UserReferralActionTypes from '../constants/actionTypes/userReferral';

/**
 * @returns Promise
 */
const fetchUserReferralLink = () => ({
  [RSAA]: {
    types: [
      UserReferralActionTypes.FETCH_USER_REFERRAL_REQUEST,
      UserReferralActionTypes.FETCH_USER_REFERRAL_SUCCESS,
      UserReferralActionTypes.FETCH_USER_REFERRAL_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/user-referral/link',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * @param data
 * @returns Promise
 */
const sendUserReferralInvite = data => ({
  [RSAA]: {
    types: [
      UserReferralActionTypes.USER_REFERRAL_INVITE_REQUEST,
      UserReferralActionTypes.USER_REFERRAL_INVITE_SUCCESS,
      UserReferralActionTypes.USER_REFERRAL_INVITE_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/user-referral/invite',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * @param data
 * @returns Promise
 */
const fetchUserReferralInviteStats = () => ({
  [RSAA]: {
    types: [
      UserReferralActionTypes.FETCH_USER_REFERRAL_INVITE_STATS_REQUEST,
      UserReferralActionTypes.FETCH_USER_REFERRAL_INVITE_STATS_SUCCESS,
      UserReferralActionTypes.FETCH_USER_REFERRAL_INVITE_STATS_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/user-referral/invite-stats',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * @param data
 * @returns Promise
 */
const resendUserReferralInvite = id => ({
  [RSAA]: {
    types: [
      UserReferralActionTypes.USER_REFERRAL_RESEND_INVITE_REQUEST,
      UserReferralActionTypes.USER_REFERRAL_RESEND_INVITE_SUCCESS,
      UserReferralActionTypes.USER_REFERRAL_RESEND_INVITE_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/user-referral/resend-invite/' + id,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

export {
  sendUserReferralInvite,
  fetchUserReferralLink,
  fetchUserReferralInviteStats,
  resendUserReferralInvite,
};
