import NProgress from 'nprogress';
import { RSAA } from 'redux-api-middleware';

// stores both request, success and failure types,
// in order we can listen on API response and stop the NProgress
let listeners = [];

const skipActionTypes = [];
/**
 * Redux Middleware
 *
 * Adds Authorization header on API requests to OP
 */
export default store => next => action => {
  // if is an API request
  if (action && action[RSAA]) {
    // store both success and failure types,
    // in order we can listen on API response and stop the NProgress
    const callAPI = action[RSAA];
    if (callAPI.types && Array.isArray(callAPI.types)) {
      let requestType = callAPI.types[0];
      if (requestType && requestType.type) {
        requestType = requestType.type;
      }

      if (skipActionTypes.indexOf(requestType) > -1) {
        // no need for this middleware to take any action
        return next(action);
      }

      // if API call supports bailout, then check if call will execute
      const bailout = callAPI.bailout;
      if (
        (typeof bailout === 'boolean' && bailout) ||
        (typeof bailout === 'function' && bailout(store.getState()))
      ) {
        // no need for this middleware to take any action
        return next(action);
      }

      let successType = callAPI.types[1];
      if (successType && successType.type) {
        successType = successType.type;
      }

      let failureType = callAPI.types[2];
      if (failureType && failureType.type) {
        failureType = failureType.type;
      }

      // start loading bar, if not started already
      if (!NProgress.isStarted()) {
        NProgress.start();
      }

      // store types in an array
      listeners.push([requestType, successType, failureType]);

      // so the middleware doesn't get applied to every single action
      return next(action);
    }
  }

  // if listeners are not empty, then check if we have to stop NProgress
  if (listeners.length > 0 && action.type) {
    // check if current action.type is registered in listeners
    // ignore REQUEST types at index=0
    const found = listeners.filter(value => value.indexOf(action.type) > 0);
    if (found.length > 0) {
      // remove used listeners
      listeners = listeners.filter(value => value.indexOf(action.type) === -1);

      // complete loading bar, if this is last item
      if (listeners.length === 0) {
        NProgress.done();
      }
    }
  }

  // so the middleware doesn't get applied to every single action
  return next(action);
};
