import { defineMessages } from 'react-intl';

export default defineMessages({
  'button.add': {
    id: 'button.add',
    defaultMessage: 'Add',
    description: 'Global button',
  },
  'button.save': {
    id: 'button.save',
    defaultMessage: 'Save',
    description: 'Global button',
  },
  'button.delete': {
    id: 'button.delete',
    defaultMessage: 'Delete',
    description: 'Global Delete',
  },
  'button.continue': {
    id: 'button.continue',
    defaultMessage: 'Continue',
    description: 'Global button',
  },
  'button.edit': {
    id: 'button.edit',
    defaultMessage: 'Edit',
    description: 'Global button',
  },
  'button.yes': {
    id: 'button.yes',
    defaultMessage: 'Yes',
    description: 'Global button',
  },
  'button.ok': {
    id: 'button.ok',
    defaultMessage: 'Ok',
    description: 'Global button',
  },
  'button.cancel': {
    id: 'button.cancel',
    defaultMessage: 'Cancel',
    description: 'Global button',
  },
  'button.back': {
    id: 'button.back',
    defaultMessage: 'Back',
    description: 'Global button',
  },
  'button.skip': {
    id: 'button.skip',
    defaultMessage: 'Skip',
    description: 'Global button',
  },
  'button.retry': {
    id: 'button.retry',
    defaultMessage: 'Retry',
    description: 'Global button',
  },
  'button.renew': {
    id: 'button.renew',
    defaultMessage: 'Renew',
    description: 'Global button',
  },
  'button.search': {
    id: 'button.search',
    defaultMessage: 'Search',
    description: 'Global button',
  },
});
