import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import Alert from 'reactstrap/lib/Alert';
import { NotificationsSystem } from 'reapop/lib/components/NotificationsSystem';
import notificationsSystemTheme from 'reapop-theme-wybo';
import { addNotification } from 'reapop';
import { List, Map } from 'immutable';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import LogRocket from 'logrocket';

import CSSTransition from 'react-transition-group/CSSTransition';

import config from 'config';
import tracking from '../utils/tracking';
import {
  APIFetch,
  LocalizableFieldsDefaultLanguage,
  UIViews,
} from '../constants';

import Sidebar from '../components/Sidebar/Sidebar';
import Footer from '../components/Footer/Footer';
import AuthorizedRoute from '../components/AuthorizedRoute';
import LoadingCurtain from '../components/LoadingCurtain/LoadingCurtain';

import Error404 from '../components/404/Error404';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

import faviconStage from '../public/SST-admin-stage.png';
import faviconProd from '../public/SST-admin-prod.png';
import faviconDev from '../public/dev.png';
import styles from './App.scss';

import {
  AsyncAccount,
  AsyncAdministration,
  AsyncHomeOffice,
  AsyncArchives,
  AsyncAuthentication,
  AsyncChallenge,
  AsyncChallenges,
  AsyncCharities,
  AsyncCharityPartners,
  AsyncCommunication,
  AsyncCompensationPartners,
  AsyncCustomActivities,
  AsyncDownloadCenter,
  AsyncDynamicLinks,
  AsyncEditorials,
  AsyncFaq,
  AsyncGlobalDashboard,
  AsyncGoodBye,
  AsyncIframe,
  AsyncInfoText,
  AsyncOrganisationDashboard,
  AsyncPrivacyPolicy,
  AsyncRecognition,
  AsyncReferralSettings,
  AsyncReports,
  AsyncRewards,
  AsyncSubscription,
  AsyncSubscriptionCancelled,
  AsyncTeams,
  AsyncTermsOfService,
  AsyncCO2ContributionEditorial,
  AsyncCustomActivityEditorial,
  AsyncUserDashboard,
  AsyncUserMarketplace,
  AsyncUserReferralInvite,
  AsyncWelcome,
  AsyncOrganisationOnboardModal,
  AsyncTools,
} from '../components/Loadable';

import errorHandler from '../decorators/errorHandler';

import { organisationType, userType } from '../types';

// Selectors
import {
  getCurrentOrganisation,
  getOrganisationLogo,
  getPublicOrganisationData,
} from '../selectors/organisations';
import {
  getOrganisationSubscription,
  getOrganisationSubscriptionBillableId,
  getOrganisationSubscriptionBillingDate,
  getOrganisationSubscriptionData,
  getOrganisationSubscriptionPaymentMethodExists,
} from '../selectors/organisationSubscription';
import { getCurrentUser } from '../selectors/users';
import { getWhitelabel } from '../selectors/whitelabel';

import * as GlobalActions from '../actions';
import {
  fetchCurrentUser,
  fetchUserActivityStats,
  fetchUserCharityStats,
  fetchUsers,
  fetchUserStats,
} from '../actions/users';
import {
  fetchInfoStats,
  fetchOrganisationActivityStats,
  fetchOrganisationChallengesStats,
  fetchOrganisationCharityStats,
  fetchOrganisationEditorials,
  fetchOrganisationLogo,
  fetchOrganisations,
  fetchOrganisationsAdminDetails,
} from '../actions/organizations';
import { fetchOrganisationSubscription } from '../actions/organizationBilling';
import { fetchChallenges } from '../actions/challenges';
import { fetchCharities } from '../actions/charities';
import { fetchCurrentUserTeams, fetchTeams } from '../actions/teams';
import { fetchTeamTypes, fetchTeamTypesAllTeams } from '../actions/teamTypes';
import { fetchTreeTypes } from '../actions/treeTypes';
import { fetchTreePlantings } from '../actions/treePlantings';
import { fetchTreePlantingPartners } from '../actions/treePlantingPartners';
import { fetchDonations } from '../actions/donations';
import { fetchDonationProjects } from '../actions/donationProjects';
import {
  fetchArchivedCustomActivities,
  fetchCustomActivities,
  fetchRestrictedCustomActivities,
} from '../actions/activities';
import {
  fetchLotteries,
  fetchLotteriesArchive,
  fetchLotteriesTags,
} from '../actions/lotteries';
import {
  fetchVouchers,
  fetchVouchersArchive,
  fetchVouchersTags,
} from '../actions/vouchers';
import { fetchGlobalStats } from '../actions/globalStats';
import { userLogout } from '../actions/auth';
import * as UIActions from '../actions/ui';
import * as LocaleActions from '../actions/locale';

import globalTranslations from '../i18n';
import { FormattedMessage } from 'react-intl';

import {
  getCommonProps,
  isAdminHost,
  isGlobalHost,
  isLegacyManagedSubscriptions,
  shouldShowUpgrade,
} from '../utils';
import { FormError } from '../utils/errors';
import { getMessageString } from '../components/i18n/GetMessage';
import { daysBetween } from '../utils/date';
import { resetWhitelabel } from '../actions/whitelabel';
import WhitelabelContext from '../decorators/WhitelabelContext';
import { fetchCustomActivityCategories } from '../actions/activityCategories';
import { getOrderedActivityCategories } from '../selectors/activityCategories';
import PublicPage from './PublicPage/PublicPage';
import { fetchOrganisationEntities } from '../actions/organizationEntities';
import { fetchClimatePartners } from '../actions/climatePartners';
import { fetchReports } from '../actions/reports';
import { fetchUserReferralInviteStats } from '../actions/userReferral';
import { loadWLConfig } from '../utils/config';

// add support for material design icons for the notification system
notificationsSystemTheme.notification.className.icon += ' material-icons';
notificationsSystemTheme.notification.className.closeButton +=
  ' material-icons';

class App extends React.Component {
  static propTypes = {
    // injected by Redux
    isAuthenticated: PropTypes.bool.isRequired,
    // challenges: challengesType,
    logo: PropTypes.string,
    organisation: organisationType,
    user: userType,

    ui: PropTypes.object,
    errorMessage: PropTypes.string,
    intl: intlShape.isRequired,
    publicOrganisationData: PropTypes.object,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object,
    organisationSubscription: PropTypes.object,
    whitelabel: PropTypes.instanceOf(Map),
    customActivityCategories: PropTypes.instanceOf(List),
    isAllowedToAccess: PropTypes.bool,
    subscriptionBillingDate: PropTypes.string,
    organisationBillableId: PropTypes.number,
    paymentMethodExists: PropTypes.bool,

    // actions
    globalActions: PropTypes.object.isRequired,
    localeActions: PropTypes.object.isRequired,
    userLogoutAction: PropTypes.func.isRequired,
    uiActions: PropTypes.object.isRequired,
    notificationActions: PropTypes.object.isRequired,
    fetchCurrentSubsription: PropTypes.func.isRequired,
    fetchActivityStatsForOrgs: PropTypes.func.isRequired,
    fetchCharityStats: PropTypes.func.isRequired,
    fetchActivityStatsForUser: PropTypes.func.isRequired,
    fetchCharityStatsForUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.loadUserState = this.loadUserState.bind(this);
    this.fetch = this.fetch.bind(this);
    this.userLogout = this.userLogout.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.isPublicRoute = this.isPublicRoute.bind(this);

    this.state = {
      // waits for API calls to load
      // if user is authenticated
      stateLoaded: !props.isAuthenticated,
    };
  }

  async componentDidMount() {
    const {
      isAuthenticated,
      history,
      intl,
      localeActions,
      notificationActions,
      dispatch,
      fetchCurrentSubsription,
      publicOrganisationData,
    } = this.props;
    tracking.initializeTracking();
    // On component mount only fetch subscription if user authenticated
    if (isAuthenticated) {
      await fetchCurrentSubsription();
    }

    const { location: { pathname, search } = {} } = history;

    const {
      isExact: isLocaleRedirect,
      params: {
        urlLocale = LocalizableFieldsDefaultLanguage,
        restPathname,
      } = {},
    } =
      matchPath(pathname, {
        path: '/:urlLocale(en|de)/:restPathname?',
      }) || {};

    // handle localization in URL, this is used so as Google can index our DE site too
    if (isLocaleRedirect || restPathname) {
      // change the locale based on URL
      localeActions.changeLocale(urlLocale);

      // remove `urlLocale` from URL so as reacr-router can match the paths
      history.replace(`${restPathname || ''}${search}`);
    }

    const languageQuery = queryString.parse(search || '');
    const language = this.isPublicRoute()
      ? languageQuery.language || 'en'
      : false;
    // get org logo for public_urls
    dispatch(fetchOrganisationLogo(language)).then(action => {
      const { error, payload: { response: { _details } = {} } = {} } = action;

      // if not a valid public_url then redirect to global domain
      if (error && _details === 2026) {
        window.location.replace(
          window.location.protocol +
            '//' +
            config.adminDomain +
            '/?warning=invalid_public_url'
        );
      }
    });

    if (search) {
      const query = queryString.parse(search);

      if (query.warning) {
        notificationActions.notify({
          id: query.warning,
          message: getMessageString(
            intl,
            ...globalTranslations['warning.app.' + query.warning]
          ),
          status: 'warning',
          dismissAfter: 7000,
        });
      }
    }

    if (isAuthenticated) {
      return this.loadUserState();
    }
    return loadWLConfig({ publicOrganisationData, dispatch });
  }

  async componentDidUpdate(prevProps) {
    const { history: { location: { hash } = {} } = {} } = this.props;
    if (
      this.props.isAuthenticated !== prevProps.isAuthenticated &&
      this.props.isAuthenticated
    ) {
      await this.props.fetchCurrentSubsription();
      this.loadUserState();
    }

    if (hash !== '') {
      this.scrollTo(hash);
    }

    let userDetails;
    if (!this.props.isAuthenticated) {
      userDetails = {
        email: 'unregisterd_user',
        recordFrontendLogging: true,
        recordFrontendNetworkCalls: true,
        recordFrontendUI: true,
        associateFrontendLogsWithUser: true,
      };
      LogRocket.init('eoiwuc/changers', {
        network: {
          isEnabled: false,
        },
      });
      LogRocket.identify(userDetails.email, {});
    }
    if (this.props?.user && this.props?.user?.toJS()?.email !== '') {
      userDetails = {
        email: this.props.user.toJS().email,
        recordFrontendLogging: true,
        recordFrontendNetworkCalls: true,
        recordFrontendUI: true,
        associateFrontendLogsWithUser: true,
      };
      if (
        userDetails &&
        userDetails.recordFrontendNetworkCalls &&
        process.env.NODE_ENV !== 'development'
      ) {
        LogRocket.init('eoiwuc/changers', {
          network: {
            isEnabled: true,
          },
        });
      } else {
        LogRocket.init('eoiwuc/changers', {
          network: {
            isEnabled: false,
          },
        });
      }
      if (userDetails && userDetails.associateFrontendLogsWithUser) {
        LogRocket.identify(userDetails.email, {});
      }
    }
  }

  isPublicRoute() {
    const { location } = this.props;
    const publicPaths = ['/faq', '/privacy-policy', '/terms-of-service'];
    return publicPaths.includes(location.pathname.toLowerCase());
  }

  scrollTo(hash, smooth = true) {
    const { history } = this.props;

    // push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        window.requestAnimationFrame(() => {
          const elemPosY = element
            ? element.getBoundingClientRect().top // + window.pageYOffset // for window.scroll
            : 0;

          // sticky navbar height
          const fixedNavbarOffset = 100;
          window.scrollBy({
            top: elemPosY - fixedNavbarOffset,
            left: 0,
            behavior: smooth ? 'smooth' : 'auto',
          });

          const { location: { pathname, search } = {} } = history;

          // replace URL, removing hash in order code doesn't run again
          history.replace(`${pathname}${search}`);
        });
      }
    }, 0);
  }

  loadUserState() {
    const { uiActions } = this.props;

    // show loading curtain on initial page loading
    uiActions.showLoadingCurtain();

    // dashboard mostly utilizes organization and challenges,
    // so let's preload them
    return Promise.all([
      this.fetch(APIFetch.CURRENT_USER),
      this.fetch(APIFetch.CHALLENGES),
      this.fetch(APIFetch.ACTIVITY_CATEGORIES),
    ])
      .then(action => {
        this.setState({
          stateLoaded: true,
        });
        uiActions.hideLoadingCurtain();
        const { organisation, publicOrganisationData, dispatch } = this.props;
        loadWLConfig({ organisation, publicOrganisationData, dispatch });

        if (isAdminHost()) {
          const {
            user,
            intl,
            notificationActions,
            subscriptionBillingDate,
            organisationBillableId,
            paymentMethodExists,
          } = this.props;
          const now = new Date();
          const showUpgrade = shouldShowUpgrade({
            paymentMethodExists,
            user,
            subscriptionBillingDate,
            organisationBillableId,
          });

          const daysRange = daysBetween(now, subscriptionBillingDate);

          if (
            !isLegacyManagedSubscriptions(user) &&
            showUpgrade &&
            // show warning to user the last 2 days before trial expires
            daysRange <= 2
          ) {
            notificationActions.notify({
              message: getMessageString(
                intl,
                ...globalTranslations['warning.app.trialExpires']
              ),
              status: 'warning',
              dismissAfter: 7000,
            });
          }
          tracking.identify(user, organisation);
        }
      })
      .catch(action => {
        // let the Authentication container handle the loading of state
        this.setState({
          stateLoaded: true,
        });

        uiActions.hideLoadingCurtain();
      });
  }

  /**
   * Fetches collections from API
   *
   * @param entity  Entity to fetch
   * @param force   Forces fetch of entity even if already loaded in redux state
   * @param allowUI Allow UI changes (notifications) from within this action
   * @param locale  Force a specific locale, useful for Localizable fields
   * @returns {*}
   */
  fetch(entity, force = false, allowUI = true, locale) {
    const {
      globalActions,
      intl,
      fetchActivityStatsForOrgs,
      fetchCharityStats,
      fetchActivityStatsForUser,
      fetchCharityStatsForUser,
    } = this.props;

    let action;

    if (entity === APIFetch.CURRENT_USER) {
      action = fetchCurrentUser;
    } else if (entity === APIFetch.USERS) {
      action = fetchUsers;
    } else if (entity === APIFetch.USER_STATS) {
      action = fetchUserStats;
    } else if (entity === APIFetch.USER_ACTIVITY_STATS) {
      action = fetchActivityStatsForUser; // debugger;
    } else if (entity === APIFetch.ORGANISATIONS) {
      action = fetchOrganisations;
    } else if (entity === APIFetch.ORGANISATIONS_ADMIN_DETAILS) {
      action = fetchOrganisationsAdminDetails;
    } else if (entity === APIFetch.ORGANISATION_ACTIVITY_STATS) {
      action = fetchActivityStatsForOrgs; // debugger;
    } else if (entity === APIFetch.ORGANISATION_CHALLENGES_STATS) {
      action = fetchOrganisationChallengesStats; // debugger;
    } else if (entity === APIFetch.ORGANISATION_CHARITY_STATS) {
      action = fetchCharityStats; // debugger;
    } else if (entity === APIFetch.USER_CHARITY_STATS) {
      action = fetchCharityStatsForUser; // debugger;
    } else if (entity === APIFetch.ORGANISATION_INFO_STATS) {
      action = fetchInfoStats; // debugger;
    } else if (entity === APIFetch.GLOBAL_STATS) {
      action = fetchGlobalStats;
    } else if (entity === APIFetch.CHALLENGES) {
      action = fetchChallenges;
    } else if (entity === APIFetch.CHARITIES) {
      action = fetchCharities;
    } else if (entity === APIFetch.CURRENT_USER_TEAMS) {
      action = fetchCurrentUserTeams;
    } else if (entity === APIFetch.TEAM_TYPES) {
      action = fetchTeamTypes;
    } else if (entity === APIFetch.TEAM_TYPES_ALL_TEAMS) {
      action = fetchTeamTypesAllTeams;
    } else if (entity === APIFetch.TEAMS) {
      action = fetchTeams;
    } else if (entity === APIFetch.ORGANISATION_ENTITIES) {
      action = fetchOrganisationEntities;
    } else if (entity === APIFetch.TREE_PLANTINGS) {
      action = fetchTreePlantings;
    } else if (entity === APIFetch.TREE_TYPES) {
      action = fetchTreeTypes;
    } else if (entity === APIFetch.TREE_PLANTING_PARTNERS) {
      action = fetchTreePlantingPartners;
    } else if (entity === APIFetch.DONATIONS) {
      action = fetchDonations;
    } else if (entity === APIFetch.DONATION_PROJECTS) {
      action = fetchDonationProjects;
    } else if (entity === APIFetch.ACTIVITIES) {
      action = fetchCustomActivities;
    } else if (entity === APIFetch.RESTRICTED_ACTIVITIES) {
      action = fetchRestrictedCustomActivities;
    } else if (entity === APIFetch.ARCHIVED_ACTIVITIES) {
      action = fetchArchivedCustomActivities;
    } else if (entity === APIFetch.ACTIVITY_CATEGORIES) {
      action = fetchCustomActivityCategories;
    } else if (entity === APIFetch.LOTTERIES) {
      action = fetchLotteries;
    } else if (entity === APIFetch.LOTTERIES_ARCHIVED) {
      action = fetchLotteriesArchive;
    } else if (entity === APIFetch.VOUCHERS) {
      action = fetchVouchers;
    } else if (entity === APIFetch.LOTTERIES_TAGS) {
      action = fetchLotteriesTags;
    } else if (entity === APIFetch.VOUCHERS_TAGS) {
      action = fetchVouchersTags;
    } else if (entity === APIFetch.VOUCHERS_ARCHIVED) {
      action = fetchVouchersArchive;
    } else if (entity === APIFetch.CLIMATE_PARTNERS) {
      action = fetchClimatePartners;
    } else if (entity === APIFetch.EDITORIALS) {
      action = fetchOrganisationEditorials;
    } else if (entity === APIFetch.REPORTS) {
      action = fetchReports;
    } else if (entity === APIFetch.USER_INVITES) {
      action = fetchUserReferralInviteStats;
    }

    return globalActions.fetchEntity(
      action,
      entity,
      intl,
      force,
      allowUI,
      locale
    );
  }

  userLogout() {
    const { userLogoutAction, history, dispatch } = this.props;

    return userLogoutAction().then(action => {
      const { meta = {}, error } = action;
      tracking.reset();
      if (error) {
        // handle redux-form errors
        if (meta.name === 'FormError') {
          throw new FormError(meta.formErrors);
        }

        // all good, continue
      } else {
        if (isAdminHost()) {
          dispatch(resetWhitelabel());
        }
        // move back to home
        history.push('/auth');
      }
    });
  }

  render() {
    const {
      isAuthenticated,
      logo,
      publicOrganisationData,
      organisation,
      user = Map(),
      subscriptionData,
      organisationSubscription,
      ui,
      intl,
      errorMessage,
      globalActions,
      localeActions,
      notificationActions,
      whitelabel,
      customActivityCategories,
      history,
      location,
      isAllowedToAccess,
      subscriptionBillingDate,
      organisationBillableId,
      paymentMethodExists,
    } = this.props;

    const { stateLoaded } = this.state;
    const isSuperAdmin = user && user.get('is_superadmin', false);
    const userId = user && user.get('id');
    const showUpgrade = shouldShowUpgrade({
      paymentMethodExists,
      user,
      subscriptionBillingDate,
      organisationBillableId,
    });
    const canReactivateSubscription = !!organisationSubscription.get(
      'canceled_at'
    );

    const isSdkEnabled = organisation
      ? !!organisation.getIn(['settings', 'sdk', 'enable'])
      : false;
    const isRecognitionFeatureEnabled = organisation
      ? !!organisation.getIn(['settings', 'recognition_feature'])
      : false;

    const isWhiteLabel = organisation
      ? !!organisation.get('white_label')
      : false;

    const commonProps = {
      ...getCommonProps(this.props),
      fetch: this.fetch,
      isAuthenticated,
      customActivityCategories,
      isAllowedToAccess,
      isSuperAdmin,
      isSdkEnabled,
    };

    const extraWrapperClass = ui.getIn(
      [UIViews.COMMON, 'extraWrapperClass'],
      ''
    );

    const showLoadingCurtain = ui.hasIn([UIViews.LOADING_CURTAIN], {});
    const loadingCurtainTitle = ui.getIn([UIViews.LOADING_CURTAIN, 'title']);
    const loadingCurtainDescription = ui.getIn([
      UIViews.LOADING_CURTAIN,
      'description',
    ]);

    let favicon;
    switch (process.env.NODE_ENV) {
      case 'development':
        favicon = faviconDev;
        break;
      case 'production-develop':
        favicon = faviconDev;
        break;
      case 'production-staging':
        favicon = faviconStage;
        break;
      case 'production':
        favicon = faviconProd;
        break;
    }

    const isAuthenticatedAndHasUser = userId && isAuthenticated;

    if (this.isPublicRoute()) {
      return (
        <PublicPage
          publicOrganisationData={publicOrganisationData}
          location={location}
          favicon={favicon}
        />
      );
    }

    return (
      <WhitelabelContext.Provider value={whitelabel}>
        <div
          className={classnames(
            styles.appContainer,
            whitelabel.get('wrapperClassName')
          )}
        >
          <Helmet>
            <link
              rel="shortcut icon"
              type="image/png"
              href={whitelabel.getIn(['auth', 'icons', 'logo']) || favicon}
            />
          </Helmet>
          {isAdminHost() ? (
            <title>
              <FormattedMessage
                {...globalTranslations[
                  whitelabel.getIn(['translations', 'appTitle']) ||
                    'text.app.title'
                ]}
              />
            </title>
          ) : (
            <title>
              <FormattedMessage
                {...globalTranslations[
                  whitelabel.getIn(['translations', 'appTitle']) ||
                    'text.app.title.notAdmin'
                ]}
              />
            </title>
          )}
          {/* <Header
            isAllowedToAccess={isAllowedToAccess}
            isAuthenticated={isAuthenticated}
            isGlobal={organisation && organisation.get('is_global', false)}
            isSuperAdmin={isSuperAdmin}
            isLegacyManagedSubscriptions={
              user && isLegacyManagedSubscriptions(user)
            }
            isSdkEnabled={isSdkEnabled}
            isRecognitionFeatureEnabled={isRecognitionFeatureEnabled}
            isWhiteLabel={isWhiteLabel}
            hasSubscription={subscriptionData.size !== 0}
            logo={logo}
            activeLocale={intl.locale}
            logoutAction={this.userLogout}
            onLocaleChange={localeActions.changeLocale}
            whitelabel={whitelabel}
            customActivityCategories={customActivityCategories}
            intl={intl}
            showUpgrade={showUpgrade}
          /> */}
          {isAuthenticatedAndHasUser && (
            <Sidebar
              user={user}
              organisation={organisation}
              isAllowedToAccess={isAllowedToAccess}
              isAuthenticated={isAuthenticated}
              isGlobal={organisation && organisation.get('is_global', false)}
              isSuperAdmin={isSuperAdmin}
              isLegacyManagedSubscriptions={
                user && isLegacyManagedSubscriptions(user)
              }
              isSdkEnabled={isSdkEnabled}
              isRecognitionFeatureEnabled={isRecognitionFeatureEnabled}
              isWhiteLabel={isWhiteLabel}
              hasSubscription={subscriptionData.size !== 0}
              logo={logo}
              activeLocale={intl.locale}
              logoutAction={this.userLogout}
              onLocaleChange={localeActions.changeLocale}
              whitelabel={whitelabel}
              customActivityCategories={customActivityCategories}
              intl={intl}
              showUpgrade={showUpgrade}
              canReactivateSubscription={canReactivateSubscription}
              subscriptionBillingDate={subscriptionBillingDate}
              notify={notificationActions.notify}
            />
          )}
          <div
            className={classnames(styles.pageWrapperContainer, {
              [styles.pageWrapperContainerPt]: isAuthenticatedAndHasUser,
              [styles.pageWrapperContainerGuest]: !isAuthenticatedAndHasUser,
            })}
          >
            <div
              className={classnames({
                [`page-wrapper ${extraWrapperClass}`]: isAuthenticatedAndHasUser,
              })}
            >
              {errorMessage && (
                <Alert
                  color="warning"
                  className="container-fluid"
                  onDismiss={globalActions.resetErrorMessage}
                >
                  {errorMessage}
                </Alert>
              )}

              <ErrorBoundary
                key={userId}
                debug={config.DEBUG}
                domain={config.domain}
                history={history}
                gaid={config.GOOGLE_MANAGER_ID || 'GTM-00000-1'}
                user={userId}
              >
                <Switch>
                  {stateLoaded && isGlobalHost() && (
                    <Route
                      path="/"
                      exact
                      render={props => (
                        <AsyncGlobalDashboard {...props} {...commonProps} />
                      )}
                    />
                  )}

                  <Route
                    path="/links"
                    render={props => (
                      <AsyncDynamicLinks
                        {...props}
                        {...commonProps}
                        organisation={organisation}
                        whitelabel={whitelabel}
                      />
                    )}
                  />

                  {!isGlobalHost() && (
                    <React.Fragment>
                      <Route
                        path="/auth"
                        render={props => (
                          <AsyncAuthentication
                            {...props}
                            {...commonProps}
                            logo={logo}
                            organisation={organisation}
                            whitelabel={whitelabel}
                            activeLocale={intl.locale}
                            onLocaleChange={localeActions.changeLocale}
                          />
                        )}
                      />

                      {stateLoaded && [
                        // AuthorizedRoute in order we redirect to login page when user is signed out
                        <AuthorizedRoute
                          key="root"
                          path="/"
                          exact
                          component={AsyncOrganisationDashboard}
                          {...commonProps}
                          organisation={organisation}
                        />,
                        <Route
                          key="account"
                          path="/account"
                          render={props => (
                            <AsyncAccount {...props} {...commonProps} />
                          )}
                        />,
                        isAllowedToAccess ? (
                          <React.Fragment>
                            <AuthorizedRoute
                              key="subscription"
                              path="/billing"
                              showUpgrade={showUpgrade}
                              organisation={organisation}
                              component={AsyncSubscription}
                              {...commonProps}
                            />
                            <AuthorizedRoute
                              key="subscription"
                              path="/current-plan"
                              showUpgrade={showUpgrade}
                              organisation={organisation}
                              component={AsyncSubscription}
                              {...commonProps}
                            />
                          </React.Fragment>
                        ) : null,
                      ]}
                      {stateLoaded && /* !isAdminHost() && */ [
                        <AuthorizedRoute
                          key="UserDashboard"
                          path="/dashboards/user"
                          component={AsyncUserDashboard}
                          {...commonProps}
                          organisation={organisation}
                          user={user}
                        />,
                        isAllowedToAccess
                          ? [
                              <AuthorizedRoute
                                key="UserMarketplace"
                                path="/marketplace"
                                component={AsyncUserMarketplace}
                                {...commonProps}
                              />,
                            ]
                          : null,
                      ]}

                      {stateLoaded &&
                        isAdminHost() &&
                        isAllowedToAccess && [
                          <AuthorizedRoute
                            key="welcome"
                            path="/welcome"
                            component={AsyncWelcome}
                            {...commonProps}
                            user={user}
                          />,
                          !isWhiteLabel && (
                            <AuthorizedRoute
                              key="homeOffice"
                              path="/home-office"
                              component={AsyncHomeOffice}
                              {...commonProps}
                            />
                          ),
                          <AuthorizedRoute
                            key="challenge"
                            path="/settings"
                            component={AsyncChallenge}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="challenges"
                            path="/challenges"
                            component={AsyncChallenges}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="charities"
                            path="/charities"
                            component={AsyncCharities}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="charity-partners"
                            path="/charity-partners"
                            component={AsyncCharityPartners}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="teams"
                            path="/teams"
                            component={AsyncTeams}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="recognition"
                            path="/recognition"
                            component={AsyncRecognition}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="customActivities"
                            path="/custom-activities"
                            component={AsyncCustomActivities}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="compensationPartners"
                            path="/compensation-partners"
                            component={AsyncCompensationPartners}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="rewards"
                            path="/rewards"
                            component={AsyncRewards}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="communication"
                            path="/communication"
                            component={AsyncCommunication}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="tools"
                            path="/tools"
                            component={AsyncTools}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="user-invite"
                            path="/user-invite"
                            component={AsyncUserReferralInvite}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="widget"
                            path="/widget"
                            component={AsyncIframe}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="about-info-text"
                            path="/about-info-text"
                            component={AsyncInfoText}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="privacy-policy"
                            path="/privacy-policy/settings"
                            component={AsyncPrivacyPolicy}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="faq"
                            path="/faq/settings"
                            component={AsyncFaq}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="terms-of-service"
                            path="/terms-of-service/settings"
                            component={AsyncTermsOfService}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="co2-contribution"
                            path="/co2-contribution/settings"
                            component={AsyncCO2ContributionEditorial}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="custom-activity-editorial"
                            path="/custom-activity/settings"
                            component={AsyncCustomActivityEditorial}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="referral-settings"
                            path="/referral-settings"
                            component={AsyncReferralSettings}
                            {...commonProps}
                          />,
                          <AuthorizedRoute
                            key="archives"
                            path="/archives"
                            component={AsyncArchives}
                            {...commonProps}
                          />,
                        ]}

                      <AuthorizedRoute
                        key="download-center"
                        path="/download-center"
                        component={AsyncDownloadCenter}
                        {...commonProps}
                      />

                      <AuthorizedRoute
                        key="reports"
                        path="/reports"
                        component={AsyncReports}
                        {...commonProps}
                      />
                      <AuthorizedRoute
                        key="editorials"
                        path="/editorials"
                        component={AsyncEditorials}
                        {...commonProps}
                      />

                      {stateLoaded &&
                        isAdminHost() &&
                        isSuperAdmin &&
                        isAllowedToAccess && [
                          <AuthorizedRoute
                            key="administration"
                            path="/administration"
                            component={AsyncAdministration}
                            {...commonProps}
                            user={user}
                          />,
                        ]}

                      <Route path="/goodbye" component={AsyncGoodBye} />
                      <Route
                        path="/subscription/cancelled"
                        component={AsyncSubscriptionCancelled}
                      />
                    </React.Fragment>
                  )}

                  <Route component={Error404} />
                </Switch>
                {stateLoaded &&
                  isAuthenticatedAndHasUser &&
                  !whitelabel.get('disableOnboard') && (
                    <AsyncOrganisationOnboardModal />
                  )}
              </ErrorBoundary>
              {isAuthenticatedAndHasUser && (
                <Footer locale={intl.locale} whitelabel={whitelabel} />
              )}
            </div>
          </div>

          {!showLoadingCurtain ? null : (
            <CSSTransition
              classNames="fade-in-out"
              timeout={{ enter: 300, exit: 300 }}
            >
              <LoadingCurtain
                key="loading-curtain-fade"
                title={loadingCurtainTitle}
                description={loadingCurtainDescription}
                pastDelay={true}
              />
            </CSSTransition>
          )}

          <NotificationsSystem
            theme={notificationsSystemTheme}
            notifications={this.props.notifications}
            defaultValues={{ closeButton: true }}
          />
        </div>
      </WhitelabelContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  isAllowedToAccess: isAdminHost() || state.getIn(['auth', 'allow_access']),
  isAuthenticated: !!state.getIn(['auth', 'accessToken']),
  logo: getOrganisationLogo(state),
  notifications: state.get('notifications'),
  publicOrganisationData: getPublicOrganisationData(state),
  user: getCurrentUser(state),
  organisation: getCurrentOrganisation(state),
  errorMessage: state.getIn(['error', 'message']),
  ui: state.get('ui'),
  whitelabel: getWhitelabel(state),
  customActivityCategories: getOrderedActivityCategories(state),
  subscriptionData: getOrganisationSubscriptionData(state),
  subscriptionBillingDate: getOrganisationSubscriptionBillingDate(state),
  paymentMethodExists: getOrganisationSubscriptionPaymentMethodExists(state),
  organisationBillableId: getOrganisationSubscriptionBillableId(state),
  organisationSubscription: getOrganisationSubscription(state),
});

const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(GlobalActions, dispatch),
  localeActions: bindActionCreators(LocaleActions, dispatch),
  uiActions: bindActionCreators(UIActions, dispatch),
  userLogoutAction: () => dispatch(userLogout()),
  notificationActions: bindActionCreators(
    { notify: addNotification },
    dispatch
  ),
  fetchActivityStatsForOrgs: bindActionCreators(
    fetchOrganisationActivityStats,
    dispatch
  ),
  fetchCharityStats: bindActionCreators(
    fetchOrganisationCharityStats,
    dispatch
  ),
  fetchActivityStatsForUser: bindActionCreators(
    fetchUserActivityStats,
    dispatch
  ),
  fetchCharityStatsForUser: bindActionCreators(fetchUserCharityStats, dispatch),
  fetchCurrentSubsription: bindActionCreators(
    fetchOrganisationSubscription,
    dispatch
  ),
});

// Add error handling in App
App = errorHandler()(App);

// Connect Redux
App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

// Add Intl support for language switching
App = injectIntl(App);

// Handle https://reacttraining.com/react-router/core/guides/redux-integration/blocked-updates
export default withRouter(App);
