export const TREE_PLANTINGS_FETCH_REQUEST =
  'changers/admin/TREE_PLANTINGS_FETCH_REQUEST';
export const TREE_PLANTINGS_FETCH_SUCCESS =
  'changers/admin/TREE_PLANTINGS_FETCH_SUCCESS';
export const TREE_PLANTINGS_FETCH_FAILURE =
  'changers/admin/TREE_PLANTINGS_FETCH_FAILURE';

export const TREE_PLANTINGS_ADD_REQUEST =
  'changers/admin/TREE_PLANTINGS_ADD_REQUEST';
export const TREE_PLANTINGS_ADD_SUCCESS =
  'changers/admin/TREE_PLANTINGS_ADD_SUCCESS';
export const TREE_PLANTINGS_ADD_FAILURE =
  'changers/admin/TREE_PLANTINGS_ADD_FAILURE';

export const TREE_PLANTINGS_UPDATE_REQUEST =
  'changers/admin/TREE_PLANTINGS_UPDATE_REQUEST';
export const TREE_PLANTINGS_UPDATE_SUCCESS =
  'changers/admin/TREE_PLANTINGS_UPDATE_SUCCESS';
export const TREE_PLANTINGS_UPDATE_FAILURE =
  'changers/admin/TREE_PLANTINGS_UPDATE_FAILURE';

export const TREE_PLANTINGS_GOALS_FETCH_REQUEST =
  'changers/admin/TREE_PLANTINGS_GOALS_FETCH_REQUEST';
export const TREE_PLANTINGS_GOALS_FETCH_SUCCESS =
  'changers/admin/TREE_PLANTINGS_GOALS_FETCH_SUCCESS';
export const TREE_PLANTINGS_GOALS_FETCH_FAILURE =
  'changers/admin/TREE_PLANTINGS_GOALS_FETCH_FAILURE';

export const TREE_PLANTINGS_GOALS_UPDATE_REQUEST =
  'changers/admin/TREE_PLANTINGS_GOALS_UPDATE_REQUEST';
export const TREE_PLANTINGS_GOALS_UPDATE_SUCCESS =
  'changers/admin/TREE_PLANTINGS_GOALS_UPDATE_SUCCESS';
export const TREE_PLANTINGS_GOALS_UPDATE_FAILURE =
  'changers/admin/TREE_PLANTINGS_GOALS_UPDATE_FAILURE';
