import { fromJS } from 'immutable';

import * as UIActionTypes from '../constants/actionTypes/ui';

const initialState = fromJS({});

/**
 * Handles UI temporary state
 *
 * Each view can have its own namespace so as we can easier perform cleanup of the state
 *
 * When view/namespace is not provided a `common` namespace is used,
 * which is the default view for the ui actions
 */
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  let newState;

  switch (type) {
    case UIActionTypes.SET_UI_STATE:
      // if view/namespace is set then use it
      if (payload.view) {
        newState = state.mergeIn([payload.view], fromJS(payload.data));

        // fallback is to merge in root level
      } else {
        newState = state.merge(fromJS(payload.data));
      }

      return newState;

    case UIActionTypes.RESET_UI_STATE:
      // if view/namespace is set then only clear the namespace
      if (payload) {
        newState = state.delete(payload);

        // fallback is to clear everything
      } else {
        newState = state.clear();
      }

      return newState;

    default:
      return state;
  }
};

export default reducer;
