import { RSAA, getJSON, ApiError } from 'redux-api-middleware';
import config from 'config';

import { FormError, RetryDelayError } from '../utils/errors';

import * as ChallengesActionTypes from '../constants/actionTypes/challenges';

/**
 * Fetch Challenges
 * @returns Promise
 */
const fetchChallenges = () => ({
  [RSAA]: {
    types: [
      ChallengesActionTypes.CHALLENGES_FETCH_REQUEST,
      ChallengesActionTypes.CHALLENGES_FETCH_SUCCESS,
      ChallengesActionTypes.CHALLENGES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/challenge?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Adds a new Challenge
 * @param data
 * @returns Promise
 */
const addChallenge = data => ({
  [RSAA]: {
    types: [
      ChallengesActionTypes.CHALLENGES_ADD_REQUEST,
      ChallengesActionTypes.CHALLENGES_ADD_SUCCESS,
      failureAddChallenge(),
    ],
    endpoint: config.apiEndpoint + '/api/challenge',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

const failureAddChallenge = () => ({
  type: ChallengesActionTypes.CHALLENGES_ADD_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
    } else if (status === 403) {
      return new FormError('challenges.error.notAllowed.AddNew').json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

/**
 * Update a Challenge
 * @param data
 * @returns Promise
 */
const updateChallenge = (challengeId, data) => ({
  [RSAA]: {
    types: [
      ChallengesActionTypes.CHALLENGES_UPDATE_REQUEST,
      successUpdateChallenge(challengeId),
      failureUpdateChallenge(),
    ],
    endpoint: config.apiEndpoint + '/api/challenge/' + challengeId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Fetch Challenges
 * @returns Promise
 */
const deleteChallenge = challengeId => ({
  [RSAA]: {
    types: [
      ChallengesActionTypes.CHALLENGES_DELETE_REQUEST,
      ChallengesActionTypes.CHALLENGES_DELEETE_SUCCESS,
      ChallengesActionTypes.CHALLENGES_DELETE_FAILURE,
    ],
    endpoint: `${config.apiEndpoint}/api/challenge/${challengeId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

const successUpdateChallenge = challengeId => ({
  type: ChallengesActionTypes.CHALLENGES_UPDATE_SUCCESS,
  meta: (action, state, res) => ({ challengeId }),
});

const failureUpdateChallenge = () => ({
  type: ChallengesActionTypes.CHALLENGES_UPDATE_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403 / 404: Authentication failed
    } else if (status === 403) {
      return new FormError('challenges.error.notAllowed.modify').json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

/**
 * Auto new challenge
 * @param organisationId
 * @param data
 * @returns Promise
 */
const autoRenewChallenge = (organisationId, data) => ({
  [RSAA]: {
    types: [
      ChallengesActionTypes.CHALLENGES_AUTO_RENEW_REQUEST,
      ChallengesActionTypes.CHALLENGES_AUTO_RENEW_SUCCESS,
      ChallengesActionTypes.CHALLENGES_AUTO_RENEW_FAILURE,
    ],
    endpoint: `${config.apiEndpoint}/api/organisation/${organisationId}/auto-renew-challenge`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

export {
  fetchChallenges,
  addChallenge,
  updateChallenge,
  deleteChallenge,
  autoRenewChallenge,
};
