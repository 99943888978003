import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as CharitiesActionTypes from '../constants/actionTypes/charities';

/**
 * Fetch Charities
 * @returns Promise
 */
const fetchCharities = () => ({
  [RSAA]: {
    types: [
      CharitiesActionTypes.CHARITIES_FETCH_REQUEST,
      CharitiesActionTypes.CHARITIES_FETCH_SUCCESS,
      CharitiesActionTypes.CHARITIES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/charity?limit=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Adds a new Charity entry
 * @param data
 * @returns Promise
 */
const addCharityEntry = data => ({
  type: CharitiesActionTypes.CHARITIES_ADD_ENTRY,
  payload: { data },
});

/**
 * Update a Charity entry
 * @param data
 * @returns Promise
 */
const updateCharityEntry = data => ({
  type: CharitiesActionTypes.CHARITIES_UPDATE_ENTRY,
  payload: { data },
});

export { fetchCharities, addCharityEntry, updateCharityEntry };
