export const LOTTERIES_FETCH_REQUEST = 'changers/admin/LOTTERIES_FETCH_REQUEST';
export const LOTTERIES_FETCH_SUCCESS = 'changers/admin/LOTTERIES_FETCH_SUCCESS';
export const LOTTERIES_FETCH_FAILURE = 'changers/admin/LOTTERIES_FETCH_FAILURE';

export const LOTTERIES_ARCHIVE_FETCH_REQUEST =
  'changers/admin/LOTTERIES_ARCHIVE_FETCH_REQUEST';
export const LOTTERIES_ARCHIVE_FETCH_SUCCESS =
  'changers/admin/LOTTERIES_ARCHIVE_FETCH_SUCCESS';
export const LOTTERIES_ARCHIVE_FETCH_FAILURE =
  'changers/admin/LOTTERIES_ARCHIVE_FETCH_FAILURE';

export const ADD_LOTTERY_REQUEST = 'changers/admin/ADD_LOTTERY_REQUEST';
export const ADD_LOTTERY_SUCCESS = 'changers/admin/ADD_LOTTERY_SUCCESS';
export const ADD_LOTTERY_FAILURE = 'changers/admin/ADD_LOTTERY_FAILURE';

export const UPDATE_LOTTERY_REQUEST = 'changers/admin/UPDATE_LOTTERY_REQUEST';
export const UPDATE_LOTTERY_SUCCESS = 'changers/admin/UPDATE_LOTTERY_SUCCESS';
export const UPDATE_LOTTERY_FAILURE = 'changers/admin/UPDATE_LOTTERY_FAILURE';

export const ACTIVATE_LOTTERY_REQUEST =
  'changers/admin/ACTIVATE_LOTTERY_REQUEST';
export const ACTIVATE_LOTTERY_SUCCESS =
  'changers/admin/ACTIVATE_LOTTERY_SUCCESS';
export const ACTIVATE_LOTTERY_FAILURE =
  'changers/admin/ACTIVATE_LOTTERY_FAILURE';

export const DEACTIVATE_LOTTERY_REQUEST =
  'changers/admin/DEACTIVATE_LOTTERY_REQUEST';
export const DEACTIVATE_LOTTERY_SUCCESS =
  'changers/admin/DEACTIVATE_LOTTERY_SUCCESS';
export const DEACTIVATE_LOTTERY_FAILURE =
  'changers/admin/DEACTIVATE_LOTTERY_FAILURE';

export const LOTTERY_UPLOAD_IMAGE_REQUEST =
  'changers/admin/LOTTERY_UPLOAD_IMAGE_REQUEST';
export const LOTTERY_UPLOAD_IMAGE_SUCCESS =
  'changers/admin/LOTTERY_UPLOAD_IMAGE_SUCCESS';
export const LOTTERY_UPLOAD_IMAGE_FAILURE =
  'changers/admin/LOTTERY_UPLOAD_IMAGE_FAILURE';

export const PURCHASE_LOTTERY_REQUEST =
  'changers/admin/PURCHASE_LOTTERY_REQUEST';
export const PURCHASE_LOTTERY_SUCCESS =
  'changers/admin/PURCHASE_LOTTERY_SUCCESS';
export const PURCHASE_LOTTERY_FAILURE =
  'changers/admin/PURCHASE_LOTTERY_FAILURE';

export const LOTTERIES_TAGS_FETCH_REQUEST =
  'changers/admin/LOTTERIES_TAGS_FETCH_REQUEST';
export const LOTTERIES_TAGS_FETCH_SUCCESS =
  'changers/admin/LOTTERIES_TAGS_FETCH_SUCCESS';
export const LOTTERIES_TAGS_FETCH_FAILURE =
  'changers/admin/LOTTERIES_TAGS_FETCH_FAILURE';

export const ADD_LOTTERIES_TAG_REQUEST =
  'changers/admin/ADD_LOTTERIES_TAG_REQUEST';
export const ADD_LOTTERIES_TAG_SUCCESS =
  'changers/admin/ADD_LOTTERIES_TAG_SUCCESS';
export const ADD_LOTTERIES_TAG_FAILURE =
  'changers/admin/ADD_LOTTERIES_TAG_FAILURE';

export const ARCHIVE_LOTTERY_REQUEST = 'changers/admin/ARCHIVE_LOTTERY_REQUEST';
export const ARCHIVE_LOTTERY_SUCCESS = 'changers/admin/ARCHIVE_LOTTERY_SUCCESS';
export const ARCHIVE_LOTTERY_FAILURE = 'changers/admin/ARCHIVE_LOTTERY_FAILURE';

export const UNARCHIVE_LOTTERY_REQUEST =
  'changers/admin/UNARCHIVE_LOTTERY_REQUEST';
export const UNARCHIVE_LOTTERY_SUCCESS =
  'changers/admin/UNARCHIVE_LOTTERY_SUCCESS';
export const UNARCHIVE_LOTTERY_FAILURE =
  'changers/admin/UNARCHIVE_LOTTERY_FAILURE';
