const PREFIX = 'changers/admin/';

export const FETCH_ORGANISATIONS_LIST_REQUEST =
  PREFIX + 'FETCH_ORGANISATIONS_LIST_REQUEST';
export const FETCH_ORGANISATIONS_LIST_SUCCESS =
  PREFIX + 'FETCH_ORGANISATIONS_LIST_SUCCESS';
export const FETCH_ORGANISATIONS_LIST_FAILURE =
  PREFIX + 'FETCH_ORGANISATIONS_LIST_FAILURE';

export const ADD_ADMIN_TO_ORGANISATION_REQUEST =
  PREFIX + 'ADD_ADMIN_TO_ORGANISATION_REQUEST';
export const ADD_ADMIN_TO_ORGANISATION_SUCCESS =
  PREFIX + 'ADD_ADMIN_TO_ORGANISATION_SUCCESS';
export const ADD_ADMIN_TO_ORGANISATION_FAILURE =
  PREFIX + 'ADD_ADMIN_TO_ORGANISATION_FAILURE';

export const REMOVE_ADMIN_FROM_ORGANISATION_REQUEST =
  PREFIX + 'REMOVE_ADMIN_FROM_ORGANISATION_REQUEST';
export const REMOVE_ADMIN_FROM_ORGANISATION_SUCCESS =
  PREFIX + 'REMOVE_ADMIN_FROM_ORGANISATION_SUCCESS';
export const REMOVE_ADMIN_FROM_ORGANISATION_FAILURE =
  PREFIX + 'REMOVE_ADMIN_FROM_ORGANISATION_FAILURE';

export const DEACTIVATE_AUTOMATIC_BILLING_REQUEST =
  PREFIX + 'DEACTIVATE_AUTOMATIC_BILLING_REQUEST';
export const DEACTIVATE_AUTOMATIC_BILLING_SUCCESS =
  PREFIX + 'DEACTIVATE_AUTOMATIC_BILLING_SUCCESS';
export const DEACTIVATE_AUTOMATIC_BILLING_FAILURE =
  PREFIX + 'DEACTIVATE_AUTOMATIC_BILLING_FAILURE';

export const EXTEND_TRIAL_ACTION_REQUEST =
  PREFIX + 'EXTEND_TRIAL_ACTION_REQUEST';
export const EXTEND_TRIAL_ACTION_SUCCESS =
  PREFIX + 'EXTEND_TRIAL_ACTION_SUCCESS';
export const EXTEND_TRIAL_ACTION_FAILURE =
  PREFIX + 'EXTEND_TRIAL_ACTION_FAILURE';
