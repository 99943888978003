import { Map, List } from 'immutable';
import { LocalizableFieldsDefaultLanguage } from '../../constants';

/**
 * Process localized fields content arriving from the API
 * Used in normalizr schema.
 * Parses native arrays and not immutable Lists
 *
 * input:
 * [
 *   {
 *     "en": "Changers EN",
 *   },
 *   {
 *     "de": "Changers DE"
 *   }
 * ]
 *
 * output:
 * {
 *   "en": "Changers EN",
 *   "de": "Changers DE"
 * }
 *
 * @param entry
 */
const processLocalizedFieldsFromAPI = entry =>
  Array.isArray(entry)
    ? entry.reduce((cur, next) => {
        const [[key, val]] = Object.entries(next);
        cur[key] = val;
        return cur;
      }, {})
    : entry;

/**
 * Process redux-form localized fields content for the API
 * Used inside submit functions.
 * Parses Immutable Map and converts it to a List
 *
 * input:
 * Map({
 *   "en": "Changers EN",
 *   "de": "Changers DE"
 * })
 *
 * output:
 * List([
 *   Map({
 *     "code": "en",
 *     "value": "Changers EN"
 *   }),
 *   Map({
 *     "code": "de",
 *     "value": "Changers DE"
 *   })
 * ])
 *
 * @param entry
 */
const processLocalizedFieldsToAPI = (entry = List([]), locale) => {
  if (entry) {
    entry = entry.reduce(
      (list, value, key) =>
        list.push(
          Map({
            code: key,
            value: value,
          })
        ),
      List([])
    );

    // check if default language (en) is set
    if (
      entry.size > 0 &&
      !entry.find(val => val.get('code') === LocalizableFieldsDefaultLanguage)
    ) {
      let userEntry;
      if (locale) {
        // find user input based on the selected locale for whole admin,
        // fallback to user input for any language
        userEntry = entry.find(
          val => val.get('code') === locale,
          null,
          entry.first() // fallback
        );
      } else {
        // if no lang set yet for default language (de),
        // pick user input from any language and use it as default
        userEntry = entry.first();
      }

      if (userEntry && userEntry.has('value')) {
        return entry.push(
          Map({
            code: LocalizableFieldsDefaultLanguage,
            value: userEntry.get('value'),
          })
        );
      }
    }
  }

  return entry;
};

export { processLocalizedFieldsFromAPI, processLocalizedFieldsToAPI };
