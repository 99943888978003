export const ACTIVATE_HOME_OFFICE_REQUEST =
  'changers/admin/ACTIVATE_HOME_OFFICE_REQUEST';
export const ACTIVATE_HOME_OFFICE_SUCCESS =
  'changers/admin/ACTIVATE_HOME_OFFICE_SUCCESS';
export const ACTIVATE_HOME_OFFICE_FAILURE =
  'changers/admin/ACTIVATE_HOME_OFFICE_FAILURE';

export const DEACTIVATE_HOME_OFFICE_REQUEST =
  'changers/admin/  DEACTIVATE_HOME_OFFICE_REQUEST';
export const DEACTIVATE_HOME_OFFICE_SUCCESS =
  'changers/admin/  DEACTIVATE_HOME_OFFICE_SUCCESS';
export const DEACTIVATE_HOME_OFFICE_FAILURE =
  'changers/admin/  DEACTIVATE_HOME_OFFICE_FAILURE';

export const GET_HOME_OFFICE_STATS_REQUEST =
  'changers/admin/  GET_HOME_OFFICE_STATS_REQUEST';
export const GET_HOME_OFFICE_STATS_SUCCESS =
  'changers/admin/  GET_HOME_OFFICE_STATS_SUCCESS';
export const GET_HOME_OFFICE_STATS_FAILURE =
  'changers/admin/  GET_HOME_OFFICE_STATS_FAILURE';
