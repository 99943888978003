import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { lotteriesListSchema, lotteriesSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as LotteriesActionTypes from '../../constants/actionTypes/lotteries';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Lotteries
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {}, meta = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case LotteriesActionTypes.LOTTERIES_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, lotteriesListSchema);
      const normalizedlotteries = fromJS(normalizedFetch.entities.lotteries);

      if (!normalizedlotteries) {
        return state.merge(initialState);
      }

      return state.merge({
        byId: normalizedlotteries,
        result: fromJS(normalizedFetch.result),
      });
    }

    case LotteriesActionTypes.LOTTERIES_ARCHIVE_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, lotteriesListSchema);
      const normalizedlotteries = fromJS(normalizedFetch.entities.lotteries);

      return state.updateIn(['archived'], val => fromJS(normalizedlotteries));
    }

    case LotteriesActionTypes.ADD_LOTTERY_SUCCESS:
    case LotteriesActionTypes.UPDATE_LOTTERY_SUCCESS: {
      const normalizedUpdate = normalize(data, lotteriesSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.lotteries))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.unshift(data.id) : val
        );
    }

    case LotteriesActionTypes.ACTIVATE_LOTTERY_SUCCESS:
    case LotteriesActionTypes.DEACTIVATE_LOTTERY_SUCCESS: {
      const { lotteryId } = meta;

      const lotteryIndex = state
        .getIn(['byId'])
        .findKey(lottery => lottery.get('id') === lotteryId);

      if (lotteryIndex) {
        state = state.updateIn(['byId', lotteryIndex], lottery =>
          lottery.set(
            'enabled',
            type === LotteriesActionTypes.ACTIVATE_LOTTERY_SUCCESS
          )
        );
      }

      return state;
    }

    case LotteriesActionTypes.LOTTERY_UPLOAD_IMAGE_SUCCESS: {
      const normalizedUpdate = normalize(data, lotteriesSchema);
      return state.mergeIn(
        ['byId'],
        fromJS(normalizedUpdate.entities.lotteries)
      );
    }

    case LotteriesActionTypes.PURCHASE_LOTTERY_SUCCESS: {
      const { lotteryId } = meta;
      const { code } = data;

      const lotteryIndex = state
        .getIn(['byId'])
        .findKey(lottery => lottery.get('id') == lotteryId);

      if (code && lotteryIndex) {
        state = state.updateIn(['byId', lotteryIndex], lottery =>
          lottery.set('code', code)
        );
      }

      return state;
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
