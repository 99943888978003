import { fromJS } from 'immutable';
import { normalize } from 'normalizr';
import { donationsListSchema, donationSchema } from '../../schemas';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as DonationsActionTypes from '../../constants/actionTypes/donations';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Challenges
 */
export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = {} } = payload;
  const { items = [] } = data || {};

  switch (type) {
    case DonationsActionTypes.DONATIONS_FETCH_SUCCESS: {
      const normalizedFetch = normalize(items, donationsListSchema);
      const normalizedDonations = fromJS(normalizedFetch.entities.donations);

      if (!normalizedDonations) {
        return state;
      }

      return state.merge({
        byId: normalizedDonations,
        result: fromJS(normalizedFetch.result),
      });
    }

    case DonationsActionTypes.DONATIONS_ADD_SUCCESS:
    case DonationsActionTypes.DONATIONS_UPDATE_SUCCESS: {
      const normalizedUpdate = normalize(data, donationSchema);
      return state
        .mergeIn(['byId'], fromJS(normalizedUpdate.entities.donations))
        .updateIn(['result'], val =>
          !val.includes(data.id) ? val.push(data.id) : val
        );
    }
    case DonationsActionTypes.DONATIONS_GOALS_FETCH_SUCCESS: {
      const normalizedGoals = fromJS(data);
      if (normalizedGoals.size) {
        const donationId = normalizedGoals.first().get('donation_id');
        return state.setIn(['goals', donationId], normalizedGoals);
      }
      return state;
    }
    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
