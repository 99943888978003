import { RSAA } from 'redux-api-middleware';
import config from 'config';

import { basicFailureRSAAHandlerForUpdate } from '../utils/rsaa';

import * as TeamTypesActionTypes from '../constants/actionTypes/teamTypes';

/**
 * Fetch Teams
 * @returns Promise
 */
const fetchTeamTypes = () => ({
  [RSAA]: {
    types: [
      TeamTypesActionTypes.TEAM_TYPES_FETCH_REQUEST,
      TeamTypesActionTypes.TEAM_TYPES_FETCH_SUCCESS,
      TeamTypesActionTypes.TEAM_TYPES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/team-type?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Fetch Teams for all Team Types
 * @returns Promise
 */
const fetchTeamTypesAllTeams = () => ({
  [RSAA]: {
    types: [
      TeamTypesActionTypes.TEAM_TYPES_ALL_TEAMS_FETCH_REQUEST,
      TeamTypesActionTypes.TEAM_TYPES_ALL_TEAMS_FETCH_SUCCESS,
      TeamTypesActionTypes.TEAM_TYPES_ALL_TEAMS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/team-types/teams?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update Team Types
 * @param data
 * @returns Promise
 */
const updateTeamTypes = data => ({
  [RSAA]: {
    types: [
      TeamTypesActionTypes.TEAM_TYPES_UPDATE_REQUEST,
      TeamTypesActionTypes.TEAM_TYPES_UPDATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ TeamTypesActionTypes.TEAM_TYPES_UPDATE_FAILURE,
        /* entity */ 'teamTypes'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/team-types',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

export { fetchTeamTypes, fetchTeamTypesAllTeams, updateTeamTypes };
