import config from 'config';

let Root;

if (config.DEBUG) {
  Root = require('./Root.dev').default;
} else {
  Root = require('./Root.prod').default;
}

export default Root;
