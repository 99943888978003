import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForImageUpload,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as VouchersActionTypes from '../constants/actionTypes/vouchers';
import {
  USER_VOUCHER_TYPE_ID,
  USER_VOUCHERS_LIST_DEFAULT_PAGE,
  USER_VOUCHERS_LIST_PAGINATION_LIMIT,
  VOUCHERS_LIST_DEFAULT_PAGE,
  VOUCHERS_LIST_PAGINATION_LIMIT,
} from '../constants';

/**
 * Fetch Vouchers
 * @returns Promise
 */
const fetchVouchers = (
  locale = '',
  limit = VOUCHERS_LIST_PAGINATION_LIMIT,
  page = VOUCHERS_LIST_DEFAULT_PAGE
) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + `/api/offer?limit=${limit}&page=${page}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Enabled Vouchers
 * @returns Promise
 */
const fetchEnabledVouchers = (
  locale = '',
  limit = VOUCHERS_LIST_PAGINATION_LIMIT,
  page = VOUCHERS_LIST_DEFAULT_PAGE,
  isEnabled = ''
) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint +
      `/api/offer?enabled=${isEnabled}&limit=${limit}&page=${page}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Vouchers By Type
 * @returns Promise
 */
const fetchVouchersByType = (
  locale = '',
  limit = VOUCHERS_LIST_PAGINATION_LIMIT,
  page = VOUCHERS_LIST_DEFAULT_PAGE,
  offer_type_id = ''
) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint +
      `/api/offer?offer_type_id=${offer_type_id}&limit=${limit}&page=${page}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Vouchers by Secondary Filters
 * @returns Promise
 */
const fetchVouchersBySecondaryFilter = (
  locale = '',
  limit = VOUCHERS_LIST_PAGINATION_LIMIT,
  page = VOUCHERS_LIST_DEFAULT_PAGE,
  own = '',
  new_ = ''
) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint +
      `/api/offer?own=${own}&new=${new_}&limit=${limit}&page=${page}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Vouchers by Search
 * @returns Promise
 */
const fetchVouchersBySearch = (locale = '', searchParam = '') => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + `/api/offer?search=${searchParam}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch User Vouchers
 * @returns Promise
 */
const fetchUserVouchers = (
  locale = '',
  limit = USER_VOUCHERS_LIST_PAGINATION_LIMIT,
  page = USER_VOUCHERS_LIST_DEFAULT_PAGE,
  voucher_type_id = USER_VOUCHER_TYPE_ID
) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiCoinStageEndpoint +
      `/api/v1/offers?limit=${limit}&page=${page}&offer_type_id=${voucher_type_id}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Create Voucher Link
 * @returns Promise
 */
const fetchCreateVoucherLink = (locale = '') => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.CREATE_VOUCHER_LINK_FETCH_REQUEST,
      VouchersActionTypes.CREATE_VOUCHER_LINK_FETCH_SUCCESS,
      VouchersActionTypes.CREATE_VOUCHER_LINK_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/offer/create/link',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Vouchers tags
 * @returns Promise
 */
const fetchVouchersTags = (locale = '') => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_TAGS_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_TAGS_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_TAGS_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/tag/voucher?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Archived Vouchers
 * @returns Promise
 */
const fetchVouchersArchive = (locale = '') => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_ARCHIVE_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_ARCHIVE_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_ARCHIVE_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/voucher?per_page=500&archived=true',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Adds a new Voucher
 * @param data
 * @returns Promise
 */
const addVoucher = data => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.ADD_VOUCHER_REQUEST,
      VouchersActionTypes.ADD_VOUCHER_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ VouchersActionTypes.ADD_VOUCHER_FAILURE,
        /* entity */ 'rewards.vouchers'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/voucher',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Adds a new Vouchers tag
 * @param data
 * @returns Promise
 */
const addVouchersTag = data => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.ADD_VOUCHERS_TAG_REQUEST,
      VouchersActionTypes.ADD_VOUCHERS_TAG_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ VouchersActionTypes.ADD_VOUCHERS_TAG_FAILURE
      ),
    ],
    endpoint: config.apiEndpoint + '/api/tag/voucher',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a Voucher
 * @param data
 * @returns Promise
 */
const updateVoucher = (voucherId, data) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.UPDATE_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.UPDATE_VOUCHER_SUCCESS,
        meta: (action, state, res) => ({ voucherId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ VouchersActionTypes.UPDATE_VOUCHER_FAILURE,
        /* entity */ 'rewards.vouchers'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/voucher/' + voucherId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Activate an Voucher
 * @param voucherId
 * @returns Promise
 */
const activateVoucher = voucherId => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.ACTIVATE_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.ACTIVATE_VOUCHER_SUCCESS,
        meta: (action, state, res) => ({ voucherId }),
      },
      VouchersActionTypes.ACTIVATE_VOUCHER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/offer/' + voucherId + '/activate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Deactivates an Voucher
 * @param voucherId
 * @returns Promise
 */
const deactivateVoucher = voucherId => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.DEACTIVATE_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.DEACTIVATE_VOUCHER_SUCCESS,
        meta: (action, state, res) => ({ voucherId }),
      },
      VouchersActionTypes.DEACTIVATE_VOUCHER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/offer/' + voucherId + '/deactivate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Toggle voucher featured property
 * @param voucherId
 * @param featured
 * @returns Promise
 */
const toggleFeaturedVoucher = (voucherId, featured) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.TOGGLE_FEATURED_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.TOGGLE_FEATURED_VOUCHER_SUCCESS,
        meta: () => ({ voucherId, featured }),
      },
      VouchersActionTypes.TOGGLE_FEATURED_VOUCHER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/offer/' + voucherId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ featured }),
  },
});

/**
 * Upload voucher image
 * @param voucherId
 * @param file
 * @returns Promise
 */
const uploadVoucherImage = (voucherId, file) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_UPLOAD_IMAGE_REQUEST,
      VouchersActionTypes.VOUCHERS_UPLOAD_IMAGE_SUCCESS,
      basicFailureRSAAHandlerForImageUpload(
        /* type */ VouchersActionTypes.VOUCHERS_UPLOAD_IMAGE_FAILURE
      ),
    ],
    endpoint: config.apiEndpoint + '/api/voucher/' + voucherId + '/image',
    method: 'POST',
    body: file,
  },
});

/**
 * Purchase a Voucher
 * @param voucherId
 * @returns Promise
 */
const purchaseVoucher = (locale = '', voucherId) => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.PURCHASE_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.PURCHASE_VOUCHER_SUCCESS,
        meta: (action, state, res) => ({ voucherId }),
      },
      VouchersActionTypes.PURCHASE_VOUCHER_FAILURE,
    ],
    endpoint:
      config.apiCoinStageEndpoint + `/api/v1/offers/${voucherId}/purchase`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'Accept-Language': locale,
    },
  },
});

/**
 * Archive a Voucher
 * @param voucherId
 * @returns Promise
 */
const archiveVoucher = voucherId => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.ARCHIVE_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.ARCHIVE_VOUCHER_SUCCESS,
        meta: (action, state, res) => ({ voucherId }),
      },
      VouchersActionTypes.ARCHIVE_VOUCHER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/voucher/' + voucherId + '/archive',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Unarchive a Voucher
 * @param voucherId
 * @returns Promise
 */
const unarchiveVoucher = voucherId => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.UNARCHIVE_VOUCHER_REQUEST,
      {
        type: VouchersActionTypes.UNARCHIVE_VOUCHER_SUCCESS,
        meta: (action, state, res) => ({ voucherId }),
      },
      VouchersActionTypes.UNARCHIVE_VOUCHER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/voucher/' + voucherId + '/unarchive',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update Voucher order
 * @param voucherId
 * @returns Promise
 */
const updateVoucherOrder = data => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.UPDATE_VOUCHER_ORDER_REQUEST,
      {
        type: VouchersActionTypes.UPDATE_VOUCHER_ORDER_SUCCESS,
      },
      VouchersActionTypes.UPDATE_VOUCHER_ORDER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/voucher/order',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

const updateVoucherOrderState = data => ({
  type: VouchersActionTypes.UPDATE_VOUCHER_ORDER_STATE,
  payload: { data },
});

/**
 * Fetch
 * @returns Promise
 */
const fetchVouchersAreActive = (locale = '') => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + `/api/offer/settings`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Put
 * @returns Promise
 */
const toggleActivateVouchers = value => ({
  [RSAA]: {
    types: [
      VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_REQUEST,
      VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_SUCCESS,
      VouchersActionTypes.VOUCHERS_ARE_ACTIVE_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + `/api/offer/settings`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      auto_activate: value,
    }),
  },
});

export {
  fetchVouchers,
  fetchEnabledVouchers,
  fetchVouchersBySearch,
  fetchVouchersBySecondaryFilter,
  fetchVouchersByType,
  fetchUserVouchers,
  fetchCreateVoucherLink,
  fetchVouchersArchive,
  addVoucher,
  updateVoucher,
  activateVoucher,
  deactivateVoucher,
  uploadVoucherImage,
  purchaseVoucher,
  fetchVouchersTags,
  addVouchersTag,
  archiveVoucher,
  unarchiveVoucher,
  updateVoucherOrder,
  updateVoucherOrderState,
  toggleFeaturedVoucher,
  fetchVouchersAreActive,
  toggleActivateVouchers,
};
