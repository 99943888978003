// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import styles from './LoadingCurtain.scss';

/**
 * Renders a layer over the dashboard that blocks all user interactions with page
 */
const LoadingCurtain = props => {
  const { description, error, timedOut, pastDelay } = props;
  let { title } = props;

  if (error) {
    title = 'Error!';
  } else if (timedOut) {
    title = 'Taking a long time...';
  } else if (pastDelay) {
    // just pass
    // title = 'Loading...';
  } else {
    return null;
  }

  return (
    <div className={styles.blockingContainer}>
      <div className="container">
        <div className={styles.loader}>
          <div className={styles.loadingBar} />
          <div className={styles.loadingBar} />
          <div className={styles.loadingBar} />
          <div className={styles.loadingBar} />
        </div>

        {title && <h1 className={styles.title}>{title}</h1>}

        {description && <p className={styles.description}>{description}</p>}
      </div>
    </div>
  );
};

LoadingCurtain.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.bool,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
};

export default LoadingCurtain;
