import { fromJS } from 'immutable';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as HomeOfficeActionTypes from '../../constants/actionTypes/homeOffice';

const initialState = fromJS({
  stats: {
    organisation: {},
    user: {},
  },
});

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case HomeOfficeActionTypes.ACTIVATE_HOME_OFFICE_SUCCESS:
    case HomeOfficeActionTypes.DEACTIVATE_HOME_OFFICE_SUCCESS: {
      window.location.reload();
      return payload;
    }

    case HomeOfficeActionTypes.GET_HOME_OFFICE_STATS_SUCCESS: {
      return {
        ...state,
        stats: {
          organisation: payload?.organisation,
          user: payload?.user,
        },
      };
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
