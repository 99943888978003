import { fromJS, List } from 'immutable';
import { normalize } from 'normalizr';

// Types
import * as types from '../../constants/actionTypes/compensationPartners';

// Schema
import { compensationPartnersListSchema } from '../../schemas/compensationPartners';

const initialState = fromJS({
  result: [],
  loading: false,
  current_page: 1,
  total: 0,
  searchQuery: '',
  byId: {},
});

export default function reducer(state = initialState, action) {
  const { type, payload = {} } = action;
  const { data = [], current_page = 1, total = 0 } = payload;

  switch (type) {
    case types.FETCH_COMPENSATION_PARTNERS_LIST_REQUEST:
      return state
        .set('loading', true)
        .set('searchQuery', payload.searchQuery)
        .set('current_page', payload.page);
    case types.FETCH_COMPENSATION_PARTNERS_LIST_SUCCESS: {
      const normalizedFetch = normalize(data, compensationPartnersListSchema);
      const normalizedCompensationPartners = fromJS(
        normalizedFetch.entities.compensationPartners
      );
      if (!normalizedCompensationPartners) {
        return state
          .set('loading', false)
          .setIn(['byId'], {})
          .setIn(['result'], val => List())
          .set('current_page', current_page)
          .set('total', total);
      }
      return state
        .set('loading', false)
        .setIn(['byId'], normalizedCompensationPartners)
        .setIn(['result'], val => List(normalizedFetch.result))
        .set('current_page', current_page)
        .set('total', total);
    }
    case types.UPDATE_COMPENSATION_PARTNER_LIST_ORDER_STATUS:
      data.forEach(compensationPartner => {
        const compensationPartnerId = compensationPartner.get('id');
        const compensationPartnerIndex = state
          .getIn(['byId'])
          .findKey(x => x.get('id') === compensationPartnerId);
        state = state.updateIn(['byId', compensationPartnerIndex], current => {
          return current.set('order', compensationPartner.get('order'));
        });
      });

      return state;

    default:
      return state;
  }
}
