import { RSAA } from 'redux-api-middleware';

import * as WhitelabelActionTypes from '../constants/actionTypes/whitelabel';

/**
 * Fetch Vouchers
 * @returns Promise
 */
const fetchWhitelabel = prefix => {
  return {
    [RSAA]: {
      types: [
        WhitelabelActionTypes.WHITELABEL_FETCH_REQUEST,
        WhitelabelActionTypes.WHITELABEL_FETCH_SUCCESS,
        WhitelabelActionTypes.WHITELABEL_FETCH_FAILURE,
      ],
      endpoint: `//${window.location.hostname}/${prefix}.config.json`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  };
};

const resetWhitelabel = () => ({
  type: WhitelabelActionTypes.WHITELABEL_RESET,
});

export { fetchWhitelabel, resetWhitelabel };
