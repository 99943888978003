export const CURRENT_USER_FETCH_REQUEST =
  'changers/admin/CURRENT_USER_FETCH_REQUEST';
export const CURRENT_USER_FETCH_SUCCESS =
  'changers/admin/CURRENT_USER_FETCH_SUCCESS';
export const CURRENT_USER_FETCH_FAILURE =
  'changers/admin/CURRENT_USER_FETCH_FAILURE';

export const USERS_FETCH_REQUEST = 'changers/admin/USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'changers/admin/USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'changers/admin/USERS_FETCH_FAILURE';

export const UPDATE_USER_REQUEST = 'changers/admin/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'changers/admin/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'changers/admin/UPDATE_USER_FAILURE';

export const USER_STATS_FETCH_REQUEST =
  'changers/admin/USER_STATS_FETCH_REQUEST';
export const USER_STATS_FETCH_SUCCESS =
  'changers/admin/USER_STATS_FETCH_SUCCESS';
export const USER_STATS_FETCH_FAILURE =
  'changers/admin/USER_STATS_FETCH_FAILURE';

export const USER_ACTIVITY_STATS_FETCH_REQUEST =
  'changers/admin/USER_ACTIVITY_STATS_FETCH_REQUEST';
export const USER_ACTIVITY_STATS_FETCH_SUCCESS =
  'changers/admin/USER_ACTIVITY_STATS_FETCH_SUCCESS';
export const USER_ACTIVITY_STATS_FETCH_FAILURE =
  'changers/admin/USER_ACTIVITY_STATS_FETCH_FAILURE';

export const USER_CHANGE_PASSWORD_REQUEST =
  'changers/admin/USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS =
  'changers/admin/USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILURE =
  'changers/admin/USER_CHANGE_PASSWORD_FAILURE';

export const USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST =
  'changers/USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST';
export const USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST_SUCCESS =
  'changers/USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST_SUCCESS';
export const USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST_FAILURE =
  'changers/USER_VALIDATE_MARKETING_EMAIL_OPT_IN_TOKEN_REQUEST_FAILURE';

export const USER_CHARITY_STATS_FETCH_REQUEST =
  'changers/admin/USER_CHARITY_STATS_FETCH_REQUEST';
export const USER_CHARITY_STATS_FETCH_SUCCESS =
  'changers/admin/USER_CHARITY_STATS_FETCH_SUCCESS';
export const USER_CHARITY_STATS_FETCH_FAILURE =
  'changers/admin/USER_CHARITY_STATS_FETCH_FAILURE';
