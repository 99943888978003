// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// Selectors
import { getDefaultMessage } from '../../selectors/intl';
/**
 * Returns back a localized message
 *
 * `config.id` can be of the following forms
 *
 * `example.key`
 * `example.key|||param_key:param_value`
 * `example.key|||param1_key:param1_value,param2_key:param2_value`
 *
 * @param config  object  {id, defaultMessage, description}
 * @param values
 * @param intl
 * @return string
 */
const getMessageString = (intl, config = {}, values = {}) => {
  // accept an id with the String or a localised object
  let id = typeof config === 'string' ? config : config.id;

  if (!id) {
    throw new Error(`Please check that you pass a valid config ${config}`);
  }

  // console.log(id);
  let [key, params] = id.split('|||'); // eslint-disable-line prefer-const

  // update key if found
  if (key) {
    id = key;
  }

  // check if params passed in id
  if (params) {
    // console.log(params);
    // parse more than one params
    params = params.split(',');
    params = params.reduce((map, param) => {
      const [param_key, param_value] = param.split(':');
      map[param_key] = param_value;
      return map;
    }, {});

    // add parsed params from id, to the ones passed as props.values
    Object.assign(values, params);
  }
  return intl.formatMessage({ ...config, id }, values) || id.toUpperCase();
};

/**
 * Returns back a localized message component
 *
 * `config.id` can be of the following forms
 *
 * `example.key`
 * `example.key|||param_key:param_value`
 * `example.key|||param1_key:param1_value,param2_key:param2_value`
 *
 * @param id      string  equals to config.id
 * @param config  object  {id, defaultMessage, description}
 * @param values
 * @param intl
 * @constructor
 */

const GetMessage = ({
  intl,
  id,
  message,
  defaultMessage,
  config = {},
  values = {},
}) => {
  if (!('id' in config) && id) {
    config.id = id;
  }
  if (!('defaultMessage' in config) && defaultMessage) {
    config.defaultMessage = defaultMessage || message;
  }
  return getMessageString(intl, config, values);
};

const mapStateToProps = (state, ownProps) => ({
  message: getDefaultMessage(state, ownProps.id),
});

export { getMessageString };

export default injectIntl(
  connect(
    mapStateToProps,
    null
  )(GetMessage)
);
