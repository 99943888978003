import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as ActivitiesActionTypes from '../constants/actionTypes/activities';

import {
  basicFailureRSAAHandler,
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

/**
 * Fetch Custom Activities
 * @returns Promise
 */
const fetchCustomActivities = (locale = '') => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.CUSTOM_ACTIVITIES_FETCH_REQUEST,
      ActivitiesActionTypes.CUSTOM_ACTIVITIES_FETCH_SUCCESS,
      ActivitiesActionTypes.CUSTOM_ACTIVITIES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/custom-activity?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Restricted Custom Activities (only for admins)
 * @returns Promise
 */
const fetchRestrictedCustomActivities = (locale = '') => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.RESTRICTED_CUSTOM_ACTIVITIES_FETCH_REQUEST,
      ActivitiesActionTypes.RESTRICTED_CUSTOM_ACTIVITIES_FETCH_SUCCESS,
      ActivitiesActionTypes.RESTRICTED_CUSTOM_ACTIVITIES_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity-restricted?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Fetch Custom Activities
 * @returns Promise
 */
const fetchArchivedCustomActivities = (locale = '') => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.ARCHIVED_CUSTOM_ACTIVITIES_FETCH_REQUEST,
      ActivitiesActionTypes.ARCHIVED_CUSTOM_ACTIVITIES_FETCH_SUCCESS,
      ActivitiesActionTypes.ARCHIVED_CUSTOM_ACTIVITIES_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity?per_page=500&archived=true',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': locale,
    },
  },
});

/**
 * Adds a new Activity
 * @param data
 * @returns Promise
 */
const addActivity = data => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.ADD_ACTIVITY_REQUEST,
      ActivitiesActionTypes.ADD_ACTIVITY_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ ActivitiesActionTypes.ADD_ACTIVITY_FAILURE,
        /* entity */ 'activities'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/custom-activity',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Adds a new restricted Activity
 * @param data
 * @returns Promise
 */
const addRestrictedActivity = data => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.RESTRICTED_ADD_ACTIVITY_REQUEST,
      ActivitiesActionTypes.RESTRICTED_ADD_ACTIVITY_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ ActivitiesActionTypes.RESTRICTED_ADD_ACTIVITY_FAILURE,
        /* entity */ 'activities'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/custom-activity-restricted',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a Activity
 * @param data
 * @returns Promise
 */
const updateActivity = (activityId, data) => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.UPDATE_ACTIVITY_REQUEST,
      {
        type: ActivitiesActionTypes.UPDATE_ACTIVITY_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ ActivitiesActionTypes.UPDATE_ACTIVITY_FAILURE,
        /* entity */ 'activities'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/custom-activity/' + activityId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a restricted Activity
 * @param data
 * @returns Promise
 */
const updateRestrictedActivity = (activityId, data) => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.RESTRICTED_UPDATE_ACTIVITY_REQUEST,
      {
        type: ActivitiesActionTypes.RESTRICTED_UPDATE_ACTIVITY_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ ActivitiesActionTypes.RESTRICTED_UPDATE_ACTIVITY_FAILURE,
        /* entity */ 'activities'
      ),
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity-restricted/' + activityId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Activate an Activity
 * @param activityId
 * @returns Promise
 */
const activateActivity = activityId => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.ACTIVATE_ACTIVITY_REQUEST,
      {
        type: ActivitiesActionTypes.ACTIVATE_ACTIVITY_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      ActivitiesActionTypes.ACTIVATE_ACTIVITY_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity/' + activityId + '/activate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Deactivates an Activity
 * @param activityId
 * @returns Promise
 */
const deactivateActivity = activityId => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.DEACTIVATE_ACTIVITY_REQUEST,
      {
        type: ActivitiesActionTypes.DEACTIVATE_ACTIVITY_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      ActivitiesActionTypes.DEACTIVATE_ACTIVITY_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity/' + activityId + '/deactivate',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});
/**
 * Show printable QR code
 * @param activityId
 * @returns Promise
 */
const showActivityQrCode = activityId => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.SHOW_ACTIVITY_QR_CODE_REQUEST,
      {
        type: ActivitiesActionTypes.SHOW_ACTIVITY_QR_CODE_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      ActivitiesActionTypes.SHOW_ACTIVITY_QR_CODE_FAILURE,
    ],
    endpoint:
      config.apiEndpoint +
      '/api/custom-activity/' +
      activityId +
      '/show-qr-code',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});
/**
 * Track a new Activity
 * @param data
 * @returns Promise
 */
const trackNewActivity = data => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.TRACK_NEW_ACTIVITY_REQUEST,
      ActivitiesActionTypes.TRACK_NEW_ACTIVITY_SUCCESS,
      basicFailureRSAAHandler(
        /* type */ ActivitiesActionTypes.TRACK_NEW_ACTIVITY_FAILURE,
        /* entity */ 'activities'
      ),
    ],
    endpoint: config.apiTrackingEndpoint + '/api/activity/create',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Archive an Activity
 * @param activityId
 * @returns Promise
 */
const archiveActivity = activityId => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.ARCHIVE_ACTIVITY_REQUEST,
      {
        type: ActivitiesActionTypes.ARCHIVE_ACTIVITY_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      ActivitiesActionTypes.ARCHIVE_ACTIVITY_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity/' + activityId + '/archive',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Unrchive an Activity
 * @param activityId
 * @returns Promise
 */
const unarchiveActivity = activityId => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.UNARCHIVE_ACTIVITY_REQUEST,
      {
        type: ActivitiesActionTypes.UNARCHIVE_ACTIVITY_SUCCESS,
        meta: (action, state, res) => ({ activityId }),
      },
      ActivitiesActionTypes.UNARCHIVE_ACTIVITY_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/custom-activity/' + activityId + '/unarchive',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update Voucher order
 * @param voucherId
 * @returns Promise
 */
const updateActivityOrder = data => ({
  [RSAA]: {
    types: [
      ActivitiesActionTypes.UPDATE_ACTIVITY_ORDER_REQUEST,
      {
        type: ActivitiesActionTypes.UPDATE_ACTIVITY_ORDER_SUCCESS,
      },
      ActivitiesActionTypes.UPDATE_ACTIVITY_ORDER_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/custom-activity/order',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

const updateActivityOrderState = data => ({
  type: ActivitiesActionTypes.UPDATE_ACTIVITY_ORDER_STATE,
  payload: { data },
});

export {
  fetchCustomActivities,
  fetchRestrictedCustomActivities,
  fetchArchivedCustomActivities,
  addActivity,
  addRestrictedActivity,
  updateActivity,
  updateRestrictedActivity,
  activateActivity,
  deactivateActivity,
  trackNewActivity,
  showActivityQrCode,
  archiveActivity,
  unarchiveActivity,
  updateActivityOrder,
  updateActivityOrderState,
};
