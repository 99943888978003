import React from 'react';
import ReactGA from 'react-ga';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  static propTypes = {
    debug: PropTypes.bool,
    gaid: PropTypes.string,
    domain: PropTypes.string,
    user: PropTypes.number,
    history: PropTypes.object,
    children: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
      isStackOpen: this.props.debug,
    };
  }

  componentDidMount() {
    // console.log("%c errorboundary", "font-size:2em;", this.props);

    // setting multiple trackers with same id to track admin
    ReactGA.initialize(
      [
        {
          trackingId: this.props.gaid,
          gaOptions: {
            name: this.props.domain, // make tracker domain based to diff sources
          },
        },
      ],
      {
        debug: this.props.debug,
        alwaysSendToDefaultTracker: false,
        titleCase: false,
      }
    );

    if (this.props.user) {
      ReactGA.set({ userId: this.props.user }, [this.props.domain]);
    }

    this.unlisten = this.props.history.listen(location => {
      ReactGA.set({ page: location.pathname }, [this.props.domain]); // Update the user's current page
      ReactGA.pageview(location.pathname);
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // Analitics exception tracking
    ReactGA.exception({
      description: error ? error.toString() : 'An error ocurred',
      fatal: true,
    });
  }

  handleStackTraceToggle = () => {
    this.setState({
      isStackOpen: !this.state.isStackOpen,
    });
  };

  render() {
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      const header = (
        <header key={0} className="header">
          <h1>Something went wrong.</h1>
          <p>Please contact our support.</p>
        </header>
      );

      const content = (
        // __DEV__ && config.DEBUG ? (
        <div key={1} className="box justify-content-center">
          <p
            className="text-danger"
            role="button"
            onClick={() => this.handleStackTraceToggle()}
          >
            <u>{error && error.toString()}</u>
          </p>

          <Collapse isOpen={this.state.isStackOpen}>
            <div className="bg-light">
              <pre>
                <code>{errorInfo.componentStack}</code>
              </pre>
            </div>
          </Collapse>
        </div>
      );
      // ) : null;

      return [header, content];
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
