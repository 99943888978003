export const DYNAMIC_LINK_CONFIG_FETCH_REQUEST =
  'changers/admin/DYNAMIC_LINK_CONFIG_FETCH_REQUEST';
export const DYNAMIC_LINK_CONFIG_FETCH_SUCCESS =
  'changers/admin/DYNAMIC_LINK_CONFIG_FETCH_SUCCESS';
export const DYNAMIC_LINK_CONFIG_FETCH_FAILURE =
  'changers/admin/DYNAMIC_LINK_CONFIG_FETCH_FAILURE';

export const DYNAMIC_LINK_CONFIG_UPDATE_REQUEST =
  'changers/admin/DYNAMIC_LINK_CONFIG_UPDATE_REQUEST';
export const DYNAMIC_LINK_CONFIG_UPDATE_SUCCESS =
  'changers/admin/DYNAMIC_LINK_CONFIG_UPDATE_SUCCESS';
export const DYNAMIC_LINK_CONFIG_UPDATE_FAILURE =
  'changers/admin/DYNAMIC_LINK_CONFIG_UPDATE_FAILURE';
