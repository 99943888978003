import { fromJS, List, Map } from 'immutable';
import { schema } from 'normalizr';
import { processLocalizedFieldsFromAPI } from './components/LocalizedInput/utils';
import { CharitiesTypes } from './constants/charities';

const organisationSchema = new schema.Entity(
  'organisations',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const {
        display_name,
        description,
        privacy_policy,
        faq,
        terms_of_service,
        co2_contribution_info_text,
        custom_activity_info_text,
        partner_trees,
        trees,
        referral_settings,
        ...rest
      } = entity;
      let formattedPartnerTrees = Map();
      let no_partner_trees = trees;
      if (partner_trees) {
        partner_trees.forEach(partnerTree => {
          formattedPartnerTrees = formattedPartnerTrees.set(
            partnerTree.partner,
            fromJS(partnerTree)
          );
          no_partner_trees -= partnerTree.trees;
        });
      }

      return {
        ...rest,
        display_name: processLocalizedFieldsFromAPI(display_name),
        description: processLocalizedFieldsFromAPI(description),
        faq: processLocalizedFieldsFromAPI(faq),
        privacy_policy: processLocalizedFieldsFromAPI(privacy_policy),
        terms_of_service: processLocalizedFieldsFromAPI(terms_of_service),
        co2_contribution_info_text: processLocalizedFieldsFromAPI(
          co2_contribution_info_text
        ),
        custom_activity_info_text: processLocalizedFieldsFromAPI(
          custom_activity_info_text
        ),
        referral_settings: referral_settings
          ? {
              ...referral_settings,
              share_text: processLocalizedFieldsFromAPI(
                referral_settings.share_text
              ),
              share_email: processLocalizedFieldsFromAPI(
                referral_settings.share_email
              ),
              share_email_subject: processLocalizedFieldsFromAPI(
                referral_settings.share_email_subject
              ),
            }
          : null,
        partner_trees: formattedPartnerTrees,
        trees,
        no_partner_trees,
      };
    },
  }
);
const organisationsListSchema = [organisationSchema];

const userSchema = new schema.Entity('users', {
  organisation: organisationSchema,
});
const usersListSchema = [userSchema];

const treePlantingSchema = new schema.Entity('treePlantings');
const treePlantingsListSchema = [treePlantingSchema];

const treeTypesSchema = new schema.Entity('treeTypes');
const treeTypesListSchema = [treeTypesSchema];

const treePlantingPartnerSchema = new schema.Entity(
  'treePlantingPartners',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const { name, description, short_description, ...rest } = entity;
      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
        description: processLocalizedFieldsFromAPI(description),
        short_description: processLocalizedFieldsFromAPI(short_description),
      };
    },
  }
);
const treePlantingPartnersListSchema = [treePlantingPartnerSchema];

const donationSchema = new schema.Entity('donations');
const donationsListSchema = [donationSchema];

const donationProjectSchema = new schema.Entity(
  'donationProjects',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const { name, description, ...rest } = entity;
      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
        description: processLocalizedFieldsFromAPI(description),
      };
    },
  }
);
const donationProjectsListSchema = [donationProjectSchema];

const challengeSchema = new schema.Entity(
  'challenges',
  {
    organisation: organisationSchema,
    donation: donationSchema,
    treePlanting: treePlantingSchema,
  },
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const { name, ...rest } = entity;

      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
      };
    },
  }
);

const challengesListSchema = [challengeSchema];

const charitySchema = new schema.Entity(
  'charities',
  {
    organisation: organisationSchema,
  },
  {
    idAttribute: entity => `${entity.id}_${entity.type}`,
    // pre-process object before being normalized
    processStrategy: entity => {
      const { type, id } = entity;
      const key = `${id}_${type}`;
      if (type === CharitiesTypes.TREE_PLANTING) {
        const { tree_planting_partner, ...rest } = entity;
        const {
          name,
          description,
          default: treePlantingDefault,
          ...restOfTreePlantingPartner
        } = tree_planting_partner;
        let treePlantingDefaultData = {};
        if (treePlantingDefault) {
          const {
            name: defaultName,
            description: defaultDescription,
            short_description: defaultShortDescription,
            ...restOfDefaultPartner
          } = treePlantingDefault;
          treePlantingDefaultData = {
            ...restOfDefaultPartner,
            name: processLocalizedFieldsFromAPI(defaultName),
            description: processLocalizedFieldsFromAPI(defaultDescription),
            short_description: processLocalizedFieldsFromAPI(
              defaultShortDescription
            ),
          };
        }
        return {
          ...rest,
          key,
          tree_planting_partner: {
            ...restOfTreePlantingPartner,
            name: processLocalizedFieldsFromAPI(name),
            description: processLocalizedFieldsFromAPI(description),
            default: treePlantingDefaultData,
          },
        };
      } else if (type == CharitiesTypes.DONATION) {
        const { donation_project, ...rest } = entity;

        const {
          name,
          description,
          default: donationProjectDefault,
          ...restOfDonationProject
        } = donation_project;
        let donationProjectDefaultData = {};
        if (donationProjectDefault) {
          const {
            name: defaultName,
            description: defaultDescription,
            ...restOfDefaultProject
          } = donationProjectDefault;
          donationProjectDefaultData = {
            ...restOfDefaultProject,
            name: processLocalizedFieldsFromAPI(defaultName),
            description: processLocalizedFieldsFromAPI(defaultDescription),
          };
        }
        const data = {
          ...rest,
          key,
          donation_project: {
            ...restOfDonationProject,
            name: processLocalizedFieldsFromAPI(name),
            description: processLocalizedFieldsFromAPI(description),
            default: donationProjectDefaultData,
          },
        };
        return data;
      }
      return {
        ...entity,
        key,
      };
    },
  }
);

const charitiesListSchema = [charitySchema];

const organisationEntitiesSchema = new schema.Entity('organisationEntities');
const organisationEntitiesListSchema = [organisationEntitiesSchema];

const teamSchema = new schema.Entity('teams');
const teamsListSchema = [teamSchema];

const teamTypeSchema = new schema.Entity('teamTypes', {
  teams: teamsListSchema, // optional for certain endpoints
});
const teamTypesListSchema = [teamTypeSchema];

const activitiesSchema = new schema.Entity(
  'activities',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const { name, editorial, habit_builder, ...rest } = entity;
      let processedHabitBuilder = null;
      if (habit_builder) {
        processedHabitBuilder = {
          ...habit_builder,
          reminder_title: processLocalizedFieldsFromAPI(
            habit_builder.reminder_title
          ),
          reminder_body: processLocalizedFieldsFromAPI(
            habit_builder.reminder_body
          ),
        };
      }

      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
        editorial: processLocalizedFieldsFromAPI(editorial),
        habit_builder: processedHabitBuilder,
      };
    },
  }
);
const activitiesListSchema = [activitiesSchema];

const activityCategoriesSchema = new schema.Entity(
  'activityCategories',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const {
        name,
        description,
        featured_group_name,
        plain_group_name,
        featured_group_description,
        plain_group_description,
        ...rest
      } = entity;
      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
        description: processLocalizedFieldsFromAPI(description),
        featured_group_name: processLocalizedFieldsFromAPI(featured_group_name),
        plain_group_name: processLocalizedFieldsFromAPI(plain_group_name),
        featured_group_description: processLocalizedFieldsFromAPI(
          featured_group_description
        ),
        plain_group_description: processLocalizedFieldsFromAPI(
          plain_group_description
        ),
      };
    },
  }
);
const activityCategoriesListSchema = [activityCategoriesSchema];
const lotteriesSchema = new schema.Entity(
  'lotteries',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const {
        title,
        description,
        how_to,
        terms,
        prizes = List(),
        ...rest
      } = entity;

      return {
        ...rest,
        title: processLocalizedFieldsFromAPI(title),
        description: processLocalizedFieldsFromAPI(description),
        how_to: processLocalizedFieldsFromAPI(how_to),
        terms: processLocalizedFieldsFromAPI(terms),
        prizes: prizes.map(prize => ({
          ...prize,
          prize_name: processLocalizedFieldsFromAPI(prize.prize_name),
        })),
      };
    },
  }
);
const lotteriesListSchema = [lotteriesSchema];

const vouchersSchema = new schema.Entity(
  'vouchers',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const { title, description, how_to, terms, ...rest } = entity;

      return {
        ...rest,
        title: processLocalizedFieldsFromAPI(title),
        description: processLocalizedFieldsFromAPI(description),
        how_to: processLocalizedFieldsFromAPI(how_to),
        terms: processLocalizedFieldsFromAPI(terms),
      };
    },
  }
);
const vouchersListSchema = [vouchersSchema];

const dynamicLinkConfigSchema = new schema.Entity(
  'dynamicLinkConfig',
  {},
  {
    idAttribute: 'type',
    // pre-process object before being normalized
    processStrategy: entity => {
      const { social_title, social_description, ...rest } = entity;

      return {
        ...rest,
        social_title: processLocalizedFieldsFromAPI(social_title),
        social_description: processLocalizedFieldsFromAPI(social_description),
      };
    },
  }
);

const editorialSchema = new schema.Entity(
  'editorial',
  {},
  {
    // pre-process object before being normalized
    processStrategy: entity => {
      const { content, ...rest } = entity;
      return {
        ...rest,
        content: processLocalizedFieldsFromAPI(content),
      };
    },
  }
);

const editorialsListSchema = [editorialSchema];

const climatePartnerSchema = new schema.Entity(
  'climatePartners',
  {},
  {
    processStrategy: entity => {
      const { name, ...rest } = entity;

      return {
        ...rest,
        name: processLocalizedFieldsFromAPI(name),
      };
    },
  }
);

const climatePartnerListSchema = [climatePartnerSchema];

const reportSchema = new schema.Entity('reports', {});

const reportListSchema = [reportSchema];

const userInviteSchema = new schema.Entity('userReferrals');
const userInviteListSchema = [userInviteSchema];

export {
  organisationSchema,
  organisationsListSchema,
  userSchema,
  usersListSchema,
  challengeSchema,
  challengesListSchema,
  donationSchema,
  donationsListSchema,
  donationProjectSchema,
  donationProjectsListSchema,
  treePlantingSchema,
  treePlantingsListSchema,
  treeTypesSchema,
  treeTypesListSchema,
  treePlantingPartnerSchema,
  treePlantingPartnersListSchema,
  charitySchema,
  charitiesListSchema,
  organisationEntitiesSchema,
  organisationEntitiesListSchema,
  teamSchema,
  teamsListSchema,
  teamTypeSchema,
  teamTypesListSchema,
  activitiesSchema,
  activitiesListSchema,
  activityCategoriesSchema,
  activityCategoriesListSchema,
  lotteriesSchema,
  lotteriesListSchema,
  vouchersSchema,
  vouchersListSchema,
  dynamicLinkConfigSchema,
  editorialSchema,
  editorialsListSchema,
  climatePartnerSchema,
  climatePartnerListSchema,
  reportSchema,
  reportListSchema,
  userInviteSchema,
  userInviteListSchema,
};
