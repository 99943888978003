import { addNotification } from 'reapop';

import { setConcluded } from '../actions/auth';

import history from '../history';

// Constants
import { ERROR_STATUS_CODES_PROMPT_SIGNOUT } from './constants';

/**
 * Redux Middleware
 *
 * Global handler for API errors
 */
export default store => next => action => {
  const { payload = {} } = action || {};
  // if payload is ApiError
  if (payload && payload.name === 'ApiError') {
    const { status, response = {} } = payload;

    // 401: user is signed out (access_token not valid or expired)
    // 403: user token is invalid (changes after SDK)
    if (
      ERROR_STATUS_CODES_PROMPT_SIGNOUT.includes(status) ||
      response._details === 2002
    ) {
      // sign out user from App
      store.dispatch(setConcluded());

      history.push('/auth/login');

      // add Error message for user
      store.dispatch(
        addNotification({
          id: 'error.app.session.expired',
          message: store
            .getState()
            .getIn(['intl', 'messages', 'error.app.session.expired']),
          status: 'error',
        })
      );
      // stop promise
      return Promise.reject(action);
    }
  }

  // So the middleware doesn't get applied to every single action
  return next(action);
};
