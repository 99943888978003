import { ApiError, getJSON, RSAA } from 'redux-api-middleware';
import config from 'config';

import { FormError, RetryDelayError } from '../utils/errors';

import { LocalizableFieldsDefaultLanguage } from '../constants';
import * as AuthActionTypes from '../constants/actionTypes/auth';

import { getXHost } from '../utils';

const setToken = (key, value) => ({
  type: AuthActionTypes.SET_TOKEN,
  payload: { key, value },
});

const setTokens = payload => ({
  type: AuthActionTypes.SET_TOKENS,
  payload: payload,
});

const unsetTokens = payload => ({
  type: AuthActionTypes.UNSET_TOKENS,
  payload:
    typeof payload === 'string' || payload instanceof String
      ? [payload]
      : payload,
});

/**
 * Sets User as Authenticated
 * Persists `access_token` in session storage
 * @param accessToken String
 */
const setAuthenticated = accessToken => setToken('accessToken', accessToken);

/**
 * Signs out User from App
 * Removes `access_token` from session storage
 */
const setConcluded = () => unsetTokens('accessToken');

/**
 * Organisation Register (API Call)
 * @param endpoint
 * @param data
 * @returns Promise
 */
const organisationRegister = (
  data,
  locale = LocalizableFieldsDefaultLanguage
) => ({
  [RSAA]: {
    types: [
      {
        type: AuthActionTypes.ORGANISATION_REGISTER_REQUEST,
        payload: (action, state) =>
          state.getIn(['form', 'organization/register-form', 'values']).toJS(),
        // only perform request to set the email in state
      },
      AuthActionTypes.ORGANISATION_REGISTER_SUCCESS,
      failureOrganisationRegister(),
    ],
    endpoint: config.apiEndpoint + '/api/organisation/register',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },

    body: JSON.stringify(data),
  },
});

/**
 * Get an organisation registration token
 * @param locale
 * @param data
 * @returns Promise
 */
const getOrganizationRegistrationToken = (
  data,
  locale = LocalizableFieldsDefaultLanguage
) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.ORGANISATION_REGISTER_TOKEN_REQUEST,
      AuthActionTypes.ORGANISATION_REGISTER_TOKEN_SUCCESS,
      failureOrganisationRegister(
        AuthActionTypes.ORGANISATION_REGISTER_TOKEN_FAILURE
      ),
    ],
    endpoint: config.apiEndpoint + '/api/organisation/register/token',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },

    body: JSON.stringify(data),
  },
});

/**
 * Verify access token
 * @param locale
 * @param data
 * @returns Promise
 */
const validateOrganizationAccessToken = (
  data,
  locale = LocalizableFieldsDefaultLanguage
) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_REQUEST,
      AuthActionTypes.ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_SUCCESS,
      failureOrganisationRegister(
        AuthActionTypes.ORGANISATION_REGISTER_VERIFY_ACCESS_TOKEN_FAILURE
      ),
    ],
    endpoint:
      config.apiEndpoint + '/api/organisation/registration-code/validate',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },

    body: JSON.stringify(data),
  },
});

/**
 * Get an organisation registration token
 * @param token
 * @param locale
 * @returns Promise
 */
const validateOrganizationRegistrationToken = (
  token,
  locale = LocalizableFieldsDefaultLanguage
) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.ORGANISATION_REGISTER_VERIFY_TOKEN_REQUEST,
      AuthActionTypes.ORGANISATION_REGISTER_VERIFY_TOKEN_SUCCESS,
      AuthActionTypes.ORGANISATION_REGISTER_VERIFY_TOKEN_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/email/validate/' + token,
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },
  },
});

/**
 * Failure Org Register Action
 */
const failureOrganisationRegister = (
  type = AuthActionTypes.ORGANISATION_REGISTER_FAILURE
) => ({
  type,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
      //} else if (status === 403) {
      //  return new FormError('Wrong credentials.').json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    } else if (status === 500) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.FATAL_ERROR',
          _details: json._details,
        }).json();
      });
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

/**
 * User RegisterOrganisation (API Call)
 * @param endpoint
 * @param data
 * @returns Promise
 */
const userRegister = (data, locale = LocalizableFieldsDefaultLanguage) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.USER_REGISTER_REQUEST,
      AuthActionTypes.USER_REGISTER_SUCCESS,
      failureUserRegister(),
    ],
    endpoint: config.apiEndpoint + '/api/user',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },
    body: JSON.stringify(data),
  },
});

const failureUserRegister = () => ({
  type: AuthActionTypes.USER_REGISTER_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
      //} else if (status === 403) {
      //  return new FormError('Wrong credentials.').json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    } else if (status === 500) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.FATAL_ERROR',
          _details: json._details,
        }).json();
      });
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

/**
 * User OrganisationConfirm (API Call)
 * @param endpoint
 * @param data
 * @returns Promise
 */
const userOrganisationConfirm = token => ({
  [RSAA]: {
    types: [
      AuthActionTypes.USER_ORGANISATION_CONFIRM_REQUEST,
      AuthActionTypes.USER_ORGANISATION_CONFIRM_SUCCESS,
      AuthActionTypes.USER_ORGANISATION_CONFIRM_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/organisation/confirm/' + token,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * User login (API Call)
 * @param endpoint
 * @param data
 * @returns Promise
 */
const userLogin = (data, locale = LocalizableFieldsDefaultLanguage) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.USER_LOGIN_REQUEST,
      AuthActionTypes.USER_LOGIN_SUCCESS,
      failureUserLogin(),
    ],
    endpoint: config.apiEndpoint + '/api/login',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },
    body: JSON.stringify(data),
  },
});

const failureUserLogin = () => ({
  type: AuthActionTypes.USER_LOGIN_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400 || status === 403) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    } else if (status === 500) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.FATAL_ERROR',
          _details: json._details,
        }).json();
      });
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

/**
 * User logout (API Call)
 * @param endpoint
 * @returns Promise
 */
const userLogout = endpoint => ({
  [RSAA]: {
    types: [
      AuthActionTypes.USER_LOGOUT_REQUEST,
      AuthActionTypes.USER_LOGOUT_SUCCESS,
      AuthActionTypes.USER_LOGOUT_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/logout',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * User forgot password (API Call)
 * @param data
 * @returns Promise
 */
const userForgotPassword = (
  data,
  locale = LocalizableFieldsDefaultLanguage
) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.USER_FORGOT_PASSWORD_REQUEST,
      AuthActionTypes.USER_FORGOT_PASSWORD_SUCCESS,
      failureUserForgotPassword(),
    ],
    endpoint: config.apiEndpoint + '/api/forgot-password',
    method: 'POST',
    headers: {
      'Accept-Language': locale,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Host': getXHost(),
    },
    body: JSON.stringify(data),
  },
});

const failureUserForgotPassword = () => ({
  type: AuthActionTypes.USER_FORGOT_PASSWORD_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
    } else if (status === 403) {
      return getJSON(res).then(json => {
        return new FormError(
          json._details === 2020
            ? 'authentication.forgotPassword.userBelongsToAnotherOrg'
            : 'error.TRY_AGAIN'
        ).json();
      });

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    } else if (status === 500) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.FATAL_ERROR',
          _details: json._details,
        }).json();
      });
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

/**
 * Check if reset password token is valid (API Call)
 * @param token
 * @returns Promise
 */
const checkResetPasswordToken = token => ({
  [RSAA]: {
    types: [
      AuthActionTypes.CHECK_RESET_PASSWORD_TOKEN_REQUEST,
      AuthActionTypes.CHECK_RESET_PASSWORD_TOKEN_SUCCESS,
      AuthActionTypes.CHECK_RESET_PASSWORD_TOKEN_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/validate-token/' + token,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * User reset password (API Call)
 * @param token
 * @param data
 * @returns Promise
 */
const userResetPassword = (token, data) => ({
  [RSAA]: {
    types: [
      AuthActionTypes.USER_RESET_PASSWORD_REQUEST,
      AuthActionTypes.USER_RESET_PASSWORD_SUCCESS,
      failureUserResetPassword(),
    ],
    endpoint: config.apiEndpoint + '/api/reset-password/' + token,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

const failureUserResetPassword = () => ({
  type: AuthActionTypes.USER_RESET_PASSWORD_FAILURE,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
      //} else if (status === 403) {
      //  return new FormError('Wrong credentials.').json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    } else if (status === 500) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.FATAL_ERROR',
          _details: json._details,
        }).json();
      });
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

const updateLoginEmail = email => ({
  type: AuthActionTypes.UPDATE_LOGIN_EMAIL,
  payload: { email },
});

export {
  setToken,
  setTokens,
  unsetTokens,
  setAuthenticated,
  setConcluded,
  organisationRegister,
  getOrganizationRegistrationToken,
  validateOrganizationRegistrationToken,
  validateOrganizationAccessToken,
  userRegister,
  userOrganisationConfirm,
  userLogin,
  userLogout,
  userForgotPassword,
  checkResetPasswordToken,
  userResetPassword,
  updateLoginEmail,
};
