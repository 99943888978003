import { RSAA } from 'redux-api-middleware';
import config from 'config';

import {
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
} from '../utils/rsaa';

import * as TreePlantingPartnersActionTypes from '../constants/actionTypes/treePlantingPartners';

/**
 * Fetch TreePlantingPartners
 * @returns Promise
 */
const fetchTreePlantingPartners = () => ({
  [RSAA]: {
    types: [
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_FETCH_REQUEST,
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_FETCH_SUCCESS,
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_FETCH_FAILURE,
    ],
    endpoint:
      config.apiEndpoint + '/api/tree-planting-partner-defaults?per_page=500',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

/**
 * Update default tree planting partner
 * @param data
 * @returns Promise
 */
const updateTreePlantingProjectDefaults = (id, data) => ({
  [RSAA]: {
    types: [
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNER_DEFAULTS_UPDATE_REQUEST,
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNER_DEFAULTS_SUCCESS,
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNER_DEFAULTS_FAILURE,
    ],
    endpoint: config.apiEndpoint + `/api/tree-planting-partner-defaults/${id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Adds a new TreePlantingPartner
 * @param data
 * @returns Promise
 */
const addTreePlantingPartner = data => ({
  [RSAA]: {
    types: [
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_ADD_REQUEST,
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_ADD_SUCCESS,
      basicFailureRSAAHandlerForCreate(
        /* type */ TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_ADD_FAILURE,
        /* entity */ 'treePlantingPartners'
      ),
    ],
    endpoint: config.apiEndpoint + '/api/tree-planting-partner',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

/**
 * Update a TreePlantingPartner
 * @param data
 * @returns Promise
 */
const updateTreePlantingPartner = (treePlantingPartnerId, data) => ({
  [RSAA]: {
    types: [
      TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_UPDATE_REQUEST,
      {
        type:
          TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_UPDATE_SUCCESS,
        meta: (action, state, res) => ({ treePlantingPartnerId }),
      },
      basicFailureRSAAHandlerForUpdate(
        /* type */ TreePlantingPartnersActionTypes.TREE_PLANTING_PARTNERS_UPDATE_FAILURE,
        /* entity */ 'treePlantingPartners'
      ),
    ],
    endpoint:
      config.apiEndpoint +
      '/api/tree-planting-partner/' +
      treePlantingPartnerId,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  },
});

export {
  fetchTreePlantingPartners,
  addTreePlantingPartner,
  updateTreePlantingPartner,
  updateTreePlantingProjectDefaults,
};
