import { defineMessages } from 'react-intl';

export default defineMessages({
  'pagination.next': {
    id: 'pagination.next',
    defaultMessage: 'Next',
    description: 'Pagination Next Button',
  },

  'pagination.previous': {
    id: 'pagination.previous',
    defaultMessage: 'Previous',
    description: 'Pagination previous Button',
  },
});
