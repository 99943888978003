import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import config from 'config';

import {
  changeLocale,
  fetchApplangaLocalization,
  fetchDefaultMessages,
} from '../../actions/locale';

import history from '../../history';

import App from '../App';
import ConnectedIntlProvider from '../ConnectedIntlProvider';

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const {
      store: { getState, dispatch },
    } = this.props;
    const locale = getState().getIn(['intl', 'locale']);
    dispatch(fetchDefaultMessages());
    // load language file for used locale or default language based on user-agent
    const changeLocalePromise = dispatch(changeLocale(locale));
    if (config.useApplanga) {
      changeLocalePromise.then(() => {
        dispatch(fetchApplangaLocalization(locale));
      });
    }
  }

  render() {
    const { store } = this.props;
    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <ConnectedIntlProvider>
            <Router history={history}>
              <App />
            </Router>
          </ConnectedIntlProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}
