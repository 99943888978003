const treatAsUTC = date => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

const daysBetween = (startDate, endDate) => {
  const millisecondsSecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.round(
    (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsSecondsPerDay
  );
};

const daysInMonth = date => {
  return new Date(date.getYear(), date.getMonth(), 0).getDate();
};

const isFullCalendarMonth = (from, to) => {
  // if days in current range are not exactly a month then return false
  const daysRange = daysBetween(from, to);
  const daysInCalendarMonth = daysInMonth(to);
  return daysRange === daysInCalendarMonth;
};

const destructMilliseconds = milliseconds => {
  if (isNaN(milliseconds) || milliseconds < 0) {
    return null;
  }

  let hours, minutes, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  const days = Math.floor(hours / 24);
  hours = hours % 24;
  const ms = Math.floor((milliseconds % 1000) * 1000) / 1000;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: ms,
  };
};

const getDateDifference = val => {
  const date1 = new Date(new Date(val).toLocaleDateString('en-US'));
  const date2 = new Date(new Date().toLocaleDateString('en-US'));
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const getMonthDate = date =>
  new Date(date ? date.toString().split('T')[0] : date);

export {
  daysBetween,
  isFullCalendarMonth,
  destructMilliseconds,
  getMonthDate,
  getDateDifference,
};
