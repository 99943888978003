import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import apiAnalytics from '../middlewares/apiAnalytics';
import apiError from '../middlewares/apiError';
import apiRequestAuthorization from '../middlewares/apiRequestAuthorization';
import loadingIndicator from '../middlewares/loadingIndicator';

import rootReducer from '../reducers';

const configureStore = initialState => {
  return createStore(
    rootReducer,
    initialState,
    compose(
      // loadingIndicator here handles the start() call
      // apiAnalytics here adds the RSAA to be tracked
      applyMiddleware(
        thunk,
        apiRequestAuthorization,
        loadingIndicator,
        apiAnalytics,
        apiMiddleware,

        // loadingIndicator here handles the stop() call
        loadingIndicator,
        apiError
      ),

      // apiAnalytics here handles all request types for tracking the request timing
      applyMiddleware(apiAnalytics)
    )
  );
};

export default configureStore;
