export const CHALLENGES_FETCH_REQUEST =
  'changers/admin/CHALLENGES_FETCH_REQUEST';
export const CHALLENGES_FETCH_SUCCESS =
  'changers/admin/CHALLENGES_FETCH_SUCCESS';
export const CHALLENGES_FETCH_FAILURE =
  'changers/admin/CHALLENGES_FETCH_FAILURE';

export const CHALLENGES_ADD_REQUEST = 'changers/admin/CHALLENGES_ADD_REQUEST';
export const CHALLENGES_ADD_SUCCESS = 'changers/admin/CHALLENGES_ADD_SUCCESS';
export const CHALLENGES_ADD_FAILURE = 'changers/admin/CHALLENGES_ADD_FAILURE';

export const CHALLENGES_UPDATE_REQUEST =
  'changers/admin/CHALLENGES_UPDATE_REQUEST';
export const CHALLENGES_UPDATE_SUCCESS =
  'changers/admin/CHALLENGES_UPDATE_SUCCESS';
export const CHALLENGES_UPDATE_FAILURE =
  'changers/admin/CHALLENGES_UPDATE_FAILURE';

export const CHALLENGES_DELETE_REQUEST =
  'changers/admin/CHALLENGES_DELETE_REQUEST';
export const CHALLENGES_DELEETE_SUCCESS =
  'changers/admin/CHALLENGES_DELETE_SUCCESS';
export const CHALLENGES_DELETE_FAILURE =
  'changers/admin/CHALLENGES_DELETE_FAILURE';

export const CHALLENGES_AUTO_RENEW_REQUEST =
  'changers/admin/CHALLENGES_AUTO_RENEW_REQUEST';
export const CHALLENGES_AUTO_RENEW_SUCCESS =
  'changers/admin/CHALLENGES_AUTO_RENEW_SUCCESS';
export const CHALLENGES_AUTO_RENEW_FAILURE =
  'changers/admin/CHALLENGES_AUTO_RENEW_FAILURE';
