import { getJSON, ApiError } from 'redux-api-middleware';

import { FormError, RetryDelayError } from './errors';

const basicFailureRSAAHandler = (type, entity) => ({
  type,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
    } else if (status === 403) {
      return new FormError(`${entity}.error.notAllowed`).json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    }

    // special handling for Tracking API
    // that has different error response than rest APIs
    return getJSON(res).then(json => {
      return new FormError({
        _error: 'error.TRY_AGAIN',
        _details: json.status,
      }).json();
    });
  },
});

const basicFailureRSAAHandlerForCreate = (type, entity) => ({
  type,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403: Authentication failed
    } else if (status === 403) {
      return new FormError(`${entity}.error.notAllowed.AddNew`).json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

const basicFailureRSAAHandlerForUpdate = (type, entity) => ({
  type,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 403 / 404: Authentication failed
    } else if (status === 403) {
      return new FormError(`${entity}.error.notAllowed.modify`).json();

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

const basicFailureRSAAHandlerForImageUpload = type => ({
  type,

  // an ugly hack because `res` needs to be read again in meta
  // https://github.com/agraboso/redux-api-middleware/issues/92
  payload: (action, state, res) => {
    // unauthorized access
    if (res.status === 401) {
      return getJSON(res).then(json => {
        return new ApiError(401, '', json);
      });
    }
  },
  meta: (action, state, res) => {
    const { status } = res;

    // unauthorized access
    if (status === 401) {
      // handled in payload
      return {};

      // validation is wrong
    } else if (status === 400) {
      return getJSON(res).then(json => {
        console.log(json);
        return new FormError({
          _error: 'error.TRY_AGAIN',
          _details: json._details,
        }).json();
      });

      // 429 / 503: Rate-limited or overloaded
    } else if (status === 429 || status === 503) {
      return new RetryDelayError(res, 'error.RATE_LIMIT').json();
    } else if (status >= 400 && status <= 499) {
      return new FormError('error.FATAL_ERROR').json();
    }

    return new FormError('error.TRY_AGAIN').json();
  },
});

export {
  basicFailureRSAAHandler,
  basicFailureRSAAHandlerForCreate,
  basicFailureRSAAHandlerForUpdate,
  basicFailureRSAAHandlerForImageUpload,
};
