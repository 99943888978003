import config from 'config';
import { UIViews } from '../constants';
import Storage from '../utils/storage';

/**
 * Redux Middleware
 *
 * Global handler for persisting state to storage
 */
export default function observeStore(store) {
  let currentIntlState = store.getState().getIn(['intl', 'locale']);
  let currentAuthState = store.getState().get('auth');
  let currentCommonUIState = store.getState().getIn(['ui', UIViews.COMMON]);

  const handleChange = () => {
    // handle intl State
    const newIntlState = store.getState().getIn(['intl', 'locale']);
    if (newIntlState !== currentIntlState) {
      currentIntlState = newIntlState;
      Storage('sessionStorage').setItem(config.storage.locale, newIntlState);
    }

    // handle Auth State
    const newAuthState = store.getState().get('auth');
    if (newAuthState !== currentAuthState) {
      currentAuthState = newAuthState;
      Storage('localStorage').setItem(
        config.storage.authSession,
        JSON.stringify(newAuthState.toJS())
      );
    }

    // handle UIState
    const newCommonUIState = store.getState().getIn(['ui', UIViews.COMMON]);
    if (newCommonUIState !== currentCommonUIState) {
      currentCommonUIState = newCommonUIState;
      Storage('localStorage').setItem(
        config.storage.uiState,
        JSON.stringify(newCommonUIState ? newCommonUIState.toJS() : {})
      );
    }
  };

  const unsubscribe = store.subscribe(handleChange);

  return unsubscribe;
}
