import { fromJS } from 'immutable';

import {
  LOCALE_UPDATE,
  APPLANGA_LOCALE_SUCCESS,
  FETCH_DEFAULT_MESSAGES_SUCCESS,
} from '../constants/actionTypes/locale';

const initialState = fromJS({
  locale: null,
  messages: {},
  defaultMessages: {},
});

/**
 * Updates error message to notify about the failed fetches.
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  if (type === LOCALE_UPDATE) {
    return state.merge(fromJS(payload));
  } else if (type === APPLANGA_LOCALE_SUCCESS) {
    const processedMessages = payload.data[payload.lang]
      ? payload.data[payload.lang].main.entries
      : {};
    Object.keys(processedMessages).map(key => {
      processedMessages[key] = processedMessages[key].v;
    });
    const resolvedData = {
      locale: payload.lang,
      messages: processedMessages,
    };
    return state.merge(fromJS(resolvedData));
  } else if (type === FETCH_DEFAULT_MESSAGES_SUCCESS) {
    const defaultMessages = payload.data[payload.lang].main.entries;
    const mergedState = state.merge(fromJS({ defaultMessages }));
    return mergedState;
  }

  return state;
};

export default reducer;
