/**
 * Checks if StorageAPI is being supported from browser
 *
 * Handling of private browsing for Mac/iOS Safari browser
 * by implementing memory storage as a fallback
 */
let _isStorageSupported;
const isStorageSupported = () => {
  if (_isStorageSupported) {
    return _isStorageSupported;
  }

  const item = 'localStorageTest';
  try {
    localStorage.setItem(item, item);
    localStorage.removeItem(item);
    return true;
  } catch (err) {
    return false;
  }
};

let _storage_data = {};
const Storage = (type = 'sessionStorage') => {
  const memoryStorage = {
    getKey: key => `${type}:${key}`,
    getItem: key => {
      key = memoryStorage.getKey(key);
      return _storage_data.hasOwnProperty(key) ? _storage_data[key] : null;
    },
    setItem: (key, val) => {
      key = memoryStorage.getKey(key);
      _storage_data[key] = String(val);
    },
    removeItem: key => {
      key = memoryStorage.getKey(key);
      delete _storage_data[key];
    },
    clear: () => {
      _storage_data = {};
    },
    length: () => Object.keys(_storage_data).length,
  };

  return isStorageSupported() ? window[type] : memoryStorage;
};

export default Storage;
