export const COMMUNICATIONS_FETCH_REQUEST =
  'changers/admin/COMMUNICATIONS_FETCH_REQUEST';
export const COMMUNICATIONS_FETCH_SUCCESS =
  'changers/admin/COMMUNICATIONS_FETCH_SUCCESS';
export const COMMUNICATIONS_FETCH_FAILURE =
  'changers/admin/COMMUNICATIONS_FETCH_FAILURE';

export const COMMUNICATIONS_ADD_REQUEST =
  'changers/admin/COMMUNICATIONS_ADD_REQUEST';
export const COMMUNICATIONS_ADD_SUCCESS =
  'changers/admin/COMMUNICATIONS_ADD_SUCCESS';
export const COMMUNICATIONS_ADD_FAILURE =
  'changers/admin/COMMUNICATIONS_ADD_FAILURE';

export const COMMUNICATIONS_RESEND_REQUEST =
  'changers/admin/COMMUNICATIONS_RESEND_REQUEST';
export const COMMUNICATIONS_RESEND_SUCCESS =
  'changers/admin/COMMUNICATIONS_RESEND_SUCCESS';
export const COMMUNICATIONS_RESEND_FAILURE =
  'changers/admin/COMMUNICATIONS_RESEND_FAILURE';

export const COMMUNICATIONS_DELETE_REQUEST =
  'changers/admin/COMMUNICATIONS_DELETE_REQUEST';
export const COMMUNICATIONS_DELETE_SUCCESS =
  'changers/admin/COMMUNICATIONS_DELETE_SUCCESS';
export const COMMUNICATIONS_DELETE_FAILURE =
  'changers/admin/COMMUNICATIONS_DELETE_FAILURE';
