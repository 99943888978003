import { fromJS } from 'immutable';

import * as AuthActionTypes from '../../constants/actionTypes/auth';
import * as EntitiesActionTypes from '../../constants/actionTypes/vouchers';

const initialState = fromJS({
  byId: {},
  result: [],
});

/**
 * Handles Teams
 */
const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data = {} } = payload;

  switch (type) {
    case EntitiesActionTypes.VOUCHERS_TAGS_FETCH_SUCCESS: {
      return state.merge({
        result: fromJS(data),
      });
    }

    // clear state for next user login
    case AuthActionTypes.USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
