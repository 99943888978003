export const ORGANISATION_ENTITIES_FETCH_REQUEST =
  'changers/admin/ORGANISATION_ENTITIES_FETCH_REQUEST';
export const ORGANISATION_ENTITIES_FETCH_SUCCESS =
  'changers/admin/ORGANISATION_ENTITIES_FETCH_SUCCESS';
export const ORGANISATION_ENTITIES_FETCH_FAILURE =
  'changers/admin/ORGANISATION_ENTITIES_FETCH_FAILURE';

export const ORGANISATION_ENTITIES_UPDATE_REQUEST =
  'changers/admin/ORGANISATION_ENTITIES_UPDATE_REQUEST';
export const ORGANISATION_ENTITIES_UPDATE_SUCCESS =
  'changers/admin/ORGANISATION_ENTITIES_UPDATE_SUCCESS';
export const ORGANISATION_ENTITIES_UPDATE_FAILURE =
  'changers/admin/ORGANISATION_ENTITIES_UPDATE_FAILURE';

export const ORGANISATION_ENTITIES_AREAS_UPDATE_REQUEST =
  'changers/admin/ORGANISATION_ENTITIES_AREAS_UPDATE_REQUEST';
export const ORGANISATION_ENTITIES_AREAS_UPDATE_SUCCESS =
  'changers/admin/ORGANISATION_ENTITIES_AREAS_UPDATE_SUCCESS';
export const ORGANISATION_ENTITIES_AREAS_UPDATE_FAILURE =
  'changers/admin/ORGANISATION_ENTITIES_AREAS_UPDATE_FAILURE';
