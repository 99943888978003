import { RSAA } from 'redux-api-middleware';
import config from 'config';

import * as ActivityCategoriesActionTypes from '../constants/actionTypes/activityCategories';

/**
 * Fetch Custom Activity Categories
 * @returns Promise
 */
const fetchCustomActivityCategories = (locale = '') => ({
  [RSAA]: {
    types: [
      ActivityCategoriesActionTypes.CUSTOM_ACTIVITY_CATEGORIES_FETCH_REQUEST,
      ActivityCategoriesActionTypes.CUSTOM_ACTIVITY_CATEGORIES_FETCH_SUCCESS,
      ActivityCategoriesActionTypes.CUSTOM_ACTIVITY_CATEGORIES_FETCH_FAILURE,
    ],
    endpoint: config.apiEndpoint + '/api/custom-activity-category',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

export { fetchCustomActivityCategories };
