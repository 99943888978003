import { defineMessages } from 'react-intl';

export default defineMessages({
  'menu.logo.title': {
    id: 'menu.logo.title',
    defaultMessage: 'Changers Admin',
    description: 'Menu item',
  },
  'menu.user.addActivities': {
    id: 'menu.user.addActivities',
    defaultMessage: 'Add Activities',
    description: 'Menu item',
  },
  'menu.user.userMarketplace': {
    id: 'menu.user.userMarketplace',
    defaultMessage: 'Marketplace',
    description: 'Menu item',
  },
  'menu.projectSettings': {
    id: 'menu.projectSettings',
    defaultMessage: 'Project Settings',
    description: 'Menu item',
  },
  'menu.homeoffice': {
    id: 'menu.homeoffice',
    defaultMessage: 'Home Office',
    description: 'Menu item',
  },
  'menu.projectSettings.general': {
    id: 'menu.projectSettings.general',
    defaultMessage: 'General Settings',
    description: 'Menu item',
  },
  'menu.challenges': {
    id: 'menu.challenges',
    defaultMessage: 'Challenges',
    description: 'Menu item',
  },
  'menu.teams': {
    id: 'menu.teams',
    defaultMessage: 'Teams',
    description: 'Menu item',
  },
  'menu.recognition': {
    id: 'menu.recognition',
    defaultMessage: 'Recognition',
    description: 'Menu item',
  },
  'menu.charity': {
    id: 'menu.charity',
    defaultMessage: 'Charity',
    description: 'Menu item',
  },
  'menu.overview': {
    id: 'menu.overview',
    defaultMessage: 'Overview',
    description: 'Menu item',
  },
  'menu.overview.dashboard': {
    id: 'menu.overview.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Menu item',
  },
  'menu.overview.reports': {
    id: 'menu.overview.reports',
    defaultMessage: 'Reports',
    description: 'Menu item',
  },
  'menu.charity.treePlanting': {
    id: 'menu.charity.treePlanting',
    defaultMessage: 'Tree Planting',
    description: 'Menu item',
  },
  'menu.charity.donation': {
    id: 'menu.charity.donation',
    defaultMessage: 'Donation Project',
    description: 'Menu item',
  },
  'menu.charity.donationProjectsList': {
    id: 'menu.charity.donationProjectsList',
    defaultMessage: 'Donation Projects list',
    description: 'Menu item',
  },
  'menu.charity.treePlantingList': {
    id: 'menu.charity.treePlantingList',
    defaultMessage: 'Tree Plantings list',
    description: 'Menu item',
  },
  'menu.charity.treePlantingHeader': {
    id: 'menu.charity.treePlantingHeader',
    defaultMessage: 'Tree Plantings list',
    description: 'Menu item',
  },
  'menu.charity.list': {
    id: 'menu.charity.list',
    defaultMessage: 'Charity List',
    description: 'Menu item',
  },
  'menu.charity.treePlantingCreate': {
    id: 'menu.charity.treePlantingCreate',
    defaultMessage: 'Create Tree Planting',
    description: 'Menu item',
  },
  'menu.charityPartners.donation': {
    id: 'menu.charityPartners.donation',
    defaultMessage: 'Donation Partners',
    description: 'Menu item',
  },
  'menu.charity.donationCreate': {
    id: 'menu.charity.donationCreate',
    defaultMessage: 'Create Donation',
    description: 'Menu item',
  },
  'menu.charityPartners.treePlanting': {
    id: 'menu.charityPartners.treePlanting',
    defaultMessage: 'Tree Planting Partners',
    description: 'Menu item',
  },
  'menu.rewards': {
    id: 'menu.rewards',
    defaultMessage: 'Rewards',
    description: 'Menu item',
  },
  'menu.communication': {
    id: 'menu.communication',
    defaultMessage: 'Communication',
    description: 'Menu item',
  },
  'menu.pushNotifications': {
    id: 'menu.pushNotifications',
    defaultMessage: 'Push Notifications',
    description: 'Menu item',
  },
  'menu.tools': {
    id: 'menu.tools',
    defaultMessage: 'Tools',
    description: 'Menu item',
  },
  'menu.userReferralInvite': {
    id: 'menu.userReferralInvite',
    defaultMessage: 'Invite Users',
    description: 'Menu item',
  },
  'menu.rewards.vouchers': {
    id: 'menu.rewards.vouchers',
    defaultMessage: 'Vouchers',
    description: 'Menu item',
  },
  'menu.rewards.lotteries': {
    id: 'menu.rewards.lotteries',
    defaultMessage: 'Lotteries',
    description: 'Menu item',
  },
  'menu.rewards.referralSettings': {
    id: 'menu.rewards.referralSettings',
    defaultMessage: 'Referral Settings',
    description: 'Menu item',
  },
  'menu.referralSettings': {
    id: 'menu.referralSettings',
    defaultMessage: 'Referral Settings',
    description: 'Menu item',
  },
  'menu.reports': {
    id: 'menu.report',
    defaultMessage: 'Reports',
    description: 'Menu item',
  },
  'menu.editorials': {
    id: 'menu.editorials',
    defaultMessage: 'Editorials',
    description: 'Menu item',
  },
  'menu.upgrade': {
    id: 'menu.upgrade',
    defaultMessage: 'Upgrade',
    description: 'Menu item',
  },
  'menu.upgrade.timeLeft': {
    id: 'menu.upgrade.timeLeft',
    defaultMessage: `{remainingDays, plural,
      one {Only 1 day free to use}
      other {Only {remainingDays} days free to use}
    }`,
    description: 'Menu item',
  },
  'menu.upgrade.timeLeft2': {
    id: 'menu.upgrade.timeLeft2',
    defaultMessage: `{remainingDays, plural,
      one {Only 1 day left}
      other {Only {remainingDays} days left}
    }`,
    description: 'Menu item',
  },
  'menu.upgrade.timeLeft2.lessThanTwo': {
    id: 'menu.upgrade.timeLeft2.lessThanTwo',
    defaultMessage: 'Less than 24hrs left',
    description: 'Menu item',
  },
  'menu.upgrade.cancelling.text': {
    id: 'menu.upgrade.cancelling.text',
    defaultMessage: `Your subscription <br/>  was canceled!`,
    description: 'Menu item',
  },
  'menu.upgrade.cancelling.btn': {
    id: 'menu.upgrade.cancelling.btn',
    defaultMessage: `Reactivate Subscription`,
    description: 'Menu item',
  },
  'menu.upgrade.canceled.text': {
    id: 'menu.upgrade.canceled.text',
    defaultMessage: `Your subscription <br /> has expired!`,
    description: 'Menu item',
  },
  'menu.upgrade.canceled.btn': {
    id: 'menu.upgrade.canceled.btn',
    defaultMessage: `Renew subscription`,
    description: 'Menu item',
  },
  'menu.upgrade.freeTrial': {
    id: 'menu.upgrade.freeTrial',
    defaultMessage: `for your free trial`,
    description: 'Menu item',
  },
  'menu.upgrade.now': {
    id: 'menu.upgrade.now',
    defaultMessage: 'Now',
    description: 'Menu item',
  },
  'menu.widget': {
    id: 'menu.widget',
    defaultMessage: 'Widget',
    description: 'Menu item',
  },
  'menu.iframeBanner': {
    id: 'menu.iframeBanner',
    defaultMessage: 'iFrame Banner',
    description: 'Menu item',
  },
  'menu.aboutInfoText': {
    id: 'menu.aboutInfoText',
    defaultMessage: 'About Info-Text',
    description: 'Menu item',
  },
  'menu.privacyPolicy': {
    id: 'menu.privacyPolicy',
    defaultMessage: 'Privacy Policy',
    description: 'Menu item',
  },
  'menu.faq': {
    id: 'menu.faq',
    defaultMessage: 'FAQ',
    description: 'Menu item',
  },
  'menu.termsOfService': {
    id: 'menu.termsOfService',
    defaultMessage: 'Terms of Service',
    description: 'Menu item',
  },
  'menu.customActivityEditorial': {
    id: 'menu.customActivityEditorial',
    defaultMessage: 'Custom Activity',
    description: 'Menu item',
  },
  'menu.co2ContributionEditorial': {
    id: 'menu.co2ContributionEditorial',
    defaultMessage: 'CO2 Contribution',
    description: 'Menu item',
  },
  'menu.account': {
    id: 'menu.account',
    defaultMessage: 'Account',
    description: 'Menu item',
  },
  'menu.account.profile': {
    id: 'menu.account.profile',
    defaultMessage: 'Profile',
    description: 'Menu item',
  },
  'menu.account.changePassword': {
    id: 'menu.account.changePassword',
    defaultMessage: 'Change Password',
    description: 'Menu item',
  },
  'menu.account.teams': {
    id: 'menu.account.teams',
    defaultMessage: 'Teams',
    description: 'Menu item',
  },
  'menu.account.currentPlan': {
    id: 'menu.account.currentPlan',
    defaultMessage: 'Current Plan',
    description: 'Menu item',
  },
  'menu.account.billingDetails': {
    id: 'menu.account.billingDetails',
    defaultMessage: 'Billing Details',
    description: 'Menu item',
  },
  'menu.account.downloadCenter': {
    id: 'menu.account.downloadCenter',
    defaultMessage: 'Download Center',
    description: 'Menu item',
  },
  'menu.administration': {
    id: 'menu.administration',
    defaultMessage: 'Administration',
    description: 'Menu item',
  },
  'menu.signout': {
    id: 'menu.signout',
    defaultMessage: 'Sign Out',
    description: 'Menu item',
  },

  'menu.activities': {
    id: 'menu.activities',
    defaultMessage: 'Activities',
    description: 'Menu item',
  },
  'menu.activities.healthyNutrition': {
    id: 'menu.activities.healthyNutrition',
    defaultMessage: 'Healthy Nutrition',
    description: 'Menu item',
  },
  'menu.activities.sport': {
    id: 'menu.activities.sport',
    defaultMessage: 'Sport',
    description: 'Menu item',
  },
  'menu.activities.medicalPrevention': {
    id: 'menu.activities.medicalPrevention',
    defaultMessage: 'Medical Prevention & Seminars',
    description: 'Menu item',
  },
  'menu.activities.consciousLiving': {
    id: 'menu.activities.consciousLiving',
    defaultMessage: 'Conscious Living',
    description: 'Menu item',
  },
  'menu.activities.safety': {
    id: 'menu.activities.safety',
    defaultMessage: 'Safety Measures',
    description: 'Menu item',
  },
  'menu.activities.archives': {
    id: 'menu.activities.archives',
    defaultMessage: 'Archives',
    description: 'Menu item',
  },

  'menu.website.globalChallenge': {
    id: 'menu.website.globalChallenge',
    defaultMessage: 'Global Challenge',
    description: 'Menu item',
  },
  'menu.website.pricing': {
    id: 'menu.website.pricing',
    defaultMessage: 'Pricing',
    description: 'Menu item',
  },
  'menu.website.faq': {
    id: 'menu.website.faq',
    defaultMessage: 'FAQ',
    description: 'Menu item',
  },
  'menu.website.blog': {
    id: 'menu.website.blog',
    defaultMessage: 'Blog',
    description: 'Menu item',
  },
  'menu.website.getTrial': {
    id: 'menu.website.getTrial',
    defaultMessage: '14-DAYS FREE TRIAL',
    description: 'Menu item',
  },
  'menu.co2.compensationPartners': {
    id: 'menu.co2.compensationPartners',
    defaultMessage: 'Compensation Partners',
    description: 'Menu item',
  },
  'menu.overview.myDashboard': {
    id: 'menu.overview.myDashboard',
    defaultMessage: 'My Dashboard',
    description: 'Menu item',
  },
  'menu.overview.weAllTogether': {
    id: 'menu.overview.weAllTogether',
    defaultMessage: 'We all together',
    description: 'Menu item',
  },
  'menu.user.userMarketplace2': {
    id: 'menu.user.userMarketplace2',
    defaultMessage: 'Marketplace',
    description: 'Menu item',
  },
});
