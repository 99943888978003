import { RSAA } from 'redux-api-middleware';
import config from 'config';

import { basicFailureRSAAHandlerForUpdate } from '../utils/rsaa';

import * as ReportActionTypes from '../constants/actionTypes/reports';

const generateReport = data => ({
  [RSAA]: {
    types: [
      ReportActionTypes.REPORT_GENERATE_REQUEST,
      ReportActionTypes.REPORT_GENERATE_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ ReportActionTypes.REPORT_GENERATE_FAILURE,
        /* entity */ 'reports'
      ),
    ],
    endpoint: config.apiEndpoint + `/api/report/generate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': data.locale,
    },
    body: JSON.stringify(data),
  },
});

const fetchReports = () => ({
  [RSAA]: {
    types: [
      ReportActionTypes.REPORT_LIST_REQUEST,
      ReportActionTypes.REPORT_LIST_SUCCESS,
      basicFailureRSAAHandlerForUpdate(
        /* type */ ReportActionTypes.REPORT_LIST_FAILURE,
        /* entity */ 'reports'
      ),
    ],
    endpoint: config.apiEndpoint + `/api/report?limit=500`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

const resetReport = () => ({
  type: ReportActionTypes.REPORT_RESET,
});

export { generateReport, fetchReports, resetReport };
