import * as UIActionTypes from '../constants/actionTypes/ui';

import { UIViews } from '../constants';

/**
 * Stores state for UI
 * @param payload Object
 * @param view    String    adds supports for namespaces
 * @returns {{type, payload: Object}}
 */
export function setUIState(payload, view = UIViews.COMMON) {
  return {
    type: UIActionTypes.SET_UI_STATE,
    payload: {
      view,
      data: payload,
    },
  };
}

/**
 * Reset UI state
 * @param view    String    adds supports for namespaces
 * @returns {{type}}
 */
export function resetUIState(view = UIViews.COMMON) {
  return {
    type: UIActionTypes.RESET_UI_STATE,
    payload: view,
  };
}

/**
 * Show loading curtain
 * @param title String
 * @param description String
 * @returns {{type: String, error: boolean, meta: {message: String}}}
 */
export function showLoadingCurtain(title, description) {
  return {
    type: UIActionTypes.SET_UI_STATE,
    payload: {
      view: UIViews.LOADING_CURTAIN,
      data: {
        title,
        description,
      },
    },
  };
}

/**
 * Hide the loading curtain
 * @returns {{type: String}}
 */
export function hideLoadingCurtain() {
  return {
    type: UIActionTypes.RESET_UI_STATE,
    payload: UIViews.LOADING_CURTAIN,
  };
}
