import mixpanel from 'mixpanel-browser';
import config from 'config';
import moment from 'moment';
import { isAdminHost } from '.';

const initializeTracking = () => {
  if (!canTrack()) {
    return;
  }
  mixpanel.init(config.mixpanelKey, {
    debug: config.DEBUG,
    api_host: `${config.apiEndpoint}/mixpanel`,
  });
};

const identify = (user, organisation) => {
  try {
    if (!canTrack()) {
      return;
    }
    if (user) {
      mixpanel.identify(user.get('id'));
      updateProfile({
        $name: `${user.get('firstname')} ${user.get('lastname')}`,
        $first_name: user.get('firstname'),
        $last_name: user.get('lastname'),
        'Company Name': organisation.get('name') || null,
        $email: user.get('email'),
      });
    }
  } catch (e) {
    console.error('Mixpanel identify error: ', e);
  }
};

const alias = email => {
  try {
    if (!canTrack()) {
      return;
    }
    mixpanel.alias(email);
  } catch (e) {
    console.error('Mixpanel identify error: ', e);
  }
};

const track = (event, eventData, userData, superData) => {
  try {
    if (!canTrack()) {
      return;
    }
    if (superData) {
      mixpanel.register(superData);
    }
    mixpanel.track(event, eventData);
    if (userData) {
      updateProfile(userData);
    }
  } catch (e) {
    console.error('Mixpanel track error: ', e);
  }
};

const removeSuperProperty = property => {
  try {
    if (!canTrack()) {
      return;
    }
    mixpanel.unregister(property);
  } catch (e) {
    console.error('Mixpanel track error: ', e);
  }
};

const trackLink = (selector, event, properties) => {
  try {
    if (!canTrack()) {
      return;
    }
    if (document.querySelector(selector)) {
      mixpanel.track_links(selector, event, properties);
    }
    mixpanel.track_links(selector, event, properties);
  } catch (e) {
    console.error('Mixpanel trackLink error: ', e);
  }
};

const updateProfile = data => {
  try {
    if (!canTrack()) {
      return;
    }
    mixpanel.people.set(data);
  } catch (e) {
    console.error('Mixpanel trackLink error: ', e);
  }
};

const reset = () => {
  try {
    mixpanel.reset();
  } catch (e) {
    console.error('Mixpanel trackLink error: ', e);
  }
};

const canTrack = () => {
  return isAdminHost();
};

const getChallengeTrackingData = data => {
  // 'Type': 'Steps|KM|Coin|CO2',
  //     'Duration': 'days',
  //     "Activites": ["Walking", "Bike"],
  //     'Status': 'warmup|regular|paused'

  const trackingData = {};
  const types = [];
  types[1] = 'CO2';
  types[2] = 'Kilometer';
  types[3] = 'Coins';
  types[4] = 'Steps';
  const statuses = [];
  statuses[1] = 'warmup';
  statuses[2] = 'paused';
  statuses[3] = 'regular';
  trackingData.Type = types[data.get('type')];
  trackingData.Status = statuses[data.get('state_id')];
  const sources = [];
  sources[10] = 'Walking';
  sources[2] = 'Cycling';
  sources[3] = 'Public transportation';
  sources[3] = 'Long distance trains';
  sources[25] = 'Custom activity';
  sources[45] = 'User Referral Reward';
  trackingData.Activities = data
    .get('challenge_sources')
    .map(source => sources[source.get('source_id')])
    .toJS();
  trackingData.Duration =
    moment(data.get('end_date')).diff(moment(data.get('start_date')), 'days') +
    1 +
    ' days';
  return trackingData;
};

export default {
  initializeTracking,
  identify,
  alias,
  updateProfile,
  track,
  trackLink,
  reset,
  getChallengeTrackingData,
  removeSuperProperty,
};
