import { RSAA } from 'redux-api-middleware';
import { addLocaleData } from 'react-intl';
import config from 'config';

import {
  LOCALE_UPDATE,
  APPLANGA_LOCALE_REQUEST,
  APPLANGA_LOCALE_SUCCESS,
  APPLANGA_LOCALE_FAILURE,
  FETCH_DEFAULT_MESSAGES_FAILURE,
  FETCH_DEFAULT_MESSAGES_REQUEST,
  FETCH_DEFAULT_MESSAGES_SUCCESS,
} from '../constants/actionTypes/locale';

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const defaultLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-GB';

const langWithoutRegionCode = language =>
  language.toLowerCase().split(/[_-]+/)[0];

const changeLocale = (locale = defaultLanguage) => dispatch => {
  const lang = langWithoutRegionCode(locale || defaultLanguage);

  return Promise.all([
    import(
      /* webpackChunkName: "i18n/locale-data/[request]" */ `react-intl/locale-data/${lang}`
    ),
    dispatch(fetchApplangaLocalization(lang)), // Use this because we need to depend on applanga as our single source of truth.
  ])
    .then(([localeData, messages]) => {
      // TODO: Look into having a selector for this moving forward.
      const messagesData = messages.payload.data[lang].main.entries;
      addLocaleData(localeData);
      return dispatch(updateLocale(lang, messagesData));
    })
    .catch(err => {
      console.log('Failed to load locale: ' + locale);
      console.error(err);
    });
};

const updateLocale = (locale, messages = {}) => dispatch => {
  const lang = langWithoutRegionCode(locale);

  return import(
    /* webpackChunkName: "i18n/locale-data/[request]" */ `react-intl/locale-data/${lang}`
  )
    .then(([localeData]) => {
      addLocaleData(localeData);
      return dispatch(updateLocaleAction(lang, messages));
    })
    .catch(err => {
      console.log('Failed to load locale: ' + locale);
      console.error(err);
    });
};

const fetchApplangaLocalization = locale => {
  locale = locale || defaultLanguage;
  const lang = langWithoutRegionCode(locale);
  return {
    [RSAA]: {
      types: [
        APPLANGA_LOCALE_REQUEST,
        {
          type: APPLANGA_LOCALE_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && ~contentType.indexOf('json')) {
              return res.json().then(json => {
                json.locale = locale;
                json.lang = lang;
                return json;
              });
            }
          },
        },
        APPLANGA_LOCALE_FAILURE,
      ],
      endpoint: `https://api.applanga.com/v1/api?app=${config.applanga.app}&requestedLanguages=["${lang}"]`,
      method: 'GET',
      headers: {
        'X-Agent': null,
        Authorization: `Bearer ${config.applanga.token}`,
      },
    },
  };
};

const fetchDefaultMessages = () => {
  const locale = defaultLanguage;
  const lang = langWithoutRegionCode(defaultLanguage);
  return {
    [RSAA]: {
      types: [
        FETCH_DEFAULT_MESSAGES_REQUEST,
        {
          type: FETCH_DEFAULT_MESSAGES_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && ~contentType.indexOf('json')) {
              return res.json().then(json => {
                json.locale = locale;
                json.lang = lang;
                return json;
              });
            }
          },
        },
        FETCH_DEFAULT_MESSAGES_FAILURE,
      ],
      endpoint: `https://api.applanga.com/v1/api?app=${config.applanga.app}&requestedLanguages=["${lang}"]`,
      method: 'GET',
      headers: {
        'X-Agent': null,
        Authorization: `Bearer ${config.applanga.token}`,
      },
    },
  };
};

const updateLocaleAction = (locale, messages = {}) => ({
  type: LOCALE_UPDATE,
  payload: { locale, messages },
});

export {
  changeLocale,
  updateLocale,
  fetchApplangaLocalization,
  fetchDefaultMessages,
};
